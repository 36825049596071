import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';

import * as styled from '../../assets/css/CustomerPortalContentWrapper';
import DocumentsListContainer from '../../assets/css/Knowledge/DocumentListContainer';
import DocumentsListHeader from '../../assets/css/Knowledge/DocumentsListHeader';
import Main from '../../assets/css/Knowledge/Main';
import SearchTag from '../../assets/css/Knowledge/SearchTag';
import SearchTagContainer from '../../assets/css/Knowledge/SearchTagContainer';
import Title from '../../assets/css/Knowledge/Title';
import { axiosGet } from '../../client/axios';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
// Layout and Helper Components
import Container from '../../components/CustomerPortal-New-Container';
import CustomerPortalSearchInput from '../../components/CustomerPortal-SearchInput';
import CustomerPortalSlider from '../../components/CustomerPortal-Slider';
import CustomerPortalKBSearchResults from '../../components/knowledge/KB-SearchResults';
// Text
import CustomerPortalKBSectionCard from '../../components/knowledge/KB-SectionCard';
import NotificationBanner from '../../components/Notification-Banner';
import KB_ACTION from '../../constants/kbActions.constants';
// Constants
import type { Locale } from '../../constants/localization.constants';
import {
  KB_ASSETS_URL,
  KB_CATEGORIES_URL,
} from '../../constants/network.constants';
import { KNOWLEDGE } from '../../constants/telemetry.constants';
import { useAuth } from '../../contexts/auth';
import type { IDataObject } from '../../interfaces/dataObject.interface';
import type { IKBSearchState } from '../../interfaces/knowledgeBase.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Util Functions
import {
  handleDeleteKBFormSubmit,
  handleSearchCategoryFilterClick,
  handleSearchPaginateClick,
  handleSearchSortClick,
  KBSearchParamBuilder,
  renderKBCard,
  useKBFetcher,
} from '../../lib/knowledgebaseDocument.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import {
  kbDataReducer,
  kbSearchReducer,
} from '../../reducers/kb_reducers';
import { StoreContext } from '../../store';
/** Styling */
import Hero from './../../assets/css/Knowledge/Hero';

/* This is the page component for Knowledge Base.
  Component consumed in route level
  Renders all the layout for all things knowledge base
*/
const CustomerPortalPageKnowledge = (props: any) => {
  const {
    t, i18n,
  } = useTranslation('common');

  const language = i18n.language as Locale;

  /* Default constants */
  // From sitecore
  const initialKbDataState = {
    error: undefined,
    isLoading: true,
    data: [],
  };

  // Search variables
  const docsPerPage = 7;
  const searchParam = new URLSearchParams(window.location.search);
  const initialSearchState: IKBSearchState = {
    keyword: searchParam.get('q') || '',
    keywordInputField: searchParam.get('q') || '',
    isSearching: searchParam.get('q') ? true : false,
    isCompleteSearching: false,
    filterCategories: [],
    kbDocuments: [],
    currentPage: parseInt(searchParam.get('page') || '1', 10),
    kbTotalDocs: 0,
    kbTotalPages: 0,
    sortBy: searchParam.get('s') || 'matched_keywords',
    sortDirection: parseInt(searchParam.get('d') || '1', 10),
    skipResult:
      parseInt(searchParam.get('page') || '1', 10) === 1
        ? 0
        : (parseInt(searchParam.get('page') || '1', 10) - 1) * docsPerPage,
    filterParam: searchParam.get('c')
      ? new Set([ ...(searchParam.get('c') as any).split(',') ])
      : new Set<string>(),
    filterSubcategoriesParam: new Set<string>(),
  };

  /* Constants */
  const canEditKB = UserPermissionsHelper.isEditKBAllowed();

  /* State */
  const [ searchPaginationPage, setSearchPaginationPage ] = useState(
    initialSearchState.currentPage || 1
  );
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();

  const [ kbCategories, dispatchKbCategories ]: [any, any] = useReducer(
    kbDataReducer,
    initialKbDataState
  );
  const [ latestKbDocs, dispatchKbLatestDocs ]: [any, any] = useReducer(
    kbDataReducer,
    initialKbDataState
  );
  const [ featuredKbDocs, dispatchKbFeaturedDocs ]: [any, any] = useReducer(
    kbDataReducer,
    initialKbDataState
  );
  const [ searchState, setSearchURL, dispatchSearchAction ] = useKBFetcher(
    ``,
    docsPerPage,
    kbSearchReducer,
    initialSearchState
  );
  const [ notificationBannerMessage, setNotificationBannerMessage ] = useState({
    type: '',
    message: '',
  });

  useTrackPageViewEvent(KNOWLEDGE);

  // Category
  const categoriesSliderSettings = {
    dots: true,
    infinite: kbCategories.data.length > 4 ? true : false,
    speed: 500,
    variableWidth: true,
    arrows: true,
    slidesToScroll: 1,
    slidesToShow: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 376,
        settings: {
          centerMode: false,
          infinite: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 481,
        settings: {
          centerMode: false,
          infinite: true,
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          centerMode: false,
          infinite: kbCategories.data.length > 4 ? true : false,
          variableWidth: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          centerMode: false,
          infinite: kbCategories.data.length > 4 ? true : false,
          variableWidth: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  /* Lifecycle */
  // On initial load
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Knowledge');
    if (searchState.keyword) {
      dispatchSearchAction({
        type: KB_ACTION.FETCH_INIT,
        payload: { keyword: searchState.keyword },
      });

      const keywords = searchState.keyword.replace(/[*#]/g, '');

      const queryParam = new KBSearchParamBuilder('KB_Documents')
        .setAction('search')
        .setKeyword(keywords.split(' '))
        .setSortBy(searchState.sortBy)
        .setSortDirection(searchState.sortDirection)
        .setTop(docsPerPage)
        .setSkip(searchState.skipResult)
        .setFilterCategories(searchState.filterParam)
        .setShowDescription(true)
        .setFilterTypeCount('category')
        .build();

      setSearchURL(`${KB_ASSETS_URL}${queryParam.toString()}`);
    }
  }, []);

  // When user navigates back to home page on click
  useEffect(() => {
    if (props.location.search === '') {
      // Function to load all categories
      const fetchCategoriesPromise = async () => axiosGet(
        KB_CATEGORIES_URL,
        state.companyId,
        await getAccessToken(),
        { params: { category_type: 1 } }
      ).catch(error => error);

      const fetchLatestDocumentsPromise = async () => axiosGet(
        KB_ASSETS_URL,
        state.companyId,
        await getAccessToken(),
        { params: { action: 'latest' } }
      );

      // Function to get featured knowledge base documents
      const fetchFeaturedDocumentsPromise = async () => axiosGet(
        KB_ASSETS_URL,
        state.companyId,
        await getAccessToken(),
        { params: { action: 'featured' } }
      );

      (async () => {
        try {
          const [
            categoriesRes,
            latestDocsRes,
            featuredDocsRes,
          ] = await axios.all([
            fetchCategoriesPromise(),
            fetchLatestDocumentsPromise(),
            fetchFeaturedDocumentsPromise(),
          ]);

          if (categoriesRes.status === 200 && categoriesRes.data.data) {
            dispatchKbCategories({
              type: KB_ACTION.FETCH_SUCCESS,
              data: categoriesRes.data.data,
            });
          } else if (categoriesRes instanceof Error) {
            dispatchKbCategories({
              type: KB_ACTION.FETCH_FAIL,
              error: `Unable to fetch knowledge base categories: ${categoriesRes.message}`,
            });
          } else {
            dispatchKbCategories({
              type: KB_ACTION.FETCH_FAIL,
              error: `Unable to resolve categories`,
            });
          }

          if (latestDocsRes.status === 200 && latestDocsRes.data.data) {
            dispatchKbLatestDocs({
              type: KB_ACTION.FETCH_SUCCESS,
              data: latestDocsRes.data.data,
            });
          } else if (latestDocsRes instanceof Error) {
            dispatchKbLatestDocs({
              type: KB_ACTION.FETCH_FAIL,
              error: `Unable to fetch latest documents: ${latestDocsRes.message}`,
            });
          } else {
            dispatchKbCategories({
              type: KB_ACTION.FETCH_FAIL,
              error: `Unable to resolve latest documents`,
            });
          }

          if (featuredDocsRes.status === 200 && featuredDocsRes.data.data) {
            dispatchKbFeaturedDocs({
              type: KB_ACTION.FETCH_SUCCESS,
              data: featuredDocsRes.data.data,
            });
          } else if (featuredDocsRes instanceof Error) {
            dispatchKbFeaturedDocs({
              type: KB_ACTION.FETCH_FAIL,
              error: `Unable to fetch featured documents: ${featuredDocsRes.message}.`,
            });
          } else {
            dispatchKbFeaturedDocs({
              type: KB_ACTION.FETCH_FAIL,
              error: `Unable to resolve featured documents.`,
            });
          }
        } catch (e) {
          console.log(e.toString());
        }
      })();

      // Reset these two
      setSearchURL('');
      dispatchSearchAction({ type: KB_ACTION.KEYWORD_EMPTY });
    }
  }, [ props.location.search ]);

  /* Event Handlers */
  // Form submit for search
  const handleFormSubmit = (forcedKeyword?: string) => {
    // Function is reused, so choose between looking in state if passed by child input field or if called manually
    const keyword = forcedKeyword || searchState.keywordInputField;
    const keywords = keyword.replace(/[*#]/g, '');
    if (!keywords) {
      dispatchSearchAction({ type: KB_ACTION.KEYWORD_EMPTY });
      setSearchURL('');
      props.history.push({ search: '' });
      return;
    }
    if (keywords === searchState.keyword) {
      return;
    }

    // Update state now searching
    dispatchSearchAction({
      type: KB_ACTION.FETCH_INIT,
      payload: { keyword: keywords },
    });

    const queryParam = new KBSearchParamBuilder('KB_Documents')
      .setAction('search')
      .setKeyword(keywords.split(' '))
      .setSortBy(searchState.sortBy)
      .setSortDirection(searchState.sortDirection)
      .setTop(docsPerPage)
      .setShowDescription(true)
      .setFilterCategories(searchState.filterParam)
      .setFilterTypeCount('category')
      .build();

    // Update state
    setSearchURL(`${KB_ASSETS_URL}${queryParam.toString()}`);

    const searchParams = new URLSearchParams({
      q: keywords,
      page: '1',
      s: searchState.sortBy,
      d: searchState.sortDirection,
    });

    if (searchState.filterParam?.size > 0) {
      for (const filter of searchState.filterParam) {
        searchParams.append('c', filter);
      }
    }

    const searchParamsStr = searchParams.toString();

    // Update url params
    if (initialSearchState.keyword) {
      // If this is a search after initial search, replace history so back button still goes back to knowledge base home
      props.history.replace({ search: searchParamsStr });
    } else {
      // If this is an initial search, push to web history as normal
      props.history.push({ search: searchParamsStr });
    }
  };

  // Event when a Category on the Filter column is clicked
  const handleCategoryFilterClick = (elemID: string) => {
    handleSearchCategoryFilterClick(
      docsPerPage,
      KB_ASSETS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      elemID,
      'category',
      props.history
    );
  };

  // Event when Search Sort item is clicked
  const handleSearchingSortClick = (
    sortVal: { path: string; direction: number } | null
  ) => {
    handleSearchSortClick(
      docsPerPage,
      KB_ASSETS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      sortVal,
      'category',
      props.history
    );
  };

  // Event for search input on change
  const handleSearchOnChange = (keyword: string) => {
    if (searchState.keywordInputField !== keyword) {
      dispatchSearchAction({
        type: KB_ACTION.KEYWORD_UPDATED,
        payload: { keyword },
      });
    }
  };

  const handleSearchingPaginateClick = (val: number) => {
    handleSearchPaginateClick(
      docsPerPage,
      KB_ASSETS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      val,
      'category',
      props.history
    );
  };

  // When the delete doc form is submitted
  const handleDeleteFormSubmit = async (
    fileId: string,
    categoryName: string,
    subcategoryName: string
  ) => {
    if (!fileId) {
      return;
    }

    try {
      const result: IDataObject = await handleDeleteKBFormSubmit(
        fileId,
        await getAccessToken()
      );
      if (result && result.statusCode === 204) {
        setNotificationBannerMessage({
          type: 'success',
          message: 'Document has been deleted.',
        });

        dispatchSearchAction({
          type: KB_ACTION.DOCUMENT_DELETED,
          payload: {
            id: fileId,
            categoryName,
          },
        });
      }
    } catch (e) {
      console.log(e.toString());
      setNotificationBannerMessage({
        type: 'error',
        message: e.message,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {t('knowledge_base_page_title', 'Knowledge Base | Customer Portal')}
        </title>
      </Helmet>
      {notificationBannerMessage.type && notificationBannerMessage.message && (
        <NotificationBanner
          type={notificationBannerMessage.type}
          message={notificationBannerMessage.message}
        />
      )}

      <styled.SectionContentWrapper className="CustomerPortalKnowledgeBase">
        <Hero>
          <Container cssClass="CustomerPortalKnowledgeBase__HeadingContainer">
            <Title>
              {t('knowledge_base_hero_title', 'Looking for something?')}
            </Title>
            <div className="CustomerPortalPageKnowledgeBase__SearchContainer">
              <CustomerPortalSearchInput
                placeholderText={t(
                  'search_here',
                  'Search Here'
                )}
                handleSubmit={handleFormSubmit}
                keyword={searchState.keywordInputField}
                handleOnChange={handleSearchOnChange}
              />
              {/* Hide buttons if is searching */}
              {!searchState.isSearching && (
                <SearchTagContainer>
                  <SearchTag
                    onClick={() => {
                      handleFormSubmit('Upgrade');
                    }}
                    data-testid="upgrade"
                  >
                    {t('knowledge_base_search_tag_upgrade', 'Upgrade')}
                  </SearchTag>
                  <SearchTag
                    onClick={() => {
                      handleFormSubmit('Security');
                    }}
                    data-testid="security"
                  >
                    {t('knowledge_base_search_tag_security', 'Security')}
                  </SearchTag>
                  <SearchTag
                    onClick={() => {
                      handleFormSubmit('Integration');
                    }}
                    data-testid="integration"
                  >
                    {t('knowledge_base_search_tag_integration', 'Integration')}
                  </SearchTag>
                  <SearchTag
                    onClick={() => {
                      handleFormSubmit('Certification');
                    }}
                    data-testid="certification"
                  >
                    {t(
                      'knowledge_base_search_tag_certification',
                      'Certification'
                    )}
                  </SearchTag>
                  <SearchTag
                    onClick={() => {
                      handleFormSubmit('Compliance');
                    }}
                    data-testid="compliance"
                  >
                    {t('knowledge_base_search_tag_compliance', 'Compliance')}
                  </SearchTag>
                </SearchTagContainer>
              )}
            </div>
          </Container>
        </Hero>

        {!searchState.isSearching && (
          <Main>
            <Container cssClass="CustomerPortalPageKnowledgeBase__CategoriesContainer">
              {/* Categories */}
              <CustomerPortalSlider settings={categoriesSliderSettings}>
                {kbCategories.data.length &&
                  kbCategories.data.map((categoryObj: IDataObject) => (
                    <div
                      key={categoryObj.languages.en.name}
                      data-testid="KnowledgeBase__Categories"
                    >
                      <CustomerPortalKBSectionCard
                        categoryHeadingText={categoryObj.languages[language]?.name}
                        linkHref={`/knowledge-base/category/${categoryObj.languages.en.name}`}
                        linkText={t(
                          'knowledge_base_section_card_link_text',
                          'Explore Section'
                        )}
                      />
                    </div>
                  ))}
              </CustomerPortalSlider>
              {/* On Loading */}
              {kbCategories.isLoading && <CustomerPortalLoader />}
              {/* On Error */}
              {kbCategories.error && (
                <p className="CustomerPortalP CustomerPortalP--Margin-Bottom">
                  {kbCategories.error}
                </p>
              )}
            </Container>

            {/* List of documents */}
            <Container cssClass="CustomerPortalKBDocumentsContainer">
              {/* Latest Documents */}
              <DocumentsListContainer>
                <DocumentsListHeader>
                  {t(
                    'knowledge_base_latest_knowledge_title',
                    'Latest Knowledge'
                  )}
                </DocumentsListHeader>
                {/* Knowledge Base cards */}
                {latestKbDocs.data.length > 0 &&
                  latestKbDocs.data.map((kbDocument: IDataObject) =>
                    renderKBCard({
                      ...kbDocument,
                      dataTestId: 'KnowledgeBase__LatestDoc',
                    })
                  )}
                {/* On Loading */}
                {latestKbDocs.isLoading && <CustomerPortalLoader />}
                {/* On error */}
                {latestKbDocs.error && (
                  <p className="CustomerPortalP CustomerPortalP--Margin-Bottom">
                    {latestKbDocs.error}
                  </p>
                )}
              </DocumentsListContainer>

              {/* Featured Documents */}
              <DocumentsListContainer>
                <DocumentsListHeader>
                  {t(
                    'knowledge_base_featured_knowledge_title',
                    'Featured Knowledge'
                  )}
                </DocumentsListHeader>
                {/* Knowledge Base cards */}
                {featuredKbDocs.data.length > 0 &&
                  featuredKbDocs.data.map((kbDocument: IDataObject) =>
                    renderKBCard({
                      ...kbDocument,
                      dataTestId: 'KnowledgeBase__FeaturedDoc',
                    })
                  )}
                {/* On Loading */}
                {featuredKbDocs.isLoading && <CustomerPortalLoader />}
                {/* On Error */}
                {featuredKbDocs.error && (
                  <p className="CustomerPortalP CustomerPortalP--Margin-Bottom">
                    {featuredKbDocs.error}
                  </p>
                )}
              </DocumentsListContainer>
            </Container>
          </Main>
        )}

        {searchState.isSearching && !searchState.isCompleteSearching && (
          <Container>
            <CustomerPortalLoader />
          </Container>
        )}

        {searchState.isSearching && searchState.isCompleteSearching && (
          <CustomerPortalKBSearchResults
            searchState={searchState}
            handleCategoryFilterClick={handleCategoryFilterClick}
            handleSearchSortClick={handleSearchingSortClick}
            renderKBCard={renderKBCard}
            searchDocsPerPage={docsPerPage}
            searchPaginationPage={searchPaginationPage}
            searchNumPagesBeforeEllipses={5}
            handleSearchPaginateClick={handleSearchingPaginateClick}
            uploadModalCategoryName=""
            handleDeleteFormSubmit={
              canEditKB ? handleDeleteFormSubmit : undefined
            }
          />
        )}
      </styled.SectionContentWrapper>
    </>
  ); // End of return
};

export default CustomerPortalPageKnowledge;
