import styled, { css } from 'styled-components';

export const ContentWrapper = css`
  margin-bottom: 155px !important;
  min-height: calc(100vh - 48px);
`;

export const SectionContentWrapper = styled.section`
  ${ContentWrapper}
`;
