import {
  KBDocumentType,
  KBEmbeddedVideoType,
  KBWhiteListedFileExtensions,
  MAIL_TO_LINK_REGEX,
} from '@customer-portal/constants';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import React, {
  createRef,
  useContext,
  useEffect,
  useState,
} from 'react';

// Styles
import * as styles from '../../assets/css/Category/KB-Upload-Modal';
import {
  EMBEDDED_VIDEO_VIMEO_MATCHER_REGEX,
  EXTERNAL_URL_REGEX,
} from '../../constants/knowledge.constants';
import {
  Locale,
  localeToLanguage,
} from '../../constants/localization.constants';
// Constants
import { KB_CATEGORIES_URL } from '../../constants/network.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
// Utils
import {
  isFileAttachmentSupported,
  MimeTypeToExtension,
} from '../../lib/file.utils';
import type { DocumentLanguages } from '../../pages/Category/index';
import { StoreContext } from '../../store';
import Button from '../Button/Button';
import Loader from '../CustomerPortal-Loader';
import Modal from '../Modal';
import Select from '../Select';

type AddAssetModalProps = {
  isUploadModalOpen: boolean;
  categoryName: string;
  handleFormSubmit?: Function;
  availableSubcategoriesList?: any[];
  handleModalClose: () => void;
};

const KB_DOC_TYPE_TO_HEADER_TEXT = {
  [KBDocumentType.FILE]: 'Publish a new file',
  [KBDocumentType.LINK]: 'Publish a new link',
  [KBDocumentType.EMBEDDED_VIDEO]: 'Publish a new embedded video',
};

const getDefaultLanguages = () => Object.values(Locale).reduce(
  (obj: DocumentLanguages, language: keyof typeof Locale) => {
    obj[language] = { active: true };
    return obj;
  },
  {}
);

const AddKBAssetModal = (props: AddAssetModalProps) => {
  const inputFile = createRef<any>();
  const { dispatch } = useContext(StoreContext);
  const { getAccessToken } = useAuth();

  /* State */
  const [ isLoadingFinished, setIsLoadingFinished ] = useState(false);
  const [ uploadType, setUploadType ] = useState<KBDocumentType>(KBDocumentType.FILE);
  const [ file, setFile ] = useState<any>(null);
  const [ subcategory, setSubcategory ] = useState('');
  const [ title, setTitle ] = useState('');
  const [ keywords, setKeywords ] = useState('');
  const [ description, setDescription ] = useState('');
  const [ sortRanking, setSortRanking ] = useState<number | null>(null);
  const [ featured, setFeatured ] = useState(false);
  const [ notification, setNotification ] = useState(false);
  const [ hyperlink, setHyperlink ] = useState<string>('');
  const [ embeddedVideoData, setEmbeddedVideoData ] = useState<{
    raw: string;
    type: KBEmbeddedVideoType;
    id: string;
  }>({
    raw: '',
    type: KBEmbeddedVideoType.UNKNOWN,
    id: '',
  });
  const [ isUploadProgressStart, setIsUploadProgressStart ] = useState(false);
  const [ languages, setLanguages ] = useState<DocumentLanguages>(getDefaultLanguages());
  const [ maxSortRankings, setMaxSortRankings ] = useState<{ [subcategoryName: string]: number }>({});

  // Error & warning messages
  const [ titleError, setTitleError ] = useState('');
  const [ hyperlinkError, setHyperlinkError ] = useState('');
  const [ fileError, setFileError ] = useState('');
  const [ embeddedVideoError, setEmbeddedVideoError ] = useState('');
  const [ embeddedVideoWarning, setEmbeddedVideoWarning ] = useState('');
  const [ languagesError, setLanguagesError ] = useState('');
  const [ subcategoryError, setSubcategoryError ] = useState(
    Array.isArray(props.availableSubcategoriesList) &&
      props.availableSubcategoriesList.length
      ? ''
      : 'Please create a subcategory first in "Manage KB Categories" section under your profile options'
  );
  const [ sortRankingError, setSortRankingError ] = useState('');
  const [ descriptionError, setDescriptionError ] = useState('');

  useEffect(() => {
    const fetchMaxSortRankings = async () => {
      try {
        const fetchResults = await axios.get(
          `${KB_CATEGORIES_URL}/${props.categoryName}/max_sort_rankings`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              'x-auth-type': getAuthType(),
            },
          }
        );

        if (fetchResults.status === 200 && fetchResults.data.data) {
          setMaxSortRankings(fetchResults.data.data);
        }
      } catch (e) {
        console.log(`Error loading sort rankings: ${e.toString()}`);
        setSortRankingError(`Error loading sort rankings: ${e.toString()}`);
      }
    };

    const fetchData = async () => {
      await fetchMaxSortRankings();
      setIsLoadingFinished(true);
    };

    fetchData();
  }, [ props.categoryName ]);

  /* Event */
  // Handle overall form submit
  const handleFormSubmit = async (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    // If not all fields valid, then return false
    if (!handleSubmitValidate() || !props.handleFormSubmit) {
      return;
    }

    // Set the loading spinner
    setIsUploadProgressStart(true);

    if (uploadType === KBDocumentType.LINK) {
      sendToPropsHandle({
        title,
        description,
        hyperlink,
        category: props.categoryName,
        subcategory,
        keywords,
        type: uploadType,
        languages,
        sort_ranking: sortRanking,
        featured,
        notification,
      });
      return;
    }

    if (uploadType === KBDocumentType.EMBEDDED_VIDEO) {
      sendToPropsHandle({
        title,
        description,
        category: props.categoryName,
        subcategory,
        keywords,
        type: uploadType,
        languages,
        sort_ranking: sortRanking,
        featured,
        notification,
        embedded_video_raw: embeddedVideoData.raw,
        embedded_video_type: embeddedVideoData.type,
        embedded_video_id: embeddedVideoData.id,
      });
      return;
    }

    // If the file is a video, get duration
    let videoDuration;

    if (
      MimeTypeToExtension.get(file.type) &&
      (MimeTypeToExtension.get(file.type) as any).internalFileType === 'video'
    ) {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = async () => {
        window.URL.revokeObjectURL(video.src); // Destroy
        videoDuration = Math.ceil(video.duration);
        if (props.handleFormSubmit) {
          sendToPropsHandle({
            file,
            category: props.categoryName,
            subcategory,
            specs: { duration: videoDuration },
            title,
            keywords,
            description,
            type: uploadType,
            languages,
            sort_ranking: sortRanking,
            featured,
            notification,
          });
        }
      };
      video.src = URL.createObjectURL(file);
    } else {
      // If it's file but not video
      sendToPropsHandle({
        file,
        category: props.categoryName,
        subcategory,
        title,
        keywords,
        description,
        type: uploadType,
        language: Locale.en,
        languages,
        sort_ranking: sortRanking,
        featured,
        notification,
      });
    }
  };

  const sendToPropsHandle = async (body: any) => {
    if (!props.handleFormSubmit) {
      return;
    }

    try {
      await props.handleFormSubmit(body);
    } catch (e) {
      console.log('Response returned but error');
      console.log(e.toString());
    } finally {
      handleModalClose();
    }
  };

  const handleSubmitValidate = () => {
    // Required fields
    if (uploadType === KBDocumentType.FILE && !file) {
      setFileError('Field is mandatory');
      return false;
    } else if (uploadType === KBDocumentType.LINK && !hyperlink) {
      setHyperlinkError('Field is mandatory');
      return false;
    } else if (uploadType === KBDocumentType.EMBEDDED_VIDEO && !embeddedVideoData.raw) {
      setEmbeddedVideoError('Field is mandatory');
      setEmbeddedVideoWarning('');
      return false;
    } else if (!areAnyLanguagesSelected()) {
      setLanguagesError('Please select at least 1 language');
      return false;
    } else if (
      !subcategory &&
      Array.isArray(props.availableSubcategoriesList) &&
      props.availableSubcategoriesList.length === 0
    ) {
      setSubcategoryError(
        'Please create a subcategory first in "Manage KB Categories" section under your profile options'
      );
      return false;
    } else if (!subcategory) {
      setSubcategoryError('Field is mandatory');
      return false;
    } else if (!title) {
      setTitleError('Field is mandatory');
      return false;
    }
    return true;
  };

  const handleSubcategoryOptionClick = (e: any) => {
    if (e.target?.getAttribute('value')) {
      setSubcategory(e.target.getAttribute('value'));
      setSortRanking(null);
    }
  };

  const handleModalClose = () => {
    // Reset everything
    setUploadType(KBDocumentType.FILE);
    setFile(null);
    setHyperlink('');
    setEmbeddedVideoData({
      raw: '',
      type: KBEmbeddedVideoType.UNKNOWN,
      id: '',
    });
    setNotification(false);
    setFeatured(false);
    setTitle('');
    setKeywords('');
    setDescription('');
    setSortRanking(null);
    setTitleError('');
    setFileError('');
    setHyperlinkError('');
    setEmbeddedVideoError('');
    setEmbeddedVideoWarning('');
    setLanguagesError('');
    setSubcategoryError('');
    setSortRankingError('');
    setDescriptionError('');
    setIsUploadProgressStart(false);
    setLanguages(getDefaultLanguages());
    props.handleModalClose();
  };

  const handleBrowseFileClick = () => {
    if (inputFile.current && !isUploadProgressStart) {
      inputFile.current.click();
    }
  };

  const handleInputFileChange = (e: any) => {
    if (!e?.target?.files) {
      return;
    }
    const file = e.target.files[0];

    if (!isFileAttachmentSupported(file, KBWhiteListedFileExtensions)) {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: `File [${file.name}] is not allowed!`,
      });
    } else {
      setFile(e.target.files[0]);
    }
  };

  const handleHeaderChangeClick = (headerType: KBDocumentType) => {
    // Don't allow any interaction when uploading process has begun
    if (isUploadProgressStart) {
      return;
    }

    setUploadType(headerType);

    if (headerType !== KBDocumentType.FILE) {
      setFile(null);
    }

    if (headerType !== KBDocumentType.LINK) {
      setHyperlink('');
    }

    if (headerType !== KBDocumentType.EMBEDDED_VIDEO) {
      setEmbeddedVideoData({
        raw: '',
        type: KBEmbeddedVideoType.UNKNOWN,
        id: '',
      });
    }

    setHyperlinkError('');
    setFileError('');
    setEmbeddedVideoError('');
    setEmbeddedVideoWarning('');
  };

  // Validation
  const handleTitleBlur = () => {
    if (!title) {
      setTitleError('Field is mandatory');
    } else if (title.length <= 60) {
      setTitleError('');
    }
  };

  const handleHyperlinkBlur = () => {
    if (!hyperlink) {
      setHyperlinkError('Field is mandatory');
    } else if (!EXTERNAL_URL_REGEX.test(hyperlink) && !MAIL_TO_LINK_REGEX.test(hyperlink)) {
      setHyperlinkError('Field is neither a valid URL nor valid mailto link');
    } else {
      setHyperlinkError('');
    }
  };

  const getEmbeddedVideoType = (text: string): KBEmbeddedVideoType => {
    if (EMBEDDED_VIDEO_VIMEO_MATCHER_REGEX.test(text)) {
      return KBEmbeddedVideoType.VIMEO;
    }

    return KBEmbeddedVideoType.UNKNOWN;
  };

  const getEmbeddedVideoId = (text: string, videoType: KBEmbeddedVideoType): string => {
    if (videoType === KBEmbeddedVideoType.VIMEO) {
      const match = text.match(EMBEDDED_VIDEO_VIMEO_MATCHER_REGEX);
      return match ? match[1] : '';
    }

    return '';
  };

  const handleEmbeddedVideoBlur = () => {
    if (!embeddedVideoData.raw) {
      setEmbeddedVideoError('Field is mandatory');
      setEmbeddedVideoWarning('');
      return;
    }

    const videoType = getEmbeddedVideoType(embeddedVideoData.raw);
    const videoId = getEmbeddedVideoId(embeddedVideoData.raw, videoType);
    if (videoType === KBEmbeddedVideoType.UNKNOWN || !videoId) {
      setEmbeddedVideoError('Could not parse video type or video id from embedded video');
      setEmbeddedVideoWarning('');
      return;
    }

    setEmbeddedVideoError('');
    setEmbeddedVideoData({
      raw: embeddedVideoData.raw,
      type: videoType,
      id: videoId,
    });
    setEmbeddedVideoWarning(`Video type is ${videoType} and video id is ${videoId}`);
  };

  // Validate
  const handleTitleChange = (e: any) => {
    if (e && e.target.value.length > 60) {
      setTitle(e.target.value.slice(0, 60));
      setTitleError('Maximum characters allowed is 60 characters');
      return;
    }
    setTitleError('');
    setTitle(e.target.value);
  };

  // Description
  const handleDescriptionBlur = (e: any) => {
    if (description.length <= 255) {
      setDescriptionError('');
    }
  };
  const handleDescriptionChange = (e: any) => {
    if (e && e.target.value.length > 255) {
      setDescription(e.target.value.slice(0, 255));
      setDescriptionError('Maximum characters allowed is 255 characters');
      return;
    }
    setDescriptionError('');
    setDescription(e.target.value);
  };

  const handleToggleLanguage = (language: string) => {
    const newLanguages = {
      ...languages,
      [language]: { active: !languages[language].active },
    };
    if (areAnyLanguagesSelected(newLanguages)) {
      setLanguagesError('');
    }
    setLanguages(newLanguages);
  };

  const handleToggleSelectAllLanguages = () => {
    const set = new Set(Object.values(languages).map(({ active }) => active));
    const newLanguages = Object.keys(languages).reduce((obj: DocumentLanguages, language: string) => {
      const isActive: boolean = set.size === 1 ? !languages[language].active : true;
      obj[language] = { active: isActive };
      return obj;
    }, {});
    if (areAnyLanguagesSelected(newLanguages)) {
      setLanguagesError('');
    }
    setLanguages(newLanguages);
  };

  const areAnyLanguagesSelected = (newLanguages?: DocumentLanguages) => new Set(
    Object.values(newLanguages ?? languages).map(({ active }) => active)
  ).has(true);

  const handleSortRankingOptionClick = (e: any) => {
    if (e.target) {
      const value = e.target.getAttribute('value');
      setSortRanking(value === 'No ranking' ? null : value);
    }
  };

  const getSortRankingDropdownOptions = () => {
    const totalNonZeroOptions: number = maxSortRankings[subcategory] ?? 0;

    return [
      {
        label: 'No ranking',
        value: 'No ranking',
        selected: false,
      },
      ...(!subcategory ? [] : Array.from(Array(totalNonZeroOptions).keys()).map(
        (sort_ranking: number) => ({
          label: sort_ranking + 1,
          value: sort_ranking + 1,
          selected: false,
        })
      )),
    ];
  };

  return (
    <Modal
      modalTitle="Add New Resource"
      modalHeading="Add New Resource"
      modalDescription="Modal for adding a new resource to knowledge base"
      open={props.isUploadModalOpen}
      handleClose={handleModalClose}
      innerClasses='KB__Resource-Modal'
    >
      {!isLoadingFinished && <Loader />}
      {isLoadingFinished && (
        <styles.FormWrapper onSubmit={handleFormSubmit}>
          <div className="UploadForm__TypeSelection">
            <styles.HeaderContainer>
              {
                Object.entries(KB_DOC_TYPE_TO_HEADER_TEXT).map(([ type, text ]) => (
                  <span
                    className={`
                    UploadForm__HeaderText ${uploadType === type ? `UploadForm__HeaderText--Active` : ''}
                  `}
                    onClick={() => handleHeaderChangeClick(type as KBDocumentType)}
                    data-testid={`upload-${type}-button`}
                  >
                    {text}
                  </span>
                ))
              }
            </styles.HeaderContainer>

            {uploadType === KBDocumentType.FILE && (
              <styles.FileInputContainer onClick={handleBrowseFileClick}>
                <p className="FileInputContainer__Text">
                  {file ? file.name : 'Browse file to upload'}
                </p>

                <input
                  type="file"
                  className="FileInputContainer__InputFile"
                  onChange={handleInputFileChange}
                  ref={inputFile}
                />
                {fileError && (
                  <styles.ErrorMessage className="UploadForm__FileError">
                    {fileError}
                  </styles.ErrorMessage>
                )}
              </styles.FileInputContainer>
            )}

            {uploadType === KBDocumentType.LINK && (
              <styles.HyperlinkInputContainer>
                <styles.FormControlWrapper className="UploadForm__FormControl">
                  <styles.TextInput
                    variant={'outlined' as any}
                    className="UploadForm__Input UploadForm__Hyperlink"
                    value={hyperlink}
                    onChange={e => {
                      setHyperlink(e.target.value);
                    }}
                    onBlur={handleHyperlinkBlur}
                    type="url"
                    placeholder="Enter a hyperlink"
                    helperText={hyperlinkError ? hyperlinkError : undefined}
                    disabled={isUploadProgressStart}
                    data-testid="upload-link-input"
                  />
                </styles.FormControlWrapper>
              </styles.HyperlinkInputContainer>
            )}

            {uploadType === KBDocumentType.EMBEDDED_VIDEO && (
              <styles.EmbeddedVideoInputContainer>
                <styles.FormControlWrapper className="UploadForm__FormControl">
                  <styles.TextInput
                    variant={'outlined' as any}
                    className="UploadForm__Input UploadForm__EmbeddedVideo"
                    value={embeddedVideoData.raw}
                    onChange={e => {
                      setEmbeddedVideoData({
                        ...embeddedVideoData,
                        raw: e.target.value,
                      });
                    }}
                    onBlur={handleEmbeddedVideoBlur}
                    type="text"
                    placeholder="Enter an embedded video"
                    helperText={embeddedVideoError ? embeddedVideoError : undefined}
                    data-testid="upload-embedded-video-input"
                  />
                  {embeddedVideoWarning && (
                    <div className='WarningMessage'>
                      {embeddedVideoWarning}
                    </div>
                  )}
                </styles.FormControlWrapper>
              </styles.EmbeddedVideoInputContainer>
            )}
          </div>

          <section className="UploadForm__ContentSection">
            <styles.FormControlWrapper className="">
              <div>
                <FormControlLabel
                  className="KnowledgeCategories__Checkbox"
                  control={
                    <Checkbox
                      checked={!Object.values(languages).map(({ active }) => active)
                        .includes(false)}
                      onChange={handleToggleSelectAllLanguages}
                      name="Select All"
                    />
                  }
                  label="Select All"
                />
                {Object.entries(languages).map(([ language, { active } ]: [string, { active: boolean }], index: number) => (
                  <FormControlLabel
                    key={index}
                    className="KnowledgeCategories__Checkbox"
                    control={
                      <Checkbox
                        checked={active}
                        onChange={(_e) => handleToggleLanguage(language)}
                        name={Locale[language as keyof typeof Locale]}
                      />
                    }
                    label={`${localeToLanguage.get(Locale[language as keyof typeof Locale])} (${language})`}
                  />
                ))}
              </div>
              <div className="ErrorMessage">
                {languagesError}
              </div>
            </styles.FormControlWrapper>

            {/* Subcategory */}
            <styles.FormControlWrapper className="UploadForm__FormControl">
              <span className="UploadForm__SubCategoryLabel">
                Sub-Category (Required)
              </span>
              <Select
                className={
                  isUploadProgressStart
                    ? 'UploadForm__Select--Disabled'
                    : undefined
                }
                onChange={handleSubcategoryOptionClick}
                selected={{
                  label: subcategory,
                  value: subcategory,
                }}
                id="subcategory_select"
                options={
                  (props.availableSubcategoriesList?.length
                    ? props.availableSubcategoriesList
                    : []
                  )
                    .map((availableSubcategory: any) => ({
                      label: availableSubcategory.languages[Locale.en].name,
                      value: availableSubcategory.languages[Locale.en].name,
                      selected: false,
                    }))
                }
                placeholderText="Choose subcategory"
                helperText={subcategoryError ? subcategoryError : undefined}
              />
            </styles.FormControlWrapper>

            {/* Sort Ranking */}
            <styles.FormControlWrapper className="UploadForm__FormControl">
              <span className="UploadForm__SortRankingLabel">
                Sub-Category Sort Ranking (Optional)
              </span>
              <Select
                className="UploadForm__SortRankingDropdown"
                onChange={handleSortRankingOptionClick}
                selected={{
                  label: sortRanking ? sortRanking : 'No ranking',
                  value: sortRanking ? sortRanking : 'No ranking',
                }}
                id="sort_ranking_select"
                options={getSortRankingDropdownOptions()}
                placeholderText="Choose sort ranking"
                helperText={sortRankingError ? sortRankingError : undefined}
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl">
              <styles.TextInput
                variant={'outlined' as any}
                className="UploadForm__Input"
                value={title}
                onChange={handleTitleChange}
                onBlur={handleTitleBlur}
                placeholder="Add a title..."
                helperText={titleError ? titleError : undefined}
                disabled={isUploadProgressStart}
                data-testid="upload-title-input"
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl">
              <styles.TextInput
                variant={'outlined' as any}
                className="UploadForm__Input"
                value={keywords}
                onChange={e => {
                  setKeywords(e.target.value);
                }}
                placeholder="Add keywords, separated by space"
                disabled={isUploadProgressStart}
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__DescriptionArea">
              <styles.TextInput
                variant={'outlined' as any}
                className="UploadForm__Textarea"
                value={description}
                onChange={handleDescriptionChange}
                onBlur={handleDescriptionBlur}
                rows={2}
                multiline
                placeholder="Add a short description"
                disabled={isUploadProgressStart}
                helperText={descriptionError}
              />
            </styles.FormControlWrapper>

            <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__TwoColumnRow">
              <Switch
                checked={notification}
                onChange={() => {
                  setNotification(!notification);
                }}
                value={notification}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={isUploadProgressStart}
              />
              <styles.SwitchText>
                <h6>Notification</h6>
                <p>
                  By activating this, you will make the document display in the “Notification” icon.
                  <br />
                  This should be used for urgent updates.
                </p>
              </styles.SwitchText>
            </styles.FormControlWrapper>
            <styles.FormControlWrapper className="UploadForm__FormControl UploadForm__TwoColumnRow">
              <Switch
                checked={featured}
                onChange={() => {
                  setFeatured(!featured);
                }}
                value={featured}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={isUploadProgressStart}
              />
              <styles.SwitchText>
                <h6>Featured</h6>
                <p>
                  By activating this you will make the document featured on the
                  knowledge base home page.
                </p>
              </styles.SwitchText>
            </styles.FormControlWrapper>

            <Button
              className="UploadForm__SubmitButton"
              onClick={() => { }}
              isValid={isUploadProgressStart ? false : undefined}
              disabled={isUploadProgressStart}
              text={
                isUploadProgressStart
                  ? `Uploading...`
                  : `Add Resource to ${props.categoryName}`
              }
            />
          </section>
        </styles.FormWrapper>
      )}

    </Modal>
  );
};

export default AddKBAssetModal;
