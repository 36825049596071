import {
  Icon,
  IconButton,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import {
  DesktopDatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import moment from 'moment';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import Button from '../../components/Button/Button';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import Container from '../../components/CustomerPortal-New-Container';
import MultiEmailInput from '../../components/MultiEmailInput';
import SelectWithSearch from '../../components/Select-With-Search';
import TextLabel from '../../components/TextLabel';
import {
  RFQ_REMOVE_1ST_LEVEL_LICENSE,
  RFQ_REMOVE_2ND_LEVEL_LICENSE,
  RFQ_RESET_CART,
  RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../../constants/requestForQuoteActions.constants';
import { RFQ_CHECKOUT } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import type { IDataObject } from '../../interfaces/dataObject.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import {
  getActiveBundles,
  getDeepCopyofProductsForAPI,
  getLicensesGroupedByType,
  isCartProductsInvalid,
  trimSKUName,
} from '../../lib/requestForQuote.utils';
import type { SkuType } from '../../types/requestForQuote.types';
import { isValidDate } from '../../utils/index';
// styles
import * as styles from './../../assets/css/RequestForQuote/Checkout';
// Components
import NoCompanyId from './../../components/CustomerPortal-NoCompanyId';
import { BACKEND_HOST_NAME } from './../../constants/network.constants';
// Constants
import RFQ, {
  AUTOMATION_SUITE,
  CLOUD,
  EXISTING_AUTOMATION_SUITE,
  EXISTING_CLOUD,
  EXISTING_ORCHESTRATOR,
  NEW_ORCHESTRATOR,
  QuoteDuration,
  SERVICE,
  STANDALONE,
} from './../../constants/requestForQuote.constants';
import { UserPermissionsHelper } from './../../lib/UserPermissions';
import { StoreContext } from './../../store';

interface IFormData {
  accountId: string;
  accountName: string;
  requester: string;
  phoneNumber: string;
  duration: null | { label: string; value: number };
  activationDate: string;
  informList: string[];
  notes: string;
  errors: any;
}

const CheckoutPage = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'Checkout';
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ licenses, setLicenses ] = useState<SkuType[]>([]);
  const [ redirectToHome, setRedirectToHome ] = React.useState(false);
  const [ redirectToCatalog, setRedirectToCatalog ] = React.useState(false);
  const [ isNewOrch, setNewOrch ] = React.useState<boolean>(false);
  const [ submitButtonLoading, setSubmitButtonLoading ] = React.useState(false);
  // IsValid checks for duration present if new orch + quantities
  const [ isValid, setIsValid ] = React.useState(false);
  const [ formData, setFormData ] = React.useState<IFormData | any>({
    accountId: state.companyId,
    accountName: state.companyName,
    requester: state.userName,
    phoneNumber: state.phoneNumber,
    duration: null,
    activationDate: new Date().toISOString(),
    informList: [ '' ],
    notes: '',
    errors: {},
  });
  const availableLicensesIds: string[] = getActiveBundles(
    state.companyLicenses?.boonLicense?.licenses || []
  ).map(license => license.licenseCode);

  // Check user permissions
  const isEditLicenseAllowed = UserPermissionsHelper.isEditLicenseAllowed();

  useTrackPageViewEvent(RFQ_CHECKOUT);

  useEffect(() => {
    // Fetch Accounts Data
    const fetchLicencesData = async () => {
      try {
        const licensesRes = await axios.get(
          `${BACKEND_HOST_NAME}/api/v1/rfq/licenses?selection=active_rfqs`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              'x-selected-account': state.companyId,
              'x-auth-type': getAuthType(),
            },
          }
        );

        if (Array.isArray(licensesRes.data.data)) {
          setLicenses(
            licensesRes.data.data.map((returnedSku: SkuType) => ({
              ...returnedSku,
              sku_name: trimSKUName(returnedSku.sku_name),
            }))
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchLicencesData();
    CustomerPortalGoogleAnalyticsPageView('Checkout');
  }, []);

  useEffect(() => {
    if (licenses.length > 0) {
      if (
        Array.isArray(state.rfqCart?.products) &&
        state.rfqCart.products.length > 0
      ) {
        if (
          state.rfqCart.products.length === 1 &&
          state.rfqCart.products[0].type === 'Existing Orchestrator' &&
          state.rfqCart.products[0].products?.length === 0
        ) {
          // If existing orch with no products
          setRedirectToCatalog(true);
        }

        const includesNewOrch = state.rfqCart.products.some(
          (item: IDataObject) => item.type === NEW_ORCHESTRATOR
        );
        setNewOrch(includesNewOrch);

        // Not valid if new orch bc they need to add a duration
        setIsValid(
          validateFormSubmit(
            formData.activationDate,
            formData.duration,
            includesNewOrch
          )
        );
      } else {
        setRedirectToCatalog(true);
      }
    }
  }, [ state.rfqCart, licenses ]);

  const handleDateChange = (date: Date) => {
    const newFormData = { ...formData };
    newFormData.activationDate = isValidDate(date) ? date.toISOString() : '';
    setFormData(newFormData);
    setIsValid(
      validateFormSubmit(newFormData.activationDate, formData.duration)
    );
  };

  const handleEmailsUpdate = (items: string[]) => {
    const newFormData = { ...formData };
    newFormData.informList = items;
    setFormData(newFormData);
  };

  const handleFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newFormData = { ...formData };
    newFormData[event.target.id] = event.target.value;
    setFormData(newFormData);
  };

  /**
   * Call this method on each required event handler
   * @param activationDate
   * @param duration
   */
  const validateFormSubmit = (
    activationDate: string = formData.activationDate,
    duration = formData.duration,
    includesNewOrch = isNewOrch
  ) => {
    let activationDateDate;
    try {
      activationDateDate = new Date(activationDate);
    } catch (e) {
      return false;
    }

    if (
      licenses.length > 0 &&
      isCartProductsInvalid(
        state.rfqCart.products,
        licenses,
        availableLicensesIds
      )
    ) {
      return false;
    } else if (
      !isValidDate(activationDateDate) ||
      moment(activationDate)
        .startOf('day')
        .toDate() <
        moment(new Date())
          .startOf('day')
          .toDate() ||
      moment(activationDate) > moment(new Date('2100-01-01'))
    ) {
      // Activation Date is invalid
      // Less than today
      // Greater than the maximum date
      return false;
    } else if (includesNewOrch && !duration?.value) {
      return false;
    }

    return true;
  };

  const handleDurationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setIsValid(validateFormSubmit(formData.activationDate, event));
    const newFormData = { ...formData };
    newFormData.duration = event;
    setFormData(newFormData);
  };

  const handleFormSubmit = async () => {
    setSubmitButtonLoading(true);

    const body: any = {
      phone_number: formData.phoneNumber.replace(/\D/g, ''),
      activation_date: formData.activationDate,
      inform_list: formData.informList.flat(),
      note: formData.notes,
      products: getDeepCopyofProductsForAPI(state.rfqCart.products),
      company_id: state.companyId,
      company_name: state.companyName,
    };

    if (isNewOrch) {
      body.duration = formData.duration.value;
    }

    try {
      const response = await axios.put(
        `${BACKEND_HOST_NAME}/api/v1/rfq/cart/checkout`,
        body,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );

      if (response.status === 200 && response.data?.products) {
        dispatch({
          type: RFQ_RESET_CART,
          payload: null,
        });
        handleSuccess();
        setRedirectToHome(true);
      }
    } catch (err) {
      console.log(err);
      handleError(err.message);
    } finally {
      setSubmitButtonLoading(false);
    }
  };

  const handleError = (errMessage: string) => {
    dispatch({
      type: 'setBannerType',
      payload: 'error',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'rfq_checkout_cart_error_msg',
        `There was an error in submitting your quote. Please try again. ${errMessage}`,
        { errMessage }
      ),
    });
  };

  const handleSuccess = () => {
    dispatch({
      type: 'setBannerType',
      payload: 'success',
    });
    dispatch({
      type: 'setBannerMsg',
      payload: t(
        'rfq_checkout_cart_success_msg',
        'Your quote was successfully submitted.'
      ),
    });
  };

  /**
   * If changing quantity of 2nd level child, must pass in parentOrchId
   * @param productId: The sku_id of the product (actual license)
   * @param parentOrchId: The generated _id of orch in products array in cart
   */
  const handleQuantityChange = (productId: string, parentOrchId?: string) => (
    event: any // React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    const quantity = parseInt(event.target.value) || 0;

    if (parentOrchId) {
      dispatch({
        type: RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
        payload: {
          parent_id: parentOrchId,
          sku_id: productId,
          quantity,
        },
      });
    } else {
      dispatch({
        type: RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
        payload: {
          sku_id: productId,
          quantity,
        },
      });
    }
    event.target.value = quantity;
  };

  /**
   *
   * @param cartProductId: The generated _id of the product in cart
   * @param productId : The sku_id of the product (actual license)
   */
  const handleRemoveTopLevel = (cartProductId?: string, productId?: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    const payload = cartProductId
      ? { _id: cartProductId }
      : productId
        ? { sku_id: productId }
        : undefined;
    dispatch({
      type: RFQ_REMOVE_1ST_LEVEL_LICENSE,
      payload,
    });
  };

  /**
   * @param parentOrchId: The generated _id of orch
   * @param productId: The actual _id of license
   */
  const handleRemoveChildItem = (parentOrchId: string, productId: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    if (!parentOrchId || !productId) {
      return;
    }
    dispatch({
      type: RFQ_REMOVE_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: parentOrchId,
        sku_id: productId,
      },
    });
  };

  const generateLicense = (
    productType: string,
    productSkuName: string,
    cartProductId: string | undefined,
    productQuantity: number,
    key: number,
    productId: string,
    parentOrchId?: string,
    parentOrchIndex?: number
  ) => {
    let classes = `${baseClass}__Cart-Product`;
    let quantityHelperText = undefined;
    const isStandalone = productType === STANDALONE;
    const isService = productType === SERVICE;
    const isCloud = productType === CLOUD;
    const isExistingCloud = productType === EXISTING_CLOUD;
    const isNewOrch = productType === NEW_ORCHESTRATOR;
    const isExistingOrch = productType === EXISTING_ORCHESTRATOR;
    const isAutomationSuite = productType === AUTOMATION_SUITE;
    const isExistingAutomationSuite = productType === EXISTING_AUTOMATION_SUITE;
    if (isStandalone) {
      classes += ` ${baseClass}__Cart-Product-Standalone`;
    } else if (isCloud || isExistingCloud) {
      classes += ` ${baseClass}__Cart-Product-Cloud`;
    } else if (isNewOrch) {
      classes += ` ${baseClass}__Cart-Product-New-Orch`;
    } else if (isExistingOrch) {
      classes += ` ${baseClass}__Cart-Product-Existing-Orch`;
    } else if (isService) {
      classes += ` ${baseClass}__Cart-Product-Service`;
    } else if (isExistingAutomationSuite || isAutomationSuite) {
      classes += ` ${baseClass}__Cart-Product-Automation-Suite`;
    }

    const productName = (
      <h4 className={`${baseClass}__Cart-Product-Title`}>{productSkuName}</h4>
    );

    let removeItem;
    if (
      state.rfqCart.products.length === 1 &&
      !state.rfqCart.products[0].products
    ) {
      const tooltipText: string = t(
        'rfq_checkout_cart_remove_item_tooltip_2',
        'You need at least one item in order to request quote'
      );
      removeItem = (
        <Tooltip
          title={tooltipText}
          placement="bottom">
          <p className={`${baseClass}__Cart-Product-Remove`}>
            {t('rfq_checkout_cart_remove_item_label', 'Remove')}
          </p>
        </Tooltip>
      );
    } else if (isExistingOrch || isNewOrch) {
      removeItem = (
        <p
          onClick={handleRemoveChildItem(parentOrchId || '', productId || '')}
          data-child-id={cartProductId}
          data-parent-id={parentOrchId}
          className={`${baseClass}__Cart-Product-Remove`}
        >
          {t('rfq_checkout_cart_remove_item_label', 'Remove')}
        </p>
      );
    } else if (isStandalone || isCloud || isService) {
      removeItem = (
        <p
          onClick={handleRemoveTopLevel(cartProductId, undefined)}
          className={`${baseClass}__Cart-Product-Remove`}
          id={productId}
        >
          {t('rfq_checkout_cart_remove_item_label', 'Remove')}
        </p>
      );
    }

    const rawLicense = licenses.find(sku => sku._id === productId);

    if (rawLicense) {
      if (
        rawLicense.min_quantity &&
        productQuantity < rawLicense.min_quantity
      ) {
        quantityHelperText = t(
          'rfq_checkout_cart_required_min_quantity_helper',
          `A minimum of ${rawLicense.min_quantity} is required`,
          { minQuantity: rawLicense.min_quantity }
        );
      } else if (
        productQuantity >
        (rawLicense.max_quantity
          ? rawLicense.max_quantity
          : Number.MAX_SAFE_INTEGER)
      ) {
        quantityHelperText = t(
          'rfq_checkout_cart_max_quantity_helper',
          `The maximum limit of ${rawLicense.max_quantity} has been reached`,
          { maxQuantity: rawLicense.max_quantity }
        );
      } else if (productQuantity < 1) {
        quantityHelperText = t(
          'rfq_checkout_cart_enter_min_one_item_helper',
          'Please enter quantity of 1 or more'
        );
      }
    } else {
      quantityHelperText = t(
        'rfq_checkout_cart_product_not_in_catalog_helper',
        'This product no longer exists in the catalog. Please remove it from the cart to continue.'
      );
    }

    return (
      <div
        className={classes}
        key={key}>
        {(isStandalone || isCloud || isService || isAutomationSuite) && (
          <div className={`${baseClass}__Cart-Product-Standalone-Info`}>
            {productName}
          </div>
        )}

        {(isNewOrch || isExistingOrch) && productName}

        <div className={`${baseClass}__Cart-Quantity-Container`}>
          <>
            <TextField
              className={`${baseClass}__Cart-Product-Quantity`}
              value={productQuantity}
              onChange={handleQuantityChange(productId!, parentOrchId)}
              onClick={e => e.stopPropagation()}
              onFocus={e => e.target.select()}
              error={false}
              required={false}
              InputLabelProps={{ shrink: true }}
              type="number"
              InputProps={{ className: 'Tall' }}
              inputProps={{
                'data-child-id': cartProductId,
                'data-parent-id': parentOrchId,
                'data-is-standalone': isStandalone || isCloud || isService,
                'data-parent-index': parentOrchIndex,
                'data-child-index': key,
              }}
              id={cartProductId}
            />
            {removeItem}
          </>
          {quantityHelperText && (
            <p className={`${baseClass}__Cart-Product-HelperText`}>
              {quantityHelperText}
            </p>
          )}
        </div>
      </div>
    );
  };

  const generateCartPanelSubtitle = (
    baseClass: string,
    subtitleText: string,
    children: JSX.Element[],
    displayDivider: boolean = false
  ) => (
    <>
      {displayDivider && (
        <div className={`${baseClass}__Cart-Products-Divider`} />
      )}
      <div className={`${baseClass}__Cart-Standalone-Products-Container`}>
        <p className={`${baseClass}__Cart-Standalone-Container-Title`}>
          {subtitleText}
        </p>
        {children}
      </div>
    </>
  );

  const generateProducts = () => {
    const groupedRes = getLicensesGroupedByType(state.rfqCart.products);
    const hasOnPremOrchLicenses =
      groupedRes['New Orchestrator']?.length > 0 ||
      groupedRes['Existing Orchestrator']?.length > 0;
    const hasStandaloneLicenses = groupedRes.Standalone?.length > 0;
    const tooltipText: string = t(
      'rfq_checkout_cart_remove_item_tooltip_1',
      'Removing Orchestrator will also remove the products for it.'
    );
    const hasServiceLicenses = groupedRes.Service?.length > 0;
    const hasCloudLicenses =
      groupedRes[EXISTING_CLOUD]?.length > 0 || groupedRes[CLOUD]?.length > 0;
    const hasAutomationSuiteLicenses =
      groupedRes[EXISTING_AUTOMATION_SUITE]?.length > 0 ||
      groupedRes[AUTOMATION_SUITE]?.length > 0;
    return (
      <div className={`${baseClass}__Cart-ContentWrapper--nonEmpty`}>
        {hasOnPremOrchLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.onPrem,
            (
              groupedRes['New Orchestrator']?.map(newOrch => (
                <React.Fragment key={newOrch._id}>
                  <div className={`${baseClass}__Cart-Orchestrator-New`}>
                    <h4 className={`${baseClass}__Cart-Product-Title`}>
                      {newOrch.sku_name}
                    </h4>
                    <Tooltip
                      title={tooltipText}
                      placement="bottom">
                      <p
                        onClick={handleRemoveTopLevel(newOrch._id, undefined)}
                        id={newOrch._id}
                        data-orchestrator
                        className={`${baseClass}__Cart-Product-Remove`}
                      >
                        {t('rfq_checkout_cart_remove_item_label', 'Remove')}
                      </p>
                    </Tooltip>
                  </div>
                  {newOrch.products && newOrch.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {newOrch.products.map((orchProduct, i) => generateLicense(
                        NEW_ORCHESTRATOR,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        newOrch._id,
                        undefined
                      ))}
                    </div>
                  )}
                </React.Fragment>
              )) || []
            ).concat(
              groupedRes['Existing Orchestrator']?.map(existingOrch => (
                <React.Fragment key={existingOrch._id}>
                  <div className={`${baseClass}__Cart-Orchestrator`}>
                    <p className={`${baseClass}__Cart-Orchestrator-Title`}>
                      {existingOrch.display_name ||
                        existingOrch.existing_license_name}
                    </p>
                    <p className={`${baseClass}__Cart-Orchestrator-Code`}>
                      {existingOrch.existing_license_code}
                    </p>
                    {!availableLicensesIds.includes(
                      existingOrch.existing_license_code || ''
                    ) && (
                      <p
                        className={`${baseClass}__Cart-Orchestrator-HelperText`}
                      >
                        {t(
                          'rfq_checkout_cart_invalid_license_info_text',
                          'This license is no longer active. Please remove products to continue.'
                        )}
                      </p>
                    )}
                  </div>
                  {existingOrch.products && existingOrch.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {existingOrch.products.map((orchProduct, i) => generateLicense(
                        EXISTING_ORCHESTRATOR,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        existingOrch._id,
                        undefined
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ))
            ),
            false
          )}
        {hasAutomationSuiteLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.automationSuite,
            (
              groupedRes[AUTOMATION_SUITE]?.map(newOrch => (
                <React.Fragment key={newOrch._id}>
                  <div className={`${baseClass}__Cart-Orchestrator-New`}>
                    <h4 className={`${baseClass}__Cart-Product-Title`}>
                      {newOrch.sku_name}
                    </h4>
                    <Tooltip
                      title={tooltipText}
                      placement="bottom">
                      <p
                        onClick={handleRemoveTopLevel(newOrch._id, undefined)}
                        id={newOrch._id}
                        data-orchestrator
                        className={`${baseClass}__Cart-Product-Remove`}
                      >
                        {t('rfq_checkout_cart_remove_item_label', 'Remove')}
                      </p>
                    </Tooltip>
                  </div>
                  {newOrch.products && newOrch.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {newOrch.products.map((orchProduct, i) => generateLicense(
                        AUTOMATION_SUITE,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        newOrch._id,
                        undefined
                      ))}
                    </div>
                  )}
                </React.Fragment>
              )) || []
            ).concat(
              groupedRes[EXISTING_AUTOMATION_SUITE]?.map((skuProduct, i) =>
                generateLicense(
                  skuProduct.type,
                  skuProduct.sku_name!,
                  skuProduct._id,
                  skuProduct.quantity!,
                  i,
                  skuProduct.sku_id!
                )
              )),
            hasOnPremOrchLicenses
          )}
        {hasCloudLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.cloud,
            (
              groupedRes[CLOUD]?.map(newOrch => (
                <React.Fragment key={newOrch._id}>
                  <div className={`${baseClass}__Cart-Orchestrator-New`}>
                    <h4 className={`${baseClass}__Cart-Product-Title`}>
                      {newOrch.sku_name}
                    </h4>
                    <Tooltip
                      title={tooltipText}
                      placement="bottom">
                      <p
                        onClick={handleRemoveTopLevel(newOrch._id, undefined)}
                        id={newOrch._id}
                        data-orchestrator
                        className={`${baseClass}__Cart-Product-Remove`}
                      >
                        {t('rfq_checkout_cart_remove_item_label', 'Remove')}
                      </p>
                    </Tooltip>
                  </div>
                  {newOrch.products && newOrch.products.length > 0 && (
                    <div
                      className={`${baseClass}__Cart-Product-Bundle-Container`}
                    >
                      {newOrch.products.map((orchProduct, i) => generateLicense(
                        CLOUD,
                        orchProduct.sku_name,
                        orchProduct._id,
                        orchProduct.quantity,
                        i,
                        orchProduct.sku_id,
                        newOrch._id,
                        undefined
                      ))}
                    </div>
                  )}
                </React.Fragment>
              )) || []
            ).concat(
              groupedRes[EXISTING_CLOUD]?.map((skuProduct, i) =>
                generateLicense(
                  skuProduct.type,
                  skuProduct.sku_name!,
                  skuProduct._id,
                  skuProduct.quantity!,
                  i,
                  skuProduct.sku_id!
                )
              )),
            hasOnPremOrchLicenses || hasAutomationSuiteLicenses
          )}
        {hasStandaloneLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.standalone,
            groupedRes.Standalone?.map((skuProduct, i) =>
              generateLicense(
                skuProduct.type,
                skuProduct.sku_name!,
                skuProduct._id,
                skuProduct.quantity!,
                i,
                skuProduct.sku_id!
              )
            ),
            hasOnPremOrchLicenses ||
              hasAutomationSuiteLicenses ||
              hasCloudLicenses
          )}
        {hasServiceLicenses &&
          generateCartPanelSubtitle(
            baseClass,
            RFQ.productLabelText.services,
            groupedRes.Service?.map((skuProduct, i) =>
              generateLicense(
                skuProduct.type,
                skuProduct.sku_name!,
                skuProduct._id,
                skuProduct.quantity!,
                i,
                skuProduct.sku_id!
              )
            ),
            hasOnPremOrchLicenses ||
              hasAutomationSuiteLicenses ||
              hasCloudLicenses ||
              hasStandaloneLicenses
          )}
      </div>
    );
  };

  if (redirectToCatalog) {
    return <Redirect to="/request-quote" />;
  }

  if (redirectToHome) {
    return <Redirect to="/" />;
  }

  /* Return if no company id selected */
  if (state.companyId === '') {
    return (
      <>
        <Helmet>
          <title>
            {t(
              'rfq_checkout_page_title',
              'Request Quote Checkout | Customer Portal'
            )}
          </title>
        </Helmet>
        <NoCompanyId />
      </>
    );
  } else if (!isEditLicenseAllowed) {
    return <Redirect to="/unauthorized" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'rfq_checkout_page_title',
            'Request Quote Checkout | Customer Portal'
          )}
        </title>
      </Helmet>
      <Container>
        <styles.CheckoutContainer>
          <styles.CheckoutForm>
            <div className={`${baseClass}__Form-Fields`}>
              <div className={`${baseClass}__Form-Field--Row`}>
                <div className={`${baseClass}__Form-Field--InputGroup`}>
                  <TextLabel
                    label={t(
                      'rfq_checkout_account_name_text_field_label',
                      'Business/Account Name'
                    )}
                  />
                  <TextField
                    className={`${baseClass}__Form-Field`}
                    placeholder={state.companyName}
                    error={false}
                    disabled
                    required={false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                  />
                </div>
                <div className={`${baseClass}__Form-Field--InputGroup`}>
                  <TextLabel
                    label={t(
                      'rfq_checkout_requester_text_field_label',
                      'Requester'
                    )}
                  />
                  <TextField
                    className={`${baseClass}__Form-Field`}
                    placeholder={state.userName}
                    error={false}
                    disabled
                    required={false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                  />
                </div>
              </div>

              <div className={`${baseClass}__Form-Field--Row`}>
                <div className={`${baseClass}__Form-Field--InputGroup`}>
                  <TextLabel
                    label={t(
                      'rfq_checkout_phone_number_text_field_label',
                      'Requester Phone Number'
                    )}
                  />
                  <TextField
                    className={`${baseClass}__Form-Field`}
                    value={formData.phoneNumber}
                    onChange={handleFieldChange}
                    error={false}
                    required={false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: 'Tall' }}
                    id="phoneNumber"
                    fullWidth
                  />
                </div>
                <div className={`${baseClass}__Form-Field--InputGroup`}>
                  <TextLabel
                    label={t(
                      'rfq_checkout_email_text_field_label',
                      'Requester Email'
                    )}
                  />
                  <TextField
                    className={`${baseClass}__Form-Field`}
                    placeholder={state.userEmail}
                    error={false}
                    disabled
                    required={false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: 'Tall' }}
                    fullWidth
                  />
                </div>
              </div>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TextLabel
                  label={t(
                    'rfq_checkout_activation_date_text_field_label',
                    'Preferred Activation Date'
                  )}
                  required
                />
                <DesktopDatePicker
                  view='day'
                  views={[ 'day' ]}
                  openTo='day'
                  slots={{
                    openPickerButton: (props) => (
                      <IconButton
                        {...props}
                        data-testid='' />
                    ),
                    openPickerIcon: () => (
                      <Icon className='material-icons-outlined'>
                        calendar_today
                      </Icon>
                    ),
                  }}
                  slotProps={{
                    textField: {
                      id: 'date-picker-inline',
                      className: 'Date-Picker',
                      required: true,
                      fullWidth: true,
                      InputProps: { className: 'Tall' },
                    },
                    field: { className: `${baseClass}__Form-Field--Full ${baseClass}__Form-Field--Datepicker` },
                    toolbar: { hidden: true },
                  }}
                  format="MM/dd/yyyy"
                  className={`${baseClass}__Form-Field--Full ${baseClass}__Form-Field--Datepicker`}
                  minDate={new Date()}
                  value={moment(formData.activationDate).toDate()}
                  onChange={handleDateChange as any}
                />
              </LocalizationProvider>
              {isNewOrch && (
                <SelectWithSearch
                  value={formData.duration}
                  className={`${baseClass}__Form-Field--Full Custom-Select--Outlined`}
                  onChange={handleDurationChange}
                  options={QuoteDuration}
                  placeholder={t(
                    'rfq_checkout_duration_text_field_placeholder',
                    'Select duration...'
                  )}
                  id="duration"
                  name="duration"
                  searchable
                  label={t(
                    'rfq_checkout_duration_text_field_label',
                    'Duration'
                  )}
                  required
                />
              )}

              <>
                <TextLabel
                  label={t(
                    'rfq_checkout_inform_emails_text_field_label',
                    'Whom else should be informed about your RFQ within your organization?'
                  )}
                />
                <MultiEmailInput
                  inputClass={`${baseClass}__Form-Field--Full`}
                  containerClass={`${baseClass}__Multi-Email-Field`}
                  updateItems={handleEmailsUpdate}
                />
              </>

              <>
                <TextLabel
                  label={t(
                    'rfq_checkout_comments_text_field_label',
                    'Comments (optional)'
                  )}
                />
                <TextField
                  className={`${baseClass}__Form-Field--Full`}
                  value={formData.notes}
                  onChange={handleFieldChange}
                  error={false}
                  required={false}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  inputProps={{ maxLength: 2000 }}
                  id="notes"
                  multiline
                />
              </>

              <Button
                className={`${baseClass}__Form-Button`}
                isLoading={submitButtonLoading}
                disabled={!isValid}
                isValid={isValid}
                onClick={handleFormSubmit}
                text={t('rfq_checkout_request_quote_btn', 'Request Quote')}
              />
              <p className={`${baseClass}__Form-Text`}>
                {t(
                  'rfq_checkout_disclaimer_text',
                  'By clicking the button "Request for Quote", a request for quote will be sent by email to your UiPath Account Manager with all the other recipients you listed above in copy.'
                )}
              </p>
            </div>
          </styles.CheckoutForm>
          <styles.CheckoutCart>
            {licenses.length > 0 && (
              <>
                <div
                  data-testid="CheckoutCart__Inner"
                  className={`${baseClass}__Cart-Inner ${baseClass}__Cart-Inner--nonEmpty`}
                >
                  <h3 className={`${baseClass}__Cart-Title`}>
                    {t('rfq_checkout_cart_title', 'Cart')}
                  </h3>
                  {generateProducts()}
                </div>
              </>
            )}
          </styles.CheckoutCart>
        </styles.CheckoutContainer>
      </Container>
    </>
  );
};

export default CheckoutPage;
