import { UserRole } from '@customer-portal/constants';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// styles
import * as styles from '../../assets/css/edit_profile/EditProfileCompaniesAndPermissions';
import infoIcon from '../../assets/img/svg/status_icons/info_gray.svg';
// images
import successIcon from '../../assets/img/svg/status_icons/success.svg';
// Clients
import { axiosGet } from '../../client/axios';
import {
  AccountType,
  AllowedHapoAccounts,
} from '../../constants/account.constants';
import { permissionInfo } from '../../constants/addUserPermissions';
// constants
import { BROWSE_CUSTOMERS_URL } from '../../constants/network.constants';
import {
  COLLAB,
  HAPO,
  LICENSE,
  PREMIUMCARE,
  SEND_INVITATION_AND_PERMISSION_EDITING,
  SUPPORT,
} from '../../constants/roles.constants';
import { useAuth } from '../../contexts/auth';
// contexts
import { StoreContext } from '../../store';
import Loader from '../CustomerPortal-Loader';
// components
import Container from '../CustomerPortal-New-Container';

const useStyles = makeStyles(theme => ({ customWidth: { maxWidth: 400 } }));

const UserCompaniesAndPermissions = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const canViewAccountsList: boolean = props.showAccountList;
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const history = useHistory();
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const [ loading, setLoading ] = useState(true);

  const permissionsList = [
    {
      label: t(
        'user_profile_company_and_permissions_license_rfq_label',
        'License & Request Quote'
      ),
      scope: LICENSE,
    },
    {
      label: t('user_profile_company_and_permissions_support_label', 'Support'),
      scope: SUPPORT,
    },
    {
      label: t(
        'user_profile_company_and_permissions_premium_care_label',
        'Preventive Care'
      ),
      scope: PREMIUMCARE,
    },
    {
      label: t(
        'user_profile_company_and_permissions_collab_space_label',
        'Collaboration Space'
      ),
      scope: COLLAB,
    },
    {
      label: state.isUtoEnabled ? t(
        'user_profile_company_and_permissions_uto_label',
        'UiPath Tailored Offering'
      ) : t(
        'user_profile_company_and_permissions_hapo_label',
        'Enterprise Agreement Licenses'
      ),
      scope: HAPO,
    },
    {
      label: t(
        'user_profile_company_and_permissions_invitations_label',
        'Invitation and permission editing'
      ),
      scope: SEND_INVITATION_AND_PERMISSION_EDITING,
    },
  ];

  const getSharedCompaniesIds = (sharedCompanies: any[]) => {
    const sharedCompaniesIds: string[] = [];

    if (Array.isArray(sharedCompanies) && sharedCompanies.length > 0) {
      for (const company of sharedCompanies) {
        sharedCompaniesIds.push(company.companyId);
      }
    }

    return sharedCompaniesIds;
  };

  const fetchCustomers = async () => {
    let cosmosResults: any;

    try {
      const config = {
        params: {
          action: 'sort',
          keywords: '',
          sort_by: 'Name',
          sort_direction: 1,
          pageNum: 1,
          limit: 100,
          ids: getSharedCompaniesIds(state.sharedCompanies),
        },
      };
      cosmosResults = await axiosGet(
        BROWSE_CUSTOMERS_URL,
        state.companyId,
        await getAccessToken(),
        config
      );
    } catch (err) {
      setLoading(false);
      console.log(err);
    }

    const sharedCompanies: any[] = [ ...state.sharedCompanies ];

    if (cosmosResults?.data?.data) {
      for (const company of sharedCompanies) {
        for (const c of cosmosResults.data.data.customers) {
          if (company.companyId === c.id) {
            company.companyType = c.maintenanceFlag;
          }
        }
      }
    }

    dispatch({
      type: 'setSharedCompanies',
      payload: sharedCompanies,
    });
    setLoading(false);
  };

  const generatePermissionsMatrix = (
    label: string,
    scope: string,
    role: string,
    permissions: string[],
    companyId: string
  ) => {
    const [ viewPermissionInfo, editPermissionInfo ] = permissionInfo(
      scope,
      true,
    );

    return (
      <div
        key={`${companyId}-${scope}`}
        className="CompaniesListItem__bodyHeader CompaniesListItem__bodyHeader--content"
      >
        <span className="CompaniesListItem__bodyPermissions">
          <span>{label}</span>
          <Tooltip
            classes={{ tooltip: classes.customWidth }}
            placement="bottom-start"
            title={
              <div className="CompaniesListItem__permissionDescription">
                <p>{t(viewPermissionInfo.keyText, viewPermissionInfo.fallbackText)}</p>
                <p>{t(editPermissionInfo.keyText, editPermissionInfo.fallbackText)}</p>
              </div>
            }
          >
            <div className="CompaniesListItem__infoIcon">
              <img
                src={infoIcon}
                alt="info icon" />
            </div>
          </Tooltip>
        </span>
        <div className="CompaniesListItem__bodyPermissionsType">
          {scope !== SEND_INVITATION_AND_PERMISSION_EDITING && (
            <>
              <span>
                {permissions.includes(`${scope}:read`) ? (
                  <img
                    src={successIcon}
                    alt="Success Icon" />
                ) : (
                  '-'
                )}
              </span>
              <span>
                {permissions.includes(`${scope}:write`) ? (
                  <img
                    src={successIcon}
                    alt="Success Icon" />
                ) : (
                  '-'
                )}
              </span>
            </>
          )}

          {scope === SEND_INVITATION_AND_PERMISSION_EDITING && (
            <>
              <span>
                {role === UserRole.CP_ADMIN ? (
                  <img
                    src={successIcon}
                    alt="Success Icon" />
                ) : (
                  '-'
                )}
              </span>
              <span>
                {role === UserRole.CP_ADMIN ? (
                  <img
                    src={successIcon}
                    alt="Success Icon" />
                ) : (
                  '-'
                )}
              </span>
            </>
          )}
        </div>
      </div>
    );
  };

  const handleCompanyClick = (e: any) => {
    const baseClassName = 'CompaniesListItem';

    if (!e.currentTarget.parentNode.className.includes('active')) {
      e.currentTarget.parentNode.className =
        baseClassName + ` ${baseClassName}--active`;
    } else {
      e.currentTarget.parentNode.className = baseClassName;
    }
  };

  const handleContactAdminClick = (e: any) => {
    e.preventDefault();
    history.push('/company?section=users');
  };

  useEffect(() => {
    if (canViewAccountsList) {
      fetchCustomers();
    }
  }, []);

  return (
    <Container>
      <styles.CompaniesAndPermissions data-testid="UserCompaniesAndPermissions__wrapper">
        <h4 className="headerTitle">
          {t(
            'user_profile_company_and_permissions_headline',
            'Company and permissions'
          )}
        </h4>
        <div className="CompaniesList">
          {loading && (
            <div
              className="CompaniesList__loader"
              data-testid="ProfilePage__companiesAndPermissionsLoader"
            >
              <Loader />
            </div>
          )}
          {!loading &&
            state.sharedCompanies.map((company: any) => {
              let listItemModifier: string = '';

              if (state.companyId === company.companyId) {
                listItemModifier = ` CompaniesListItem--active`;
              }

              return (
                <div
                  key={company.companyId}
                  id={company.companyId}
                  className={`CompaniesListItem${listItemModifier}`}
                  data-testid="ProfilePage__companyItem"
                >
                  <div
                    className="CompaniesListItem__header"
                    onClick={e => handleCompanyClick(e)}
                    data-testid="UserCompaniesAndPermissions__header"
                  >
                    <span className="CompanyName">{company.companyName}</span>
                    <span className="CompanyRole">
                      {company.cpRole === UserRole.CP_ADMIN
                        ? t(
                          'user_profile_company_and_permissions_admin_role_label',
                          'Admin'
                        )
                        : t(
                          'user_profile_company_and_permissions_regular_user_label',
                          'Regular User'
                        )}
                    </span>
                  </div>
                  <div className="CompaniesListItem__body">
                    <div className="CompaniesListItem__bodyHeader">
                      <span className="CompaniesListItem__bodyPermissions">
                        {t(
                          'user_profile_company_and_permissions_th_permissions',
                          'Permissions'
                        )}
                      </span>
                      <div className="CompaniesListItem__bodyPermissionsType">
                        <span>
                          {t(
                            'user_profile_company_and_permissions_th_can_view',
                            'Can View'
                          )}
                        </span>
                        <span>
                          {t(
                            'user_profile_company_and_permissions_th_can_edit',
                            'Can Edit'
                          )}
                        </span>
                      </div>
                    </div>
                    {permissionsList
                      .filter((item: any) => {
                        const isPremiumAccount: boolean = [
                          AccountType.PREMIUM,
                          AccountType.PREMIUM_PLUS,
                          AccountType.ENTERPRISE,
                        ].includes(company.companyType);
                        const isHAPOAllowed: boolean = AllowedHapoAccounts.includes(
                          company.companyId
                        );

                        if (
                          (!isPremiumAccount && item.scope === PREMIUMCARE) ||
                          (!isHAPOAllowed && item.scope === HAPO)
                        ) {
                          return false;
                        }

                        if (company.isCollabSpaceEnabled === false && item.scope === COLLAB) {
                          return false;
                        }

                        return true;
                      })
                      .map((item: any) =>
                        generatePermissionsMatrix(
                          item.label,
                          item.scope,
                          company.cpRole,
                          company.permissions,
                          company.companyId
                        )
                      )}
                  </div>
                </div>
              );
            })}
        </div>
        {!loading && (
          <div className="ContactAccountAdmin">
            <span>
              {t(
                'user_profile_company_and_permissions_footer_part_1',
                'Contact your'
              ) + ' '}
              <a
                href="#"
                onClick={handleContactAdminClick}>
                {t(
                  'user_profile_company_and_permissions_footer_part_2',
                  'account admin'
                )}
              </a>
              {' '}
              {t(
                'user_profile_company_and_permissions_footer_part_3',
                'about any inquiries.'
              )}
              .
            </span>
          </div>
        )}
      </styles.CompaniesAndPermissions>
    </Container>
  );
};

export default UserCompaniesAndPermissions;
