import moment from 'moment';

import i18n from '../../i18n';
import { Locale } from '../constants/localization.constants';
import {
  COMMUNITY_LICENSES,
  LICENSE_EXPIRED_WINDOW_DAYS,
  VersionDropdownField,
} from '../constants/support.constants';
import type {
  DeprecatedProductComponentVersion,
  DropDownItemData,
  SupportFormFieldMappings,
} from '../interfaces/sfdc.interface';
import {
  getBundleDisplayName,
  getBundleName,
  getSubscriptionName,
} from './licenses.utils';

export const isValidSiteUrl = (siteUrl: string): boolean => {
  let urlInstance: URL;

  // Can be empty
  if (!siteUrl.length) {
    return true;
  }

  // Must be a valid URL
  try {
    urlInstance = new URL(siteUrl.toLowerCase());
    if (!urlInstance) {
      return false;
    }
  } catch (e) {
    return false;
  }

  // top-level and sub-top-level domain must be exactly 'com' and 'uipath'`
  const domains = urlInstance.hostname.split('.').reverse();
  if (domains[0] !== 'com' || domains[1] !== 'uipath') {
    return false;
  }

  // Must have at least first path (organization name)
  if (!urlInstance.pathname.split('/').filter(e => e.length).length) {
    return false;
  }

  return true;
};

export const getLicenseOptions = (
  deploymentTypes: string[],
  licensesData: any[],
  customInfoArr: any,
  companyName: string,
) => {
  if (companyName !== customInfoArr?.boonLicense?.name) {
    return [];
  }
  const filteredLicenseOptions = licensesData?.filter(
    license => {
      if (license.deploymentTypes?.some((type: any) => deploymentTypes.includes(type))) {
        const checkExpiredDays = moment(license.endDate).isBefore(moment().subtract(LICENSE_EXPIRED_WINDOW_DAYS, 'days'));
        return !checkExpiredDays && license.enabled && !COMMUNITY_LICENSES.includes(license.subscriptionCode);
      }
    }
  );

  const licensesOptionsData = filteredLicenseOptions?.slice().sort(
    (a: any, b: any) => {
      const numericA = parseInt(a.licenseCode.replace('-', ''), 10);
      const numericB = parseInt(b.licenseCode.replace('-', ''), 10);
      return numericA - numericB;
    }
  )
    .map(
      license => {
        const bundleName = getBundleName(
          license.bundleCode,
          getSubscriptionName(license.subscriptionCode)
        ).replace('<br/>', '');
        const displayName = getBundleDisplayName(
          license.licenseCode,
          customInfoArr?.licenses
        );

        let optionValue = license.licenseCode;
        if (displayName !== '' && bundleName !== '-') {
          optionValue += ' - (' + bundleName + ' for ' + displayName + ')';
        } else if (bundleName !== '-') {
          optionValue += ' - (' + bundleName + ')';
        } else if (companyName) {
          optionValue += ' - (' + companyName + ')';
        }

        return optionValue;
      }
    );
  return licensesOptionsData ?? [];
};

export const getPrimaryVersion = (
  supportFormFieldMapping: SupportFormFieldMappings
) => {
  let primaryVer;

  const {
    studioOrRobotVersion,
    orchestratorVersion,
    productComponentVersion,
    automationSuiteVersion,
  } = supportFormFieldMapping;

  switch (true) {
    case studioOrRobotVersion?.isPrimaryVersionPicklist:
      primaryVer = VersionDropdownField.STUDIO_OR_ROBOT_VERSION;
      break;
    case orchestratorVersion?.isPrimaryVersionPicklist:
      primaryVer = VersionDropdownField.ORCHESTRATOR_VERSION;
      break;
    case productComponentVersion?.isPrimaryVersionPicklist:
      primaryVer = VersionDropdownField.PRODUCT_COMPONENT_VERSION;
      break;
    case automationSuiteVersion?.isPrimaryVersionPicklist:
      primaryVer = VersionDropdownField.AUTOMATION_SUITE_VERSION;
      break;
    default:
      primaryVer = VersionDropdownField.NONE;
  }
  return primaryVer;
};

export const sanitizeVersion = (version: string) => {
  if (version.split('.')[0].length === 2) {
    return `20${version}`;
  }
  return version;
};

export const getMajorVersion = (version: string): string => {
  const sanitized = sanitizeVersion(version);
  const splitted = sanitized.split('.');
  return splitted[0];
};

export const getMinorVersion = (version: string): string => {
  const splitted = version.split('.');
  const minor = splitted[1] ?? '';
  if (minor.includes(' ')) {
    return minor.split(' ')[0];
  }
  if (minor.includes('(')) {
    return minor.split('(')[0];
  }
  return minor;
};

// Check if a `XXXX.XX` version is less than a combination of major and minor version strings
export const isVersionLessThan = (version: string, {
  major, minor,
}: { major: string; minor: string }): boolean => {
  const majorVersion = getMajorVersion(version);
  const minorVersion = getMinorVersion(version);

  if (!major.length) {
    return false;
  }

  if (Number(majorVersion) < Number(major)) {
    return true;
  } else if (Number(majorVersion) === Number(major)) {
    if (!minor.length) {
      return false;
    }
    if (Number(minorVersion) < Number(minor)) {
      return true;
    }
  }

  return false;
};

/**
   * Return a union of all matches that match on the following 3 fields across studio/robot
   * versions, orchestrator versions, and product component versions as `deprecations`:
   * 1. Deployment type (if it exists)
   * 2. Product
   * 3. Version
   *
   * and return the lowest accounted version for each
   */
export const getAllDeprecationMatches = (
  deprecatedProductComponentVersions: any,
  formData: any, supportFormFieldMappings: any
): {
  deprecations: DeprecatedProductComponentVersion[];
  lowestStudioAndRobot: { major: string; minor: string };
  lowestOrchestrator: { major: string; minor: string };
  lowestProductComponent: { major: string; minor: string };
} => {
  // Keep track of the lowest accounted versions for the 3 version
  // dropdowns as we iterate through the deprecations list
  const lowestDeprecatedStudioAndRobotVersion = {
    major: '10000',
    minor: '0',
  };
  const lowestDeprecatedOrchestratorVersion = {
    major: '10000',
    minor: '0',
  };
  const lowestDeprecatedProductComponentVersion = {
    major: '10000',
    minor: '0',
  };

  const filtered = deprecatedProductComponentVersions.filter((record: any) => {
    const deploymentType = record.deploymentTypeValue;
    const product = record.productValue;
    const productComponentVersion = sanitizeVersion(record.productComponentVersionValue);

    // Deployment type related checks
    const deploymentTypeMatches = deploymentType ? deploymentType === formData.deploymentType.value : true;

    // Studio/Robot related checks
    const productIsRelatedToStudioAndRobot = !!supportFormFieldMappings?.studioOrRobotVersion;
    const studioAndRobotVersionMatches = [ 'Studio', 'Studio X', 'Robot' ].includes(product)
      && sanitizeVersion(formData.studioOrRobotVersion.value)
        .startsWith(productComponentVersion);

    // Orchestrator related checks
    const productIsRelatedToOrchestrator = !!supportFormFieldMappings?.orchestratorVersion;
    const orchestratorVersionMatches = [ 'Orchestrator' ].includes(product)
      && sanitizeVersion(formData.orchestratorVersion.value)
        .startsWith(productComponentVersion);

    // Product Component related checks
    const productMatches = product === formData.product.value;
    const productComponentVersionMatches = sanitizeVersion(formData.productComponentVersion.value)
      .startsWith(productComponentVersion);

    // Get union of all possible matches
    const allStudioAndRobotVersionMatches = deploymentTypeMatches && productIsRelatedToStudioAndRobot && studioAndRobotVersionMatches;
    const allOrchestratorVersionMatches = deploymentTypeMatches && productIsRelatedToOrchestrator && orchestratorVersionMatches;
    const allProductComponentVersionMatches = deploymentTypeMatches && productMatches && productComponentVersionMatches;

    // Keep lowest Studio/Robot version deprecation up to date
    if (deploymentTypeMatches && productIsRelatedToStudioAndRobot) {
      if (Number(getMajorVersion(productComponentVersion)) < Number(lowestDeprecatedStudioAndRobotVersion.major)) {
        lowestDeprecatedStudioAndRobotVersion.major = getMajorVersion(productComponentVersion);
        lowestDeprecatedStudioAndRobotVersion.minor = getMinorVersion(productComponentVersion);
      } else if (Number(getMajorVersion(productComponentVersion)) === Number(lowestDeprecatedStudioAndRobotVersion.major)) {
        if (Number(getMinorVersion(productComponentVersion)) < Number(lowestDeprecatedStudioAndRobotVersion.minor)) {
          lowestDeprecatedStudioAndRobotVersion.minor = getMinorVersion(productComponentVersion);
        }
      }
    }

    // Keep lowest Orchestrator version deprecation up to date
    if (deploymentTypeMatches && productIsRelatedToOrchestrator) {
      if (Number(getMajorVersion(productComponentVersion)) < Number(lowestDeprecatedOrchestratorVersion.major)) {
        lowestDeprecatedOrchestratorVersion.major = getMajorVersion(productComponentVersion);
        lowestDeprecatedOrchestratorVersion.minor = getMinorVersion(productComponentVersion);
      } else if (Number(getMajorVersion(productComponentVersion)) === Number(lowestDeprecatedOrchestratorVersion.major)) {
        if (Number(getMinorVersion(productComponentVersion)) < Number(lowestDeprecatedOrchestratorVersion.minor)) {
          lowestDeprecatedOrchestratorVersion.minor = getMinorVersion(productComponentVersion);
        }
      }
    }

    // Keep lowest Product Component version deprecation up to date
    if (deploymentTypeMatches && productMatches) {
      if (Number(getMajorVersion(productComponentVersion)) < Number(lowestDeprecatedProductComponentVersion.major)) {
        lowestDeprecatedProductComponentVersion.major = getMajorVersion(productComponentVersion);
        lowestDeprecatedProductComponentVersion.minor = getMinorVersion(productComponentVersion);
      } else if (Number(getMajorVersion(productComponentVersion)) === Number(lowestDeprecatedProductComponentVersion.major)) {
        if (Number(getMinorVersion(productComponentVersion)) < Number(lowestDeprecatedProductComponentVersion.minor)) {
          lowestDeprecatedProductComponentVersion.minor = getMinorVersion(productComponentVersion);
        }
      }
    }

    return allStudioAndRobotVersionMatches || allOrchestratorVersionMatches || allProductComponentVersionMatches;
  });

  return {
    deprecations: filtered,
    lowestStudioAndRobot: {
      major: lowestDeprecatedStudioAndRobotVersion.major === '10000' ? '' : lowestDeprecatedStudioAndRobotVersion.major,
      minor: lowestDeprecatedStudioAndRobotVersion.minor === '0' ? '' : lowestDeprecatedStudioAndRobotVersion.minor,
    },
    lowestOrchestrator: {
      major: lowestDeprecatedOrchestratorVersion.major === '10000' ? '' : lowestDeprecatedOrchestratorVersion.major,
      minor: lowestDeprecatedOrchestratorVersion.minor === '0' ? '' : lowestDeprecatedOrchestratorVersion.minor,
    },
    lowestProductComponent: {
      major: lowestDeprecatedProductComponentVersion.major === '10000' ? '' : lowestDeprecatedProductComponentVersion.major,
      minor: lowestDeprecatedProductComponentVersion.minor === '0' ? '' : lowestDeprecatedProductComponentVersion.minor,
    },
  };
};

export const reduceListToLocalizedList = (list: DropDownItemData[], acceptableEnglishValues: string[]): Array<{
  _id: string;
  label: string;
  value: string;
}> => {
  const language = i18n.language as keyof typeof Locale;
  const localizedList: Array<{
    _id: string;
    label: string;
    value: string;
  }> = [];

  for (const item of list) {
    const { languages } = item;
    let label: string;

    if (item.value === 'None') {
      label = item.label;
    } else if (
      !languages ||
      !acceptableEnglishValues.includes(languages[Locale.en]?.value ?? '')
    ) {
      continue;
    } else {
      label = languages[language]?.value
        ? languages[language]!.value
        : languages[Locale.en]?.value ?? item.label;
    }

    localizedList.push({
      _id: item._id,
      label,
      value: item.value,
    });
  }

  return localizedList;
};

export const getIsProductAutomationCloud = (deploymentTypeValue: string) => deploymentTypeValue === 'Automation Cloud';

export const getIsProductAutomationHub = (productValue: string) => productValue === 'Connect Enterprise';

export const getCaseWebFormRegion = (
  deploymentTypeValue: string,
  productValue: string
) => {
  if (getIsProductAutomationCloud(deploymentTypeValue)) {
    return 'Cloud RPA';
  }

  if (getIsProductAutomationHub(productValue)) {
    return 'Connect Enterprise';
  }

  return '';
};
