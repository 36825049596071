import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { getAuthType } from '../contexts/auth';

const downloadDocument = async (
  downloadUrl: string,
  data?: any,
  config?: AxiosRequestConfig,
  isTokenRequired: boolean = true
) => new Promise<void>(async (resolve, reject) => {
  try {
    let tokenQueryString = '';
    let response = null;
    if (isTokenRequired) {
      const result = await axios.post(
        `${downloadUrl}`,
        data,
        {
          ...config,
          headers: {
            ...config?.headers,
            'x-auth-type': getAuthType(),
          },
        }
      );

      if (!result.data.data || result.data.statusCode !== 307) {
        throw new Error('Obtaining download token unsuccessful');
      }
      response = result.data.data;
      tokenQueryString = `?token=${result.data.data.token}`;
    }

    const tempAnchor = document.createElement('a');
    tempAnchor.href = `${downloadUrl}/download${tokenQueryString}`;
    document.body.appendChild(tempAnchor);
    tempAnchor.click();
    document.body.removeChild(tempAnchor);
    resolve(response);
  } catch (e) {
    reject(e);
    console.log(e.toString());
    console.log('Unable to download');
  }
});

export default downloadDocument;
