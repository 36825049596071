import React, {
  useContext,
  useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

// Styles
import * as styles from '../../assets/css/Premium/Ticket';
// Components
import Container from '../../components/CustomerPortal-Container';
// Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import PremiumCareTicketBody from '../../components/premium/PC-Add-Ticket-Body';
import { AccountType } from '../../constants/account.constants';
import { ADD_PREMIUM_CARE } from '../../constants/telemetry.constants';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
// Store
import { StoreContext } from '../../store';

const CustomerPortalPageAddPremiumCase = (props: any) => {
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { t } = useTranslation('common');

  useTrackPageViewEvent(ADD_PREMIUM_CARE);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Add Premium Care Activity Request');
  }, []);

  const isPremiumSupport = state.companyType === AccountType.PREMIUM_SUPPORT;

  if (!UserPermissionsHelper.isEditPremiumSupportAllowed()) {
    return <Redirect to="/unauthorized" />;
  } else if (!UserPermissionsHelper.canUserSupportRestrictedAccount()) {
    return <Redirect to="/support-restricted" />;
  }

  return (
    <>
      <Helmet>
        <title>
          {isPremiumSupport
            ? t(
              'add_premium_support_request_page_title',
              'Add Preventive Care Request | Customer Portal'
            )
            : t(
              'add_premium_care_activity_request_page_title',
              'Add Preventive Care Activity Request | Customer Portal'
            )}
        </title>
      </Helmet>
      <Container>
        <styles.PremiumTicketPageWrapper>
          <styles.backButton to="/support?section=cases-premium">
            {t('support_premium_care_form_hero_go_back_btn', 'Go Back')}
          </styles.backButton>

          <styles.heroTitle>
            {isPremiumSupport
              ? t(
                'support_premium_support_form_hero_title',
                'Add Preventive Care Request'
              )
              : t(
                'support_premium_care_form_hero_title',
                'Add Preventive Care Activity Request'
              )}
          </styles.heroTitle>
          <PremiumCareTicketBody />
        </styles.PremiumTicketPageWrapper>
      </Container>
    </>
  );
};

export default CustomerPortalPageAddPremiumCase;
