import axios from 'axios';
import _ from 'lodash';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import Helmet from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
// Constants
import {
  BACKEND_HOST_NAME,
  BROWSE_CUSTOMERS_URL,
} from '../../constants/network.constants';
import { BROWSE } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import { getCloudPrefixedPath } from '../../utils/cloud';
import * as styles from './../../assets/css/CustomerPortalBrowseClients';
import Loader from './../../components/CustomerPortal-Loader';
import Container from './../../components/CustomerPortal-New-Container';
import Pagination from './../../components/CustomerPortal-Pagination';
import SelectWithSearch from './../../components/Select-With-Search';
// Interfaces
import type { IClientsList } from './../../interfaces/browseAccounts.interface';
import { StoreContext } from './../../store';

/**
 * This page is shared between UiPath, and CP users
 * @param props
 */
const CustomerPortalPageClientsSelection = (props: any) => {
  const { t } = useTranslation('common');
  const { getAccessToken } = useAuth();

  type TFilterType = typeof FilterType[keyof typeof FilterType];
  const FilterType = {
    YourCustomers: t(
      'browse_customers_page_filter_your_customers_label',
      'Your Customers'
    ),
    AllCustomers: t(
      'browse_customers_page_filter_all_customers_label',
      'All Customers'
    ),
    BookmarkedCustomers: t(
      'browse_customers_page_filter_bookmarked_customers_label',
      'Bookmarked'
    ),
  } as const;

  const allFilterTypes = [
    FilterType.YourCustomers,
    FilterType.AllCustomers,
    FilterType.BookmarkedCustomers,
  ];

  // Global state from Store
  const {
    state, dispatch,
  } = useContext(StoreContext);
  /* Default constants */
  const [ clientsList, setClientsList ] = useState<IClientsList[]>([]);
  const [ clientsListLength, setClientsListLength ] = useState(0);
  const [ allCustomersLength, setAllCustomersLength ] = useState(0);
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ searchInput, setSearchInput ] = useState(false);
  const [ searchInputText, setSearchInputText ] = useState('');
  const [ searchTextChanged, setSearchTextChanged ] = useState(false);
  const [ currentFilterType, setCurrentFilterType ] = useState(
    FilterType.YourCustomers
  );
  const [ loading, setLoading ] = useState(true);
  const [ sortType, setSortType ] = useState({
    label: t('browse_customers_page_sort_by_name_label', 'Name'),
    value: 'name',
  });

  // Hero
  const heroTitle = t('browse_customers_page_hero_title', 'UiPath Customers');
  const heroSubTitle = t(
    'browse_customers_page_hero_subTitle',
    'Browse through the list below and select the customer whose portal you\'d like to see.'
  );

  // Search
  const searchInputActiveClass: string =
    ' CustomerPortalClientsSelection__clientsSearchInput--active';
  const closeSearchInputClass: string =
    ' CustomerPortalClientsSelection__clientsSearchIcon--close';
  const bookmarkedClass: string =
    ' CustomerPortalClientsSelection__bookmarkStar--bookmarked';

  // Pagination settings
  const limit = 10;

  const getCompanyIdsForFilter = (type: TFilterType) => {
    switch (type) {
      case FilterType.YourCustomers:
        return state.myCompanies;
      case FilterType.BookmarkedCustomers:
        return state.bookmarkedCompanies;
      case FilterType.AllCustomers:
      default:
        return [];
    }
  };

  const getTotalCustomerCount = (type: TFilterType) => {
    switch (type) {
      case FilterType.AllCustomers:
        return allCustomersLength;
      case FilterType.YourCustomers:
      case FilterType.BookmarkedCustomers:
      default:
        return getCompanyIdsForFilter(type).length;
    }
  };

  const canBeActive = (type: TFilterType) => {
    switch (type) {
      case FilterType.AllCustomers:
        return true;
      case FilterType.YourCustomers:
      case FilterType.BookmarkedCustomers:
        return getCompanyIdsForFilter(type).length > 0;
    }
  };

  const handleSort = (selectedOption: any) => {
    if (sortType === selectedOption) {
      return;
    }
    setSortType(selectedOption);
    setPageNumber(1);
  };

  // Search
  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!searchInput) {
      setSearchInput(true);
    } else {
      handleSearchInput('');
    }
  };

  const [ debouncedCallApi ] = useState(() =>
    _.debounce(() => {
      setSearchTextChanged(true);
    }, 300)
  );

  const handleSearchInput = (text: string) => {
    if (text === '') {
      setSearchInput(false);
    }
    setSearchInputText(text);
    setPageNumber(1);
    debouncedCallApi();
  };

  const handleFilterTypeChange = (type: TFilterType) => {
    if (canBeActive(type) && type !== currentFilterType) {
      setCurrentFilterType(type);
      setPageNumber(1);
    }
  };

  const handlePaginateClick = (page: number) => {
    const currentScrollOffset =
      window.pageYOffset || document.documentElement.scrollTop;
    // dont scroll if already on top
    if (currentScrollOffset === 0) {
      setPageNumber(page);
    } else {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      window.onscroll = (e: any) => {
        const currentScrollOffset =
          window.pageYOffset || document.documentElement.scrollTop;
        // Scroll reach the target
        if (currentScrollOffset === 0) {
          setPageNumber(page);
          window.onscroll = null; // remove listener
        }
      };
    }
  };

  // Bookmark account, for UiPath users only
  const bookmarkAccount = async (clientId: string) => {
    const body = {
      email: state.userEmail,
      accountId: clientId,
    };

    try {
      const result: any = await axios.post(
        `${BACKEND_HOST_NAME}/api/v1/user/preferences/bookmark`,
        body,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );
      dispatch({
        type: 'setBookmarkedCompanies',
        payload: result.data.bookmarkedAccounts,
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Function to get customers from MongoDB
  const fetchCustomers = async (
    action: string,
    filterType?: TFilterType,
    runInBackground: boolean = false
  ) => {
    const filter = filterType || currentFilterType;
    let cosmosResults: any;
    const start = (pageNumber - 1) * limit;
    const end = pageNumber * limit;
    try {
      !runInBackground && setLoading(true);
      cosmosResults = await axios.get(BROWSE_CUSTOMERS_URL, {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'x-selected-account': state.companyId,
          'x-auth-type': getAuthType(),
        },
        params: {
          action,
          keywords: searchInputText,
          sort_by: sortType.value,
          sort_direction: 1,
          pageNum: pageNumber,
          limit,
          ids: getCompanyIdsForFilter(filter).slice(start, end),
        },
      });
    } catch (err) {
      !runInBackground && setLoading(false);
      console.log(err);
    }

    if (cosmosResults?.data?.data) {
      let clients = clientsList;
      let numOfClients = 0;
      clients = cosmosResults.data.data.customers;
      numOfClients = cosmosResults.data.data.total_count;

      if (action === 'sort') {
        if (filter === FilterType.AllCustomers) {
          setAllCustomersLength(numOfClients);
        } else {
          numOfClients = getTotalCustomerCount(filter);
        }
      }
      if (!runInBackground) {
        setClientsList(clients);
        setClientsListLength(numOfClients);
      }
    }
    !runInBackground && setLoading(false);
  };

  const setSelectedClient = (
    e: any,
    id: any,
    name: any,
    maintenanceFlag: string | null,
    hapoEnabled: boolean,
    utoEnabled: boolean,
  ) => {
    // setting a promise so that we give time for homepage to get new values;
    if (!e.target.className.includes('bookmark')) {
      new Promise<string | void>((resolve, reject) => {
        try {
          dispatch({
            type: 'setCompanyId',
            payload: id,
          });
          dispatch({
            type: 'setCompanyName',
            payload: name,
          });
          dispatch({
            type: 'showSelectedCompanyBanner',
            payload: true,
          });
          dispatch({
            type: 'setCompanyType',
            payload: maintenanceFlag,
          });
          dispatch({
            type: 'setIsHapoEnabled',
            payload: hapoEnabled,
          });
          dispatch({
            type: 'setIsUtoEnabled',
            payload: utoEnabled,
          });
          dispatch({
            type: 'setLicenseManagementEnabled',
            payload: hapoEnabled || utoEnabled,
          });
          // Ensure user announcements are re-fetched when selecting a company
          dispatch({
            type: 'setUserAnnouncements',
            payload: {
              announcements: [],
              fetchedAt: undefined,
            },
          });
          resolve();
        } catch {
          reject();
        }
      })
        .then(() => {
          // doing this instead of using router to ensure homepage banner shows and state is picked up
          const link = document.createElement('a');
          link.href = getCloudPrefixedPath('/');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  /* Lifecycle */
  useTrackPageViewEvent(BROWSE);

  useEffect(() => {
    // We need to debounce, to let the setUser function to properly update the
    // user's cpRole so that we can use the latest cpRole to show the list of companies
    _.debounce(() => {
      fetchCustomers('sort', FilterType.AllCustomers, true);
    }, 500)();
  }, [ state.cpRole ]);

  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Browse');

    // Causes Your Customers to be the default
    if (getCompanyIdsForFilter(FilterType.YourCustomers).length) {
      setCurrentFilterType(FilterType.YourCustomers);
      handleFilterTypeChange(FilterType.YourCustomers);
    } else {
      handleFilterTypeChange(FilterType.AllCustomers);
    }
  }, []);

  // called when searching for company name
  useEffect(() => {
    if (searchTextChanged) {
      setSearchTextChanged(false);
      const action = searchInput ? 'search' : 'sort';
      fetchCustomers(action);
    }
  }, [ searchTextChanged ]);

  useEffect(() => {
    // Still sort if searchInputText is empty, in case user opened search input without typing anything
    const action = currentFilterType === FilterType.AllCustomers &&
      searchInput && searchInputText ? 'search' : 'sort';
    fetchCustomers(action);
  }, [ sortType, currentFilterType, pageNumber ]);

  return (
    <>
      <Helmet>
        <title>
          {t(
            'browse_customers_page_title',
            'Browse customers | Customer Portal'
          )}
        </title>
      </Helmet>

      <styles.ClientsSelection data-testid="CustomerPortalBrowsePage__wrapper">
        <BasicHero
          title={heroTitle}
          subtitle={heroSubTitle} />
        <Container cssClass="CustomerPortalClientsSelection__container">
          <styles.Sidebar>
            <styles.Filter>
              <h6 className="CustomerPortalClientsSelection__filterTitle">
                {t('browse_customers_page_filter_by_label', 'Filter by')}
              </h6>
              <div className="CustomerPortalClientsSelection__filterItems">
                {allFilterTypes.map((type: TFilterType) => (
                  <styles.FilterItem
                    key={type}
                    active={currentFilterType === type} // is currently selected
                    disable={!canBeActive(type)}
                    onClick={(e: any) => {
                      e.preventDefault();
                      handleFilterTypeChange(type);
                    }}
                    data-testid="CustomerPortalBrowsePage__sidebarFilter"
                  >
                    <span className="CustomerPortalClientsSelection__filterItemName">
                      {type}
                      {getTotalCustomerCount(type) > 0 ? ` [${getTotalCustomerCount(type)}] ` : ''}
                    </span>
                  </styles.FilterItem>
                ))}
              </div>
            </styles.Filter>
          </styles.Sidebar>

          <styles.ClientsContent className="CustomerPortalClientsSelection__content">
            <div className="CustomerPortalClientsSelection__clientsHeader">
              <p className="CustomerPortalClientsSelection__clientsLeadText">
                {t(
                  'browse_customers_page_results_title',
                  'Browse all customers'
                )}
              </p>

              <div className="CustomerPortalClientsSelection__clientsActions">
                <SelectWithSearch
                  options={[
                    {
                      label: t(
                        'browse_customers_page_sort_by_name_label',
                        'Name'
                      ),
                      value: 'name',
                    },
                    {
                      label: t(
                        'browse_customers_page_sort_by_country_label',
                        'Country'
                      ),
                      value: 'country',
                    },
                    {
                      label: t(
                        'browse_customers_page_sort_by_industry_label',
                        'Industry'
                      ),
                      value: 'industry',
                    },
                  ]}
                  value={sortType}
                  searchable={false}
                  className="Custom-Select--White CustomerPortalClientsSelection__clientsSort"
                  onChange={handleSort}
                />

                {/* Search currently hidden for when a filter is selected besides All Customers */}
                {/* See https://uipath.atlassian.net/browse/CP-2537 */}
                {currentFilterType === FilterType.AllCustomers && (
                  <div className="CustomerPortalClientsSelection__clientsSearch">
                    <div
                      className={`CustomerPortalClientsSelection__clientsSearchInput${searchInput ? searchInputActiveClass : ''
                      }`}
                    >
                      {searchInput && (
                        <input
                          autoFocus
                          placeholder={t(
                            'browse_customers_page_search_placeholder',
                            'Search here...'
                          )}
                          type="text"
                          value={searchInputText}
                          onChange={(e: any) => {
                            handleSearchInput(e.target.value);
                          }}
                          data-testid="CustomerPortalBrowsePage__searchInput"
                        />
                      )}
                    </div>
                    <div
                      className={`CustomerPortalClientsSelection__clientsSearchIcon${searchInput ? closeSearchInputClass : ''
                      }`}
                      onClick={handleSearch}
                      data-testid="CustomerPortalBrowsePage__searchIcon"
                    />
                  </div>
                )}
              </div>
            </div>
            <ul className="CustomerPortalClientsSelection__clientsList">
              {loading && (
                <div className="CustomerPortalClientsSelection__loader">
                  <Loader />
                </div>
              )}

              {!loading && (
                <li className="CustomerPortalClientsSelection__clientsListItem CustomerPortalClientsSelection__clientsListItem--tableHeader">
                  <div className="CustomerPortalClientsSelection__bookmarkStar" />
                  <div className="CustomerPortalClientsSelection__client">
                    <h6 className="CustomerPortalClientsSelection__clientName">
                      {t(
                        'browse_customers_page_th_customer_details',
                        'Customer Details'
                      )}
                    </h6>
                  </div>
                  <div className="CustomerPortalClientsSelection__clientDetails">
                    <p className="CustomerPortalClientsSelection__clientCountry">
                      {t('browse_customers_page_th_team_size', 'Team size')}
                    </p>
                    <p className="CustomerPortalClientsSelection__clientCountry">
                      {t('browse_customers_page_th_country', 'Country')}
                    </p>
                    <p className="CustomerPortalClientsSelection__clientIndustry">
                      {t('browse_customers_page_th_industry', 'Industry')}
                    </p>
                  </div>
                </li>
              )}
              {!loading &&
                clientsList.length > 0 &&
                clientsList.map((client: any) => {
                  const totalContacts: number =
                    client.activeUsers +
                    client.pendingUsers +
                    client.disabledUsers;

                  return (
                    <li
                      key={client.id}
                      className="CustomerPortalClientsSelection__clientsListItem"
                      onClick={(e: any) => {
                        e.preventDefault();
                        setSelectedClient(
                          e,
                          client.id,
                          client.name,
                          client.maintenanceFlag,
                          client.hapo_feature_enabled,
                          client.uto_feature_enabled
                        );
                      }}
                      data-testid="CustomerPortalBrowsePage__accountListItem"
                    >
                      <div
                        className={`CustomerPortalClientsSelection__bookmarkStar${state.bookmarkedCompanies.includes(client.id)
                          ? bookmarkedClass
                          : ''
                        }`}
                        onClick={() => bookmarkAccount(client.id)}
                      />
                      <div className="CustomerPortalClientsSelection__client">
                        <h6 className="CustomerPortalClientsSelection__clientName">
                          {client.name}
                        </h6>
                        <span className="CustomerPortalClientsSelection__clientId">
                          {client.id}
                        </span>
                      </div>

                      <div className="CustomerPortalClientsSelection__clientDetails">
                        {client && (
                          <p className="CustomerPortalClientsSelection__clientCountry">
                            {totalContacts === 1
                              ? `${totalContacts} ${t(
                                'browse_customers_page_one_user_text',
                                'User'
                              )}`
                              : `${totalContacts} ${t(
                                'browse_customers_page_more_users_text',
                                'Users'
                              )}`}
                          </p>
                        )}

                        <p className="CustomerPortalClientsSelection__clientCountry">
                          {client.country}
                        </p>
                        <p className="CustomerPortalClientsSelection__clientIndustry">
                          {client.industry}
                        </p>
                      </div>
                    </li>
                  );
                })}
            </ul>
            {clientsList.length > 0 && (
              <Pagination
                activePage={pageNumber}
                handleClick={handlePaginateClick}
                numResults={clientsListLength}
                numPagesBeforeEllipses={5}
                numResultsPerPage={limit}
              />
            )}
          </styles.ClientsContent>
        </Container>
      </styles.ClientsSelection>
    </>
  );
};

export default CustomerPortalPageClientsSelection;
