import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, { useState } from 'react';

// Styles
import {
  ModalContentFormWrapper,
  RenameTextField,
} from '../../../assets/css/Knowledge/CategoryManager-Table';
import { KBVisibilityControls } from '../../../constants/account.constants';
import { INVALID_CATEGORY_CHARACTERS_REGEX } from '../../../constants/knowledge.constants';
import {
  Locale,
  localeToLanguage,
} from '../../../constants/localization.constants';
import type { CategoryLanguages } from '../../../pages/Admin/knowledge-categories';
import Button from '../../Button/Button';
import Modal from '../../Modal';

type AddSubcategoryModalProps = {
  oldCategoryName?: string;
  isAddSubcategoryModalOpen: boolean;
  isSubCategory: boolean;
  handleFormSubmit: (
    languages: CategoryLanguages,
    visibility: string,
    categoryName?: string,
    isSubCategory?: boolean,
  ) => void;
  handleClose: () => void;
};

const AddSubcategoryModal = (props: AddSubcategoryModalProps) => {
  const isSubCategory =
    props.oldCategoryName !== undefined && props.oldCategoryName.length > 0;
  const [ visibility, setVisibility ] = useState(Object.keys(KBVisibilityControls)[0]);
  const [ languages, setLanguages ] = useState<CategoryLanguages>(
    Object.values(Locale).reduce(
      (obj: CategoryLanguages, language: keyof typeof Locale) => {
        obj[language] = {
          name: '',
          active: true,
        };
        return obj;
      },
      {}
    )
  );

  const validInputMap = Object.values(Locale).reduce(
    (obj: {
      [key: string]: boolean;
    }, language: keyof typeof Locale) => {
      // Always valid if unactive. Otherwise, valid if name is nonempty and does not contain invalid characters
      obj[language] = !languages[language].active
        || (languages[language].name.length > 0 && !INVALID_CATEGORY_CHARACTERS_REGEX.test(languages[language].name));
      return obj;
    },
    {}
  );

  const submitDisabled = Object.values(validInputMap).includes(false);

  /* Event */
  const handleFormSubmit = (e: any) => {
    e?.preventDefault();
    const categoryName = props.oldCategoryName?.length
      ? props.oldCategoryName
      : undefined;
    props.handleFormSubmit(
      languages,
      visibility,
      categoryName,
      props.isSubCategory
    );
    handleModalClose();
  };

  const handleModalClose = () => {
    props.handleClose();
  };

  const handleToggleSelectAllLanguages = () => {
    const set = new Set(Object.values(languages).map(({ active }) => active));
    setLanguages(
      Object.keys(languages).reduce(
        (obj: CategoryLanguages, language: string) => {
          const isActive: boolean =
            set.size === 1 ? !languages[language].active : true;
          obj[language] = {
            name: languages[language].name,
            active: language === Locale.en ? true : isActive,
          };
          return obj;
        },
        {}
      )
    );
  };

  const handleToggleLanguage = (language: string) => {
    setLanguages({
      ...languages,
      [language]: {
        name: languages[language].name,
        active: !languages[language].active,
      },
    });
  };

  const handleChangeTextForLanguage = (text: string, language: string) => {
    setLanguages({
      ...languages,
      [language]: {
        name: text,
        active: languages[language].active,
      },
    });
  };
  return (
    <Modal
      modalTitle={`Add ${isSubCategory ? 'Subcategory' : 'Category'}`}
      modalHeading={`${isSubCategory
        ? `Add subcategory to "${props.oldCategoryName}"`
        : 'Add a new category'
      }`}
      modalDescription="Modal for adding a new category"
      open={props.isAddSubcategoryModalOpen}
      handleClose={handleModalClose}
      testId="kbAddCategoryModal"
    >
      <ModalContentFormWrapper onSubmit={handleFormSubmit}>
        <FormControlLabel
          className="KnowledgeCategories__Checkbox"
          control={
            <Checkbox
              checked={
                !Object.values(languages)
                  .map(({ active }) => active)
                  .includes(false)
              }
              onChange={handleToggleSelectAllLanguages}
              name="Select All"
            />
          }
          label="Select All"
          data-testid="select-all-checkbox"
        />

        {Object.entries(languages).map(
          (
            [ language, {
              name, active,
            } ]: [
              string,
              { name: string; active: boolean },
            ], index: number
          ) => (
            <div
              className="KnowledgeCategories__Item"
              key={index}>
              <FormControlLabel
                className="KnowledgeCategories__Checkbox"
                control={
                  <Checkbox
                    checked={active}
                    onChange={_e => handleToggleLanguage(language)}
                    name={Locale[language as keyof typeof Locale]}
                    disabled={language === Locale.en}
                  />
                }
                label={`${localeToLanguage.get(
                  Locale[language as keyof typeof Locale]
                )} (${language})`}
              />
              <FormControlLabel
                className="KnowledgeCategories__TextInput"
                control={
                  <RenameTextField
                    value={name}
                    onChange={e =>
                      handleChangeTextForLanguage(e.target.value, language)}
                    placeholder={name}
                    variant={'outlined' as any}
                    disabled={!active}
                    inputProps={{ maxLength: 60 }}
                    helperText={languages[language].name.length === 0
                      || validInputMap[language] ? '' : 'The name contains characters that are not allowed.'}
                    data-testid={`text-input-${language}`}
                  />
                }
                label=""
              />
            </div>
          )
        )}

        {!isSubCategory && (
          <FormControl
            variant='standard'
            className="KnowledgeCategories__VisibilityControl"
            required
          >
            <InputLabel>Visibility Control</InputLabel>
            <Select
              value={visibility}
              label="Visibility Control"
              onChange={e => setVisibility(e.target.value as string)}
            >
              {Object.entries(KBVisibilityControls).map(
                (visibility: [string, string]) => (
                  <MenuItem
                    key={visibility[1]}
                    value={visibility[0]}>
                    {visibility[1]}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        )}

        <Button
          className="KnowledgeCategories__SubmitButton"
          isValid={!submitDisabled}
          disabled={submitDisabled}
          onClick={() => { }}
          text="Submit"
        />
      </ModalContentFormWrapper>
    </Modal>
  );
};

export default AddSubcategoryModal;
