import type {
  FacetProps,
  FacetValue,
  SearchEngine,
} from '@coveo/headless';
import { buildFacet } from '@coveo/headless';
import { Skeleton } from '@mui/lab';
import { IconButton } from '@mui/material';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ApolloIcon from '../../../../components/ApolloIcon';
import {
  DEFAULT_FACET_VALUES_COUNT,
  DOCS_COMBINED_VERSION_NAME,
  SearchResultSource,
  SearchResultSourceLabelMap,
} from '../../../../constants/search.constants';
import {
  isDocsSource,
  isDocsSourceActive,
  pushNewHashToUrl,
} from '../../../../utils/search';
import type { FacetComponentProps } from '../constants';
import FacetSearchBox from '../FacetSearchBox/FacetSearchBox';
import FacetCheckBox from '../reusable-components/FacetCheckBox/FacetCheckBox';
import { FacetFilterTypes } from '../types';

// arbitrarily large value
const MAX_NUMBER_OF_VALUES: number = 100000;

const DEFAULT_FACET_PROPS: FacetProps = {
  options: {
    // This is a dummy field since field is required
    // replaced when the final facet props are created
    field: '-',
    numberOfValues: MAX_NUMBER_OF_VALUES,
  },
};

const Container = styled.div`
  padding: 10px 0;
`;

const FilterLabel = styled.button`
  position: relative;
  text-align: left;
  display: block;
  background-color: white;
  border: none;
  font-size: 1.4em;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  padding-left: 0;
`;

const SkeletonFilterLabel = styled(Skeleton)`
  && {
    width: 100%;
    height: ${p => p.theme.spacing(2)}px;
    margin-bottom: ${p => p.theme.spacing(0.25)}px;
  }
`;

const SkeletonFacetSearchBox = styled(Skeleton)`
  && {
    width: 100%;
    height: ${p => p.theme.spacing(5)}px;
    margin: ${p => p.theme.spacing(2)}px 0;
  }
`;

const IconStyled = styled(ApolloIcon)`
  position: absolute;
  right: 0;
  top: 5%;
`;

const FilterMain = styled.div<{ isHidden: boolean }>`
  display: ${p => p.isHidden ? 'none' : 'block'};
  transition: all 0.5s ease-in-out;
  margin: ${p => p.theme.spacing(1)}px 0;
`;

const FilterOptionsContainer = styled.div<{ level: number }>(
  ({ level }) => `
    display: flex;
    padding-left: calc(${level} * 16px);
    flex-flow: column nowrap;
  `
);

const FacetCheckBoxContainer = styled.div`
  position: relative;
`;

const ShowChildrenToggle = styled(IconButton)`
  && {
    position: absolute;
    left: -32px;
    top: 8px;
  }
`;

const SkeletonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin: 10px 0;
`;

const NoOptionsText = styled.p`
  margin-top: ${p => p.theme.spacing(2)}px;
  color: ${p => p.theme.palette.grey[500]};
  text-align: center;
`;

const ShowToggleText = styled.p`
  font-size: 1.5rem;
  color: ${p => p.theme.palette.blue[500]};
  font-weight: 600;
  cursor: pointer;
`;

const SkeletonShowToggleText = styled(Skeleton)`
  && {
    width: 30%;
    height: ${p => p.theme.spacing(3)}px;
  }
`;

interface FacetNode {
  parentNode?: FacetNode;
  value?: FacetValue;
  childFacetNodes?: Record<string, FacetNode>;
  isChildrenVisible?: boolean;
}

interface Props {
  engine: SearchEngine;
  field: string;
  facetId: string;
  headerLabel?: string;
  facetProps?: FacetProps;
  hasSelectAll?: boolean;
  // delegate trigger searches to parent
  asyncToggleFunction?: (facetId: string, facetValues: FacetValue[]) => void;
}

const FacetFilter = (props: Props & FacetComponentProps) => {
  const {
    field,
    facetId,
    engine,
    facetProps,
    headerLabel,
    initialVisibleRootValues = DEFAULT_FACET_VALUES_COUNT,
    isHierarchal = false,
    showSearch = false,
    searchPlaceholder,
  } = props;

  const { t } = useTranslation('common');

  const finalFacetProps: FacetProps = useMemo(() => {
    const initialProps = facetProps ? facetProps : DEFAULT_FACET_PROPS;

    return {
      ...initialProps,
      options: {
        ...initialProps.options,
        field,
        facetId,
      },
    };
  }, [ facetProps ]);
  const facetControllerRef = useRef(buildFacet(engine, finalFacetProps));
  const [ facetControllerState, setFacetControllerState ] = useState(
    facetControllerRef.current.state
  );
  const [ isShowingAll, setIsShowingAll ] = useState(false);
  const [ facetNodes, setFacetNodes ] = useState<FacetNode[]>([]);
  const [ openParentNodes, setOpenParentNodes ] = useState<
  Record<string, boolean>
  >({});
  const [ totalSelected, setTotalSelected ] = useState(0);
  const [ hideFilterMenu, setHideFilterMenu ] = useState(false);
  const [ allDocsSources, setAllDocsSources ] = useState<string[]>([]);
  const [ allDocsVersions, setAllDocsVersions ] = useState<string[]>([]);

  const totalSelectedText = useMemo(
    () => (totalSelected > 0 ? `(${totalSelected})` : ''),
    [ totalSelected ]
  );

  const hasMoreFacetsToShow = useMemo(() => {
    const totalNumberOfParents = facetNodes.length;

    return totalNumberOfParents > initialVisibleRootValues;
  }, [ facetNodes.length, initialVisibleRootValues ]);

  useEffect(() => {
    if (field === FacetFilterTypes.CONTENT_TYPE) {
      setAllDocsSources(getAllDocsSources(facetNodes));
    } else if (field === FacetFilterTypes.VERSION && isDocsSourceActive()) {
      setAllDocsVersions(getAllDocsVersions(facetNodes));
    }
  }, [ facetNodes, field ]);

  useEffect(() => {
    const updateTotalSelected = () => {
      const updatedTotalSelected = facetControllerRef.current.state.values.reduce(
        (total, currFacetValue) =>
          facetControllerRef.current.isValueSelected(currFacetValue)
            ? total + 1
            : total,
        0
      );

      setTotalSelected(updatedTotalSelected);
    };

    const createFacetNode = (
      facetValue?: FacetValue,
      parentNode?: FacetNode,
      childFacetNodes?: Record<string, FacetNode>
    ): FacetNode => ({
      value: facetValue,
      parentNode,
      childFacetNodes,
    });

    const updateFacetNodes = () => {
      // sort ensures earlier paths come first
      let updatedFacetNodes: FacetNode[];
      // map of all available facet values
      // toggled parent will be removed from updated openParentNodes
      const allFacetValues: Record<string, boolean> = {};

      if (!isHierarchal) {
        updatedFacetNodes = facetControllerRef.current.state.values.map(
          (currFacetValue) => createFacetNode(currFacetValue)
        );
      } else {
        const sortedFacetValues = [
          ...facetControllerRef.current.state.values,
        ].sort(({ value: valuePathStringA }, { value: valuePathStringB }) => {
          const valuePathA = valuePathStringA.split('|');
          const valuePathB = valuePathStringB.split('|');

          if (valuePathA.length < valuePathB.length) {
            return -1;
          } else if (valuePathA.length > valuePathB.length) {
            return 1;
          }

          return 0;
        });

        const allNodes = sortedFacetValues.reduce(
          (baseNode: FacetNode, currFacetValue) => {
            if (currFacetValue.value) {
              allFacetValues[currFacetValue.value] = true;
              const currPath = currFacetValue.value.split('|');

              let currNode = baseNode;

              while (currPath.length > 0 && currNode) {
                const currDirectory = currPath.shift();
                if (!currDirectory) {
                  break;
                }
                if (!currNode.childFacetNodes) {
                  currNode.childFacetNodes = {};
                }

                if (!currNode.childFacetNodes[currDirectory]) {
                  currNode.childFacetNodes[currDirectory] = createFacetNode(
                    undefined,
                    currNode
                  );
                }

                currNode = currNode.childFacetNodes[currDirectory];
              }

              if (currNode) {
                currNode.value = currFacetValue;
              }
            }

            return baseNode;
          },
          { childFacetNodes: {} }
        );

        updatedFacetNodes = Object.values(allNodes.childFacetNodes || {}).map(
          (rootFacetNodes) => ({
            ...rootFacetNodes,
            parentNode: undefined,
          })
        );
      }

      setFacetNodes(updatedFacetNodes);

      // remove open parent nodes if they no longer exist as a facet
      setOpenParentNodes((prevOpenParentNodes) => {
        const updatedOpenParentNodes = { ...prevOpenParentNodes };
        Object.keys(prevOpenParentNodes).forEach((facetValue) => {
          if (!allFacetValues[facetValue]) {
            delete prevOpenParentNodes[facetValue];
          }
        });

        return updatedOpenParentNodes;
      });
    };

    const unsubscribeFacet = facetControllerRef.current.subscribe(() => {
      updateTotalSelected();
      updateFacetNodes();
      setFacetControllerState(facetControllerRef.current.state);
    });

    return () => {
      unsubscribeFacet();
    };
  }, []);

  const renderSearch = () => {
    if (!showSearch) {
      return null;
    }

    if (facetControllerState.isLoading) {
      return <SkeletonFacetSearchBox variant='rectangular' />;
    }

    return facetControllerRef.current.state.values.length > 0 ? (
      <FacetSearchBox
        controller={facetControllerRef.current.facetSearch}
        state={facetControllerState.facetSearch}
        placeholder={searchPlaceholder}
      />
    ) : null;
  };

  const toggleFacet = (facetNode: FacetNode) => {
    const updatedURLSearchParams = new URLSearchParams(
      window.location.hash.slice(1)
    );

    const allFacetsDiff: { [version: string]: boolean } = {};
    const allActiveFacets: Set<string> = new Set(
      updatedURLSearchParams.get(`f-${facetId}`)?.split(',')
    );

    const populateFacetsDiff = (facetNode: FacetNode) => {
      const currFacetValue = facetNode.value;
      const currChildNodes = facetNode.childFacetNodes;

      if (!currFacetValue) {
        return;
      }
      allFacetsDiff[currFacetValue.value] = currFacetValue.state === 'idle';

      if (!currChildNodes) {
        return;
      }
      Object.entries(currChildNodes).forEach(([ _, childNode ]) => {
        const parentNode = childNode.parentNode;
        // If the parent has the same state, then toggle on child to keep parity
        if (parentNode?.value?.state === childNode.value?.state) {
          populateFacetsDiff(childNode);
        }
      });
    };

    // Compose the diff object and update the active facets accordingly
    populateFacetsDiff(facetNode);
    Object.entries(allFacetsDiff).forEach(([ version, isActive ]) => {
      // Do for all Docs sources if user toggled the Docs facet checkbox
      if (isDocsSource(version)) {
        allDocsSources.forEach((source) => {
          isActive
            ? allActiveFacets.add(source)
            : allActiveFacets.delete(source);
        });
        return;
      } else if (field === FacetFilterTypes.VERSION && version === DOCS_COMBINED_VERSION_NAME) {
        allDocsVersions.forEach((v) => {
          isActive
            ? allActiveFacets.add(v)
            : allActiveFacets.delete(v);
        });
        return;
      }

      isActive
        ? allActiveFacets.add(version)
        : allActiveFacets.delete(version);
    });

    // Update the URL Params by using the active facets
    allActiveFacets.size
      ? updatedURLSearchParams.set(
        `f-${facetId}`,
        Array.from(allActiveFacets).map(encodeURIComponent)
          .join(',')
      )
      : updatedURLSearchParams.delete(`f-${facetId}`);

    // Create new query hash from URL Params
    const updatedURLSearchParamsText = Array.from(updatedURLSearchParams.entries())
      .map(([ key, val ]) => `${key}=${val}`)
      .join('&');

    pushNewHashToUrl(updatedURLSearchParamsText);
  };

  const handleParentToggle = (toggledFacetNode: FacetNode) => {
    const currFacetValue = toggledFacetNode?.value?.value;
    if (currFacetValue) {
      setOpenParentNodes((prevOpenParentFacetNodes) => {
        const updatedToggleValue = !prevOpenParentFacetNodes[currFacetValue];

        return {
          ...prevOpenParentFacetNodes,
          [currFacetValue]: updatedToggleValue,
        };
      });
    }
  };

  const onParentToggleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    clickedParent: FacetNode
  ) => {
    e.preventDefault();
    e.stopPropagation();
    handleParentToggle(clickedParent);
  };

  const getAllDocsSources = (nodes: FacetNode[]) => nodes.map((node) => node.value?.value ?? '')
    .filter(isDocsSource);

  const getAllDocsVersions = (nodes: FacetNode[]) => {
    const versions: string[] = [];

    const recurse = (node: FacetNode) => {
      versions.push(node.value?.value ?? '');
      if (node.childFacetNodes) {
        Object.values(node.childFacetNodes)
          .forEach(recurse);
      }
    };

    nodes.forEach((node) => {
      if (Array.from(Array(10).keys()).map(e => `${e}`)
        .includes(node.value?.value ?? '')) {
        recurse(node);
      }
    });

    return versions;
  };

  const applyCustomFacetFilterSorting = (nodes: FacetNode[]) => {
    // Keep 'Other' in the bottom of the list
    if (field === FacetFilterTypes.PRODUCT) {
      return nodes.sort(({ value: a }: FacetNode, { value: b }: FacetNode) => {
        if (a?.value === 'Other') {
          return 1;
        }
        if (b?.value === 'Other') {
          return -1;
        }
        return 0;
      });
    }

    return nodes;
  };

  const applyCustomFacetFilterGrouping = (nodes: FacetNode[], level: number) => {
    // Combine all Docs sources
    if (field === FacetFilterTypes.CONTENT_TYPE) {
      const newNodes: FacetNode[] = [];
      const firstIndex = nodes.findIndex((facet) => isDocsSource(facet.value?.value ?? ''));
      let newDocsNode: FacetNode = {};

      nodes.forEach((node, i) => {
        if (i === firstIndex) {
          newDocsNode = {
            ...nodes[firstIndex],
            value: {
              ...(nodes[firstIndex].value as FacetValue),
              value: SearchResultSource.DOCS,
            },
          };
          newNodes.push(newDocsNode);
          return;
        }

        if (isDocsSource(node.value?.value ?? '')) {
          newDocsNode.value!.numberOfResults = (node.value?.numberOfResults ?? 0) + (newDocsNode.value?.numberOfResults ?? 0);
          return;
        }

        newNodes.push(node);
      });

      return newNodes;
    } else if (isDocsSourceActive() && field === FacetFilterTypes.VERSION && level === 0) {
      const newNodes: FacetNode[] = [];
      const newCloudNode: FacetNode = {
        value: {
          value: DOCS_COMBINED_VERSION_NAME,
          state: 'idle',
          numberOfResults: 0,
        },
      };

      const combineAllCloudFacetNodes = (node: FacetNode) => {
        if (node.value?.state === 'selected') {
          newCloudNode.value!.state = 'selected';
        }

        if (node.childFacetNodes) {
          Object.values(node.childFacetNodes)
            .forEach(combineAllCloudFacetNodes);
        }
      };

      nodes.forEach((node) => {
        if (Array.from(Array(10).keys()).map(e => `${e}`)
          .includes(node.value?.value ?? '')) {
          newCloudNode.value!.numberOfResults += node.value?.numberOfResults ?? 0;
          combineAllCloudFacetNodes(node);
        } else {
          newNodes.push(node);
        }
      });

      return [ newCloudNode, ...newNodes ];
    }

    return nodes;
  };

  const renderCheckboxList = (currentNodesArr: FacetNode[], level: number = 0) => {
    let initialNodesToShow = applyCustomFacetFilterGrouping(
      applyCustomFacetFilterSorting(currentNodesArr),
      level
    );

    if (level === 0 && hasMoreFacetsToShow && !isShowingAll) {
      initialNodesToShow = initialNodesToShow.slice(0, initialVisibleRootValues);
    }

    return (
      <FilterOptionsContainer level={level}>
        {initialNodesToShow.map((currNode, idx) => {
          const currFacetValue = currNode.value;
          const currChildNodes = currNode.childFacetNodes;

          if (currFacetValue) {
            let facetLabel = isHierarchal
              ? currFacetValue.value.split('|').join('.')
              : currFacetValue.value;

            // For content type labels, apply custom rename and localization
            if (field === FacetFilterTypes.CONTENT_TYPE) {
              if (SearchResultSourceLabelMap[facetLabel]) {
                const sourceLabelRecord = SearchResultSourceLabelMap[facetLabel];
                facetLabel = t(
                  sourceLabelRecord.localText,
                  sourceLabelRecord.fallbackText
                );
              }
            }

            const areChildrenVisible = openParentNodes[currFacetValue.value];

            return (
              <FacetCheckBoxContainer key={`${facetLabel}-${level}`}>
                {currChildNodes ? (
                  <ShowChildrenToggle
                    onClick={(e: any) => onParentToggleClick(e, currNode)}
                    disableRipple
                    size='small'
                  >
                    <ApolloIcon
                      icon={!areChildrenVisible ? 'expand_more' : 'expand_less'}
                      fontSize='small'
                    />
                  </ShowChildrenToggle>
                ) : null}
                <FacetCheckBox
                  key={`${currFacetValue.value}-${idx}`}
                  facetValue={currFacetValue}
                  label={`${facetLabel} (${currFacetValue.numberOfResults})`}
                  onChange={() => toggleFacet(currNode)}
                />
                {currChildNodes && areChildrenVisible ? (
                  <>
                    {renderCheckboxList(
                      Object.values(currChildNodes),
                      level + 1
                    )}
                  </>
                ) : null}
              </FacetCheckBoxContainer>
            );
          }

          return null;
        })}
      </FilterOptionsContainer>
    );
  };

  const renderFilterOptions = () => {
    if (facetControllerState.isLoading) {
      return Array.apply(null, Array(DEFAULT_FACET_VALUES_COUNT)).map((_, idx) => (
        <SkeletonContainer key={`option-skeleton-${facetId}-${idx}`}>
          <Skeleton
            variant='rectangular'
            width={22}
            height={22} />
          <Skeleton
            width={200}
            height={22} />
        </SkeletonContainer>
      ));
    }

    return facetNodes.length > 0 ? (
      renderCheckboxList(facetNodes)
    ) : (
      <NoOptionsText>
        {t('search_page_filter_no_filters_available', 'No filters available for current selection')}
      </NoOptionsText>
    );
  };

  const renderShowMoreLessButton = () => {
    if (facetControllerState.isLoading) {
      return (
        <SkeletonShowToggleText variant='rectangular' />
      );
    }

    const buttonText = isShowingAll
      ? t('search_page_filter_show_less', 'Show less')
      : t('search_page_filter_show_all', 'Show all');

    return hasMoreFacetsToShow ? (
      <ShowToggleText onClick={() => setIsShowingAll((prev) => !prev)}>
        {buttonText}
      </ShowToggleText>
    ) : null;
  };

  const toggleMenu = () => {
    setHideFilterMenu((currHideFilterMenu) => !currHideFilterMenu);
  };

  return (
    <Container data-testid='FacetFilterContainer'>
      {facetControllerState.isLoading ? (
        <SkeletonFilterLabel variant='rectangular' />
      ) : (
        <FilterLabel onClick={toggleMenu}>
          {[ headerLabel, totalSelectedText ].join(' ')}
          <IconStyled icon={hideFilterMenu ? 'expand_more' : 'expand_less'} />
        </FilterLabel>
      )}
      <>
        <FilterMain isHidden={hideFilterMenu}>
          {renderSearch()}
          {renderFilterOptions()}
          {renderShowMoreLessButton()}
        </FilterMain>
      </>
    </Container>
  );
};

export default FacetFilter;
