import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import browsers from '../../assets/img/browsers.png';
import ErrorPage from '../../components/ErrorPage/ErrorPage';

const BrowserNotSupported = (props: any) => {
  const { t } = useTranslation('common');

  return (
    <>
      <Helmet>
        <title>{t('404_page_title', 'Browser Not Supported | Customer Portal')}</title>
      </Helmet>

      <ErrorPage
        isPublic
        errorType='browser-not-supported'
        header={t(
          'not_supported_browser_component_hero_text',
          'Browser Not Supported'
        )}
        description={t(
          'not_supported_browser_component_description',
          'For the best experience in using the Customer Portal, we recommend you use the following browsers.'
        )}
      >
        <div>
          <img
            src={browsers}
            alt='Supported Browsers'
          />
          <br />
          <br />
          <br />
          <a
            data-testid='ErrorPage-browser-not-supported__CTA'
            className='link'
            href='https://docs.uipath.com/customer-portal/other/latest/user-guide/software-requirements'
          >
            {t('not_supported_browser_component_link', 'Learn more')}
          </a>
        </div>
      </ErrorPage>
    </>
  );
};

export default BrowserNotSupported;
