import React, {
  useEffect,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../../assets/css/Knowledge/LinkOut';
// Assets
import AddIcon from '../../../assets/img/svg/kb/add_circle.svg';
import FileIcon from '../../../assets/img/svg/kb/file.svg';
import ForumIcon from '../../../assets/img/svg/kb/forum.svg';
// Helpers
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
// Components
import Container from '../../CustomerPortal-New-Container';
import AddNewCasePopOver from '../newCasePopover/AddNewCasePopOver';

const links = [
  {
    keyText: 'support_kb_link_out_component_ask_community_btn',
    fallbackText: 'Ask the Community',
    href: 'https://forum.uipath.com/',
    icon: ForumIcon,
    alt: 'Community Forum Icon',
  },
  {
    keyText: 'support_kb_link_out_component_check_docs_btn',
    fallbackText: 'Check out docs',
    href: 'https://docs.uipath.com/',
    icon: FileIcon,
    alt: 'Documentation Icon',
  },
  {
    keyText: 'support_kb_link_out_component_create_new_case_btn',
    fallbackText: 'Create New Case',
    icon: AddIcon,
    alt: 'Create New Case Icon',
  },
];

type SupportKBLinkOutProps = {
  screenName: string; // for analytics
};

const SupportKBLinkOut = ({ screenName }: SupportKBLinkOutProps) => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'SupportKBLinkOut';
  const canCreateNewCase = UserPermissionsHelper.isCreateIncidentCaseAllowed();

  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);
  const [ supportUrl, setSupportUrl ] = useState('');

  const handleButtonNewTicketClick = (e: any) => {
    if (!canCreateNewCase) {
      return;
    }

    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }
    setPopoverAnchorElem(e.currentTarget);
  };

  const handleCategoryOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  const onCaseCreateClick = (link: string) => {
    setSupportUrl(link);
  };

  useEffect(() => {
    if (supportUrl !== '') {
      const link = document.createElement('a');
      link.href = supportUrl;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    return () => {
      setSupportUrl('');
    };
  }, [ supportUrl ]);

  return (
    <Container>
      <styles.LinkOutContainer data-testid={`${baseClass}`}>
        <p
          className={`${baseClass}__Title`}
          data-testid={`${baseClass}__Title`}
        >
          {t(
            'support_kb_link_out_component_title',
            'Couldn\'t find what you are looking for?'
          )}
        </p>
        {links.map((link: IDataObject, idx: number) => {
          if (link.href) {
            return (
              <a
                key={idx}
                href={link.href}
                target="_blank"
                rel="noopener noreferrer"
                className={`${baseClass}__SupportSectionLink`}
                data-testid={`${baseClass}__SupportSectionLink`}
              >
                <img
                  src={link.icon}
                  alt={link.alt}
                  className={`${baseClass}__Link-Icon`}
                />
                <p className={`${baseClass}__Link-Text`}>
                  {t(link.keyText, link.fallbackText)}
                </p>
              </a>
            );
          } else if (canCreateNewCase) {
            return (
              <div
                key={idx}
                className={`${baseClass}__SupportSectionLink`}
                onClick={handleButtonNewTicketClick}
                data-testid={`${baseClass}__CreateCase`}
              >
                <div className={`${baseClass}__SupportSectionNewCase`}>
                  <img
                    src={link.icon}
                    alt={link.alt}
                    className={`${baseClass}__Link-Icon`}
                  />
                  <p className="SupportKBLinkOut__Link-Text">
                    {t(link.keyText, link.fallbackText)}
                  </p>
                </div>
                <AddNewCasePopOver
                  popoverAnchorElem={popoverAnchorElem}
                  handleCategoryOptionClose={handleCategoryOptionClose}
                  onCaseCreateClick={onCaseCreateClick}
                  screenName={screenName}
                />
              </div>
            );
          }
          return undefined;
        })}
      </styles.LinkOutContainer>
    </Container>
  );
};

export default SupportKBLinkOut;
