import { CollabWhiteListedFileExtensions } from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import {
  Trans,
  useTranslation,
} from 'react-i18next';
import {
  Redirect,
  useParams,
} from 'react-router-dom';

// styles
import * as styles from '../../assets/css/Collab/index';
import NoUserIcon from '../../assets/img/No_User_Profile.svg';
// Images
import CloudUp from '../../assets/img/svg/collab/Cloud_Up_Fill.svg';
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
import Button from '../../components/Button/Button';
import CustomerPortalCollabDoc from '../../components/collab/Collab-Doc';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
import MinimalHero from '../../components/CustomerPortal-MinimalHero';
// Components
import Container from '../../components/CustomerPortal-New-Container';
import CustomerPortalPagination from '../../components/CustomerPortal-Pagination';
import Modal from '../../components/Modal';
import SelectWithSearch from '../../components/Select-With-Search';
// Constants
import COLLAB_CONSTANTS from '../../constants/collab.constants';
import {
  COLLAB_ASSETS_URL,
  COLLAB_FOLDERS_URL,
} from '../../constants/network.constants';
import { COLLAB } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
// Interfaces
import type { ISearchState } from '../../interfaces/collab.interface';
import type { IDataObject } from '../../interfaces/dataObject.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Utils
import downloadDocument from '../../lib/customerPortalDownload.utils';
import { isFileAttachmentSupported } from '../../lib/file.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { searchReducer } from '../../reducers/collab_reducers';
import { getCloudPrefixedPath } from '../../utils/cloud';
import CustomerPortalPage404 from '../404';
import NoCompanyId from './../../components/CustomerPortal-NoCompanyId';
import { StoreContext } from './../../store';

/* This is the page component for Knowledge Base.
  Component consumed in route level
  Renders all the layout for all things knowledge base
*/
const CustomerPortalPageCollab = (props: any) => {
  const { t } = useTranslation('common');
  /* Default constants */
  const fileInput = React.createRef<any>();
  const docsPerPage = 10;
  const { fileId: folderId } = useParams<any>();

  // Translations
  const sortOptions: {
    [key: string]: { textKey: string; fallbackText: string };
  } = {
    Name: {
      textKey: 'collaboration_space_table_row_name',
      fallbackText: 'Name',
    },
    Date: {
      textKey: 'collaboration_space_table_row_date',
      fallbackText: 'Date',
    },
  };

  /* State */
  const searchParam = new URLSearchParams(window.location.search);
  const initialSearchState: ISearchState = {
    keyword: searchParam.get('q') || '',
    isCompleteSearching: false,
    documents: [],
    currentPage: parseInt(searchParam.get('page') || '1', 10),
    totalDocs: 0,
    sortBy: searchParam.get('s') || 'Date',
    sortDirection: parseInt(searchParam.get('d') || '-1', 10),
    skipResult:
      parseInt(searchParam.get('page') || '1', 10) === 1
        ? 0
        : (parseInt(searchParam.get('page') || '1', 10) - 1) * docsPerPage,
    folderViewId: folderId || '',
    folderViewName: '',
  };
  const [ folderNotFound, setFolderNotFound ] = useState(false);

  const isCreateAllowed = UserPermissionsHelper.isCreateCollabAllowed();
  const isViewAllowed = UserPermissionsHelper.isViewCollabAllowed();
  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ searchState, dispatchSearchAction ] = useReducer(
    searchReducer,
    initialSearchState
  );
  const [ searchInputField, setSearchInputField ] = useState(
    initialSearchState.keyword
  );
  const [ isSearchInputFieldActive, setSearchInputFieldActive ] = useState(
    initialSearchState.keyword ? true : false
  );
  // New folder
  const [ isNewFolderModalOpen, setIsNewFolderModalOpen ] = useState(false);
  const [ newFolderInputField, setNewFolderInputField ] = useState('');
  const [ newFolderErrorMessage, setNewFolderErrorMessage ] = useState('');
  const [ newFolderSubmitting, setNewFolderSubmitting ] = useState(false);
  // Delete document/folder
  const [ deleteSelectedDocument, setDeleteSelectedDocument ] = useState({
    fileId: '',
    fileName: '',
  });
  const [ isDeleteModalOpen, setIsDeleteModalOpen ] = useState(false);

  useTrackPageViewEvent(COLLAB);
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Collab');
  }, []);

  /* Effects */
  useEffect(() => {
    // Function fetches the documents to load
    const fetchDocuments = async () => {
      try {
        const result = await axios.get(
          `${
            folderId
              ? `${COLLAB_ASSETS_URL}/${folderId}`
              : `${COLLAB_ASSETS_URL}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              'x-selected-account': state.companyId,
              'x-auth-type': getAuthType(),
            },
            params: {
              company_id: state.companyId,
              keywords: initialSearchState.keyword,
              sort_by: initialSearchState.sortBy,
              sort_direction: initialSearchState.sortDirection,
              top: docsPerPage,
              skip: initialSearchState.skipResult,
            },
          }
        );

        if (result.data.data && result.data.statusCode === 200) {
          dispatchSearchAction({
            type: COLLAB_CONSTANTS.FETCH_SUCCESS,
            payload: {
              documents: result.data.data.docs,
              totalDocs: result.data.data.total_count,
            },
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    // Function to fetch name if in folder view
    const fetchFolderName = async () => {
      try {
        const result = await axios.get(`${COLLAB_FOLDERS_URL}/${folderId}`, {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
          params: { company_id: state.companyId },
        });
        if (result.data.data && result.data.statusCode === 200) {
          dispatchSearchAction({
            type: COLLAB_CONSTANTS.FETCH_FOLDER_SUCCESS,
            payload: { folderName: result.data.data },
          });
        }
      } catch (e) {
        if (e.response?.status === 404) {
          setFolderNotFound(true);
        }
        console.log(`Unable to fetch folder name: ${e.toString()}`);
      }
    };

    if (state.companyId !== '') {
      dispatchSearchAction({ type: COLLAB_CONSTANTS.FETCH_INIT });

      fetchDocuments();

      if (folderId) {
        fetchFolderName();
      }
    }
  }, [ props.location.pathname ]);

  /* Event Handlers */
  const handleUploadButtonClick = () => {
    if (!isCreateAllowed) {
      return;
    }
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileUploadInputChange = async (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    const file = e.target.files[0];

    if (file && file.size >= 250 * 1024 * 1024) {
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: t(
          'collaboration_space_file_size_error_msg',
          'Error uploading document: File exceeds 250MB'
        ),
      });
      return;
    }

    if (!isFileAttachmentSupported(file, CollabWhiteListedFileExtensions)) {
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: file.type.length ? t(
          'support_form_attachment_file_type_error',
          'Invalid file type ({{file_type}}) is not allowed! Instead, try zipping the file(s) and then attaching the result.',
          { file_type: file.type }
        ) : t(
          'support_form_attachment_no_file_type_error',
          'This file is not allowed! Instead, try zipping the file(s) and then attaching the result.'
        ),
      });
      return;
    }

    // Package into form Data
    const formData = new FormData();
    formData.set('file', file);
    formData.set('company_id', state.companyId);
    if (folderId) {
      formData.set('folder_id', folderId);
    }

    try {
      dispatchContext({
        type: 'setBannerType',
        payload: 'info',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: t(
          'collaboration_space_please_wait_info_msg',
          'File is uploading, it might take a while, you will receive notification when it’s uploaded. Please don\'t leave the page until the upload is finished.'
        ),
      });

      dispatchContext({
        type: 'setBannerAutoHide',
        payload: false,
      });

      const result = await axios.post(`${COLLAB_ASSETS_URL}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${await getAccessToken()}`,
          'x-selected-account': state.companyId,
          'x-auth-type': getAuthType(),
        },
      });

      if (result.status === 201 && result.data.data) {
        dispatchSearchAction({
          type: COLLAB_CONSTANTS.ASSET_UPLOADED,
          payload: {
            docsPerPage,
            _id: result.data.data.id,
            slug: result.data.data.slug,
            name: result.data.data.name,
            mime_type: result.data.data.mime_type,
            display_name: result.data.data.display_name,
            is_folder: false,
            created_on: result.data.data.created_on,
            created_by_uipath: state.isUiPath,
            created_by_name: state.userName,
            created_by: state.userId,
            created_by_user: { picture: state.profilePhoto || NoUserIcon },
          },
        });

        dispatchContext({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: t(
            'collaboration_space_file_upload_success_msg',
            `${result.data.data.display_name ||
              'File'} has successfully uploaded`,
            { fileName: result.data.data.display_name || 'File' }
          ),
        });

        dispatchContext({
          type: 'setBannerAutoHide',
          payload: true,
        });

        // Make url search bar back to first page
        props.history.push({
          search: `s=${searchState.sortBy}&d=${
            searchState.sortDirection
          }&page=${1}&q=${searchInputField}`,
        });
      }
    } catch (e) {
      const errorMessage =
        e.response?.data?.data
          ? `${t(
            'collaboration_space_file_upload_error_msg',
            'Error uploading document:'
          )} ${e.response.data.data}`
          : `${t(
            'collaboration_space_file_upload_error_msg',
            'Error uploading document:'
          )} ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
      dispatchContext({
        type: 'setBannerAutoHide',
        payload: true,
      });
    }
  };

  const handleFileClick = async (
    isFolder: boolean,
    fileId: string,
    slug: string
  ) => {
    if (isFolder) {
      // Change search URL
      props.history.push(`/collab-space/folders/${fileId}/${slug}`);
      dispatchSearchAction({
        type: COLLAB_CONSTANTS.FOLDER_CLICKED,
        payload: { folder_id: fileId },
      });
      setSearchInputField('');
    } else {
      // Download
      try {
        await downloadDocument(
          `${COLLAB_ASSETS_URL}/${fileId}`,
          { company_id: state.companyId },
          {
            headers: {
              Authorization: `Bearer ${await getAccessToken()}`,
              'x-selected-account': state.companyId,
            },
          }
        );
      } catch (e) {
        console.log(e.toString());
        const errorMessage =
          e.response?.data?.data
            ? `${t(
              'collaboration_space_file_download_error_msg',
              'Error downloading request:'
            )} ${e.response.data.data}`
            : `${t(
              'collaboration_space_file_download_error_msg',
              'Error downloading request:'
            )} ${e.toString()}`;
        dispatchContext({
          type: 'setBannerType',
          payload: 'error',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: errorMessage,
        });
      }
    }
  };

  // Submit search form
  const handleMiniFormSubmit = async (e: any, keyword?: string) => {
    if (e) {
      e.preventDefault();
    }

    // If currently on folder view, redirect to main page, otherwise dynamic
    if (folderId) {
      const tempAnchor = document.createElement('a');
      tempAnchor.href = getCloudPrefixedPath(`/collab-space?q=${searchInputField}`);
      document.body.appendChild(tempAnchor);
      tempAnchor.click();
      document.body.removeChild(tempAnchor);
      return;
    }

    updateKeyword(keyword);
  };

  // Helper function
  const updateKeyword = async (keyword?: string) => {
    const keywords = keyword !== undefined ? keyword : searchInputField;
    dispatchSearchAction({
      type: COLLAB_CONSTANTS.KEYWORD_UPDATED,
      payload: { keyword: keywords },
    });

    try {
      // Because search is always global
      const result = await axios.get(`${COLLAB_ASSETS_URL}`, {
        params: {
          company_id: state.companyId,
          sort_by: searchState.sortBy,
          sort_direction: searchState.sortDirection,
          skip: 0,
          keywords,
        },
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'x-selected-account': state.companyId,
          'x-auth-type': getAuthType(),
        },
      });
      if (result.data.data && result.data.statusCode === 200) {
        dispatchSearchAction({
          type: COLLAB_CONSTANTS.FETCH_SUCCESS,
          payload: {
            documents: result.data.data.docs,
            totalDocs: result.data.data.total_count,
          },
        });
      }
    } catch (e) {
      console.log('Error searching');
    }

    props.history.push({
      search: `s=${searchState.sortBy}&d=${
        searchState.sortDirection
      }&page=${1}&q=${keywords}`,
    });
  };

  const getSortOptionFromSelectedLabel = (label: string) =>
    Object.keys(sortOptions).find((sortField: string) => {
      const {
        textKey, fallbackText,
      } = sortOptions[sortField];
      return t(textKey, fallbackText) === label;
    });

  // When an option on sort is clicked
  const handleSortOptionClick = async (selectedOption: any) => {
    const type = getSortOptionFromSelectedLabel(selectedOption.label);
    const direction = selectedOption.value;

    dispatchSearchAction({
      type: COLLAB_CONSTANTS.SORT_UPDATED,
      payload: {
        sortBy: type,
        direction,
      },
    });

    // Make url and call backend
    try {
      const result = await axios.get(
        `${
          folderId ? `${COLLAB_ASSETS_URL}/${folderId}` : `${COLLAB_ASSETS_URL}`
        }`,
        {
          params: {
            company_id: state.companyId,
            sort_by: type,
            sort_direction: direction,
            skip: 0,
            keywords: searchState.keyword,
          },
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );
      if (result.data.data && result.data.statusCode === 200) {
        dispatchSearchAction({
          type: COLLAB_CONSTANTS.FETCH_SUCCESS,
          payload: {
            documents: result.data.data.docs,
            totalDocs: result.data.data.total_count,
          },
        });
      }
    } catch (e) {
      console.log('Error sorting');
    }

    props.history.push({ search: `q=${searchState.keyword}&s=${type}&d=${direction}&page=${1}` });
  };

  // Click of search icon
  const handleMiniSearchActive = () => {
    setSearchInputFieldActive(!isSearchInputFieldActive);

    if (isSearchInputFieldActive) {
      // Means closing active field
      setSearchInputField('');

      if (!folderId) {
        // If inside a folder, then do nothing
        updateKeyword('');
      }
    }
  };

  // Click of New Folder button
  const handleAddNewFolderClick = () => {
    setIsNewFolderModalOpen(true);
  };

  // Modal for New Folder
  const handleNewFolderModalClose = () => {
    setIsNewFolderModalOpen(false);
    setNewFolderErrorMessage('');
    setNewFolderInputField('');
    setNewFolderSubmitting(false);
  };

  const handleNewFolderSubmit = async (e: any) => {
    if (!e) {
      return;
    }
    e.preventDefault();

    if (!newFolderInputField) {
      setNewFolderErrorMessage('Field is mandatory');
    }

    try {
      const result: IDataObject = await axios.post(
        COLLAB_FOLDERS_URL,
        {
          folder_name: newFolderInputField,
          company_id: state.companyId,
        },
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );

      dispatchContext({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: t(
          'collaboration_space_folder_create_success_msg',
          `${newFolderInputField} has been created.`,
          { folderName: newFolderInputField }
        ),
      });
      // Add to front end document
      if (result.data.statusCode === 201 && result.data.data) {
        dispatchSearchAction({
          type: COLLAB_CONSTANTS.FOLDER_CREATED,
          payload: {
            _id: result.data.data._id,
            name: newFolderInputField.toLowerCase(),
            display_name: newFolderInputField,
            slug: result.data.data.slug,
            is_folder: true,
            created_on: result.data.data.created_on,
            created_by_uipath: state.isUiPath,
            created_by_name: state.userName,
            created_by: state.userId,
            created_by_user: { picture: state.profilePhoto || NoUserIcon },
          },
        });
      }
    } catch (e) {
      const errorMessage =
        e.response?.data?.data
          ? `${t(
            'collaboration_space_folder_create_error_msg',
            'Error creating folder:'
          )} ${e.response.data.data}`
          : `${t(
            'collaboration_space_folder_create_error_msg',
            'Error creating folder:'
          )} ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: `${errorMessage}`,
      });
      console.log(e);
    } finally {
      handleNewFolderModalClose();
    }
  };

  const handleDeleteDocOptionClick = (fileId: string, fileName: string) => {
    setDeleteSelectedDocument({
      fileId,
      fileName,
    });
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setDeleteSelectedDocument({
      fileId: '',
      fileName: '',
    });
    setIsDeleteModalOpen(false);
  };

  const handleDeleteDocumentSubmit = async (e: any) => {
    if (!e) {
      return;
    }

    e.preventDefault();

    try {
      const result = await axios.delete(COLLAB_ASSETS_URL, {
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'x-selected-account': state.companyId,
          'x-auth-type': getAuthType(),
        },
        data: {
          item_id: deleteSelectedDocument.fileId,
          company_id: state.companyId,
        },
      });

      if (result.data.data && result.data.statusCode === 204) {
        dispatchSearchAction({
          type: COLLAB_CONSTANTS.FILE_DELETED,
          payload: { fileId: deleteSelectedDocument.fileId },
        });

        dispatchContext({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: t(
            'collaboration_space_delete_file_success_msg',
            `${deleteSelectedDocument.fileName} has been deleted`,
            { fileName: deleteSelectedDocument.fileName }
          ),
        });
      }
    } catch (e) {
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      const errorMessage =
        e.response?.data?.data
          ? t(
            'collaboration_space_delete_file_error_msg',
            `Error deleting ${deleteSelectedDocument.fileName}:`,
            { fileName: deleteSelectedDocument.fileName }
          ) + e.response.data.data
          : t(
            'collaboration_space_delete_file_error_msg',
            `Error deleting ${deleteSelectedDocument.fileName}:`,
            { fileName: deleteSelectedDocument.fileName }
          ) + e.message;

      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    } finally {
      handleDeleteModalClose();
    }
  };

  // For handling pagination
  const handlePaginationClick = async (val: number) => {
    const newPageNum = val > 1 ? val - 1 : 0;
    const url = folderId
      ? `${COLLAB_ASSETS_URL}/${folderId}`
      : COLLAB_ASSETS_URL;

    try {
      const result = await axios.get(url, {
        params: {
          company_id: state.companyId,
          sort_by: searchState.sortBy,
          sort_direction: searchState.sortDirection,
          keywords: searchState.keyword,
          top: docsPerPage,
          skip: docsPerPage * newPageNum,
        },
        headers: {
          Authorization: `Bearer ${await getAccessToken()}`,
          'x-selected-account': state.companyId,
          'x-auth-type': getAuthType(),
        },
      });

      dispatchSearchAction({
        type: COLLAB_CONSTANTS.PAGE_CLICKED,
        payload: {
          pageNumber: val,
          skipResult: docsPerPage * newPageNum,
        },
      });

      if (result.data.data && result.data.statusCode === 200) {
        dispatchSearchAction({
          type: COLLAB_CONSTANTS.FETCH_SUCCESS,
          payload: {
            documents: result.data.data.docs,
            totalDocs: result.data.data.total_count,
          },
        });
      }
    } catch (e) {
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: `${t(
          'collaboration_space_retriving_page_error_msg',
          'Error retrieving page:'
        )} ${val}`,
      });
    }

    // Update location
    props.history.push({ search: `s=${searchState.sortBy}&d=${searchState.sortDirection}&page=${val}&q=${searchInputField}` });
  };

  // Handling back button on a folder drilled down
  const handleFolderBackButtonClick = () => {
    // Hard load front page of collab
    const anchor = document.createElement('a');
    anchor.href = getCloudPrefixedPath('/collab-space');
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  /* Dynamic generate display */
  const generateHero = () => {
    if (!folderId) {
      return (
        <BasicHero
          title={t(
            'collaboration_space_hero_title',
            `${state.companyName || ''}\n Collaboration Space`,
            {
              customer_name: state.companyName || '',
              new_line: '\n',
            }
          )}
          subtitle={t(
            'collaboration_space_hero_description',
            `A secure space where you can upload, share, and organize documents. The documents you upload in this Collaboration section are shared between your team and your dedicated UiPath contacts who are bound by strict confidentiality agreements. Please share only those documents that you are allowed to, in accordance with your Company's internal regulations.`
          )}
        />
      );
    }
    return (
      <MinimalHero
        backButton
        handleBackButtonClick={handleFolderBackButtonClick}
      />
    );

  };

  const generateResultsTextHeading = () => {
    if (folderId) {
      return (
        <h2 className="CustomerPortalCollabResults__Header">
          {searchState.folderViewName}
        </h2>
      );
    }

    return (
      <h2 className="CustomerPortalCollabResults__Header">
        {t('collaboration_space_body_title', 'Browse all documents')}
      </h2>
    );
  };

  const generateUploadContainerClassName = () => {
    let className = 'CustomerPortalCollab__Upload-Container';
    if (folderId) {
      className = className + ' CustomerPortalCollab__Upload-Container--Folder';
    }
    return className;
  };

  const generateNewFolderButton = () => {
    if (folderId) {
      return null;
    }

    return (
      <styles.CreateFolderButton
        data-testid="Collab__CreateFolderBtn"
        onClick={handleAddNewFolderClick}
        disabled={!isCreateAllowed}
      >
        <span className="CreateButton__Text">
          {t('collaboration_space_button_new_folder', 'New Folder')}
        </span>
      </styles.CreateFolderButton>
    );
  };

  /* Return if no company id selected */
  if (state.companyId === '') {
    return (
      <>
        <Helmet>
          <title>
            {t(
              'collab_space_page_title',
              'Collaboration Space | Customer Portal'
            )}
          </title>
        </Helmet>
        <NoCompanyId />
      </>
    );
  } else if (!isViewAllowed) {
    return <Redirect to="/unauthorized" />;
  } else if (folderNotFound) {
    return <CustomerPortalPage404 />;
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'collab_space_page_title',
            'Collaboration Space | Customer Portal'
          )}
        </title>
      </Helmet>

      <styles.PageWrapper>
        {generateHero()}

        <Container cssClass={generateUploadContainerClassName()}>
          <styles.UploadContainerInner
            className="CustomerPortalCollab__Upload-Container--Inner"
            onClick={handleUploadButtonClick}
            disabled={!isCreateAllowed}
          >
            <img
              className="CustomerPortalCollab__Upload"
              src={CloudUp}
              alt="Upload"
              data-testid="uploadDocButton"
            />
          </styles.UploadContainerInner>
          <form className="CustomerPortalUploadForm">
            <input
              className="CustomerPortalUploadForm__Input"
              name="fileInput"
              type="file"
              onChange={handleFileUploadInputChange}
              ref={fileInput}
            />
          </form>
        </Container>

        {!searchState.isCompleteSearching && (
          <Container cssClass="CustomerPortalCollabResults">
            <CustomerPortalLoader />
          </Container>
        )}

        {searchState.isCompleteSearching && (
          <Container
            cssClass="CustomerPortalCollabResults"
            dataTestId="CollabSearchCompletedContainer"
          >
            <styles.CollabResultsHeader>
              {generateResultsTextHeading()}
              <div className="CustomerPortalCollabResults__clientsActions">
                <SelectWithSearch
                  options={[
                    {
                      label: t(
                        sortOptions.Name.textKey,
                        sortOptions.Name.fallbackText
                      ),
                      value: 1,
                    },
                    {
                      label: t(
                        sortOptions.Date.textKey,
                        sortOptions.Date.fallbackText
                      ),
                      value: -1,
                    },
                  ]}
                  searchable={false}
                  value={searchState.sortBy}
                  className="Custom-Select--White CustomerPortalClientsSelection__clientsSort"
                  onChange={handleSortOptionClick}
                  placeholder={
                    <Trans
                      t={t}
                      i18nKey="collaboration_space_dropdown_sort_by"
                      defaults="Sort by: <span>{{sort_field}}</span>"
                      values={{
                        sort_field: t(
                          sortOptions[searchState.sortBy!].textKey,
                          sortOptions[searchState.sortBy!].fallbackText
                        ),
                      }}
                      components={{
                        span: (
                          <span className="Custom-Select__Placeholder-Value" />
                        ),
                      }}
                    />
                  }
                />

                <div
                  className="CustomerPortalClientsSelection__clientsSearch"
                  onClick={handleMiniSearchActive}
                >
                  <div
                    className={`CustomerPortalClientsSelection__clientsSearchInput${
                      isSearchInputFieldActive
                        ? ' CustomerPortalClientsSelection__clientsSearchInput--Active'
                        : ''
                    }`}
                  >
                    <form onSubmit={handleMiniFormSubmit}>
                      {isSearchInputFieldActive && (
                        <input
                          autoFocus
                          placeholder={t(
                            'collaboration_space_dropdown_keyword_placeholder',
                            'Keyword'
                          )}
                          type="text"
                          value={searchInputField}
                          onChange={e => {
                            setSearchInputField(e.target.value);
                          }}
                        />
                      )}
                    </form>
                  </div>
                  <div
                    className={`CustomerPortalClientsSelection__clientsSearchIcon${
                      isSearchInputFieldActive
                        ? ' CustomerPortalClientsSelection__clientsSearchIcon--Close'
                        : ''
                    }`}
                  />
                </div>
                {generateNewFolderButton()}
              </div>
            </styles.CollabResultsHeader>

            <div className="CustomerPortalCollabResults__Table-Header">
              <span className="CustomerPortalCollabResults__Table-Label">
                {t('collaboration_space_table_row_name', 'Name')}
              </span>
              <span className="CustomerPortalCollabResults__Table-Label">
                {t('collaboration_space_table_row_author', 'Author')}
              </span>
              <span className="CustomerPortalCollabResults__Table-Label">
                {t('collaboration_space_table_row_date', 'Date')}
              </span>
            </div>
            {searchState.documents.map((docObj: any) => {
              const userHasAvatar =
                docObj.created_by_user?.picture &&
                docObj.created_by_user?.picture_storage_account_name;
              let profilePhoto;
              if (userHasAvatar) {
                profilePhoto = `${docObj.created_by_user.picture_storage_account_name}/${docObj.created_by_user.picture}`;
              }
              return (
                <CustomerPortalCollabDoc
                  key={docObj._id}
                  fileId={docObj._id}
                  isFolder={docObj.is_folder}
                  mimeType={docObj.mime_type}
                  fileName={docObj.display_name}
                  createdByUiPath={docObj.created_by_uipath}
                  authorName={
                    docObj.created_by_uipath ? 'UiPath' : docObj.created_by_name
                  }
                  authorId={docObj.created_by}
                  authorAvatar={profilePhoto}
                  slug={docObj.slug}
                  dateString={docObj.created_on}
                  handleFileClick={handleFileClick}
                  handleDeleteDocClick={handleDeleteDocOptionClick}
                />
              );
            })}
            <CustomerPortalPagination
              className="CustomerPortalPagination"
              activePage={searchState.currentPage}
              numResults={searchState.totalDocs}
              numResultsPerPage={docsPerPage}
              handleClick={handlePaginationClick}
              numPagesBeforeEllipses={5}
            />

            {/* New Folder Modal */}
            {isNewFolderModalOpen && (
              <Modal
                modalTitle={t(
                  'collaboration_space_new_folder_modal_title',
                  'Create a new folder'
                )}
                modalDescription="Create a new folder modal"
                open={isNewFolderModalOpen}
                handleClose={handleNewFolderModalClose}
                modalHeading={t(
                  'collaboration_space_new_folder_modal_title',
                  'Create a new folder'
                )}
                testId="Collab__NewFolderModal"
              >
                <styles.FormWrapper onSubmit={handleNewFolderSubmit}>
                  <styles.TextInput
                    value={newFolderInputField}
                    variant={'outlined' as any}
                    id="CollabNewFolderInputField"
                    onChange={(e: any) => {
                      setNewFolderInputField(e.target.value);
                    }}
                    placeholder={t(
                      'collaboration_space_new_folder_modal_input_placeholder',
                      'Insert folder name...'
                    )}
                    helperText={
                      newFolderErrorMessage ? newFolderErrorMessage : undefined
                    }
                  />
                  <Button
                    className="NewFolderButton"
                    isValid={newFolderSubmitting ? true : undefined}
                    text={
                      newFolderSubmitting
                        ? t(
                          'collaboration_space_new_folder_modal_saving_btn_text',
                          'Saving...'
                        )
                        : t(
                          'collaboration_space_new_folder_modal_save_btn_text',
                          'Save'
                        )
                    }
                    onClick={() => {
                      setNewFolderSubmitting(true);
                    }}
                  />
                </styles.FormWrapper>
              </Modal>
            )}

            {/* Delete File Modal */}
            {isDeleteModalOpen && (
              <Modal
                modalTitle="Delete Document or Folder"
                modalDescription="Delete Document or Folder Modal"
                open={isDeleteModalOpen}
                handleClose={handleDeleteModalClose}
                modalHeading={t(
                  'collaboration_space_delete_file_modal_msg',
                  `Are you sure you want to delete ${deleteSelectedDocument.fileName}?`,
                  { fileName: deleteSelectedDocument.fileName }
                )}
              >
                <styles.FormWrapper onSubmit={handleDeleteDocumentSubmit}>
                  <Button
                    className="collab_submit_delete_button"
                    text={t(
                      'collaboration_space_delete_file_modal_btn',
                      'Submit'
                    )}
                    onClick={() => {}}
                  />
                </styles.FormWrapper>
              </Modal>
            )}
          </Container>
        )}
      </styles.PageWrapper>
    </>
  ); // End of return
};

export default CustomerPortalPageCollab;
