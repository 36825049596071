import type {
  FacetValueRequest,
  SearchEngine,
} from '@coveo/headless';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { QUERY_TEXT_URL_KEY } from '../../../../constants/search.constants';
import { pushNewHashToUrl } from '../../../../utils/search';

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: ${p => p.theme.spacing(2)}px;
  .reset-button {
    font-size: 1.5rem;
    color: ${p => p.theme.palette.blue[500]};
    font-weight: 600;
    cursor: pointer;
  }
`;

const Main = styled.div`
  margin: ${p => p.theme.spacing(4)}px ${p => p.theme.spacing(6)}px;
`;

type Props = {
  engine: SearchEngine;
  children?: any;
};

type FacetValueRequestWithId = FacetValueRequest & { facetId: string };

const FacetsMenu = (props: Props) => {
  const { children } = props;
  const { t } = useTranslation('common');

  // clear query params
  const resetAllFacetValues = () => {
    // TODO: See if we can remove eslint-disable and use window?.location.hash instead
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (typeof window !== 'undefined' && window.location.hash) {
      const hashParams = window.location.hash.slice(1);
      const searchQuery = new URLSearchParams(hashParams).get(QUERY_TEXT_URL_KEY);
      if (searchQuery) {
        const resetSearchParams = new URLSearchParams();
        resetSearchParams.set(QUERY_TEXT_URL_KEY, searchQuery);
        pushNewHashToUrl(resetSearchParams.toString());
      }
    }
  };

  const renderTitleSection = () => (
    <TitleSection>
      <div>{t('search_page_filter_filter_by', 'Filter by')}</div>
      <div
        className='reset-button'
        onClick={resetAllFacetValues}
      >
        {t('search_page_filter_reset', 'Reset')}
      </div>
    </TitleSection>
  );

  return (
    <>
      <Main>
        {renderTitleSection()}
        {children}
      </Main>
    </>
  );
};

export default FacetsMenu;
