// Dependencies
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
// Components
import Popover from '@mui/material/Popover';
import React, { useContext } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';

import type { HeaderProps } from '../assets/css/CustomerPortal-Header';
import ArrowRight from '../assets/img/svg/arrows/ArrowLong_Right.svg';
import ArrowRightHover from '../assets/img/svg/arrows/ArrowLong_Right_Blue.svg';
// Images
import DownArrow from '../assets/img/svg/arrows/Downward_Arrow.svg';
// Nav Icons
import { transitionStandard } from '../assets/js_mixins/transitions';
import type {
  HeaderItem,
  HeaderNavItem,
} from '../constants/header.constants';
import { HeaderNavHeirarchy } from '../constants/header.constants';
import type { IDataObject } from '../interfaces/dataObject.interface';
import { UserPermissionsHelper } from '../lib/UserPermissions';
import { matchesUrlPattern } from '../utils';
import { getUrlWithAllParams } from '../utils/cloud';
import { StoreContext } from './../store';
import ApolloIcon from './ApolloIcon';

const MainMenuLinkContainer = styled.div<HeaderProps>`
  .CustomerPortalMainMenuLink {
    &.No-Click {
      opacity: 0.1;
      cursor: default;
      .CustomerPortalMainMenuLink__Text {
        cursor: default;
        pointer-events: none;
      }
    }

    &__Text {
      display: flex;
      width: max-content;
      &-Link {
        line-height: 2.4rem;
      }
      &-Icon {
        font-size: 2.4rem;
        @media (max-width: 1393px) {
          font-size: 2rem;
          margin-top: 2px;
        }
      }
    }

    position: relative;
    cursor: pointer;
    ${props => props.theme.breakpoints.up('md')} {
      margin-left: 12px;
      margin-right: 12px;
    }

    a,.CustomerPortalMainMenuLink__Parent-Text {
      color: ${props => props.theme.palette.ink[700]};
      font-size: 1.4rem;
      font-weight: 600;
      ${transitionStandard('color')};

      &:hover {
        color: ${props => props.theme.palette.blue[500]};
      }

      &.Open {
        color: ${props => props.theme.palette.blue[500]};
      }

      @media (min-width: 1238px) and (max-width: 1393px) {
        font-size: ${p => p.headerCount && p.headerCount > 4 ? '1.1rem' : '1.4rem'};
      }
    }

    ${props => props.theme.breakpoints.up('md')} {
      .CustomerPortalMainMenuLink__Text--orange {
        color: #FA4616;

        &:after {
          content: none;
        }
      }
    }

    a {
      &:after {
        content: "";
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -${p => p.theme.spacing(1.5)}px;
        ${transitionStandard('opacity')};
        @media (min-width: 1238px) {
          border-bottom: 5px solid ${props => props.theme.palette.blue[500]};
        }
        @media (max-width: 1393px) {
          bottom: -${p => p.theme.spacing(1.8)}px;
        }
        opacity: 0;
      }

      &.Active-Page {
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .CustomerPortalMainMenuLink__Mobile-Drawer {
    padding: 0 16px !important;
    .CustomerPortalMainMenuLink__Text {
      height: 64px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .CustomerPortalMainMenuLink__Mobile-Drawer,.CustomerPortalMainMenuLink__ExpansionPanel-Summary {
    border-left: 4px solid white;
    background: white;
    ${transitionStandard('all')};
    &:hover {
      border-left: 4px solid ${props => props.theme.palette.blue[500]};
      background: ${props => props.theme.palette.grey[100]};
      .CustomerPortalMainMenuLink__Text {
        color: ${props => props.theme.palette.blue[500]};
      }
    }
  }

  .CustomerPortalMainMenuLink__Text {
    cursor: pointer;

    &:before {
      position: absolute;
      left: 24px;
      width: 14px;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);
      background-size: 14px 14px;
      background-repeat: no-repeat;
      background-position: center;
      ${props => props.theme.breakpoints.up('md')} {
        left: 2px;
      }
    }
  }

  .MainMenuDropdown__Outer {
    cursor: pointer;
    .MuiPopover-paper {
      top: 80px !important;
      width: 180px !important;
      border-radius: 8px;
      -webkit-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
      -moz-box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
      box-shadow: 0px 16px 24px 0px rgba(3,5,8,0.1);
    }
  }

  .CustomerPortalMainMenuLink__ExpansionPanel {
    border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
    &:before {
      display: none;
    }
    &.Mui-disabled {
      background-color: unset;
      .MuiExpansionPanelSummary-root {
        opacity: 1;
      }
      .MuiExpansionPanelSummary-expandIcon {
        opacity: 0.1;
      }
    }
    &.MuiExpansionPanel-root.Mui-expanded {
      margin: 0;
      border-bottom: none;
      &:before {
        opacity: 1;
      }
    }
    .MuiExpansionPanelSummary-root,.MuiExpansionPanelSummary-root.Mui-expanded {
      min-height: 0;
      padding: 0;
      border-bottom: none;
      border-left: 4px solid white;
      &:hover {
        border-left: 4px solid ${props => props.theme.palette.blue[500]};
      }
    }
    .MuiExpansionPanelSummary-content,.MuiExpansionPanelSummary-content.Mui-expanded {
      margin: 0;
    }
    .MuiExpansionPanelDetails-root {
      padding: 18px 32px;
    }
    .MuiExpansionPanelSummary-content {
      padding: 16px 24px 16px 0;
    }
    .MuiIconButton-edgeEnd {
      margin-right: 8px;
    }
  }

  .CustomerPortalMainMenuLink__ExpansionPanel--User {
    border: 1px solid #E3E3E3;
    border-radius: 0 !important;
    margin-bottom: 16px !important;
    .MuiExpansionPanelSummary-root,.MuiExpansionPanelSummary-root {
      border-left: none;
      &:hover {
        border-left: none;
      }
      &.Mui-expanded {
        border-bottom: 1px solid #E3E3E3;
        border-left: none;
        &:hover {
          border-left: none;
        }
      }
    }
    .MuiExpansionPanelSummary-content {
      padding: 0;
    }
  }

  .CustomerPortalMainMenuLink__Mobile-Drawer {
    padding: 0;
    cursor: pointer;
    border-bottom: 1px solid ${props => props.theme.palette.grey[100]};
  }

  .CustomerPortalMainMenuLink__ExpansionPanel-Details {
    background: ${props => props.theme.palette.grey[100]};
    cursor: pointer;
    border-left: 4px solid ${props => props.theme.palette.grey[100]};
    ${transitionStandard('all')};
    position: relative;
    border-bottom: 1px solid ${props => props.theme.palette.grey[300]};
    padding: 16px 16px 16px;
    &:after {
      content: "";
      ${transitionStandard('background-image')};
      background-image: url("${ArrowRight}");
      position: absolute;
      right: 20px;
      width: 14px;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      border-left: 4px solid ${props => props.theme.palette.blue[500]};
      .CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link {
        color: ${props => props.theme.palette.blue[500]};
      }
      &:after {
        content: "";
        background-image: url("${ArrowRightHover}");
      }
    }
  }

  .CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.70;
    color: ${props => props.theme.palette.ink[600]};
    text-decoration: none;
  }

  .dropdown-arrow {
    transition: transform 0.3s ease;
  }
  .dropdown-arrow.rotate {
    transform: rotate(-180deg);
  }
`;

const MenuDropdownOuter = styled(Popover)`
  cursor: pointer;
  .MuiPopover-paper {
    top: 56px !important;
    width: 208px !important;
    -webkit-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    -moz-box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }
`;

const MenuDropdownInner = styled.div`
  padding: 4px 0;
  .CustomerPortalMainMenuLink__Dropdown-Link {
    display: block;
    font-family: ${p => p.theme.typography.fontFamily};
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2.4rem;
    color: ${props => props.theme.palette.ink[600]};
    text-decoration: none;
    position: relative;
    padding: 8px 24px;
    background: white;
    ${transitionStandard('color')};
    ${transitionStandard('background')};
    &:after {
      content: "";
      ${transitionStandard('background-image')};
      background-image: url("${ArrowRight}");
      position: absolute;
      right: 12px;
      width: 14px;
      height: 14px;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-position: center;
    }
    &:hover {
      text-decoration: none;
      color: ${props => props.theme.palette.blue[500]};
      background: ${props => props.theme.palette.grey[100]};
      &:after {
        content: "";
        background-image: url("${ArrowRightHover}");
      }
    }
  }
`;

interface MainMenuLinkProps {
  headerCount?: number;
  data: HeaderItem & { link: { text?: string } };
  isMobile?: boolean;
  dropdownMenuLinks?: { [key: string]: any };
  [key: string]: any;
  handleDrawerClose?: any;
}

const CustomerPortalMainMenuLink = (props: MainMenuLinkProps) => {
  const { state } = useContext(StoreContext);
  const location = useLocation();
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const mainMenuLink = props.data;
  const headerCount = props.headerCount;

  const isAllowedViewCollab = UserPermissionsHelper.isViewCollabAllowed();
  const isAllowedViewSupport = UserPermissionsHelper.isViewSupportAllowed();
  const isAllowedViewPremiumAccount = UserPermissionsHelper.isViewPremiumSupportAllowed();
  const isAllowedViewLicense = UserPermissionsHelper.isViewLicenseAllowed();
  const fullURL = getUrlWithAllParams(location as unknown as Location);
  const { t } = useTranslation('common');

  let isActive = '';

  // Used to toggle if main menu item should be highlighted as active
  if (HeaderNavHeirarchy.get(mainMenuLink.link.id as HeaderNavItem)?.some(
    (href => matchesUrlPattern(fullURL, href))
  )) {
    isActive = 'Active-Page';
  }

  const linkIsDisabled = () => {
    // Always show documentation, downloads, and enablement
    if (mainMenuLink.link.textKey === 'header_menu_documentation') {
      return false;
    } else if (mainMenuLink.link.textKey === 'header_menu_product_downloads') {
      return false;
    } else if (mainMenuLink.link.textKey === 'header_menu_enablement') {
      return false;
    } else if (mainMenuLink.link.textKey === 'header_menu_collaboration') {
      return !isAllowedViewCollab;
    } else if (mainMenuLink.link.textKey === 'header_menu_company') {
      return !state.companyId?.length;
    } else if (mainMenuLink.link.textKey === 'header_menu_company_request_for_quote') {
      return !isAllowedViewLicense;
      // We use `id` here because the textKey is dependent on the company's maintenance flag
    } else if (mainMenuLink.link.id === 'premiumAccount') {
      return !isAllowedViewPremiumAccount || !isAllowedViewSupport;
    } else if (mainMenuLink.link.textKey === 'header_menu_support') {
      return !isAllowedViewSupport;
    }

    return true;
  };

  const linkDisabled = linkIsDisabled();

  const linkClasses = (isMobile: boolean) => {
    const menuOpen = Boolean(anchorEl);
    const base = 'CustomerPortalMainMenuLink__Text';
    const specificLinkClass = `${base}--${mainMenuLink.link.id}`;
    const isOpen = menuOpen ? 'Open' : '';
    const mobileLink = isMobile ? `${base}--Mobile` : '';

    return `${base} ${isOpen} ${isActive} ${specificLinkClass} ${mobileLink}`;
  };

  const handleParentLinkClick = (event: any) => {
    event.preventDefault();
    if (anchorEl !== event.currentTarget && !linkDisabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDisabledClick = (event: any) => {
    event.preventDefault();
  };

  const generateLink = () => {
    if (mainMenuLink?.dropdownChildren) {
      return (
        <Link
          className={linkClasses(false)}
          to={mainMenuLink.link.href}
          aria-owns={anchorEl ? 'CustomerPortalMainMenuLink__Menu' : undefined}
          aria-haspopup="true"
          onClick={handleParentLinkClick}
        >
          <div className='CustomerPortalMainMenuLink__Text-Link'>
            {t(mainMenuLink.link.textKey, mainMenuLink.link.fallbackText)}
          </div>
          <ApolloIcon
            className={`CustomerPortalMainMenuLink__Text-Icon dropdown-arrow ${!anchorEl ? 'rotate' : ''}`}
            icon='expand_less'
          />
        </Link>
      );
    }
    return (
      <Link
        {...(mainMenuLink.link.newTab ? {
          target: '_blank',
          rel: 'noopener noreferrer',
          to: { pathname: mainMenuLink.link.href },
        } : { to: mainMenuLink.link.href })}
        className={linkClasses(false)}
        aria-haspopup="false"
        onClick={linkDisabled ? handleDisabledClick : undefined}
      >
        {t(mainMenuLink.link.textKey, mainMenuLink.link.fallbackText)}
      </Link>
    );

  };

  const generateChildLink = () => {
    const childLink = mainMenuLink.dropdownChildren.map(
      (listItem: IDataObject, i: number) => listItem.link.absolute ? (
        <a
          key={i}
          href={listItem.link.href}
          {...(listItem.link.newTab && {
            target: '_blank',
            rel: 'noopener noreferrer',
          })}
          className="CustomerPortalMainMenuLink__Dropdown-Link"
          data-testid="MainMenuDropDownChildren"
        >
          {t(listItem.link.textKey, listItem.link.fallbackText)}
        </a>
      ) : (
        <Link
          key={i}
          {...(listItem.link.newTab ? {
            target: '_blank',
            rel: 'noopener noreferrer',
            to: { pathname: listItem.link.href },
          } : { to: listItem.link.href })}
          className="CustomerPortalMainMenuLink__Dropdown-Link"
          data-testid="MainMenuDropDownChildren"
        >
          {t(listItem.link.textKey, listItem.link.fallbackText)}
        </Link>
      ));
    return childLink;
  };

  const generateMainMenuLink = () => {
    const canClickClass = linkDisabled ? 'No-Click' : 'Can-Click';
    if (props.isMobile) {
      if (mainMenuLink.dropdownChildren) {
        return (
          <Accordion
            className={`CustomerPortalMainMenuLink__ExpansionPanel CustomerPortalMainMenuLink__ExpansionPanel--${mainMenuLink.link.id} ${canClickClass}`}
            elevation={0}
            disabled={linkDisabled}
          >
            <AccordionSummary
              expandIcon={<img
                src={DownArrow}
                alt="DownArrow" />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className="CustomerPortalMainMenuLink__ExpansionPanel-Summary"
            >
              <div
                className={`CustomerPortalMainMenuLink ${canClickClass}`}
                data-testid={`MainMenuLink ${mainMenuLink.link.text}`}
              >
                <p
                  className={`${linkClasses(
                    true
                  )} CustomerPortalMainMenuLink__Parent-Text`}
                  aria-haspopup="false"
                >
                  {t(mainMenuLink.link.textKey, mainMenuLink.link.fallbackText)}
                </p>
              </div>
            </AccordionSummary>
            {mainMenuLink.dropdownChildren.map(
              (listItem: IDataObject, i: number) => (
                <Link
                  to={listItem.link.href}
                  onClick={props.handleDrawerClose}
                  className={canClickClass}
                  key={i}
                  data-testid="MainMenuDropDownChildren"
                >
                  <AccordionDetails className="CustomerPortalMainMenuLink__ExpansionPanel-Details">
                    <div className="CustomerPortalMainMenuLink__Inner--Mobile-Drawer">
                      <p className="CustomerPortalMainMenuLink__ExpansionPanel-Dropdown-Link">
                        {t(
                          listItem.link.textKey,
                          listItem.link.fallbackText
                        )}
                      </p>
                    </div>
                  </AccordionDetails>
                </Link>
              )
            )}
          </Accordion>
        );
      }
      return (
        <div
          className={`CustomerPortalMainMenuLink CustomerPortalMainMenuLink__Mobile-Drawer ${canClickClass}`}
          data-testid={`MainMenuLink ${t(
            mainMenuLink.link.textKey,
            mainMenuLink.link.fallbackText
          )}`}
        >
          <Link
            className={linkClasses(true)}
            onClick={props.handleDrawerClose}
            to={mainMenuLink.link.href}
            aria-haspopup="false"
          >
            {t(mainMenuLink.link.textKey, mainMenuLink.link.fallbackText)}
          </Link>
        </div>
      );

    }
    return (
      <li
        className={`CustomerPortalMainMenuLink ${canClickClass}`}
        data-testid={`MainMenuLink ${t(
          mainMenuLink.link.textKey,
          mainMenuLink.link.fallbackText
        )}`}
      >
        {generateLink()}
        {mainMenuLink.dropdownChildren && (
          <MenuDropdownOuter
            className="MainMenuDropdown__Outer"
            open={Boolean(anchorEl)}
            elevation={0}
            anchorEl={anchorEl}
            onClick={handleClose}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            disableRestoreFocus
          >
            <MenuDropdownInner>{generateChildLink()}</MenuDropdownInner>
          </MenuDropdownOuter>
        )}
      </li>
    );

  };

  return (
    <MainMenuLinkContainer headerCount={headerCount}>{generateMainMenuLink()}</MainMenuLinkContainer>
  );
};

export default CustomerPortalMainMenuLink;
