import {
  UserPermission,
  UserRole,
} from '@customer-portal/constants';

import {
  AccountType,
  PREMIUM_ACCOUNT_TYPES,
} from '../constants/account.constants';
import { privilegedUsers } from '../constants/roles.constants';
import state from './RevealStateHelper';

export const UserPermissionsHelper = {
  isRegisteredUser() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return globalState.isRegistered;
  },
  isGlobalSearchAllowed() {
    return UserPermissionsHelper.isRegisteredUser();
  },
  // is upath user with no company selected
  isUiPathUserWithNoCompanySelected() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return !globalState.companyId && globalState.isUiPath;
  },
  isCompanySelected() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return !!globalState.companyId;
  },
  doesMatchRole(role: string) {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const globalStateCpRole = globalState.cpRole;
    return globalStateCpRole === role;
  },
  isCompanyInUserTerritories() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const {
      territories, companyTerritory,
    } = globalState;
    return companyTerritory?.length > 0 &&
      territories?.length > 0 &&
      territories.includes(companyTerritory);
  },
  isShowNotificationsAllowed() {
    if (!UserPermissionsHelper.isRegisteredUser()) {
      return false;
    }

    return (
      UserPermissionsHelper.isUiPathSuperAdmin() ||
      UserPermissionsHelper.isUiPathAdminForSelectedCompany() ||
      UserPermissionsHelper.isCompanyAdmin() ||
      UserPermissionsHelper.isCompanyRegularUser() ||
      UserPermissionsHelper.isSupportUser() ||
      UserPermissionsHelper.isTechnicalAccountManager() ||
      UserPermissionsHelper.isSpecificAccountUser()
    );
  },
  canUserSupportRestrictedAccount() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    // the restrictions are only for uipath users except for uipath super admin
    if (!globalState.isUiPath || globalState.cpRole === UserRole.UI_SUPER_ADMIN) {
      return true;
    }
    if (!globalState.supportOnlyProvidedByCountry) {
      return true;
    }
    return globalState.canUserSupportAccount;
  },
  isViewAnalyticsAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.ADMIN_ANALYTICS_READ ]);
  },
  isEditKBAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.KNOWLEDGE_WRITE ]);
  },
  isEditSuperAdminsAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.ADMIN_USERS_WRITE ]);
  },
  isEditProfileAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.PROFILE_WRITE ]);
  },
  isEditEmailNotificationAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.EMAILNOTIFICATIONS_WRITE ]);
  },
  isViewLicenseAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.LICENSE_READ ]) ||
      this.isCompanyInUserTerritories();
  },
  isEditLicenseAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    return (
      (this.doesUserHavePermissions([ UserPermission.LICENSE_WRITE ]) &&
        this.isUiPathSuperAdmin()) ||
      (this.doesUserHavePermissions([ UserPermission.LICENSE_WRITE ]) &&
        this.isUiPathAdminForSelectedCompany()) ||
      (this.doesUserHavePermissions([ UserPermission.LICENSE_WRITE ]) &&
        (this.isCompanyAdmin() || this.isCompanyRegularUser()))
    );
  },
  isViewTeamUsersAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.TEAMUSERS_READ ]) ||
      this.isCompanyInUserTerritories();
  },
  isEditTeamUsersAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.TEAMUSERS_WRITE ]);
  },
  isUiPathUser() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return globalState.isUiPath;
  },
  isViewSwitchAccountModalAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return !globalState.isUiPath && globalState.sharedCompanies.length > 1;
  },
  isViewBrowsePageAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return globalState.isUiPath && !globalState.companyId;
  },
  isUiPathAdminForSelectedCompany() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const globalMyCompanies = globalState.myCompanies || [];
    const globalStateCompanyId = globalState.companyId || '';
    return this.isCompanyMatch(globalMyCompanies, globalStateCompanyId);
  },
  isUiPathAdmin() {
    return this.doesMatchRole(UserRole.UI_ADMIN);
  },
  isUiPathSuperAdmin() {
    return this.doesMatchRole(UserRole.UI_SUPER_ADMIN);
  },
  isUiPathSuperOperationsAdmin() {
    return this.doesMatchRole(UserRole.UI_SUPER_OPERATIONS_ADMIN);
  },
  isSupportUser() {
    return this.doesMatchRole(UserRole.UI_SUPPORT);
  },
  isTechnicalAccountManager() {
    return this.doesMatchRole(UserRole.UI_TECHNICAL_ACCOUNT_MANAGER);
  },
  isSpecificAccountUser() {
    return this.doesMatchRole(UserRole.UI_SPECIFIC_ACCOUNT_USER);
  },
  isAutomationProgramAdvisor() {
    return this.doesMatchRole(UserRole.UI_AUTOMATION_PROGRAM_ADVISOR);
  },
  isCompanyAdmin() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const globalStateCpRole = globalState.cpRole;
    return (
      globalStateCpRole === UserRole.CP_ADMIN || privilegedUsers().includes(globalState.userEmail)
    );
  },
  isAdminOnAccountDetails() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const cpRole = globalState.cpRole;
    return [
      UserRole.SFDC_ACCOUNT_OWNER,
      UserRole.SFDC_CUSTOMER_SUCCESS_MANAGER,
      UserRole.SFDC_TECHNICAL_ACCOUNT_MANAGER,
      UserRole.SFDC_AUTOMATION_PROGRAM_ADVISOR,
    ].includes(cpRole as UserRole);
  },
  isCompanyRegularUser() {
    return this.doesMatchRole(UserRole.CP_USER);
  },
  isCompanyMatch(myCompanies: string[] = [], companyId: string) {
    // Function for uipathers to see if uipath-admin matches companyid
    if (myCompanies.includes(companyId)) {
      return true;
    }
    return false;
  },
  // Global check for whether it should show or not (not just disable)
  isCollabSpaceEnabled() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    // UiPath users don't have sharedCompanies, so they read from the one stored at the top level
    if (globalState.isUiPath) {
      return globalState.isCollabSpaceEnabled === false ? false : true;
    }

    const globalStateSharedCompany = globalState.sharedCompanies.find((company) => globalState.companyId === company.companyId);
    if (globalStateSharedCompany && globalStateSharedCompany.isCollabSpaceEnabled === false) {
      return false;
    }

    return true;
  },
  isEditCompanySettingsAllowed() {
    return (
      this.isCompanyAdmin() ||
      this.isUiPathAdminForSelectedCompany() ||
      this.isAdminOnAccountDetails()
    ) && this.isEditTeamUsersAllowed();
  },
  isViewCollabAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (!globalState.companyId) {
      return false;
    }

    if (!this.isCollabSpaceEnabled()) {
      return false;
    }

    const isUipathRoleAllowed: boolean = [
      UserRole.UI_ADMIN,
      UserRole.UI_INVISIBLE_VIEW,
    ].includes(globalState.cpRole as UserRole);

    if (
      [
        UserRole.UI_SUPER_ADMIN,
        UserRole.UI_SUPPORT,
        UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
        UserRole.UI_SPECIFIC_ACCOUNT_USER,
        UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
        UserRole.SFDC_ACCOUNT_OWNER,
        UserRole.SFDC_CUSTOMER_SUCCESS_MANAGER,
        UserRole.SFDC_TECHNICAL_ACCOUNT_MANAGER,
        UserRole.SFDC_AUTOMATION_PROGRAM_ADVISOR,
      ].includes(globalState.cpRole as UserRole) ||
      (isUipathRoleAllowed &&
        this.isCompanyMatch(globalState.myCompanies, globalState.companyId)) ||
      this.isCompanyInUserTerritories() ||
      (!globalState.isUiPath && this.doesUserHavePermissions([ UserPermission.COLLAB_READ ]))
    ) {
      return true;
    }

    return false;
  },
  isCreateCollabAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    if (!this.isCollabSpaceEnabled()) {
      return false;
    }

    if (
      [
        UserRole.UI_SUPER_ADMIN,
        UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
        UserRole.UI_SPECIFIC_ACCOUNT_USER,
        UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
        UserRole.SFDC_ACCOUNT_OWNER,
        UserRole.SFDC_CUSTOMER_SUCCESS_MANAGER,
        UserRole.SFDC_TECHNICAL_ACCOUNT_MANAGER,
        UserRole.SFDC_AUTOMATION_PROGRAM_ADVISOR,
      ].includes(globalState.cpRole as UserRole) ||
      (globalState.cpRole === UserRole.UI_ADMIN &&
        this.isCompanyMatch(globalState.myCompanies, globalState.companyId)) ||
      this.isCompanyInUserTerritories() ||
      (!globalState.isUiPath && this.doesUserHavePermissions([ UserPermission.COLLAB_WRITE ]))
    ) {
      return true;
    }

    return false;
  },
  isDeleteCollabAllowed(authorId: string, createdByUiPath: boolean) {
    const globalState = state();

    if (!globalState) {
      return false;
    }

    const globalStateCompanyId = globalState.companyId || '';
    const globalStateCpRole = globalState.cpRole;
    const globalMyCompanies = globalState.myCompanies || [];
    const globalStateUserId = globalState.userId || '';

    if (!this.isCollabSpaceEnabled()) {
      return false;
    }

    if (
      this.doesUserHavePermissions([ UserPermission.COLLAB_WRITE ]) &&
      (globalState.cpRole === UserRole.UI_SUPER_ADMIN ||
        (!createdByUiPath && globalStateCpRole === UserRole.CP_ADMIN) ||
        this.isCompanyInUserTerritories() ||
        (createdByUiPath &&
          this.isCompanyMatch(globalMyCompanies, globalStateCompanyId)) ||
        authorId === globalStateUserId)
    ) {
      return true;
    }
    return false;
  },
  isViewSupportAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (
      UserPermissionsHelper.isRegisteredUser() &&
      (!globalState.companyId && !globalState.isUiPath)
    ) {
      return false;
    }

    return this.doesUserHavePermissions([ UserPermission.SUPPORT_READ ]);
  },
  isCreateIncidentCaseAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (!UserPermissionsHelper.isRegisteredUser() || UserPermissionsHelper.isUiPathUser()) {
      return true;
    }

    return this.doesUserHavePermissions([ UserPermission.SUPPORT_WRITE ]);
  },
  isEscalateIncidentCaseAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    return this.doesUserHavePermissions([ UserPermission.SUPPORT_WRITE ]);
  },
  isViewPremiumSupportAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (!globalState.companyId) {
      return false;
    }

    return this.doesUserHavePermissions([ UserPermission.SUPPORT_PREMIUMCARE_READ ]) &&
      this.isPremiumAccount();
  },
  isEditPremiumSupportAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (!globalState.companyId) {
      return false;
    }
    return this.doesUserHavePermissions([ UserPermission.SUPPORT_PREMIUMCARE_WRITE ]) &&
      this.isPremiumAccount() && !UserPermissionsHelper.isActivateSuccessCustomer();
  },
  isPremiumAccount() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    return PREMIUM_ACCOUNT_TYPES.includes(globalState.companyType);
  },

  isActivateSuccessCustomer() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    return (
      globalState && [ AccountType.ACTIVATE ].includes(globalState.companyType)
    );
  },

  isRegularUsersInTeam() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    const globalStateTeamUsers = globalState.teamUsers;

    if (globalStateTeamUsers) {
      if (globalStateTeamUsers.length !== 0) {
        const regularUsers = globalStateTeamUsers.filter(function(member: any) {
          return !member.CP_Admin__c;
        });
        if (regularUsers.length === 0) {
          return false;
        }
        return true;

      }
      const regularUsers = globalStateTeamUsers.filter(function(member: any) {
        return !member.CP_Admin__c;
      });
      if (regularUsers.length === 0) {
        return false;
      }
      return true;

    }
  },
  isViewProductCatalogAllowed() {
    const globalState = state();

    if (!globalState) {
      return false;
    }

    return this.doesUserHavePermissions([ UserPermission.ADMIN_PRODUCTCATALOG_READ ]);
  },
  isEditProductCatalogAllowed() {
    const globalState = state();

    if (!globalState) {
      return false;
    }

    return this.doesUserHavePermissions([ UserPermission.ADMIN_PRODUCTCATALOG_WRITE ]);
  },

  isViewHAPOLicenseAllowed() {
    const globalState = state();

    if (!globalState?.companyId) {
      return false;
    }

    return (
      this.doesUserHavePermissions([
        UserPermission.LICENSE_READ,
        UserPermission.HAPO_LICENSE_READ,
      ]) &&
      globalState.isLicenseManagementEnabled
    );
  },

  isEditHAPOLicenseAllowed() {
    const globalState = state();
    if (!globalState?.companyId) {
      return false;
    }

    return (
      this.doesUserHavePermissions([
        UserPermission.LICENSE_READ,
        UserPermission.HAPO_LICENSE_READ,
        UserPermission.HAPO_LICENSE_WRITE,
      ]) &&
      globalState.isLicenseManagementEnabled
    );
  },

  isCancelHAPOOrderAllowed() {
    // Only Super Admins and Super Operations Admin are allowed to cancel HAPO orders
    const globalState = state();
    if (!globalState?.cpRole) {
      return false;
    }
    const globalStateRole = globalState.cpRole || '';

    return (
      ([ UserRole.UI_SUPER_OPERATIONS ].includes(globalStateRole as UserRole) ||
        this.doesUserHavePermissions([ UserPermission.ADMIN_HAPO_EDIT ])) &&
      (this.isUiPathSuperAdmin() || this.isUiPathSuperOperationsAdmin()) &&
      globalState.isLicenseManagementEnabled
    );
  },

  isCompleteHAPOOrderAllowed() {
    const globalState = state();
    if (!globalState?.cpRole) {
      return false;
    }
    const globalStateRole = globalState.cpRole || '';

    return (
      ([ UserRole.UI_SUPER_OPERATIONS ].includes(globalStateRole as UserRole) ||
        this.doesUserHavePermissions([ UserPermission.ADMIN_HAPO_EDIT ])) &&
      globalState.isLicenseManagementEnabled
    );
  },

  isManageHAPOAllowed() {
    const globalState = state();
    if (!globalState?.cpRole) {
      return false;
    }

    return this.doesUserHavePermissions([ UserPermission.ADMIN_HAPO_EDIT ]);
  },

  isViewHAPOOrderChangesInCSVAllowed() {
    return UserPermissionsHelper.isUiPathUser();
  },

  isView360QuestionnaireAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    if (!this.isPremiumAccount() || this.isActivateSuccessCustomer()) {
      return false;
    }

    if (
      globalState.isUiPath ||
      (!globalState.isUiPath && this.doesUserHavePermissions([ UserPermission.SUPPORT_READ ]))
    ) {
      return true;
    }

    return false;
  },

  isEdit360QuestionnaireAllowed() {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    if ([
      UserRole.UI_SUPER_ADMIN,
      UserRole.CP_ADMIN,
      UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
      UserRole.UI_SPECIFIC_ACCOUNT_USER,
      UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
      UserRole.SFDC_TECHNICAL_ACCOUNT_MANAGER,
      UserRole.SFDC_AUTOMATION_PROGRAM_ADVISOR,
    ].includes(globalState.cpRole as UserRole)) {
      return true;
    }

    return false;
  },

  isRFQEnabled() {
    const globalState = state();
    return !(globalState?.isSoldToPartner) && globalState?.companyCountry !== 'Japan';
  },

  doesUserHavePermissions(permissions: string[]) {
    const globalState = state();
    if (!globalState) {
      return false;
    }

    if (!permissions.length) {
      return true;
    }

    const foundCompany = (globalState.sharedCompanies ?? [])
      .find((company) => (
        company?.companyId &&
        company?.companyId === globalState?.companyId
      ));

    const userPermissions: string[] = foundCompany
      ? foundCompany.permissions
      : (globalState?.permissions ?? []);

    return permissions.every((p) => userPermissions.includes(p));
  },
  hasSharedCompanies() {
    const globalState = state();
    if (!globalState) {
      return false;
    }
    if (globalState.sharedCompanies.length > 0) {
      return true;
    }
    return false;
  },
};
