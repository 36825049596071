import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { CP_PRIVATE_STATE_KEY } from '../constants/state.constants';
import type { storeState } from '../interfaces/contextStore';
import { logTrace } from '../lib/AppInsights/AppInsights';

const COVEO_SESSION_KEY_NAME = 'coveo_session';
const SESSION_ID_TTL_HOURS = 3;

interface CustomCoveoSessionData {
  visitorId: string;
  sessionId: string;
  sessionIdExpires: string;
  isUipathUser: boolean;
}

export default class CoveoUtil {
  public static getSessionData() {
    let session: CustomCoveoSessionData | null = JSON.parse(
      localStorage.getItem(COVEO_SESSION_KEY_NAME) ?? 'null'
    );

    const state: storeState | null = JSON.parse(
      localStorage.getItem(CP_PRIVATE_STATE_KEY) ?? 'null'
    );

    // If session in localstorage doesn't exist or it has expired, re-initialize the session
    // NOTE: We always create a new sessionId, but we want to re-use an existing visitorId
    if (!session || moment().isAfter(moment(session.sessionIdExpires)) || session.visitorId !== state?.uuid) {
      let visitorId: string = '';
      if (state?.uuid) {
        visitorId = state?.uuid;
        logTrace(`visitorId is set to state uuid: ${visitorId}`);
      } else if (session?.visitorId) {
        visitorId = session?.visitorId;
        logTrace(`visitorId is set to session visitorId: ${visitorId}`);
      } else {
        visitorId = uuidv4();
        logTrace(`visitorId is randomly generated: ${visitorId}`);
      }

      session = {
        visitorId,
        sessionId: uuidv4(),
        sessionIdExpires: moment().add(SESSION_ID_TTL_HOURS, 'hours')
          .toISOString(),
        isUipathUser: state?.isUiPath ?? false,
      };

      localStorage.setItem(COVEO_SESSION_KEY_NAME, JSON.stringify(session));
    }

    return session;
  }

  public static deleteSessionData() {
    localStorage.removeItem(COVEO_SESSION_KEY_NAME);
  }
}
