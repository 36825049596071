import { Button } from '@mui/material';
import React, {
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  CaseAssistAnalyticsActions,
  CustomEventType,
  FeedbackResponse,
} from '../../../constants/caseAssist.constants';
import { useAuth } from '../../../contexts/auth';
import type { CasePageProps } from '../../../interfaces/support.interface';
import { useTriggerTrackEventWithStateData } from '../../../lib/AppInsights/AppInsights';
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
import { StoreContext } from '../../../store';
import {
  logCaseAssistAnalyticsEvent,
  logCustomEvent,
  logPublicCustomEvent,
} from '../../../utils/caseAssist';
import ApolloIcon from '../../ApolloIcon';
import TypewriterComponent from '../../TypewriterComponent';
import SupportCaseDeflectionModal from '../CaseCreation/SupportCaseDeflection-Modal';

const DocsGptDeflection = (props: CasePageProps) => {
  const { t } = useTranslation('common');
  const baseClass = 'SupportCaseBody';
  const {
    engine,
    caseAssistState,
    formData,
    gptResponse,
    isGptResponseLoading,
    receivedFeedbackResponse,
    setReceivedFeedbackResponse,
    isAnswerHelpful,
    setIsAnswerHelpful,
    resultsShownFirst,
    doneStreamedText,
    setDoneStreamedText,
  } = props;
  const isPublic = props.isPublic ?? false;
  const isRegistered = !isPublic && UserPermissionsHelper.isRegisteredUser();
  const { state } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const triggerTrackEventWithStateData = useTriggerTrackEventWithStateData();

  const caseData = {
    subject: formData.summary,
    description: formData.description,
    deploymentType: formData.deploymentType.value,
    product: formData.product.value,
    errorMessage: formData.errorMessage,
  };

  const onYesBtn = async () => {
    setReceivedFeedbackResponse(true);
    setIsAnswerHelpful(true);

    const coveoData = {
      'docsgpt_response': gptResponse,
      'feedback_response': FeedbackResponse.YES,
      'case_data': caseData,
    };
    if (isPublic || !isRegistered) {
      logPublicCustomEvent(
        CustomEventType.FEEDBACK_RESPONSE,
        coveoData,
      );
    } else {
      logCustomEvent(
        CustomEventType.FEEDBACK_RESPONSE,
        coveoData,
        state.companyId,
        await getAccessToken(),
      );
    }
    triggerTrackEventWithStateData(
      'GPT.ResponseHelpful',
      {
        'DocsGPTResponse': gptResponse,
        'CaseData': caseData,
      }
    );
  };

  const onNoBtn = async () => {
    setReceivedFeedbackResponse(true);
    setIsAnswerHelpful(false);

    const coveoData = {
      'docsgpt_response': gptResponse,
      'feedback_response': FeedbackResponse.NO,
      'case_data': caseData,
    };
    if (isPublic || !isRegistered) {
      logPublicCustomEvent(
        CustomEventType.FEEDBACK_RESPONSE,
        coveoData,
      );
    } else {
      logCustomEvent(
        CustomEventType.FEEDBACK_RESPONSE,
        coveoData,
        state.companyId,
        await getAccessToken(),
      );
    }
    triggerTrackEventWithStateData(
      'GPT.ResponseNotHelpful',
      {
        'DocsGPTResponse': gptResponse,
        'CaseData': caseData,
      }
    );
  };

  const onSourceClicked = async (source: string) => {
    const coveoData = { 'docsgpt_article_clicked': source };
    if (isPublic || !isRegistered) {
      logPublicCustomEvent(
        CustomEventType.DOCSGPT_SUGGESTION_CLICK,
        coveoData,
      );
    } else {
      logCustomEvent(
        CustomEventType.DOCSGPT_SUGGESTION_CLICK,
        coveoData,
        state.companyId,
        await getAccessToken(),
      );
    }
  };

  useEffect(() => {
    if (doneStreamedText) {
      const data = {
        Response: gptResponse.text,
        Articles: gptResponse.sources,
        ResultsShownFirst: resultsShownFirst,
      };
      logCaseAssistAnalyticsEvent(
        engine,
        CaseAssistAnalyticsActions.CASE_DOCSGPT_SUGGESTIONS_SHOWN,
        JSON.stringify(data)
      );
    }
  }, [ doneStreamedText ]);

  return (
    <div>
      {isGptResponseLoading ? (
        <div>
          <h6 className={`${baseClass}__Case-Name`}>
            {t('support_form_generating_gpt_answer_text',
              'Generating an answer to your inquiry...')}
          </h6>
          <ApolloIcon
            className='loadingIcon'
            icon="settings"
            fontSize='inherit'
          />
        </div>
      ) : (!receivedFeedbackResponse && gptResponse.text) || (receivedFeedbackResponse && isAnswerHelpful) ? (
        <div>
          <div className={`${baseClass}__Case-Deflection-Response`}>
            <h6 className={`${baseClass}__Case-Name deflection-section`}>
              {t(
                'support_form_gpt_response_title',
                'Hello! I found some suggestions for your issue:'
              )}
            </h6>
            <TypewriterComponent
              text={gptResponse.text}
              completeMessage={gptResponse.text}
              completionCallback={() => {}}
              completedTying={() => setDoneStreamedText(true)}
              speed={3}
            />
          </div>
          <br />
          {doneStreamedText && (
            <div>
              {gptResponse.sources.length !== 0 && (
                <div>
                  <div className={`${baseClass}__Case-Text`}>
                    <b>{t('support_form_more_information', 'More information:')}</b>
                  </div>
                  <div className={`${baseClass}__Case-Text deflection-section`}>
                    {gptResponse.sources.map((source: any) => <li>
                      <a
                        href={source}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => onSourceClicked(source)}>
                        {source}
                      </a>
                    </li>)}
                  </div>
                </div>
              )}
              {receivedFeedbackResponse ? (
                <div>
                  <p>{t('support_form_answer_is_helpful', 'Thank you for your feedback. We\'re glad to have assisted you.')}</p>
                  <br />
                </div>
              ) : (
                <div className={`${baseClass}__Case-Text deflection-section`}>
                  <b>{t('support_form_was_answer_helpful', 'Was this answer helpful?')}</b>
                  <Button
                    className={`${baseClass}__Case-Button`}
                    variant='contained'
                    onClick={onYesBtn}>
                    {t('support_form_yes', 'Yes')}
                  </Button>
                  <Button
                    className={`${baseClass}__Case-Button`}
                    variant='outlined'
                    onClick={onNoBtn}>
                    {t('support_form_no', 'No')}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          <p className='deflection-section'>
            {t('support_form_answer_is_not_helpful', 'Thanks for the feedback; adding additional details will allow us to give a more accurate answer to your inquiry. Use the below “back” Button.')}
          </p>
          <h6 className={`${baseClass}__Case-Name-Spacing`}>
            {t('support_form_suggested_article_alternative',
              'You may also review these suggested resources:')}
          </h6>
          <SupportCaseDeflectionModal
            engine={engine}
            responseState={caseAssistState}
            isNewSupportCase
            resultsShownFirst={resultsShownFirst} />
        </div>
      )}
    </div>
  );
};

export default DocsGptDeflection;
