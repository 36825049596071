// Styles
import {
  Popover,
  TableCell,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';

// Icons
import MoreVT from '../../../assets/img/svg/file_icons/More_VT.svg';

type AdminUserRowProps = {
  index: number;
  admin: any;
  adminRole: string;
  handleEditClick: (admin: any) => void;
  handleInactivateClick: (email: string, active: number) => void;
};

const TableRowIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: ${p => p.theme.spacing(2)}px;
  margin-right: ${p => p.theme.spacing(2)}px;
  cursor: pointer;
  position: relative;
`;

const OptionsPopover = styled(Popover)`
  .MuiPopover-paper {
    background: white;
    width: 200px;
    box-shadow: 0px 16px 24px 0px rgba(3, 5, 8, 0.1);
  }
`;

const PopoverOption = styled.p`
  padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(3)}px;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.palette.grey[50]}};
    color: ${p => p.theme.palette.blue[500]};
  }
`;

const AdminUserRow = ({
  index,
  admin,
  adminRole,
  handleEditClick,
  handleInactivateClick,
}: AdminUserRowProps) => {
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);

  /* Events */
  // Popover actions
  const handleRowOptionClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }

    setPopoverAnchorElem(e.currentTarget);
  };
  const handleRowOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  return (
    <TableRow
      key={index}
      data-testid="AdminUserRow">
      <TableCell align="left">{admin.name}</TableCell>
      <TableCell
        align="left"
        data-testid="AdminRowEmail">
        {admin.email}
      </TableCell>
      <TableCell align="left">{adminRole}</TableCell>
      <TableCell align="left">
        {admin.active === 0 ? 'Inactive' : 'Active'}
      </TableCell>
      <TableCell align="left">
        {admin.created_on
          ? moment(admin.created_on).format('MM/DD/YYYY')
          : ''}
      </TableCell>
      <TableCell align="left">{admin.created_by}</TableCell>
      <TableCell align="left">
        <p
          className={`action-pill ${admin.active === 0 ? 'activate' : 'inactivate'}`}
          onClick={() => handleInactivateClick(admin.email, admin.active)}
        >
          {admin.active === 0 ? 'Reactivate' : 'Inactivate'}
        </p>
      </TableCell>
      <TableCell>
        <TableRowIcon
          src={MoreVT}
          alt="Options"
          onClick={(e: any) => {
            handleRowOptionClick(e);
          }}
          data-testid="MenuIcon"
        />
      </TableCell>
      <OptionsPopover
        open={Boolean(popoverAnchorElem)}
        elevation={0}
        anchorEl={popoverAnchorElem}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleRowOptionClose}
      >
        <PopoverOption
          onClick={() => {
            handleEditClick(admin);
            handleRowOptionClose();
          }}
        >
          Edit Role
        </PopoverOption>
      </OptionsPopover>
    </TableRow>
  );
};

export default AdminUserRow;
