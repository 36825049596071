import { FacetFilterTypes } from './types';

export const FACET_FILTER_TYPES_TO_LABELS: {
  [key in FacetFilterTypes]: {
    label: {
      textKey: string;
      fallbackText: string;
    };
    searchLabel?: {
      textKey: string;
      fallbackText: string;
    };
  };
} = {
  [FacetFilterTypes.CONTENT_TYPE]: {
    label: {
      textKey: 'search_page_filter_content_type',
      fallbackText: 'Content type',
    },
  },
  [FacetFilterTypes.PRODUCT]: {
    label: {
      textKey: 'search_page_filter_product',
      fallbackText: 'Product',
    },
    searchLabel: {
      textKey: 'search_page_filter_search_products',
      fallbackText: 'Search products',
    },
  },
  [FacetFilterTypes.VERSION]: {
    label: {
      textKey: 'search_page_filter_version',
      fallbackText: 'Version',
    },
  },
  [FacetFilterTypes.LANGUAGE]: {
    label: {
      textKey: 'search_page_filter_language',
      fallbackText: 'Language',
    },
  },
};

export interface FacetComponentProps {
  initialVisibleRootValues?: number;
  isHierarchal?: boolean;
  showSearch?: boolean;
  searchPlaceholder?: string;
}

export const FACETS_COMPONENT_PROPS: {
  [key in FacetFilterTypes]?: FacetComponentProps;
} = {
  [FacetFilterTypes.CONTENT_TYPE]: {
    initialVisibleRootValues: 5,
    showSearch: false,
  },
  [FacetFilterTypes.PRODUCT]: {
    initialVisibleRootValues: 5,
    showSearch: true,
  },
  [FacetFilterTypes.VERSION]: {
    initialVisibleRootValues: 5,
    isHierarchal: true,
    showSearch: false,
  },
  [FacetFilterTypes.LANGUAGE]: {
    initialVisibleRootValues: 5,
    showSearch: false,
  },
};
