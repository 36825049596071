export const ACTIVE = 'active';
export const PENDING = 'pending';
export const DISABLED = 'disabled';

export const DELETE_INVITE_LABEL = {
  keyText: 'company_team_users_context_menu_delete_invite_label',
  fallbackText: 'Delete invite',
};
export const ACTIVATE_USER_LABEL = {
  keyText: 'company_team_users_context_menu_activate_user_label',
  fallbackText: 'Activate',
};
export const DISABLE_USER_LABEL = {
  keyText: 'company_team_users_context_menu_disable_user_label',
  fallbackText: 'Disable',
};
export const RESEND_INVITE_LABEL = {
  keyText: 'company_team_users_context_menu_resend_invite_label',
  fallbackText: 'Resend invite',
};
export const EDIT_PERMISSIONS_LABEL = {
  keyText: 'company_team_users_context_menu_edit_permissions_label',
  fallbackText: 'Edit Permissions',
};
export const EDIT_PROFILE_LABEL = {
  keyText: 'company_team_users_context_menu_profile_label',
  fallbackText: 'Profile',
};

export const SET_USER = 'SET_USER';
export const UPDATE_GLOBAL_STATE = 'UPDATE_GLOBAL_STATE';
