import axios from 'axios';
import React, {
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  Redirect,
  useHistory,
} from 'react-router-dom';

import DocumentsListContainer from '../../assets/css/Knowledge/DocumentListContainer';
import DocumentsListHeader from '../../assets/css/Knowledge/DocumentsListHeader';
import Main from '../../assets/css/Knowledge/Main';
import Title from '../../assets/css/Knowledge/Title';
// Styling
import * as styles from '../../assets/css/Support/SupportKB/CustomerPortalSupportKB';
import SearchButton from '../../assets/css/Support/SupportKB/SearchButton';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import CustomerPortalLoader from '../../components/CustomerPortal-Loader';
// Layout and Helper Components
import Container from '../../components/CustomerPortal-New-Container';
// Text
import CustomerPortalSearchInput from '../../components/CustomerPortal-SearchInput';
import SupportKBDocument from '../../components/support_kb/document/SupportKBDocument';
import SupportKBLinkOut from '../../components/support_kb/link_out/SupportKBLinkOut';
import SupportKBSearchResults from '../../components/support_kb/searchResult/SupportKBSearchResult';
// Constants
import KB_ACTION from '../../constants/kbActions.constants';
import {
  SUPPORT_KB_DOCS_URL,
  SUPPORT_KB_PRODUCTS,
} from '../../constants/network.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import type { IKBSearchState } from '../../interfaces/knowledgeBase.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Util Functions
import {
  handleSearchCategoryFilterClick,
  handleSearchPaginateClick,
  handleSearchSortClick,
  KBSearchParamBuilder,
  useKBFetcher,
} from '../../lib/knowledgebaseDocument.utils';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import {
  kbDataReducer,
  kbSearchReducer,
} from '../../reducers/kb_reducers';
import { getCloudPrefixedPath } from '../../utils/cloud';
import Hero from './../../assets/css/Support/SupportKB/Hero';

const CustomerPortalSupportKB = (props: any) => {
  const { getAccessToken } = useAuth();
  // Translate method
  const { t } = useTranslation('common');

  const baseClass = 'CustomerPortalSupportKB';
  const history = useHistory();
  const docsPerPage = 7;
  const h1Text = t(
    'support_kb_page_hero_title',
    'Need Help? Browse Our Technical Solution Articles'
  );
  const searchDefaultText = t(
    'support_kb_page_search_placeholder',
    'Search here...'
  );
  const searchParam = new URLSearchParams(window.location.search);
  const screenName = 'Support KB Landing Page';

  const initialSearchState: IKBSearchState = {
    keyword: searchParam.get('q') || '',
    keywordInputField: searchParam.get('q') || '',
    isSearching: searchParam.get('q') ? true : false,
    isCompleteSearching: false,
    filterCategories: [],
    kbDocuments: [],
    currentPage: parseInt(searchParam.get('page') || '1', 10),
    kbTotalDocs: 0,
    kbTotalPages: 0,
    sortBy: searchParam.get('s') || 'created_on',
    sortDirection: parseInt(searchParam.get('d') || '-1', 10),
    skipResult:
      parseInt(searchParam.get('page') || '1', 10) === 1
        ? 0
        : (parseInt(searchParam.get('page') || '1', 10) - 1) * docsPerPage,
    filterParam: searchParam.get('c')
      ? new Set([ ...(searchParam.get('c') as any).split(',') ])
      : new Set<string>(),
    filterSubcategoriesParam: new Set<string>(),
  };

  const initialKbDataState = {
    error: undefined,
    isLoading: true,
    data: [],
  };

  const [ back, setBack ] = useState(false);
  const [ gotoDetails, setGotoDetails ] = useState(false);
  const [ selectedArticle, setSelectedArticle ] = useState('');

  const [ searchPaginationPage, setSearchPaginationPage ] = useState(
    initialSearchState.currentPage || 1
  );

  const [ searchState, setSearchURL, dispatchSearchAction ] = useKBFetcher(
    ``,
    docsPerPage,
    kbSearchReducer,
    initialSearchState
  );
  const [ latestKbDocs, dispatchKbLatestDocs ]: [any, any] = useReducer(
    kbDataReducer,
    initialKbDataState
  );

  const [ mostViewedKbDocs, dispatchKbMostViewedDocs ]: [any, any] = useReducer(
    kbDataReducer,
    initialKbDataState
  );

  const [ products, dispatchProducts ]: [any, any] = useReducer(
    kbDataReducer,
    initialKbDataState
  );

  // Event when a Category on the Filter column is clicked
  const handleCategoryFilterClick = (elemID: string) => {
    handleSearchCategoryFilterClick(
      docsPerPage,
      SUPPORT_KB_DOCS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      elemID,
      'category',
      props.history
    );
  };

  // Event when Search Sort item is clicked
  const handleSearchingSortClick = (
    sortVal: { path: string; direction: number } | null
  ) => {
    handleSearchSortClick(
      docsPerPage,
      SUPPORT_KB_DOCS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      sortVal,
      'category',
      props.history
    );
  };

  const handleFormSubmit = (forcedKeyword?: string) => {
    // Function is reused, so choose between looking in state if passed by child input field or if called manually
    const keyword = forcedKeyword || searchState.keywordInputField;
    const keywords = keyword.replace(/[*#]/g, '');

    if (!keywords) {
      dispatchSearchAction({ type: KB_ACTION.KEYWORD_EMPTY });
      setSearchURL('');
      props.history.push({ search: '' });
      return;
    }
    if (keywords === searchState.keyword) {
      return;
    }

    // Update state now searching
    dispatchSearchAction({
      type: KB_ACTION.FETCH_INIT,
      payload: { keyword: keywords },
    });

    const queryParam = new KBSearchParamBuilder('Support_KB_Documents')
      .setAction('search')
      .setKeyword(keywords.split(' '))
      .setTop(docsPerPage)
      .setSkip(searchState.skipResult)
      .build();

    // Update state
    setSearchURL(`${SUPPORT_KB_DOCS_URL}${queryParam.toString()}`);

    const searchParams = `q=${keywords}&page=1&s=${searchState.sortBy}&d=${searchState.sortDirection}`;
    // Update url params
    if (initialSearchState.keyword) {
      // If this is a search after initial search, replace history so back button still goes back to knowledge base home
      props.history.replace({ search: searchParams });
    } else {
      // If this is an initial search, push to web history as normal
      props.history.push({ search: searchParams });
    }
  };

  const handleSearchingPaginateClick = (val: number) => {
    handleSearchPaginateClick(
      docsPerPage,
      SUPPORT_KB_DOCS_URL,
      searchState,
      setSearchURL,
      dispatchSearchAction,
      setSearchPaginationPage,
      val,
      'category',
      props.history
    );
  };

  // Event for search input on change
  const handleSearchOnChange = (keyword: string) => {
    if (searchState.keywordInputField !== keyword) {
      dispatchSearchAction({
        type: KB_ACTION.KEYWORD_UPDATED,
        payload: { keyword },
      });
    }
  };

  useEffect(() => {
    if (back) {
      history.goBack();
      setBack(false);
    }
  }, [ back ]);

  /* Lifecycle */
  useTrackPageViewEvent(screenName);
  // On initial load
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView(screenName);

    if (searchState.keyword) {
      dispatchSearchAction({
        type: KB_ACTION.FETCH_INIT,
        payload: { keyword: searchState.keyword },
      });

      const keywords = searchState.keyword.replace(/[*#]/g, '');

      const queryParam = new KBSearchParamBuilder('Support_KB_Documents')
        .setAction('search')
        .setKeyword(keywords.split(' '))
        .setTop(docsPerPage)
        .setSkip(searchState.skipResult)
        .setFilterCategories(searchState.filterParam)
        .setShowDescription(true)
        .build();

      setSearchURL(`${SUPPORT_KB_DOCS_URL}${queryParam.toString()}`);
    }
  }, []);

  useEffect(() => {
    if (props.location.search !== '') {
      return;
    }

    // Function to get featured knowledge base documents
    const fetchLatestDocumentsPromise = async () => axios.get(SUPPORT_KB_DOCS_URL, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'x-auth-type': getAuthType(),
      },
      params: { action: 'latest' },
    });

    // Function to get featured knowledge base documents
    const fetchMostViewedDocumentsPromise = async () => axios.get(SUPPORT_KB_DOCS_URL, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'x-auth-type': getAuthType(),
      },
      params: { action: 'most-viewed' },
    });

    // Function to get featured knowledge base documents
    const fetchProducts = async () => axios.get(SUPPORT_KB_PRODUCTS, {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'x-auth-type': getAuthType(),
      },
    });

    (async () => {
      try {
        const [
          productsRes,
          latestDocsRes,
          mostViewedDocsRes,
        ] = await axios.all([
          fetchProducts(),
          fetchLatestDocumentsPromise(),
          fetchMostViewedDocumentsPromise(),
        ]);
        if (
          productsRes.status === 200 &&
          productsRes.data?.data
        ) {
          dispatchProducts({
            type: KB_ACTION.FETCH_SUCCESS,
            data: productsRes.data.data,
          });
        } else if (productsRes instanceof Error) {
          dispatchProducts({
            type: KB_ACTION.FETCH_FAIL,
            error: `Unable to fetch the products`,
          });
        } else {
          dispatchProducts({
            type: KB_ACTION.FETCH_FAIL,
            error: `Unable to resolve products`,
          });
        }

        if (
          latestDocsRes.status === 200 &&
          latestDocsRes.data?.data
        ) {
          dispatchKbLatestDocs({
            type: KB_ACTION.FETCH_SUCCESS,
            data: latestDocsRes.data.data,
          });
        } else if (latestDocsRes instanceof Error) {
          dispatchKbLatestDocs({
            type: KB_ACTION.FETCH_FAIL,
            error: `Unable to fetch latest documents: ${latestDocsRes.message}`,
          });
        } else {
          dispatchKbLatestDocs({
            type: KB_ACTION.FETCH_FAIL,
            error: `Unable to resolve products`,
          });
        }

        if (
          mostViewedDocsRes.status === 200 &&
          mostViewedDocsRes.data?.data
        ) {
          dispatchKbMostViewedDocs({
            type: KB_ACTION.FETCH_SUCCESS,
            data: mostViewedDocsRes.data.data,
          });
        } else if (mostViewedDocsRes instanceof Error) {
          dispatchKbMostViewedDocs({
            type: KB_ACTION.FETCH_FAIL,
            error: `Unable to fetch featured documents: ${mostViewedDocsRes.message}.`,
          });
        } else {
          dispatchKbMostViewedDocs({
            type: KB_ACTION.FETCH_FAIL,
            error: `Unable to resolve featured documents.`,
          });
        }
      } catch (e) {
        console.log(e.toString());
      }
    })();

    // Reset these two
    setSearchURL('');
    dispatchSearchAction({ type: KB_ACTION.KEYWORD_EMPTY });
  }, [ props.location.search ]);

  if (gotoDetails) {
    return (
      <Redirect
        push
        to={getCloudPrefixedPath(
          `/kb/${selectedArticle}`
        )}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {t(
            'support_kb_page_title',
            'Technical Solution Articles | Customer Portal'
          )}
        </title>
      </Helmet>
      <styles.SupportKB>
        <div className={`${baseClass}`}>
          <Hero>
            <Container cssClass={`${baseClass}__HeadingContainer`}>
              <div
                className="heroBackButton"
                onClick={() => {
                  setBack(true);
                }}
              >
                {t('support_kb_page_go_back_btn', 'Go Back')}
              </div>
              <Title>{h1Text}</Title>
              <div className={`${baseClass}__SearchContainer`}>
                <CustomerPortalSearchInput
                  placeholderText={searchDefaultText}
                  handleSubmit={handleFormSubmit}
                  keyword={searchState.keywordInputField}
                  handleOnChange={handleSearchOnChange}
                />
              </div>
            </Container>
          </Hero>

          {!searchState.isSearching && (
            <Main>
              <Container cssClass={`${baseClass}__CategoriesContainer`}>
                <styles.ProductsContainer>
                  {!products.isLoading &&
                    products.data.length > 0 &&
                    products.data
                      .filter((product: string) => product != null)
                      .map((product: string, idx: number) => (
                        <SearchButton
                          key={idx}
                          component={RouterLink as any}
                          {...({
                            to: `/support/knowledge-base/${encodeURI(
                              product
                            )}`,
                          } as any)}
                          data-testid="product-button"
                        >
                          {product}
                        </SearchButton>
                      ))}
                  {/* On Loading */}
                  {products.isLoading && <CustomerPortalLoader />}
                </styles.ProductsContainer>
              </Container>
              {/* List of documents */}
              <Container cssClass="CustomerPortalKBDocumentsContainer">
                {/* Latest Documents */}
                <DocumentsListContainer>
                  <DocumentsListHeader>
                    {t(
                      'recently_updated',
                      'Recently Updated'
                    )}
                  </DocumentsListHeader>
                  {/* Knowledge Base cards */}
                  {latestKbDocs.data.length > 0 &&
                    latestKbDocs.data.map((kbDocument: any) => (
                      <SupportKBDocument
                        key={kbDocument.id}
                        productComponentTitle={kbDocument.category}
                        documentTitleText={kbDocument.title}
                        articleViews={kbDocument.totalViewCount}
                        articleId={kbDocument.id}
                        articleNumber={kbDocument.articleNumber}
                      />
                    ))}
                  {/* On Loading */}
                  {latestKbDocs.isLoading && <CustomerPortalLoader />}
                  {/* On error */}
                  {latestKbDocs.error && (
                    <p className="CustomerPortalP CustomerPortalP--Margin-Bottom">
                      {latestKbDocs.error}
                    </p>
                  )}
                </DocumentsListContainer>
                {/* Most Viewed Documents */}
                <DocumentsListContainer>
                  <DocumentsListHeader>
                    {t('support_kb_page_most_viewed_articles', 'Most Viewed')}
                  </DocumentsListHeader>
                  {/* Knowledge Base cards */}
                  {mostViewedKbDocs.data.length > 0 &&
                    mostViewedKbDocs.data.map((kbDocument: any) => (
                      <SupportKBDocument
                        key={kbDocument.id}
                        productComponentTitle={kbDocument.category}
                        documentTitleText={kbDocument.title}
                        articleViews={kbDocument.totalViewCount}
                        articleId={kbDocument.id}
                        articleNumber={kbDocument.articleNumber}
                      />
                    ))}
                  {/* On Loading */}
                  {mostViewedKbDocs.isLoading && <CustomerPortalLoader />}
                  {/* On Error */}
                  {mostViewedKbDocs.error && (
                    <p className="CustomerPortalP CustomerPortalP--Margin-Bottom">
                      {mostViewedKbDocs.error}
                    </p>
                  )}
                </DocumentsListContainer>
              </Container>
              {UserPermissionsHelper.isViewPremiumSupportAllowed() && (
                <SupportKBLinkOut screenName={screenName} />
              )}
            </Main>
          )}

          {/* Search Reasult */}
          {searchState.isSearching && (
            <SupportKBSearchResults
              searchState={searchState}
              handleCategoryFilterClick={handleCategoryFilterClick}
              handleSearchSortClick={handleSearchingSortClick}
              searchDocsPerPage={docsPerPage}
              searchPaginationPage={searchPaginationPage}
              searchNumPagesBeforeEllipses={5}
              handleSearchPaginateClick={handleSearchingPaginateClick}
              screenName={screenName}
            />
          )}
        </div>
      </styles.SupportKB>
    </>
  );
};

export default CustomerPortalSupportKB;
