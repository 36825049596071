const CP_LOCALSTORAGE_PREFIX = 'cp_';

export const CP_PRIVATE_STATE_KEY = `${CP_LOCALSTORAGE_PREFIX}state_private`;

// Used to redirect to intially requested url before the user had to Login/Register
export const CP_POST_AUTH_REDIRECT_URL_KEY = `${CP_LOCALSTORAGE_PREFIX}redirect_url`;

// Used to redirect to intially requested url before the user had to Activate Account
export const CP_POST_ACCOUNT_ACTIVATION_REDIRECT_KEY: string = `${CP_LOCALSTORAGE_PREFIX}post_account_activation_redirect`;

// For version update checking (useUpdateDetector hook)
export const CP_VERSION_UPDATE_ATTEMPT_KEY = `${CP_LOCALSTORAGE_PREFIX}version_update_attempt`;
export const CP_VERSION_UPDATE_ATTEMPT_TIMEOUT_SECONDS = 15;
