import React, { useState } from 'react';
// Components
import type {
  DraggableProvided,
  DroppableProvided,
} from 'react-beautiful-dnd';
import {
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';

// Styles
import {
  OptionsPopover,
  PopoverOption,
  TableContentWrapper,
  TableRowCategory,
  TableRowIcon,
  TableRowSubcategory,
} from '../../../assets/css/Knowledge/CategoryManager-Table';
// Icons
import ArrowDown from '../../../assets/img/svg/arrows/Arrow_Down.svg';
import MoreVT from '../../../assets/img/svg/file_icons/More_VT.svg';
// Constants
import { Locale } from '../../../constants/localization.constants';
// Interfaces
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import type { CategoryLanguages } from '../../../pages/Admin/knowledge-categories';

type CategoryRowProps = {
  categoryId: string;
  name: string;
  index: number;
  last_updated_by: string;
  last_updated_on: string;
  subcategories: any[];
  languages: CategoryLanguages;
  visibility: string;
  handleRenameClick: (
    languages: CategoryLanguages,
    visibility: string,
    categoryInfo: { categoryId: string; categoryName: string },
    subcategoryInfo: { subcategoryId?: string; subcategoryName?: string },
    _isSubCategory?: boolean
  ) => void;
  handleDeleteClick: (categoryName: string, subcategoryName?: string) => void;
  handleAddSubcategoryClick: (categoryName: string, isSubCategory: boolean) => void;
};

const CategoryRow = (props: CategoryRowProps) => {
  const subcategoriesArr = props.subcategories;

  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);
  const [ isCategoryExpanded, setIsCategoryExpanded ] = useState(false);

  /* Events */
  // Popover actions
  const handleCategoryOptionClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }

    setPopoverAnchorElem(e.currentTarget);
  };
  const handleCategoryOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  // Expand option
  const handleExpandOptionClick = (e: any) => {
    setIsCategoryExpanded(!isCategoryExpanded);
  };

  return (
    <Draggable
      draggableId={props.name}
      index={props.index}>
      {(provided: DraggableProvided) => (
        <TableContentWrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TableRowCategory
            data-testid="kbCategoryRow"
          >
            <div>
              <TableRowIcon
                className={`ExpandOption ${isCategoryExpanded ? 'ExpandOption--Active' : ''}`}
                src={ArrowDown}
                alt="Expand"
                onClick={handleExpandOptionClick}
                data-testid="categoryRowArrow"
              />
              <p className="TableRowCategory__Name">{props.name}</p>
            </div>
            <p>{props.last_updated_by}</p>
            <Moment
              format="MMM DD YYYY hh:mm A"
              className="TableRow__DateTime">
              {props.last_updated_on}
            </Moment>
            <Link
              to={`/knowledge-base/category/${props.name}`}
              className="TableRow__Hyperlink"
            >
              View documents
            </Link>
            <TableRowIcon
              src={MoreVT}
              alt="Options"
              onClick={(e: any) => {
                handleCategoryOptionClick(e);
              }}
              data-testid="categoryKebabMenu"
            />

            <OptionsPopover
              open={Boolean(popoverAnchorElem)}
              elevation={0}
              anchorEl={popoverAnchorElem}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleCategoryOptionClose}
              className='categoryKebabOptionsPopover'
            >
              <PopoverOption
                onClick={() => {
                  props.handleAddSubcategoryClick(props.name, true);
                  handleCategoryOptionClose();
                }}
              >
                Add subcategory
              </PopoverOption>
              <PopoverOption
                onClick={(e: any) => {
                  props.handleRenameClick(
                    props.languages,
                    props.visibility,
                    {
                      categoryId: props.categoryId,
                      categoryName: props.name,
                    },
                    {}
                  );
                  handleCategoryOptionClose();
                }}
              >
                Edit
              </PopoverOption>
              <PopoverOption
                onClick={() => {
                  props.handleDeleteClick(props.name);
                  handleCategoryOptionClose();
                }}
              >
                Delete
              </PopoverOption>
            </OptionsPopover>
          </TableRowCategory>
          {isCategoryExpanded && subcategoriesArr.length === 0 && (
            <TableRowSubcategory>
              <p className="TableRowSubcategory__Name">
                Please add a subcategory on the options menu on the right.
              </p>
            </TableRowSubcategory>
          )}
          {isCategoryExpanded && (
            <Droppable
              droppableId={`droppable-subcategory-${props.name}`}
              type={`SUBCATEGORY||${props.name}`}>
              {(provided: DroppableProvided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}>
                  {subcategoriesArr.map((val: IDataObject, index) => {
                    if (!val) {
                      return null;
                    }
                    return (
                      <SubcategoryRow
                        key={val.languages[Locale.en].name}
                        categoryId={props.categoryId}
                        subcategoryId={val._id}
                        name={val.languages[Locale.en].name}
                        index={index}
                        last_updated_by={val.last_updated_by}
                        last_updated_on={val.last_updated_on}
                        categoryName={props.languages[Locale.en].name} // English should always exist
                        languages={val.languages}
                        handleRenameClick={(
                          languages,
                          categoryInfo,
                          subcategoryInfo,
                          isSubCategory
                        ) =>
                          props.handleRenameClick(
                            languages,
                            props.visibility,
                            categoryInfo,
                            subcategoryInfo,
                            isSubCategory
                          )}
                        handleDeleteClick={props.handleDeleteClick}
                      />
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </TableContentWrapper>
      )}
    </Draggable>
  );
};

// Subcomponent for subcategory row
type SubcategoryRowProps = {
  categoryId: string;
  subcategoryId: string;
  name: string;
  index: number;
  last_updated_by: string;
  last_updated_on: string;
  categoryName: string;
  languages: CategoryLanguages;
  handleRenameClick: (
    languages: CategoryLanguages,
    categoryInfo: { categoryId: string; categoryName: string },
    subcategoryInfo: { subcategoryId: string; subcategoryName: string },
    _isSubCategory?: boolean
  ) => void;
  handleDeleteClick: (categoryName: string, subcategoryName?: string) => void;
};

const SubcategoryRow = (props: SubcategoryRowProps) => {
  /* State */
  const [ popoverAnchorElem, setPopoverAnchorElem ] = useState(null);

  /* Events */
  // Popover actions
  const handleCategoryOptionClick = (e: any) => {
    if (e.currentTarget === popoverAnchorElem || !e) {
      return;
    }

    setPopoverAnchorElem(e.currentTarget);
  };
  const handleCategoryOptionClose = () => {
    setPopoverAnchorElem(null);
  };

  if (!props.name) {
    return null;
  }
  return (
    <Draggable
      draggableId={props.name}
      index={props.index}>
      {(provided: DraggableProvided) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <TableRowSubcategory
            key={`${props.name}_${props.name}`}
            data-testid="kbSubcategoryRow"
          >
            <div>
              <p className="TableRowSubcategory__Name">{props.name}</p>
            </div>
            <p>{props.last_updated_by}</p>
            <Moment
              format="MMM DD YYYY hh:mm A"
              className="TableRow__DateTime">
              {props.last_updated_on}
            </Moment>
            <span />
            <TableRowIcon
              src={MoreVT}
              alt="Options"
              onClick={(e: any) => {
                handleCategoryOptionClick(e);
              }}
              data-testid="categoryKebabMenu"
            />

            <OptionsPopover
              open={Boolean(popoverAnchorElem)}
              elevation={0}
              anchorEl={popoverAnchorElem}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleCategoryOptionClose}
              className='categoryKebabOptionsPopover'
            >
              <PopoverOption
                onClick={() => {
                  props.handleRenameClick(
                    props.languages,
                    {
                      categoryName: props.categoryName,
                      categoryId: props.categoryId,
                    },
                    {
                      subcategoryName: props.name,
                      subcategoryId: props.subcategoryId,
                    },
                    true
                  );
                  handleCategoryOptionClose();
                }}
              >
                Edit
              </PopoverOption>
              <PopoverOption
                onClick={() => {
                  props.handleDeleteClick(props.categoryName, props.name);
                  handleCategoryOptionClose();
                }}
              >
                Delete
              </PopoverOption>
            </OptionsPopover>
          </TableRowSubcategory>
        </div>
      )}
    </Draggable>
  );
};

export default CategoryRow;
