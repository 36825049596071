import AuthUserUtil from '../lib/auth.util';
import getState from '../lib/RevealStateHelper';
import { UserPermissionsHelper } from '../lib/UserPermissions';
import { PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE } from './account.constants';
import { CS_DIGITAL_ONBOARDING_KB_SUBCATEGORY_ID } from './knowledge.constants';

export interface HeaderItem {
  link: {
    href: string;
    textKey: string;
    fallbackText: string;
    newTab?: boolean;
    absolute?: boolean;
    id: string;
    featureFlag?: string; // not currently being used, but could be useful later?
  };
  dropdownChildren?: any;
}

const MAIN_HEADER_ITEMS: HeaderItem[] = [
  {
    link: {
      href: '/documentation',
      textKey: 'header_menu_documentation',
      fallbackText: 'Documentation',
      id: 'documentation',
    },
  },
  {
    link: {
      href: '/product-downloads',
      textKey: 'header_menu_product_downloads',
      fallbackText: 'Downloads',
      id: 'downloads',
    },
  },
  {
    link: {
      href: '/collab-space',
      textKey: 'header_menu_collaboration',
      fallbackText: 'Collaboration',
      id: 'collaboration',
    },
  },
  {
    link: {
      href: '/company',
      textKey: 'header_menu_company',
      fallbackText: 'Company',
      id: 'company',
    },
  },
  {
    link: {
      href: '/premium-account',
      // We leave these 2 fields empty since they will be set dynamically based on the company's maintenance flag
      textKey: '',
      fallbackText: '',
      id: 'premiumAccount',
    },
  },
  {
    link: {
      href: '/enablement',
      textKey: 'header_menu_enablement',
      fallbackText: 'Enablement',
      id: 'enablement',
    },
  },
  {
    link: {
      href: '/support',
      textKey: 'header_menu_support',
      fallbackText: 'Support',
      id: 'support',
    },
  },
];

const DOCUMENTATION_DROPDOWN_CHILDREN: HeaderItem[] = [
  {
    link: {
      href: '/knowledge-base',
      textKey: 'header_menu_documentation_knowledge_base',
      fallbackText: 'Knowledge Base',
      id: 'knowledgeBase',
    },
  },
  {
    link: {
      href: '/support/knowledge-base',
      textKey: 'header_menu_documentation_technical_solution_articles',
      fallbackText: 'Technical Solution Articles',
      id: 'technicalSolutionArticles',
    },
  },
  {
    link: {
      href: 'https://docs.uipath.com',
      textKey: 'header_menu_documentation_product_documentation',
      fallbackText: 'Product Documentation',
      newTab: true,
      absolute: true,
      id: 'productDocumentation',
    },
  },
  {
    link: {
      href: 'https://www.uipath.com/legal/trust-and-security/security-advisories',
      textKey: 'header_menu_documentation_security_advisories',
      fallbackText: 'Security Advisories',
      newTab: true,
      absolute: true,
      id: 'securityAdvisories',
    },
  },
  {
    link: {
      href: 'https://forum.uipath.com',
      textKey: 'header_menu_documentation_user_communities',
      fallbackText: 'User Communities',
      newTab: true,
      absolute: true,
      id: 'userCommunities',
    },
  },
  {
    link: {
      href: 'https://academy.uipath.com',
      textKey: 'header_menu_documentation_academy',
      fallbackText: 'Academy',
      newTab: true,
      absolute: true,
      id: 'academy',
    },
  },
];

const USER_SETTINGS_DROPDOWN_CHILDREN: Array<{
  link: {
    href: string;
    textKey?: string;
    fallbackText?: string;
    newTab?: boolean;
  };
}> = [
  {
    link: {
      href: '/profile',
      textKey: 'header_menu_preferences',
      fallbackText: 'Preferences',
    },
  },
  {
    link: {
      href: 'switch-account',
      textKey: 'header_menu_switch_account',
      fallbackText: 'Switch Account',
    },
  },
  {
    link: {
      href: 'notifications',
      textKey: 'header_menu_notifications_settings',
      fallbackText: 'Notification Settings',
    },
  },
  {
    link: {
      href: '/admin/knowledge-base/categories',
      textKey: 'header_menu_manage_kb_categories',
      fallbackText: 'Manage KB Categories',
    },
  },
  {
    link: {
      href: '/admin/analytics',
      textKey: 'header_menu_analytics',
      fallbackText: 'Analytics',
    },
  },
  {
    link: {
      href: '/admin/user-management',
      textKey: 'header_menu_manage_admin_users',
      fallbackText: 'Manage Admin Users',
    },
  },
  {
    link: {
      href: '/admin/hapo',
      textKey: 'header_menu_manage_hapo',
      fallbackText: 'Manage Licenses',
    },
  },
  {
    link: {
      href: '/admin/product-catalog',
      textKey: 'header_menu_manage_product_catalog',
      fallbackText: 'Manage Product Catalog',
    },
  },
  { link: { href: '/language' } },
  {
    link: {
      href: '/logout',
      textKey: 'header_menu_logout',
      fallbackText: 'Log out',
    },
  },
];

// Dropdown children for `Documentation`
const getDocumentationDropdownChildren = (): HeaderItem[] => {
  const isRegisteredUser = UserPermissionsHelper.isRegisteredUser();

  // Non-registered users shouldn't see KB nor TS articles
  return DOCUMENTATION_DROPDOWN_CHILDREN
    .filter(val => {
      if (isRegisteredUser) {
        return true;
      }

      return [
        'https://docs.uipath.com',
        'https://forum.uipath.com',
        'https://academy.uipath.com',
      ].includes(val.link.href);
    });
};

// Dropdown children for `Company`
const getCompanyDropdownChildren = (): HeaderItem[] | undefined => {
  const isRegisteredUser = UserPermissionsHelper.isRegisteredUser();
  const isViewLicenseAllowed = UserPermissionsHelper.isViewLicenseAllowed();
  const isViewTeamUsersAllowed = UserPermissionsHelper.isViewTeamUsersAllowed();
  const isViewHAPOLicenseAllowed = UserPermissionsHelper.isViewHAPOLicenseAllowed();
  const isRFQEnabled = UserPermissionsHelper.isRFQEnabled();

  if (!isRegisteredUser) {
    return;
  }

  const temp: HeaderItem[] = [
    {
      link: {
        href: '/company?section=contacts',
        textKey: 'header_menu_company_uipath_contacts',
        fallbackText: 'UiPath Contacts',
        id: 'uipathContacts',
      },
    },
  ];

  if (isViewTeamUsersAllowed) {
    temp.push({
      link: {
        href: '/company?section=users',
        textKey: 'header_menu_company_team_users',
        fallbackText: 'Team Users',
        id: 'teamUsers',
      },
    });
  }

  if (isViewLicenseAllowed) {
    temp.push({
      link: {
        href: '/company?section=licenses',
        textKey: 'header_menu_company_licenses',
        fallbackText: 'Licenses',
        id: 'licenses',
      },
    });
  }

  if (isViewHAPOLicenseAllowed) {
    temp.push({
      link: {
        href: '/company?section=hapo',
        textKey: 'header_menu_company_enterprise_agreement',
        fallbackText: 'License Management',
        id: 'licenseManagement',
      },
    });
  }

  if (isRFQEnabled && isViewLicenseAllowed) {
    temp.push({
      link: {
        href: '/request-quote',
        textKey: 'header_menu_company_request_for_quote',
        fallbackText: 'Request for Quote',
        id: 'requestForQuote',
      },
    });
  }

  return temp;
};

// Dropdown children for `Premium Account`
const getPremiumAccountDropdownChildren = (): HeaderItem[] | undefined => {
  const isViewPremiumSupportAllowed = UserPermissionsHelper.isViewPremiumSupportAllowed();
  const isViewSupportAllowed = UserPermissionsHelper.isViewSupportAllowed();
  const isActivateSuccessCustomer = UserPermissionsHelper.isActivateSuccessCustomer();

  if (isViewPremiumSupportAllowed && isViewSupportAllowed) {
    return [
      ...(!isActivateSuccessCustomer ? [
        {
          link: {
            href: '/support?section=cases-premium',
            textKey: 'header_menu_premium_account_preventive_care',
            fallbackText: 'Preventive Care',
            id: 'preventiveCare',
          },
        },
      ] : []),
      {
        link: {
          href: '/support?section=tickets-dashboard',
          textKey: 'header_menu_premium_account_support_dashboard',
          fallbackText: 'Support Dashboard',
          id: 'supportDashboard',
        },
      },
      {
        link: {
          href: `/knowledge-base/category/Onboarding?sub=${CS_DIGITAL_ONBOARDING_KB_SUBCATEGORY_ID}`,
          textKey: 'header_menu_enablement_onboarding_content',
          fallbackText: 'Onboarding Content',
          id: 'onboardingContent',
        },
      },
    ];
  }
};

const getEnablementDropdownChildren = (): HeaderItem[] => [
  {
    link: {
      href: `/knowledge-base/category/Onboarding?sub=${CS_DIGITAL_ONBOARDING_KB_SUBCATEGORY_ID}`,
      textKey: 'header_menu_enablement_onboarding_content',
      fallbackText: 'Onboarding Content',
      id: 'onboardingContent',
    },
  },
];

// Dropdown children for `Customer Portal Settings`
const getUserSettingsDropdownChildren = (): Array<{
  link: {
    href: string;
    textKey?: string;
    fallbackText?: string;
    newTab?: boolean;
  };
}> => {
  const isRegisteredUser = UserPermissionsHelper.isRegisteredUser();
  const isEditKBAllowed = UserPermissionsHelper.isEditKBAllowed();
  const isEditEmailNotificationAllowed = UserPermissionsHelper.isEditEmailNotificationAllowed();
  const isEditProfileAllowed = UserPermissionsHelper.isEditProfileAllowed();
  const isEditSuperAdminsAllowed = UserPermissionsHelper.isEditSuperAdminsAllowed();
  const isViewProductCatalogAllowed = UserPermissionsHelper.isViewProductCatalogAllowed();
  const isManageHAPOAllowed = UserPermissionsHelper.isManageHAPOAllowed();
  const isViewAnalyticsAllowed = UserPermissionsHelper.isViewAnalyticsAllowed();
  const hasSharedCompanies = UserPermissionsHelper.hasSharedCompanies();

  const state = getState();
  const isUiPath = state?.isUiPath ?? false;

  return USER_SETTINGS_DROPDOWN_CHILDREN
    .filter((item) => {
      switch (item.link.href) {
        case '/admin/knowledge-base/categories': {
          return isRegisteredUser && isEditKBAllowed;
        }
        case 'notifications': {
          return isRegisteredUser && isEditEmailNotificationAllowed;
        }
        case '/profile': {
          return isRegisteredUser && isEditProfileAllowed;
        }
        case '/admin/user-management': {
          return isRegisteredUser && isEditSuperAdminsAllowed;
        }
        case '/admin/product-catalog': {
          return isRegisteredUser && isViewProductCatalogAllowed;
        }
        case '/admin/hapo': {
          return isRegisteredUser && isManageHAPOAllowed;
        }
        case '/admin/analytics': {
          return isRegisteredUser && isViewAnalyticsAllowed;
        }
        case 'switch-account': {
          return isRegisteredUser && !isUiPath && hasSharedCompanies;
        }
        case '/language': {
          return !AuthUserUtil.isCloudUser();
        }
        case '/logout': {
          return !AuthUserUtil.isCloudUser();
        }
        default: {
          return true;
        }
      }
    });
};

// Dropdown children for analytics
export const mainMenuLinkAnalytics = [
  {
    link: {
      href: '/admin/analytics',
      textKey: 'header_menu_analytics_adoption',
      fallbackText: 'Adoption',
    },
  },
  {
    link: {
      href: '/admin/analytics-rfq-hapo',
      textKey: 'header_menu_analytics_rfq_hapo',
      fallbackText: 'Licenses',
    },
  },
];

const getHeaderLogoHref = (): string => {
  const isCompanySelected = UserPermissionsHelper.isCompanySelected();

  const state = getState();
  const isUiPath = state?.isUiPath ?? false;

  // If the user is a uipath user and has no company selected, send them to the browse companies page
  return isUiPath && !isCompanySelected ? '/browse' : '/';
};

const getMainHeaderItems = (): HeaderItem[] => {
  const isRegisteredUser = UserPermissionsHelper.isRegisteredUser();
  const isUiPathUserWithNoCompanySelected = UserPermissionsHelper.isUiPathUserWithNoCompanySelected();
  const isCollabSpaceEnabled = UserPermissionsHelper.isCollabSpaceEnabled();
  const isViewPremiumSupportAllowed = UserPermissionsHelper.isViewPremiumSupportAllowed();
  const isViewSupportAllowed = UserPermissionsHelper.isViewSupportAllowed();
  const isPremiumAccount = UserPermissionsHelper.isPremiumAccount();

  const state = getState();
  const companyType = state?.companyType ?? '';

  const mainHeaderItems = MAIN_HEADER_ITEMS
    .map((item) => {
      switch (item.link.href) {
        case '/documentation': {
          // Get all the dropdown items for the documentation module
          item.dropdownChildren = getDocumentationDropdownChildren();
          return item;
        }
        case '/company': {
          // Get all the dropdown items for the company module
          item.dropdownChildren = getCompanyDropdownChildren();
          return item;
        }
        case '/premium-account': {
          // Get all the dropdown items for the premium account module
          item.dropdownChildren = getPremiumAccountDropdownChildren();
          // Change the text of the premium account module based on the company type
          if (isPremiumAccount) {
            item.link.textKey = PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE[companyType].textKey;
            item.link.fallbackText = PREMIUM_ACCOUNT_TYPE_TO_MAIN_MENU_TITLE[companyType].fallbackText;
          }
          return item;
        }
        case '/enablement': {
          // Get all the dropdown items for the enablement module
          item.dropdownChildren = getEnablementDropdownChildren();
          return item;
        }
        case '/support': {
          // Return a new object to avoid modifying original link.href for future runs of this switch statement
          return {
            ...item,
            link: {
              ...item.link,
              // Change the link of support based on the user type
              href: isUiPathUserWithNoCompanySelected
                ? '/support/add-case'
                : '/support?section=tickets-incident',
            },
          };
        }
        default: {
          return item;
        }
      }
    })
    .filter((item) => {
      // Non-registered users should only see the documentation module
      if (!isRegisteredUser) {
        return item.link.href === '/documentation';
      }

      switch (item.link.href) {
        case '/collab-space': {
          return isCollabSpaceEnabled;
        }
        case '/premium-account': {
          return isViewSupportAllowed && isViewPremiumSupportAllowed;
        }
        case '/enablement': {
          return !isPremiumAccount;
        }
        case '/support': {
          return isViewSupportAllowed;
        }
        default: {
          return true;
        }
      }
    });

  return mainHeaderItems;
};

const getSideHeaderItemsMetaData = () => {
  const isRegisteredUser = UserPermissionsHelper.isRegisteredUser();
  const isGlobalSearchAllowed = UserPermissionsHelper.isGlobalSearchAllowed();
  const isShowNotificationsAllowed = UserPermissionsHelper.isShowNotificationsAllowed();
  const isCompanySelected = UserPermissionsHelper.isCompanySelected();

  const showGlobalSearch = isRegisteredUser && isGlobalSearchAllowed;
  const showNotifications = isRegisteredUser && isShowNotificationsAllowed && isCompanySelected;
  const userSettingsDropdownChildren = getUserSettingsDropdownChildren();

  return {
    showGlobalSearch,
    showNotifications,
    userSettingsDropdownChildren,
  };
};

export const getHeaderItems = (): {
  headerLogoHref: string;
  mainHeaderItems: HeaderItem[];
  sideHeaderItemsMetaData: {
    showGlobalSearch: boolean;
    showNotifications: boolean;
    userSettingsDropdownChildren: Array<{
      link: {
        href: string;
        textKey?: string;
        fallbackText?: string;
        newTab?: boolean;
      };
    }>;
  };
} => {
  const headerLogoHref = getHeaderLogoHref();
  const mainHeaderItems = getMainHeaderItems();
  const sideHeaderItemsMetaData = getSideHeaderItemsMetaData();

  return {
    headerLogoHref,
    mainHeaderItems,
    sideHeaderItemsMetaData,
  };
};

export enum HeaderNavHrefPattern {
  knowledgeBaseHome = '/knowledge-base',
  knowledgeBaseCategory = '/knowledge-base/category/:categoryName',
  technicalSolutionArticlesHome = '/support/knowledge-base',
  technicalSolutionArticlesProduct = '/support/knowledge-base/:productName',
  technicalSolutionArticle = '/kb/:articleNumber',
  productDownloadsHome = '/product-downloads',
  collabSpaceHome = '/collab-space',
  collabSpaceFolder = '/collab-space/folders/:folderId/:slug',
  companyHome = '/company',
  companyUiPathContacts = '/company?section=contacts',
  companyTeamUsers = '/company?section=users',
  companyLicenses = '/company?section=licenses',
  companyLicenseManagement = '/company?section=hapo',
  companyRequestForQuote = '/request-quote',
  supportIncidentCases = '/support?section=tickets-incident',
  supportIncidentCaseCreation = '/support/add-case',
  supportPremiumCases = '/support?section=cases-premium',
  supportPremiumCaseCreation = '/support/premium-care/add-case',
  supportPremiumDashboard = '/support?section=tickets-dashboard',
}

export enum HeaderNavItem {
  documentation = 'documentation',
  downloads = 'downloads',
  collaboration = 'collaboration',
  company = 'company',
  premiumAccount = 'premiumAccount',
  support = 'support',
}

export const HeaderNavHeirarchy: Map<HeaderNavItem, HeaderNavHrefPattern[]> = new Map([
  [
    HeaderNavItem.documentation,
    [
      HeaderNavHrefPattern.knowledgeBaseHome,
      HeaderNavHrefPattern.knowledgeBaseCategory,
      HeaderNavHrefPattern.technicalSolutionArticlesHome,
      HeaderNavHrefPattern.technicalSolutionArticlesProduct,
      HeaderNavHrefPattern.technicalSolutionArticle,
    ],
  ],
  [
    HeaderNavItem.downloads,
    [ HeaderNavHrefPattern.productDownloadsHome ],
  ],
  [
    HeaderNavItem.collaboration,
    [
      HeaderNavHrefPattern.collabSpaceHome,
      HeaderNavHrefPattern.collabSpaceFolder,
    ],
  ],
  [
    HeaderNavItem.company,
    [
      HeaderNavHrefPattern.companyHome,
      HeaderNavHrefPattern.companyUiPathContacts,
      HeaderNavHrefPattern.companyTeamUsers,
      HeaderNavHrefPattern.companyLicenses,
      HeaderNavHrefPattern.companyLicenseManagement,
      HeaderNavHrefPattern.companyRequestForQuote,
    ],
  ],
  [
    HeaderNavItem.premiumAccount,
    [
      HeaderNavHrefPattern.supportPremiumCases,
      HeaderNavHrefPattern.supportPremiumCaseCreation,
      HeaderNavHrefPattern.supportPremiumDashboard,
    ],
  ],
  [
    HeaderNavItem.support,
    [
      HeaderNavHrefPattern.supportIncidentCases,
      HeaderNavHrefPattern.supportIncidentCaseCreation,
    ],
  ],
]);

