import { axiosGet } from '../client/axios';
import { ACCOUNT_INFO_URL } from '../constants/network.constants';

class CompanyHelper {
  getCompanyData = async (companyId: string, token: string) => {
    const companyData: {
      companyType: string;
      companyLogo: string;
      companyCountry: string;
      accountQuestionnaire: string | undefined;
      uiPathContacts: Array<{
        email: string;
        id: string;
        name: string;
        profilePhoto: string;
        roles: string;
      }>;
      teamUsers: Array<{
        Email: string;
        Name: string;
        cpRole: string;
        oid: string;
        permissions: string[];
        photo: string;
        status: string;
        userAccounts: string[];
      }>;
      isHapoEnabled: boolean;
      isUtoEnabled: boolean;
      supportOnlyProvidedByCountry: string | undefined;
    } = {
      companyType: '',
      companyLogo: '',
      companyCountry: '',
      accountQuestionnaire: undefined,
      uiPathContacts: [],
      teamUsers: [],
      isHapoEnabled: false,
      isUtoEnabled: false,
      supportOnlyProvidedByCountry: undefined,
    };
    try {
      const companyInfoRes = await axiosGet(
        `${ACCOUNT_INFO_URL}`,
        companyId,
        token
      );

      if (companyInfoRes.status === 200 && companyInfoRes.data) {
        companyData.companyType =
          companyInfoRes.data.account?.maintenanceFlag || '';
        companyData.accountQuestionnaire =
          companyInfoRes.data.account?.accountQuestionnaire;
        let companyLogo = '';
        if (
          companyInfoRes.data.account?.picture_storage_account_name &&
          companyInfoRes.data.account?.logo
        ) {
          companyLogo = `${companyInfoRes.data.account.picture_storage_account_name}/${companyInfoRes.data.account.logo}`;
        }
        companyData.companyLogo = companyLogo;
        companyData.companyCountry = companyInfoRes.data.account.country || '';
        companyData.uiPathContacts = companyInfoRes.data.uipathTeam || [];
        // Filter out multi account users that aren't allowed to access the selected company
        companyData.teamUsers = (
          companyInfoRes.data.account?.contacts?.records || []
        ).filter((user: any) => user.userAccounts.includes(companyId));
        companyData.isHapoEnabled =
          companyInfoRes.data.account?.hapo_feature_enabled;
        companyData.isUtoEnabled =
          companyInfoRes.data.account?.uto_feature_enabled;
        companyData.supportOnlyProvidedByCountry = companyInfoRes.data.account?.supportOnlyProvidedByCountry;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
    return companyData;
  };
}

export default new CompanyHelper();
