import type {
  Auth0Client,
  User,
} from '@auth0/auth0-spa-js';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import type { History } from 'history';
import React, {
  createContext,
  type ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import * as AUTH_CONFIG from '../../constants/auth.constants';

export type TAuth0AppState = { [key: string]: any } & { targetUrl: string };
export interface IAuth0Context {
  isAuthenticated: boolean;
  user?: User;
  isLoading: boolean;
  loginWithRedirect?: typeof Auth0Client.prototype.loginWithRedirect;
  getTokenSilently: () => Promise<string | undefined>;
  logout?: typeof Auth0Client.prototype.logout;
}

interface Props {
  children?: ReactNode;
  onRedirectCallback?: (history: History, appState?: TAuth0AppState) => void;
}

const DEFAULT_REDIRECT_CALLBACK = (history: History, appState?: TAuth0AppState) => {
  history.push(appState?.targetUrl ?? window.location.pathname);
};

export const Auth0Context = createContext({} as IAuth0Context);
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
}: Props) => {
  const [ isAuthenticated, setIsAuthenticated ] = useState<boolean>(false);
  const [ user, setUser ] = useState<User>();
  const [ auth0Client, setAuth0Client ] = useState<Auth0Client>();
  const [ isLoading, setIsLoading ] = useState<boolean>(true);

  const history: History = useHistory();

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0 = await createAuth0Client({
          domain: AUTH_CONFIG.AUTH0_DOMAIN,
          clientId: AUTH_CONFIG.AUTH0_CLIENT_ID,
          authorizationParams: {
            audience: AUTH_CONFIG.AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
            scope: AUTH_CONFIG.AUTH0_SCOPES,
          },
        });
        setAuth0Client(auth0);

        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          // Will contain the targetUrl as the callback URL
          try {
            const { appState } = await auth0.handleRedirectCallback() as { appState?: TAuth0AppState };
            onRedirectCallback(history, appState);
          } catch (e) {
            console.error('An Auth0 error occurred:', e);
            // Reload page but without the code and state in the URL
            history.replace(window.location.pathname);
            // return window.location.href = window.location.origin + window.location.pathname;
          }
        }

        const _isAuthenticated = await auth0.isAuthenticated();

        setIsAuthenticated(_isAuthenticated);

        if (_isAuthenticated) {
          const _user = await auth0.getUser();
          setUser(_user);
        }

        setIsLoading(false);
      } catch (e) {
        console.error('Error loading Auth0', e);
        setIsLoading(false);
      }
    };

    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithRedirect = async (options: any) => auth0Client?.loginWithRedirect(options);
  const getTokenSilently = async () => auth0Client && await auth0Client.getTokenSilently();
  const logout = async (options: any) => auth0Client?.logout(options);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        isLoading,
        loginWithRedirect,
        getTokenSilently,
        logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
