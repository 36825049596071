import type {
  SearchBoxProps,
  SearchBoxState,
  SearchEngine,
  Suggestion,
} from '@coveo/headless';
import { buildSearchBox } from '@coveo/headless';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@mui/material';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ApolloIcon from '../../../components/ApolloIcon';
import {
  Locale,
  localeToLanguage,
} from '../../../constants/localization.constants';
import {
  QUERY_TEXT_URL_KEY,
  SEARCH_MINIMUM_CHARACTER_LENGTH,
  SEARCH_SUGGESTIONS_DELAY_MS,
} from '../../../constants/search.constants';
import { FacetFilterTypes } from '../../../pages/Search/Facets/types';
import { DEFAULT_SEARCH_PROPS } from '../../../pages/Search/SearchBar/constants';
import { KeyboardInputs } from '../../../pages/Search/SearchBar/types';
import { getCloudPrefixedPath } from '../../../utils/cloud';

const Container = styled.div`
  position: relative;
  margin-right: 10px;
`;

const CollapsedIconContainer = styled.div`
  height: ${p => p.theme.spacing(2.5)}px;
`;

const CollapsedIcon = styled(ApolloIcon)`
  color: black;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.palette.blue[500]};
  }

  &.medium-size {
    font-size: ${p => p.theme.spacing(2.5)}px;
  }
`;

const SearchBarContainer = styled.div`
  width: 400px;
  z-index: 100;
  position: absolute;
  right: -8px;
  top: -${p => p.theme.spacing(1)}px;
  background-color: white;
  ${p => p.hidden && `
    display: none;
  `}
`;

const AutocompleteStyled = styled(Autocomplete)`
  display: flex;
  flex-grow: 1;
  width: inherit;
  height: inherit;
  padding: unset;
  margin: unset;

  .MuiAutocomplete-inputRoot {
    padding: ${p => p.theme.spacing(1)}px !important;
    flex-wrap: unset;
  }
`;

const SuggestionListItem = styled.li`
  font-size: 1.6rem;
`;

const SearchBarStyled: typeof TextField = styled(TextField as any)`
  width: inherit;

  &&& {
    .MuiInputBase-root {
      height: 36px;
      padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(1)}px;
    }
  }

  // Direct parent of input element
  .MuiOutlinedInput-adornedEnd {
    padding: 0;
  }

  // Input element
  input.MuiInputBase-input {
    font-size: 1.6rem !important;
  }

  // Error label
  .MuiInputBase-root.Mui-error::after {
    display: none;
  }

  // Helper text
  .MuiFormHelperText-root.Mui-error {
    color: ${p => p.theme.palette.red[600]};
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid ${p => p.theme.palette.grey[500]} !important;
    &:hover, &:focus, &:focus-within, &:active {
      border-color: transparent !important;
    }
  }

  .MuiInputBase-input {
    border: none;
    padding: ${p => p.theme.spacing(0.5)}px !important;
  }
`;

const SearchBarAdornmentIcon = styled(ApolloIcon)`
  color: ${p => p.theme.palette.grey[600]};

  && {
    font-size: ${p => p.theme.spacing(2.5)}px;
  }

  &.search {
    cursor: default;
  }

  &.close:hover {
    cursor: pointer;
    color: ${p => p.theme.palette.blue[500]};
  }
`;

interface Props {
  engine: SearchEngine;
  searchBoxProps?: SearchBoxProps;
  id?: string;
  className?: string;
  onSearchCallback?: (
    event: React.ChangeEvent<{}>,
    value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => void;
}

const MiniSearchBar = (props: Props) => {
  const {
    engine,
    onSearchCallback,
    searchBoxProps = DEFAULT_SEARCH_PROPS,
    className = '',
    id,
  } = props;
  const {
    i18n, t,
  } = useTranslation('common');

  if (id && searchBoxProps.options) {
    searchBoxProps.options.id = id;
  }

  const inputRef = useRef<HTMLDivElement | null>(null);
  const searchBoxControllerRef = useRef(buildSearchBox(engine, searchBoxProps));
  const [ searchBoxState, setSearchBoxState ] = useState<SearchBoxState>(
    searchBoxControllerRef.current.state
  );
  const [ isSearchCollapsed, setIsSearchCollapsed ] = useState<boolean>(true);
  const [ searchText, setSearchText ] = useState<string>('');
  const [ suggestionClicked, setSuggestionClicked ] = useState<{
    clicked: boolean;
    suggestion: string;
  }>({
    clicked: false,
    suggestion: '',
  });
  const [ isInputError, setIsInputError ] = useState<boolean>(false);
  const [ locale, setLocale ] = useState<Locale>(i18n.language as Locale);

  useEffect(() => {
    setLocale(i18n.language as Locale);
  }, [ i18n.language ]);

  useEffect(() => {
    if (!isSearchCollapsed) {
      inputRef?.current?.focus();
    }
  }, [ isSearchCollapsed ]);

  useEffect(() => {
    const {
      clicked, suggestion,
    } = suggestionClicked;

    if (clicked && suggestion) {
      onSearch(suggestion);
      setSuggestionClicked({
        clicked: false,
        suggestion: '',
      });
    }
  }, [ suggestionClicked ]);

  const setAndSubscribeController = useCallback(() => {
    const cancelSubscription = searchBoxControllerRef.current.subscribe(() => {
      // do stuff with the suggestions
      setSearchBoxState(searchBoxControllerRef.current.state);
    });

    return () => {
      cancelSubscription && cancelSubscription();
    };
  }, []);

  useEffect(setAndSubscribeController, [ setAndSubscribeController ]);

  const handleToggleCollapsed = () => {
    setSearchText('');
    searchBoxControllerRef.current.clear();
    setIsInputError(false);
    setIsSearchCollapsed(!isSearchCollapsed);
  };

  const debouncedControllerUpdateText = useCallback(
    debounce((value: string) => {
      searchBoxControllerRef.current.updateText(value);
    }, SEARCH_SUGGESTIONS_DELAY_MS),
    []
  );

  const onSearchTextChange = (
    e: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    switch (reason) {
      case 'clear': {
        setSearchText(value);
        searchBoxControllerRef.current.updateText(value);
        setIsInputError(false);
        break;
      }
      case 'input': {
        setSearchText(value);
        value.length > 0
          ? debouncedControllerUpdateText(value)
          : searchBoxControllerRef.current.clear();
        setIsInputError(false);
        break;
      }
      default: {
        // When reason is 'reset'
        // Triggered when 'Enter' key is pressed.
        // Do nothing. let component handle enter
        break;
      }
    }
  };

  const onSearch = (autocompleteText?: string) => {
    const text: string = autocompleteText ?? searchText;
    if (text.length < SEARCH_MINIMUM_CHARACTER_LENGTH) {
      setIsInputError(true);
      return;
    }

    window.location.replace(
      getCloudPrefixedPath(`/search#${QUERY_TEXT_URL_KEY}=${text}&f-${FacetFilterTypes.LANGUAGE}=${localeToLanguage.get(locale) ?? localeToLanguage.get(Locale.en)}`)
    );
    handleToggleCollapsed();
  };

  const autocompleteChange = (
    e: React.ChangeEvent<any>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    const text = (value as Suggestion)?.rawValue;

    // Clearing text should not execute search
    // Have 'select-option' (which is the reason on 'Enter') handled
    // by setting the 'suggestionClicked' state and letting the 'useEffect'
    // and 'onAutocompleteKeyDown' handling the rest
    const EXCLUDED_REASONS: Set<AutocompleteChangeReason> = new Set([
      'clear',
      'create-option',
    ]);

    if (!EXCLUDED_REASONS.has(reason)) {
      onSearchTextChange(e, text, 'input');
      setSuggestionClicked({
        clicked: true,
        suggestion: text,
      });
    }
  };

  const onAutocompleteKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Only run submit from 'searchText' if suggestion option wasn't clicked
    if (!suggestionClicked.clicked && e.key === KeyboardInputs.ENTER) {
      onSearch();
    }
  };

  return (
    <Container>
      <CollapsedIconContainer
        data-testid='GlobalSearch__CollapsedIcon'
        onClick={handleToggleCollapsed}
      >
        <CollapsedIcon
          icon='search'
          className='medium-size' />
      </CollapsedIconContainer>

      <SearchBarContainer
        className={className}
        data-testid='GlobalSearch__SearchBarContainer'
        hidden={isSearchCollapsed}
      >
        <AutocompleteStyled
          freeSolo
          inputValue={searchText}
          onChange={onSearchCallback ? onSearchCallback : autocompleteChange}
          onInputChange={onSearchTextChange}
          options={searchBoxState.suggestions}
          getOptionLabel={(suggestion: any) => (
            (suggestion as Suggestion)?.rawValue ?? ''
          )}
          filterOptions={(suggestion: any) => suggestion}
          renderOption={(suggestion: any) => (
            <SuggestionListItem>
              {(suggestion as Suggestion).highlightedValue}
            </SuggestionListItem>
          )}
          onKeyDown={onAutocompleteKeyDown}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <SearchBarStyled
              {...params}
              data-testid='GlobalSearch__Input'
              error={isInputError}
              helperText={isInputError
                ? t('global_search_minimum_characters_error', 'Search term must be 3 characters or longer in length')
                : ''}
              variant={'outlined' as any}
              placeholder={t('global_search_placeholder', 'Search across UiPath')}
              InputProps={{
                ...params.InputProps,
                inputRef,
                startAdornment: (
                  <SearchBarAdornmentIcon
                    icon='search'
                    className='search'
                  />
                ),
                endAdornment: (
                  <SearchBarAdornmentIcon
                    data-testid='GlobalSearch__CloseButton'
                    icon='close'
                    className='close'
                    onClick={handleToggleCollapsed}
                  />
                ),
              }}
            />
          )}
        />
      </SearchBarContainer>
    </Container>
  );
};

export default MiniSearchBar;
