import { UserRole } from '@customer-portal/constants';
import { Tooltip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// Components
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, {
  useContext,
  useEffect,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

import * as styles from '../../assets/css/Select';
import InfoIcon from '../../assets/img/svg/status_icons/info_gray.svg';
// Constants
import { AccountType } from '../../constants/account.constants';
import {
  addUserPermissions,
  addUserPermissionsPremium,
  permissionInfo,
  standardPermissionValues,
} from '../../constants/addUserPermissions';
// Helpers
import type { IDataObject } from '../../interfaces/dataObject.interface';
import { UserPermissionsHelper } from '../../lib/UserPermissions';
import { StoreContext } from '../../store';

const SelectPermissions = (props: any) => {
  // Translate method
  const { t } = useTranslation('common');
  const base = 'Select-Permissions';
  const { state } = useContext(StoreContext);
  const isRFQEnabled = UserPermissionsHelper.isRFQEnabled();
  const permissionsToUse = [
    AccountType.PREMIUM,
    AccountType.PREMIUM_PLUS,
    AccountType.PREMIUM_SUPPORT,
    AccountType.ACTIVATE,
    AccountType.ENTERPRISE,
  ].includes(state.companyType)
    ? addUserPermissionsPremium(
      isRFQEnabled,
      state.isHapoEnabled,
      state.isUtoEnabled,
      UserPermissionsHelper.isCollabSpaceEnabled()
    )
    : addUserPermissions(
      isRFQEnabled,
      state.isHapoEnabled,
      state.isUtoEnabled,
      UserPermissionsHelper.isCollabSpaceEnabled()
    );

  const [ selectedUserType, selectUserType ] = React.useState(
    permissionsToUse[0].type
  );
  const [ permissionData, setPermissionData ] = React.useState<any>(
    permissionsToUse[0].permissionItems
  );
  const [ anchorEl, setAnchorEl ] = React.useState(null);
  const [ placeholder, setPlaceholder ] = React.useState(props.placeholderText);

  const userTypes = {
    [UserRole.CP_USER]: {
      textKey: 'user_profile_company_and_permissions_regular_user_label',
      fallbackText: 'Regular User',
    },
    [UserRole.CP_ADMIN]: {
      textKey: 'user_profile_company_and_permissions_admin_role_label',
      fallbackText: 'Admin',
    },
  };

  // if there is a pre-selected user type, fill out the matrix
  useEffect(() => {
    // We assume that if there is a selected user type, there is permissions if they are are a regular user.
    if (props.selectedUserType) {
      selectUserType(props.selectedUserType);
      const selectedPlaceholder =
        props.selectedUserType === UserRole.CP_USER ? userTypes[UserRole.CP_USER] : userTypes[UserRole.CP_ADMIN];
      setPlaceholder(
        t(selectedPlaceholder.textKey, selectedPlaceholder.fallbackText)
      );
      if (!props.selected) {
        setFormItemsForUser();
      } else if (
        props.selectedUserType !== UserRole.CP_ADMIN &&
        props.selected.length &&
        props.selected.length > 0
      ) {
        setClickedFormItems();
      }
    }
  }, [ props.selectedUserType, props.selected ]);

  useEffect(() => {
    setFormItemsForUser();
  }, [ selectedUserType ]);

  const setFormItemsForUser = () => {
    for (let i = 0; i < permissionsToUse.length; i++) {
      if (selectedUserType === permissionsToUse[i].type) {
        setPermissionData(permissionsToUse[i]);
      }
    }
  };

  const setClickedFormItems = () => {
    const newPermissionItems = [ ...permissionData.permissionItems ];

    const newResults = newPermissionItems.map(
      (scope: IDataObject, i: number) => {
        let scopePermsCopy = [ ...scope.permissions ];

        const matchedPerms = props.selected.filter((element: any, j: number) => {
          const splitPerm = element.split(':');
          return scope.id === splitPerm[0];
        });
        const readScopePerm = { ...scopePermsCopy[0] };
        const writeScopePerm = { ...scopePermsCopy[1] };

        if (matchedPerms && matchedPerms.length > 0) {
          if (
            matchedPerms.includes(`${scope.id}:read`) &&
            matchedPerms.includes(`${scope.id}:write`)
          ) {
            readScopePerm.value = true;
            writeScopePerm.value = true;
          } else if (
            matchedPerms.includes(`${scope.id}:read`) &&
            !matchedPerms.includes(`${scope.id}:write`)
          ) {
            readScopePerm.value = true;
            writeScopePerm.value = false;
          } else if (
            matchedPerms.includes(`${scope.id}:write`) &&
            !matchedPerms.includes(`${scope.id}:read`)
          ) {
            readScopePerm.value = false;
            writeScopePerm.value = true;
          }
          scopePermsCopy[0] = readScopePerm;
          scopePermsCopy[1] = writeScopePerm;
        } else {
          readScopePerm.value = false;
          writeScopePerm.value = false;
          scopePermsCopy = [ readScopePerm, writeScopePerm ];
        }

        return {
          ...scope,
          permissions: scopePermsCopy,
        };
      }
    );

    const newPermState = {
      ...permissionData,
      permissionItems: newResults,
    };
    setPermissionData(newPermState);
  };

  const handleClick = (event: any) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    props.onPermissionsChange(
      selectedUserType,
      permissionData.permissionItems,
      props.userId
    );
    setPlaceholder(
      t(permissionData.label.keyText, permissionData.label.fallbackText)
    );
  };

  const handleUserTypeChange = (e: any) => {
    selectUserType(e.target.value); // CP_ADMIN|CP_USER
    const foundPermission = Object.values(userTypes).find(
      (perm) => perm.fallbackText === e.target.name
    );
    setPlaceholder(
      t(foundPermission!.textKey, foundPermission!.fallbackText)
    ); // Admin|Regular User
  };

  const handleChange = (e: any, manualSelection?: any) => {
    const permScopeIndex = parseInt(e.target.dataset.scope);
    const checkIndex = e.target.dataset.checkPosition;

    const newPermissionItems = [ ...permissionData.permissionItems ];

    const newResults = newPermissionItems.map(
      (scope: IDataObject, i: number) => {
        if (i === permScopeIndex) {
          const scopePermsCopy = [ ...scope.permissions ];
          scopePermsCopy[checkIndex] = {
            ...scopePermsCopy[checkIndex],
            value: !scopePermsCopy[checkIndex].value,
          };
          return {
            ...scope,
            permissions: scopePermsCopy,
          };
        }
        return scope;

      }
    );

    const newPermState = {
      ...permissionData,
      permissionItems: newResults,
    };
    setPermissionData(newPermState);
  };

  const generatePermissionsMatrix = () => {
    let permissionLineItem;
    if (permissionData?.permissionItems) {
      permissionLineItem = permissionData.permissionItems.map(
        (scope: IDataObject, i: number) => {
          const [ viewPermissionInfo, editPermissionInfo ] = permissionInfo(
            scope.id,
            isRFQEnabled,
          );
          return (
            <div
              className={`${base}__Matrix-Item`}
              key={i}
              id={scope.id}>
              <div className={`${base}__Column--permissions`}>
                <Tooltip
                  placement="bottom-start"
                  title={
                    <styles.PermissionsToolTipTitleContainer>
                      <p>
                        {t(
                          viewPermissionInfo.keyText,
                          viewPermissionInfo.fallbackText
                        )}
                      </p>
                      <p>
                        {t(
                          editPermissionInfo.keyText,
                          editPermissionInfo.fallbackText
                        )}
                      </p>
                    </styles.PermissionsToolTipTitleContainer>
                  }
                >
                  <div className={`${base}__Column--permissions-Title`}>
                    <p>{t(scope.label.keyText, scope.label.fallbackText)}</p>
                    <img
                      src={InfoIcon}
                      alt="React Logo" />
                  </div>
                </Tooltip>
              </div>
              <div className={`${base}__Matrix-Checkboxes`}>
                {scope.permissions?.length &&
                  scope.permissions.map(
                    (scopePermission: IDataObject, n: number) => (
                      <div
                        className={`${base}__Column--${scopePermission.name}`}
                        key={n}
                      >
                        <Checkbox
                          checked={scopePermission.value}
                          onChange={handleChange}
                          inputProps={
                            {
                              'aria-label': 'primary checkbox',
                              'data-scope': i,
                              'data-check-position': n,
                            } as any
                          }
                          value={scopePermission.value}
                        />
                      </div>
                    )
                  )}
              </div>
            </div>
          );
        }
      );
    }

    return (
      <div>
        <div className={`${base}__Radio-Container`}>
          <RadioGroup
            aria-label="user type"
            value={selectedUserType}
            onChange={handleUserTypeChange}
            data-testid="SelectPermissions__options"
          >
            {permissionsToUse.map((userType: IDataObject, n: number) => (
              <FormControlLabel
                value={userType.type}
                control={<Radio />}
                label={t(userType.label.keyText, userType.label.fallbackText)}
                key={n}
                name={userType.label.fallbackText}
                data-testid="SelectPermissions__option"
              />
            ))}
          </RadioGroup>
        </div>
        {selectedUserType !== UserRole.CP_ADMIN && (
          <div
            className={`${base}__Permission-Items-Container`}
            data-testid="SelectPermissions__matrix"
          >
            <div className={`${base}__Column-Titles-Container`}>
              <div
                className={`${base}__Column-Title ${base}__Column--permissions`}
              >
                <p>{t('user_permissions_permissions_title', 'Permissions')}</p>
              </div>
              {standardPermissionValues.map((title, i) => (
                <div
                  className={`${base}__Column-Title ${base}__Column--${title.name}`}
                  key={i}
                >
                  <p>{t(title.label.keyText, title.label.fallbackText)}</p>
                </div>
              ))}
            </div>
            {permissionLineItem}
          </div>
        )}
      </div>
    );
  };

  const generatePlaceholder = () => {
    const selectValue =
      placeholder !== 'Permissions' ? (
        <MenuItem
          className="Select__Trigger-Placeholder-Title Selected"
          data-testid="SelectPermissions__selectedValue"
        >
          {placeholder}
        </MenuItem>
      ) : (
        <p className="Select__Trigger-Placeholder-Title">{placeholder}</p>
      );
    return <>{selectValue}</>;
  };

  return (
    <styles.SelectContainer
      className={`Select ${base}`}
      data-testid={props.testid}
    >
      <styles.SelectTrigger
        aria-owns={anchorEl ? `${base}__Menu` : undefined}
        aria-haspopup="true"
        className="Select__Trigger"
        onClick={handleClick}
        data-testid="SelectPermissions__trigger"
      >
        <div className="Select__Trigger-Text">{generatePlaceholder()}</div>
        <div className="Select__Trigger-Arrow" />
      </styles.SelectTrigger>
      {props.helperText && (
        <div className="Select__Helper-Text">{props.helperText}</div>
      )}

      <styles.SelectOuter
        id={`${base}__Menu`}
        className={`${base}__Outer ${props.className}`}
        open={Boolean(anchorEl)}
        elevation={0}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: 'center',
        }}
        onClose={handleClose}
        disableRestoreFocus
        data-testid="SelectPermissions__outer"
      >
        <div className={`${base}__Inner`}>{generatePermissionsMatrix()}</div>
      </styles.SelectOuter>
    </styles.SelectContainer>
  );
};

export default SelectPermissions;
