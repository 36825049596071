// Component for admins to manage categories and subcategories in knowledge base
import { KBCategoryType } from '@customer-portal/constants';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
// Components
import type {
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';

import * as styled from '../../assets/css/CustomerPortalContentWrapper';
// Styles
import {
  TableHeader,
  Wrapper,
} from '../../assets/css/Knowledge/CategoryManager-Table';
// Images
import AddIcon from '../../assets/img/svg/action_icons/Plus.svg';
import AddSubcategoryModal from '../../components/admin/knowledge/Admin-AddSubcategoryModal';
import CategoryRow from '../../components/admin/knowledge/Admin-CategoryRow';
import DeleteModal from '../../components/admin/knowledge/Admin-DeleteModal';
import RenameModal from '../../components/admin/knowledge/Admin-RenameModal';
import BasicHero from '../../components/BasicHero/CustomerPortal-BasicHero';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
import Container from '../../components/CustomerPortal-New-Container';
import { KBVisibilityControls } from '../../constants/account.constants';
import { Locale } from '../../constants/localization.constants';
// Constants
import { KB_CATEGORIES_URL } from '../../constants/network.constants';
import { KNOWLEDGE_CATEGORY_MANAGEMENT } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import type { IDataObject } from '../../interfaces/dataObject.interface';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
// Utils
import { StoreContext } from '../../store/index';

export interface CategoryLanguages {
  [key: string]: {
    name: string;
    active: boolean;
  };
}

const AdminKBCategories = (props: any) => {
  /* State */
  const {
    state, dispatch: dispatchContext,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const [ categoriesState, setCategoriesState ]: [any[], Function] = useState([]);
  const [ isCategoryRenameModalOpen, setIsCategoryRenameModalOpen ] = useState(
    false
  );
  const [ isCategoryDeleteModalOpen, setIsCategoryDeleteModalOpen ] = useState(
    false
  );
  const [ isAddSubcategoryModalOpen, setIsAddSubcategoryModalOpen ] = useState(
    false
  );
  const [ currentCategoryId, setCurrentCategoryId ] = useState<string>('');
  const [ currentSubcategoryId, setCurrentSubcategoryId ] = useState<string>('');
  const [ isSubCategory, setIsSubCategory ] = useState<boolean>(false);
  const [ oldCategoryName, setOldCategoryName ] = useState('');
  const [ oldSubcategoryName, setOldSubcategoryName ] = useState('');
  const [ oldLanguages, setOldLanguages ] = useState<CategoryLanguages>(
    Object.values(Locale).reduce((obj: CategoryLanguages, language: keyof typeof Locale) => {
      obj[language] = {
        name: '',
        active: true,
      };
      return obj;
    }, {})
  );
  const [ triggerRefresh, setTriggerRefresh ] = useState(false);
  const [ oldVisibility, setOldVisibility ] = useState(Object.keys(KBVisibilityControls)[0]);

  /* Events */
  // When Delete is clicked in Popover
  const handleCategoryDeleteClick = (
    categoryName: any,
    subcategoryName?: string
  ) => {
    setOldCategoryName(categoryName);

    if (subcategoryName) {
      setOldSubcategoryName(subcategoryName);
    }

    setIsCategoryDeleteModalOpen(true);
  };

  // When Rename is clicked
  const handleCategoryRenameClick = (
    languages: CategoryLanguages,
    visibility: string,
    categoryInfo: { categoryId: string; categoryName: string },
    subcategoryInfo: { subcategoryId?: string; subcategoryName?: string },
    _isSubCategory: boolean = false
  ) => {
    const {
      categoryId, categoryName,
    } = categoryInfo;
    const {
      subcategoryId, subcategoryName,
    } = subcategoryInfo;
    setOldCategoryName(categoryName);
    setCurrentCategoryId(categoryId);

    setOldLanguages(languages);
    setOldVisibility(visibility);

    if (subcategoryName) {
      setOldSubcategoryName(subcategoryName);
    }
    if (subcategoryId) {
      setCurrentSubcategoryId(subcategoryId);
    }
    setIsSubCategory(_isSubCategory);
    setIsCategoryRenameModalOpen(true);
  };

  const handleAddCategoryClick = (categoryName?: string, _isSubCategory: boolean = false) => {
    setOldCategoryName(categoryName || '');
    setIsSubCategory(_isSubCategory);
    setIsAddSubcategoryModalOpen(true);
  };

  const handleCategoryRenameModalClose = () => {
    setOldCategoryName('');
    setCurrentCategoryId('');
    setOldSubcategoryName('');
    setCurrentSubcategoryId('');
    setIsCategoryRenameModalOpen(false);
    setIsSubCategory(false);
  };

  const handleCategoryDeleteModalClose = () => {
    setOldCategoryName('');
    setOldSubcategoryName('');
    setIsCategoryDeleteModalOpen(false);
  };

  const handleAddSubcategoryModalClose = () => {
    setOldCategoryName('');
    setIsAddSubcategoryModalOpen(false);
  };

  // Function to add category or subcategory
  const handleAddCategoryFormSubmit = async (
    languages: CategoryLanguages,
    visibility: string,
    categoryName?: string,
    _isSubCategory: boolean = false
  ) => {
    const body = {
      category_type: _isSubCategory ? KBCategoryType.SUBCATEGORY : KBCategoryType.CATEGORY,
      ...(_isSubCategory && { category_parent: categoryName }),
      ...(!_isSubCategory && { sort_order: 0 }),
      languages,
      language: Locale.en,
      visibility,
    };

    try {
      const result = await axios.post(
        `${KB_CATEGORIES_URL}/${categoryName || languages.en.name}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );

      if (result.data.statusCode === 201 && result.data.data) {
        dispatchContext({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: `${result.data.data.name} has been created`,
        });

        setTriggerRefresh(!triggerRefresh);
      }
    } catch (e) {
      const errorMessage =
        e.response?.data?.data
          ? `Error adding category: ${e.response.data.data}`
          : `Error adding category: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const handleRenameFormSubmit = async (
    languages: CategoryLanguages,
    visibility: string,
    categoryInfo: { categoryId: string; categoryName: string },
    subcategoryInfo: { subcategoryId?: string; subcategoryName?: string },
    _isSubCategory: boolean = false
  ) => {
    const {
      categoryId, categoryName,
    } = categoryInfo;
    const subcategoryId = subcategoryInfo?.subcategoryId;
    const subcategoryName = subcategoryInfo?.subcategoryName;

    if (!categoryName?.length) {
      return;
    }

    const url = _isSubCategory
      ? `${KB_CATEGORIES_URL}/${categoryName}/subcategories/${subcategoryName}`
      : `${KB_CATEGORIES_URL}/${categoryName}`;

    const body = {
      category_id: categoryId,
      subcategory_id: subcategoryId,
      languages,
      visibility,
      operations: [
        {
          op: 'edit',
          path: 'name',
          value: languages,
        },
      ],
    };

    try {
      const result = await axios.patch(
        url,
        body,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );

      // Successful Update
      if (result.data.statusCode === 201 && result.data.data) {
        dispatchContext({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: `${result.data.data.name} has been updated`,
        });

        setTriggerRefresh(!triggerRefresh);
      }
    } catch (e) {
      const errorMessage =
        e.response?.data?.data
          ? `Error renaming: ${e.response.data.data}`
          : `Error renaming: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  const handleDeleteFormSubmit = async (
    categoryName: string,
    subcategoryName?: string
  ) => {
    if (!categoryName) {
      return;
    }

    const url = subcategoryName
      ? `${KB_CATEGORIES_URL}/${categoryName}/subcategories/${subcategoryName}`
      : `${KB_CATEGORIES_URL}/${categoryName}`;

    try {
      const deleteRes = await axios.delete(url, {
        ...{
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        },
        data: { language: Locale.en },
      });

      if (deleteRes.data.data && deleteRes.data.statusCode === 204) {
        dispatchContext({
          type: 'setBannerType',
          payload: 'success',
        });
        dispatchContext({
          type: 'setBannerMsg',
          payload: `${deleteRes.data.data}`,
        });

        setTriggerRefresh(!triggerRefresh);
      }
    } catch (e) {
      const errorMessage =
        e.response?.data?.message
          ? `Error deleting: ${e.response.data.message}`
          : `Error deleting: ${e.toString()}`;
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  // Fetch all the categories
  const fetchCategoriesPromise = async () => {
    try {
      const categoriesFetchResults = await axios.get(
        `${KB_CATEGORIES_URL}/all`,
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );

      if (categoriesFetchResults.data?.data) {
        setCategoriesState(categoriesFetchResults.data.data);
        return;
      }

      throw new Error('Something went wrong parsing returned data');
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    const {
      destination, source, type,
    } = result;

    if (!destination) {
      return;
    }

    // Back to where it started
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const tempCategoriesState = Array.from(categoriesState);

    // Check type
    if (type.startsWith('SUBCATEGORY')) {
      const categoryName = type.split('||');
      if (categoryName.length < 2) {
        return;
      }

      for (let i = 0; i < tempCategoriesState.length; i++) {
        if (tempCategoriesState[i].category_name === categoryName[1]) {
          const subcategories: IDataObject[] = Array.from(
            tempCategoriesState[i].subcategories
          );
          const deletedSubcategory = subcategories.splice(source.index, 1);
          subcategories.splice(destination.index, 0, deletedSubcategory[0]);
          tempCategoriesState[i].subcategories = subcategories;

          handleOrderChange(
            tempCategoriesState[i]._id,
            subcategories,
            tempCategoriesState
          );
          break;
        }
      }
    } else {
      const deletedCategory = tempCategoriesState.splice(source.index, 1);
      tempCategoriesState.splice(destination.index, 0, deletedCategory[0]);
      handleOrderChange(deletedCategory[0]._id, undefined, tempCategoriesState);
    }
  };

  // When order of category or subcategory is changed
  // categoryArr : changed array
  const handleOrderChange = async (
    categoryId: string,
    subcategoriesArr: any[] | undefined,
    newProposedState: any
  ) => {
    const config: any = {
      method: 'put',
      url: `${KB_CATEGORIES_URL}`,
      data: {
        category_id: categoryId,
        update_categories: subcategoriesArr
          ? subcategoriesArr
          : newProposedState,
        type: subcategoriesArr
          ? KBCategoryType.SUBCATEGORY
          : KBCategoryType.CATEGORY,
      },
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'x-selected-account': state.companyId,
      },
    };

    const oldState = Array.from(categoriesState);
    setCategoriesState(newProposedState);
    try {
      await axios(config);
    } catch (e) {
      // Reset the state
      setCategoriesState(oldState);
      let errorMessage = e.toString();
      if (e.response?.data) {
        errorMessage = e.response.data.message || e.response.data.toString();
      }
      dispatchContext({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatchContext({
        type: 'setBannerMsg',
        payload: errorMessage,
      });
    }
  };

  useEffect(() => {
    fetchCategoriesPromise();
  }, [ triggerRefresh ]);

  useTrackPageViewEvent(KNOWLEDGE_CATEGORY_MANAGEMENT);
  useEffect(() => {
    CustomerPortalGoogleAnalyticsPageView('Knowledge Category');
  }, []);

  return (
    <Wrapper>
      <BasicHero
        title="Knowledge Base"
        subtitle="Browse and manage the categories and subcategories below"
      />

      <Container>
        <styled.SectionContentWrapper>
          <div
            data-testid="KnowledgeCategories__Add-Category-Opener"
            className="KnowledgeCategories__Add-Category-Opener"
            onClick={() => {
              handleAddCategoryClick();
            }}
          >
            <img
              src={AddIcon}
              alt="Plus Icon"
              className="KnowledgeCategories__Add-Category-Icon"
            />
            <h6 className="Bold">Add Category</h6>
          </div>

          <TableHeader>
            <div>
              <p className="Small">Category Name</p>
            </div>
            <p className="Small">Last Updated By</p>
            <p className="Small">Last Updated</p>
          </TableHeader>

          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable
              droppableId="droppable-category"
              type="CATEGORY">
              {(provided: DroppableProvided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}>
                  {categoriesState.map((categoryObj: any, index: number) => (
                    <CategoryRow
                      categoryId={categoryObj._id}
                      key={categoryObj.category_name}
                      name={categoryObj.category_name}
                      index={index}
                      last_updated_by={categoryObj.last_updated_by}
                      last_updated_on={categoryObj.last_updated_on}
                      subcategories={categoryObj.subcategories}
                      languages={categoryObj.languages}
                      visibility={categoryObj.visibility || Object.keys(KBVisibilityControls)[0]}
                      handleRenameClick={handleCategoryRenameClick}
                      handleDeleteClick={handleCategoryDeleteClick}
                      handleAddSubcategoryClick={handleAddCategoryClick}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </styled.SectionContentWrapper>
      </Container>

      {isAddSubcategoryModalOpen && (
        <AddSubcategoryModal
          oldCategoryName={oldCategoryName}
          isAddSubcategoryModalOpen={isAddSubcategoryModalOpen}
          handleFormSubmit={handleAddCategoryFormSubmit}
          handleClose={handleAddSubcategoryModalClose}
          isSubCategory={isSubCategory}
        />
      )}

      {isCategoryRenameModalOpen && (
        <RenameModal
          categoryId={currentCategoryId}
          subcategoryId={currentSubcategoryId}
          oldCategoryName={oldCategoryName}
          oldSubcategoryName={oldSubcategoryName}
          oldLanguages={oldLanguages}
          oldVisibility={oldVisibility}
          isCategoryRenameModalOpen={isCategoryRenameModalOpen}
          handleFormSubmit={handleRenameFormSubmit}
          handleClose={handleCategoryRenameModalClose}
          isSubCategory={isSubCategory}
        />
      )}

      {isCategoryDeleteModalOpen && (
        <DeleteModal
          oldCategoryName={oldCategoryName}
          oldSubcategoryName={oldSubcategoryName}
          isCategoryDeleteModalOpen={isCategoryDeleteModalOpen}
          handleFormSubmit={handleDeleteFormSubmit}
          handleClose={handleCategoryDeleteModalClose}
        />
      )}
    </Wrapper>
  );
};

export default AdminKBCategories;
