import { getStringEnv } from '../lib/index.util';
import { Locale } from './localization.constants';

// https://developer.mozilla.org/en-US/docs/Web/Security/Subresource_Integrity
interface ScriptData {
  url: string;
  integrityHashPrefix: 'sha256' | 'sha384' | 'sha512';
  integrityHashString: string;
  elementId: string;
}

// https://github.com/Cognigy/WebchatWidget/releases/download/v2.40.6/webchat.legacy.js
export const BASE_SCRIPT: ScriptData = {
  url: `${getStringEnv(process.env.PUBLIC_URL)}/js/webchat.legacy.js`,
  integrityHashPrefix: 'sha256',
  integrityHashString: 'FNoB9aTeLCeVVYtd7TvvbMJ8Ano8CChpQVgDx9rzSY8=',
  elementId: '#webchat',
};

export const WEBCHAT_ROOT_QUERY_SELECTOR = '[data-cognigy-webchat-root].webchat-root';

export enum AzureTranslatorLocale {
  en = 'en',
  ja = 'ja',
  de = 'de',
  es = 'es',
  fr = 'fr',
  ko = 'ko',
  'pt-pt' = 'pt-pt',
  pt = 'pt',
  ru = 'ru',
  tr = 'tr',
  'zh-Hans' = 'zh-Hans',
}

export const LocaleToAzureTranslatorLocale: Map<Locale, AzureTranslatorLocale> = new Map([
  [ Locale.en, AzureTranslatorLocale.en ],
  [ Locale.ja, AzureTranslatorLocale.ja ],
  [ Locale.de, AzureTranslatorLocale.de ],
  [ Locale.es, AzureTranslatorLocale.es ],
  [ Locale['es-MX'], AzureTranslatorLocale.es ],
  [ Locale.fr, AzureTranslatorLocale.fr ],
  [ Locale.ko, AzureTranslatorLocale.ko ],
  [ Locale.pt, AzureTranslatorLocale['pt-pt'] ],
  [ Locale['pt-BR'], AzureTranslatorLocale.pt ],
  [ Locale.ru, AzureTranslatorLocale.ru ],
  [ Locale.tr, AzureTranslatorLocale.tr ],
  [ Locale['zh-CN'], AzureTranslatorLocale['zh-Hans'] ],
]);
