import { UserRole } from '@customer-portal/constants';
import React, {
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// Styles
import AddIcon from '../../../assets/img/svg/action_icons/Plus.svg';
import { axiosPost } from '../../../client/axios';
import {
  BACKEND_HOST_NAME,
  VIEW_AVATAR_URL,
} from '../../../constants/network.constants';
// Constants
import { COMPANY_TEAM_USERS } from '../../../constants/telemetry.constants';
import {
  ACTIVATE_USER_LABEL,
  ACTIVE,
  DELETE_INVITE_LABEL,
  DISABLE_USER_LABEL,
  DISABLED,
  EDIT_PERMISSIONS_LABEL,
  EDIT_PROFILE_LABEL,
  PENDING,
  RESEND_INVITE_LABEL,
} from '../../../constants/user.constants';
import { useAuth } from '../../../contexts/auth';
// Interfaces
import type { IDataObject } from '../../../interfaces/dataObject.interface';
import { useTrackPageViewEvent } from '../../../lib/AppInsights/AppInsights';
// Helpers
import { UserPermissionsHelper } from '../../../lib/UserPermissions';
// Contexts
import { StoreContext } from '../../../store';
import Loader from '../../CustomerPortal-Loader';
import Modal from '../../Modal';
import UserCard from '../../user_card/User-Card';
import AddUserForm from '../Company-Add-User-Form';
import EditUserForm from '../Company-Edit-UserPermissions-Form';
// Components
import CompanyBodyUnauthorized from './Company-Body-Unauthorized';

interface IEditingUserState {
  email: string;
  role: string;
  permissions: string[];
}

type CompanyBodyTeamUsersProps = {
  loading: boolean;
  showAddUser: boolean;
};

const CompanyBodyTeamUsers = ({
  loading = true,
  showAddUser,
}: CompanyBodyTeamUsersProps) => {
  // Translate method
  const { t } = useTranslation('common');
  // Global State
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const history = useHistory();
  const { getAccessToken } = useAuth();
  // Local State
  const [ addUserOpen, setAddUserOpen ] = useState(false);
  const [ editPermsOpen, setEditPermsOpen ] = useState(false);
  const [ editingUser, setEditUser ] = useState<IEditingUserState>({
    email: '',
    role: '',
    permissions: [],
  });
  const teamUsers = state.teamUsers ?? [];
  const baseClass = 'MyCompanyBody';
  let menuOptions: any;
  let labelType: string;
  let labelText: string;
  // Permissions
  const isCompanyAdmin = UserPermissionsHelper.isCompanyAdmin();
  const isAdminOnAccountDetails = UserPermissionsHelper.isAdminOnAccountDetails();
  const isEditTeamUsersAllowed = UserPermissionsHelper.isEditTeamUsersAllowed();
  const isUiPathAdminForSelectedCompany = UserPermissionsHelper.isUiPathAdminForSelectedCompany();
  const isEditProfileAllowed = UserPermissionsHelper.isEditProfileAllowed();
  const isViewTeamUsersAllowed = UserPermissionsHelper.isViewTeamUsersAllowed();

  // Lifecycle
  useTrackPageViewEvent(COMPANY_TEAM_USERS);

  // Methods
  const setProfilePhoto = (profilePhoto: string) => {
    if (profilePhoto && profilePhoto !== '') {
      return `${VIEW_AVATAR_URL}/${profilePhoto}`;
    }
    return '';

  };

  const handleAddUserClose = () => {
    setAddUserOpen(false);
  };

  const handleEditUserOpen = (user: any) => {
    setEditPermsOpen(true);
    setEditUser(user);
  };

  const handleEditUserClose = () => {
    setEditPermsOpen(false);
  };

  const handleShowInviteResultsModalClose = () => {
    dispatch({
      type: 'setShowInviteResultsModal',
      payload: false,
    });

    dispatch({
      type: 'setShowInviteResultsMessage',
      payload: '',
    });
  };

  const handleUpdateUserStatus = async (
    email: string,
    name: string,
    status: string
  ) => {
    const body: any = {
      status,
      email: email?.toLowerCase(),
      companyId: state.companyId,
      companyName: state.companyName,
      name,
    };

    let updateUserStatusResult: any;

    try {
      updateUserStatusResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/updateUserStatus`,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (err) {
      console.log(err.message);
    }

    if (
      updateUserStatusResult &&
      updateUserStatusResult.data.statusCode === 200
    ) {
      const newTeamUsers: any = Array.from(teamUsers).map((element: any) => {
        if (element.Email?.toLowerCase() === email?.toLowerCase()) {
          element.status = status;
        }
        return element;
      });

      dispatch({
        type: 'setTeamUsers',
        payload: newTeamUsers,
      });

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_user_status_update_success_msg',
          'User status has been updated!'
        ),
      });
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_user_status_update_error_msg',
          'Something went wrong, and the user status was not updated! Please try again later!'
        ),
      });
    }
  };

  const handleResendInvite = async (email: string) => {
    const body: {
      email: string;
    } = { email: email?.toLowerCase() };

    let inviteResult: any;

    try {
      inviteResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/resendUserInvite`,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_invite_user_error_msg',
          'Something went wrong, and the user invite was not sent!'
        ),
      });
    }

    if (inviteResult && inviteResult.status === 200) {
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_invite_user_success_msg',
          'User invite has been sent!'
        ),
      });
    }
  };

  const handleDeleteUserInvite = async (email: string) => {
    const body: {
      email: string;
    } = { email: email?.toLowerCase() };

    let deleteInviteResult: any;

    try {
      deleteInviteResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/user/deleteUserInvite`,
        state.companyId,
        await getAccessToken(),
        body
      );
    } catch (e) {
      console.log(e.message);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_delete_invite_error_msg',
          'Something went wrong, and the invitation was not deleted!'
        ),
      });
    }

    if (
      deleteInviteResult?.data?.statusCode &&
      deleteInviteResult.data.statusCode === 200
    ) {
      dispatch({
        type: 'setTeamUsers',
        payload: teamUsers.filter((el: any) =>
          el.Email !== email ? true : false
        ),
      });

      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_delete_invite_success_msg',
          'User invite has been deleted!'
        ),
      });
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_team_users_delete_invite_error_msg',
          'Something went wrong, and the invitation was not deleted!'
        ),
      });
    }
  };

  const handleRedirectToProfilePage = () => {
    history.push('/profile');
  };

  const generateUsers = () => {
    if (teamUsers.length > 0) {
      return teamUsers.map((contact: IDataObject, i: number) => {
        const isCPAdmin: boolean = contact.cpRole === UserRole.CP_ADMIN;

        switch (contact.status) {
          case ACTIVE:
            labelText = ACTIVE;
            labelType = 'active';
            menuOptions = null;

            if (isUiPathAdminForSelectedCompany && isEditTeamUsersAllowed) {
              menuOptions = [
                {
                  label: t(
                    DISABLE_USER_LABEL.keyText,
                    DISABLE_USER_LABEL.fallbackText
                  ),
                  onClick: () =>
                    handleUpdateUserStatus(
                      contact.Email,
                      contact.Name,
                      DISABLED
                    ),
                },
              ];
            }

            if (isCompanyAdmin && isEditTeamUsersAllowed) {
              // Check if the contact is the logged in account admin
              if (contact.Email === state.userEmail && isEditProfileAllowed) {
                menuOptions = [
                  {
                    label: t(
                      EDIT_PROFILE_LABEL.keyText,
                      EDIT_PROFILE_LABEL.fallbackText
                    ),
                    onClick: handleRedirectToProfilePage,
                  },
                ];
              } else {
                menuOptions = [
                  {
                    label: t(
                      EDIT_PERMISSIONS_LABEL.keyText,
                      EDIT_PERMISSIONS_LABEL.fallbackText
                    ),
                    onClick: () => handleEditUserOpen(contact),
                  },
                  {
                    label: t(
                      DISABLE_USER_LABEL.keyText,
                      DISABLE_USER_LABEL.fallbackText
                    ),
                    onClick: () =>
                      handleUpdateUserStatus(
                        contact.Email,
                        contact.Name,
                        DISABLED
                      ),
                  },
                ];
              }
            }
            break;
          case PENDING:
            labelText = PENDING;
            labelType = 'pending';
            menuOptions = null;

            if (
              (isUiPathAdminForSelectedCompany || isCompanyAdmin) &&
              isEditTeamUsersAllowed
            ) {
              menuOptions = [
                {
                  label: t(
                    RESEND_INVITE_LABEL.keyText,
                    RESEND_INVITE_LABEL.fallbackText
                  ),
                  onClick: () => handleResendInvite(contact.Email),
                },
                {
                  label: t(
                    DELETE_INVITE_LABEL.keyText,
                    DELETE_INVITE_LABEL.fallbackText
                  ),
                  onClick: () => handleDeleteUserInvite(contact.Email),
                },
              ];
            }
            break;
          case DISABLED:
            labelText = contact.status;
            labelType = contact.status;
            menuOptions = null;

            if (
              (isUiPathAdminForSelectedCompany || isCompanyAdmin) &&
              isEditTeamUsersAllowed
            ) {
              menuOptions = [
                {
                  label: t(
                    ACTIVATE_USER_LABEL.keyText,
                    ACTIVATE_USER_LABEL.fallbackText
                  ),
                  onClick: () =>
                    handleUpdateUserStatus(contact.Email, contact.Name, ACTIVE),
                },
              ];
            }
            break;
          default:
          // do nothing
        }

        if (contact.userAccounts?.includes(state.companyId)) {
          return (
            <UserCard
              name={contact.Name || contact.Contact.Name}
              email={contact.Email || contact.Contact.Email}
              avatarUrl={setProfilePhoto(contact.photo)}
              labelType={labelType}
              labelText={labelText}
              isAdmin={isCPAdmin}
              key={i}
              isMenu
              menuItems={menuOptions}
              showRole={false}
              addedBy={contact.createdBy}
              joinDate={contact.createdOn}
              isSelfRegistered={contact.selfRegistered}
              isAutoInvited={contact.autoInvited}
            />
          );
        }
      });
    }
    return (
      <p
        className={`${baseClass}__No-Licenses-Text`}
        data-testid="EmptyState"
      >
        {t(
          'company_team_users_no_results',
          `No Team users for ${state.companyName}`,
          { company_name: state.companyName }
        )}
      </p>
    );

  };

  return (
    <>
      {isViewTeamUsersAllowed ? (
        <div
          className={`${baseClass}__Users`}
          data-testid="CompanyBodyTeamUsers"
        >
          <h3
            className={`${baseClass}__Body-Heading Regular`}
            data-testid="Heading"
          >
            {t(
              'company_team_users_title_text',
              `${state.companyName} Team Users`,
              { company_name: state.companyName }
            )}
          </h3>
          <div
            className={`${baseClass}__Heading-Actions-Text`}
            data-testid="SubHeading"
          >
            <h5 className={`${baseClass}__Body-Subheading Regular`}>
              {t(
                'company_team_users_description',
                'All your company’s team members are listed below. Please contact your team admin for any questions.'
              )}
            </h5>
          </div>
          <div className={`${baseClass}__List`}>

            {(isCompanyAdmin || isAdminOnAccountDetails) &&
              isEditTeamUsersAllowed &&
              showAddUser && (
              <div
                className={`${baseClass}__Add-User`}
                data-testid="AddUser">
                <div
                  className={`${baseClass}__Add-User-Opener`}
                  onClick={() => {
                    setAddUserOpen(true);
                  }}
                  data-testid="OpenAddUserButton"
                >
                  <img
                    src={AddIcon}
                    alt="Plus Icon"
                    className={`${baseClass}__Add-User-Icon`}
                  />
                  <h6 className="Bold">
                    {t('company_team_users_add_new_user_btn', 'Add new user')}
                  </h6>
                </div>
                <Modal
                  modalTitle="Standard Modal"
                  modalDescription="Add User Modal"
                  modalHeading={t(
                    'company_team_users_add_user_modal_title',
                    'Invite new users to your team'
                  )}
                  innerClasses={`${baseClass}__Add-User-Modal`}
                  handleClose={handleAddUserClose}
                  open={addUserOpen}
                  testId="AddUserModal"
                >
                  <div className="Modal-Fields-Container">
                    <AddUserForm handleModalClose={handleAddUserClose} />
                  </div>
                </Modal>
                <Modal
                  modalTitle="Standard Modal"
                  modalDescription="Invitation not sent"
                  modalHeading="Invitation not sent"
                  innerClasses={`${baseClass}__Add-User-Modal`}
                  handleClose={handleShowInviteResultsModalClose}
                  open={state.showInviteResultsModal}
                  testId="InvitationNotSentModal"
                >
                  <div className="Modal-Fields-Container">
                    <p className="">{state.showInviteResultsMessage}</p>
                  </div>
                </Modal>
              </div>
            )}
            {loading ? (
              <div
                className={`${baseClass}__Loader`}
                data-testid="CompanyBodyTeamUsers__loader"
              >
                <Loader />
              </div>
            ) : (
              generateUsers()
            )}
            <Modal
              modalTitle="Standard Modal"
              modalDescription="Edit User Modal"
              modalHeading={t(
                'company_team_edit_permissions_modal_title',
                'Edit user'
              )}
              innerClasses={`${baseClass}__Add-User-Modal`}
              handleClose={handleEditUserClose}
              open={editPermsOpen}
              testId="EditUserModal"
            >
              <div className="Modal-Fields-Container">
                <EditUserForm
                  handleModalClose={handleEditUserClose}
                  userInfo={editingUser}
                />
              </div>
            </Modal>
          </div>
        </div>
      ) : (
        <CompanyBodyUnauthorized />
      )}
    </>
  );
};

export default CompanyBodyTeamUsers;
