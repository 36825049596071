import type { UserRole } from '@customer-portal/constants';
import React, { useReducer } from 'react';
import createPersistedReducer from 'use-persisted-reducer';
import { v4 as uuidv4 } from 'uuid';

import {
  HAPO_ADD_1ST_LEVEL_LICENSE,
  HAPO_ADD_2ND_LEVEL_LICENSE,
  HAPO_CHANGE_CATEGORY,
  HAPO_CHANGE_ONPREM_SELECTION,
  HAPO_CLICK_LICENSE_IN_CART_VIEW,
  HAPO_CONTINUE_SHOPPING,
  HAPO_DISPLAY_ANNOUNCEMENT_MODAL,
  HAPO_LICENSE_NICKNAMES_LOADED,
  HAPO_REMOVE_1ST_LEVEL_LICENSE,
  HAPO_REMOVE_2ND_LEVEL_LICENSE,
  HAPO_RESET_CART,
  HAPO_SELECT_EXISTING_ORCHESTRATOR_OPTION,
  HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../constants/hapoActions.constants';
import { LANGUAGE_KEY_NAME } from '../constants/localization.constants';
import RFQ, {
  APITypeToProductType,
  AUTOMATION_SUITE,
  CLOUD,
  EXISTING_AUTOMATION_SUITE,
  EXISTING_CLOUD,
  EXISTING_ORCHESTRATOR,
  EXISTING_PROCESS_MINING,
  NEW_ORCHESTRATOR,
  NEW_PROCESS_MINING,
} from '../constants/requestForQuote.constants';
import {
  RFQ_ADD_1ST_LEVEL_LICENSE,
  RFQ_ADD_2ND_LEVEL_LICENSE,
  RFQ_CHANGE_CATEGORY,
  RFQ_CHANGE_ONPREM_SELECTION,
  RFQ_CLICK_LICENSE_IN_CART_VIEW,
  RFQ_CONTINUE_SHOPPING,
  RFQ_CONTINUE_TO_CART_SUCCESS,
  RFQ_DISPLAY_ANNOUNCEMENT_MODAL,
  RFQ_LICENSE_NICKNAMES_LOADED,
  RFQ_REMOVE_1ST_LEVEL_LICENSE,
  RFQ_REMOVE_2ND_LEVEL_LICENSE,
  RFQ_RESET_CART,
  RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION,
  RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../constants/requestForQuoteActions.constants';
// Actions
import { UIPATH_ROLES_IN_ORDER_ASC } from '../constants/roles.constants';
import { CP_PRIVATE_STATE_KEY } from '../constants/state.constants';
import { AUTHENTICATE_LOGIN } from '../constants/telemetry.constants';
import {
  DISABLED,
  SET_USER,
  UPDATE_GLOBAL_STATE,
} from '../constants/user.constants';
import useShowPublicExperience from '../hooks/useShowPublicExperience';
import type {
  IContextProps,
  storeState,
} from '../interfaces/contextStore';
import { triggerTrackEvent } from '../lib/AppInsights/AppInsights';
// Helpers
import sessionHelper from '../lib/SessionHelper';

const usePrivatePersistedReducer = createPersistedReducer(CP_PRIVATE_STATE_KEY, globalThis.localStorage);

// The state for (1) public page users or (2) before we obtain user details on regular page render
export const initialState: storeState = {
  showActivateAdminUserModal: false,
  showActivateAdminUserModalAt: undefined,
  showGetUserInfoModal: false,
  isRegistered: false,
  isBlocked: false,
  isHapoEnabled: false,
  isUtoEnabled: false,
  isLicenseManagementEnabled: false,
  companyCountry: '',
  userId: '',
  cloudUserId: '',
  userName: '',
  userEmail: '',
  rpaRole: '',
  cpRole: '',
  permissions: [],
  jobTitle: '',
  country: '',
  timezone: '',
  phoneNumber: '',
  userInitials: 'UU',
  bannerType: '',
  bannerMsg: '',
  bannerActions: [],
  bannerAutoHide: true,
  bannerIsCloseEnabled: true,
  myCompanies: [],
  sharedCompanies: [
    {
      companyId: '',
      companyName: '',
      companyType: '',
      cpRole: '',
      permissions: [],
      isCollabSpaceEnabled: true,
    },
  ],
  bookmarkedCompanies: [],
  companyId: '',
  companyName: '',
  companyType: '',
  companyLogo: '',
  teamUsers: undefined,
  uiPathContacts: undefined,
  companyLicenses: null,
  hasCloudLicenses: false,
  selectedOrchestrator: null,
  rfqCart: {
    selectedCategory: RFQ.productTypes.cloud,
    products: [],
  },
  hapoSelectedCategory: RFQ.productTypes.onPrem,
  hapoProducts: [],
  showSelectedCompanyBanner: false,
  showSecondaryInfoBanner: false,
  finishedInitialUserCheck: false,
  isUiPath: false,
  createdOn: '',
  profilePhoto: '',
  seenFeedbackModal: false,
  notificationsLoaded: false,
  showInviteResultsModal: false,
  showInviteResultsMessage: '',
  showSwitchAccountModal: false,
  showMultiAccountTutorial: false,
  lastSelectedAccount: '',
  stateUpdatedAt: undefined,
  isSoldToPartner: true, // set by default to in order to prevent showing RFQ before having this info from customers collection
  accountQuestionnaire: undefined, // this will store the account 360 questionnaire
  isCollabSpaceEnabled: true,
  isSelfRegistrationEnabled: true,
  territories: [],
  companyTerritory: '',
  uuid: undefined,
  companyArea: '',
  notificationBannerMessages: [],
  activatedThroughCaseCreation: false,
  forceShowUserInfoModal: false,
  userAnnouncements: {
    announcements: [],
    fetchedAt: undefined,
  },
  supportOnlyProvidedByCountry: undefined,
  canUserSupportAccount: false,
};

export const reducer = (state: any, action: { [key: string]: any }) => {
  let tempRFQProducts: any[] = state.rfqCart?.products?.slice() || [];
  let tempHAPOProducts: any[] = state.hapoProducts?.slice() ?? [];
  let tempProdArr: any[] = [];
  let lastSelectedOrchestrator = state.rfqCart?.lastSelectedOrchestrator;
  const tempId = uuidv4();

  if (
    typeof action.payload === 'object' &&
    !Array.isArray(action.payload) &&
    action.payload !== null
  ) {
    // eslint-disable-next-line no-var
    var {
      _id = undefined,
      sku_id = undefined,
      parent_id = undefined,
      quantity = undefined,
    } = action.payload;
  }

  switch (action.type) {
    case 'setShowActivateAdminUserModal':
      return {
        ...state,
        showActivateAdminUserModal: action.payload,
      };
    case 'setShowActivateAdminUserModalAt':
      return {
        ...state,
        showActivateAdminUserModalAt: action.payload,
      };
    case 'setShowGetUserInfoModal':
      return {
        ...state,
        showGetUserInfoModal: action.payload,
      };
    case 'setIsRegistered':
      return {
        ...state,
        isRegistered: action.payload,
      };

    case 'setIsBlocked':
      return {
        ...state,
        isBlocked: action.payload,
      };

    case 'setIsHapoEnabled':
      return {
        ...state,
        isHapoEnabled: action.payload,
      };

    case 'setIsUtoEnabled':
      return {
        ...state,
        isUtoEnabled: action.payload,
      };

    case 'setLicenseManagementEnabled':
      return {
        ...state,
        isLicenseManagementEnabled: action.payload,
      };

    case 'setSupportOnlyProvidedByCountry':
      return {
        ...state,
        supportOnlyProvidedByCountry: action.payload,
      };

    case 'setJWT':
      return {
        ...state,
        token: action.payload,
      };

    case 'setUserId':
      return {
        ...state,
        userId: action.payload,
      };

    case 'setUserName':
      return {
        ...state,
        userName: action.payload,
      };

    case 'setUserEmail':
      return {
        ...state,
        userEmail: action.payload,
      };

    case 'setRPARole':
      return {
        ...state,
        rpaRole: action.payload,
      };

    case 'setCPRole':
      return {
        ...state,
        cpRole: action.payload,
      };

    case 'setPermissions':
      return {
        ...state,
        permissions: action.payload,
      };

    case 'setJobTitle':
      return {
        ...state,
        jobTitle: action.payload,
      };

    case 'setCountry':
      return {
        ...state,
        country: action.payload,
      };

    case 'setTimezone':
      return {
        ...state,
        timezone: action.payload,
      };

    case 'setPhoneNumber':
      return {
        ...state,
        phoneNumber: action.payload,
      };

    case 'setUserInitials':
      return {
        ...state,
        userInitials: action.payload,
      };
    case 'setCompany':
      return {
        ...state,
        companyId: action.payload.companyId,
        companyName: action.payload.companyName,
        isHapoEnabled: action.payload.isHapoEnabled,
        isUtoEnabled: action.payload.isUtoEnabled,
        isLicenseManagementEnabled:
          action.payload.isHapoEnabled || action.payload.isUtoEnabled,
        lastSelectedAccount: action.payload.companyId,
      };
    case 'setCompanyId':
      return {
        ...state,
        companyId: action.payload,
      };

    case 'setCompanyName':
      return {
        ...state,
        companyName: action.payload,
      };

    case 'setCompanyLogo':
      return {
        ...state,
        companyLogo: action.payload,
      };

    case 'setCompanyType':
      return {
        ...state,
        companyType: action.payload,
      };

    case 'setCompanyCountry':
      return {
        ...state,
        companyCountry: action.payload,
      };

    case 'setCompanyLicenses':
      return {
        ...state,
        companyLicenses: action.payload,
      };
    case 'resetCompany':
      return {
        ...state,
        companyId: '',
        companyName: '',
        companyLogo: '',
        companyType: '',
        companyCountry: '',
        companyLicenses: null,
        isHapoEnabled: false,
        isUtoEnabled: false,
        isLicenseManagementEnabled: false,
        lastSelectedAccount: '',
        companyTerritory: '',
        companyArea: undefined,
        teamUsers: undefined,
        uiPathContacts: undefined,
      };
    // This action happens on login and page refresh
    case 'setHasCloudLicenses':
      return {
        ...state,
        hasCloudLicenses: action.payload,
        rfqCart: {
          ...state.rfqCart,
          products: state.rfqCart?.products || [],
          selectedCategory: action.payload
            ? RFQ.productTypes.cloud
            : RFQ.productTypes.onPrem,
        },
        hapoSelectedCategory: RFQ.productTypes.onPrem,
        hapoProducts: state.hapoProducts ?? [],
      };

    case 'setTeamUsers':
      return {
        ...state,
        teamUsers: action.payload,
      };

    case 'setUiPathContacts':
      return {
        ...state,
        uiPathContacts: action.payload,
      };

    case 'showSelectedCompanyBanner':
      return {
        ...state,
        showSelectedCompanyBanner: action.payload,
      };

    case 'showSecondaryInfoBanner':
      return {
        ...state,
        showSecondaryInfoBanner: action.payload,
      };

    case 'setBookmarkedCompanies':
      return {
        ...state,
        bookmarkedCompanies: action.payload,
      };

    case 'setMyCompanies':
      return {
        ...state,
        myCompanies: action.payload,
      };

    case 'setSharedCompanies':
      return {
        ...state,
        sharedCompanies: action.payload,
      };

    case 'setFinishedInitialUserCheck':
      return {
        ...state,
        finishedInitialUserCheck: action.payload,
      };

    case 'setIsUiPath':
      return {
        ...state,
        isUiPath: action.payload,
      };

    case 'setCreatedOn':
      return {
        ...state,
        createdOn: action.payload,
      };

    case 'setProfilePhoto':
      return {
        ...state,
        profilePhoto: action.payload,
      };

    case 'setBannerType':
      return {
        ...state,
        bannerType: action.payload,
      };

    case 'setBannerMsg':
      return {
        ...state,
        bannerMsg: action.payload,
      };

    case 'setBannerActions':
      return {
        ...state,
        bannerActions: action.payload,
      };

    case 'setBannerAutoHide':
      return {
        ...state,
        bannerAutoHide: action.payload,
      };

    case 'setBannerIsCloseEnabled':
      return {
        ...state,
        bannerIsCloseEnabled: action.payload,
      };

    case 'setSeenFeedbackModal':
      return {
        ...state,
        seenFeedbackModal: action.payload,
      };

    case 'setNotificationsLoaded':
      return {
        ...state,
        notificationsLoaded: action.payload,
      };

    case 'setShowInviteResultsModal':
      return {
        ...state,
        showInviteResultsModal: action.payload,
      };

    case 'setShowInviteResultsMessage':
      return {
        ...state,
        showInviteResultsMessage: action.payload,
      };

    case 'setShowSwitchAccountModal':
      return {
        ...state,
        showSwitchAccountModal: action.payload,
      };

    case 'setLastSelectedAccount':
      return {
        ...state,
        lastSelectedAccount: action.payload,
      };

    case 'showMultiAccountTutorial':
      return {
        ...state,
        showMultiAccountTutorial: action.payload,
      };

    case 'setIsSoldToPartner':
      return {
        ...state,
        isSoldToPartner: action.payload,
      };

    case 'setAccountQuestionnaire':
      return {
        ...state,
        accountQuestionnaire: action.payload,
      };

    case RFQ_RESET_CART:
      return {
        ...state,
        rfqCart: initialState.rfqCart,
      };

    case RFQ_CHANGE_CATEGORY:
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          selectedCategory: action.payload,
          lastSelectedOrchestrator: undefined,
        },
      };

    case RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION: {
      const existingLicenseState = state.rfqCart.products?.find(
        (sku: any) =>
          sku.existing_license_code === action.payload.existing_license_code
      );

      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          lastSelectedOrchestrator: {
            type: action.payload.type,
            _id: existingLicenseState?._id,
            existing_license_code: action.payload.existing_license_code,
            license_name:
              action.payload.existing_license_name || action.payload.sku_name,
            display_name: action.payload.existing_license_displayName,
          },
        },
      };
    }
    // Since only one New Orchestrator is allowed at a time...
    case RFQ_CHANGE_ONPREM_SELECTION: {
      lastSelectedOrchestrator = undefined;
      if (action.payload === NEW_ORCHESTRATOR) {
        const foundNewOrchestrator = state.rfqCart.products?.find(
          (stateSku: any) => stateSku.type === action.payload
        );
        lastSelectedOrchestrator = foundNewOrchestrator
          ? {
            type: foundNewOrchestrator.type as any,
            _id: foundNewOrchestrator._id,
            license_name: foundNewOrchestrator.sku_name,
            display_name: foundNewOrchestrator.display_name,
          }
          : undefined;
      }

      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          lastSelectedOrchestrator,
        },
      };
    }
    // Show announcement popup
    case RFQ_DISPLAY_ANNOUNCEMENT_MODAL: {
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          announcementModalInfo: {
            message: action.payload.message,
            action: action.payload.action,
            sku_id: action.payload.sku_id,
            sku_name: action.payload.sku_name,
            min_quantity: action.payload.min_quantity,
            product_code: action.payload.product_code,
          },
        },
      };
    }

    // Updates products array and lastSelectedOrchestrator
    case RFQ_ADD_1ST_LEVEL_LICENSE: {
      // Set the last selected option
      if (/New Orchestrator|Existing Orchestrator/.test(action.payload.type)) {
        lastSelectedOrchestrator = {
          type: action.payload.type,
          // Not setting the _id for existing orchestrators since they're not added to cart immediately
          _id: action.payload.type === 'New Orchestrator' ? tempId : undefined,
          existing_license_code: action.payload.existing_license_code,
          license_name:
            action.payload.existing_license_name || action.payload.sku_name,
          display_name: action.payload.existing_license_displayName,
        };
      }

      const sku = {
        ...action.payload,
        _id: tempId,
      };
      tempRFQProducts.splice(tempRFQProducts.length, 0, sku);

      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          lastSelectedOrchestrator,
          products: tempRFQProducts,
        },
      };
    }
    // Delete works with either sku_id or _id (cartId) in payload
    case RFQ_REMOVE_1ST_LEVEL_LICENSE: {
      if ((!sku_id && !_id) || (sku_id && _id)) {
        return state;
      }

      const foundRemoveLicense = tempRFQProducts.find(stateSku =>
        sku_id ? stateSku.sku_id === sku_id : stateSku._id === _id
      );
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          lastSelectedOrchestrator: /New Orchestrator|Existing Orchestrator/.test(
            foundRemoveLicense!.type
          )
            ? undefined
            : state.rfqCart.lastSelectedOrchestrator,
          products: tempRFQProducts.filter(stateSku =>
            sku_id ? stateSku.sku_id !== sku_id : stateSku._id !== _id
          ),
        },
      };
    }
    // Required: parent_id, sku_id
    case RFQ_REMOVE_2ND_LEVEL_LICENSE: {
      if (!parent_id || !sku_id) {
        return state;
      }
      const foundRemoveLicenseParent = tempRFQProducts.find(
        stateSku => stateSku._id === parent_id
      );
      const foundLicenseProductsDeleted = (
        foundRemoveLicenseParent?.products || []
      ).filter((stateChildSku: any) => stateChildSku.sku_id !== sku_id);

      tempProdArr = tempRFQProducts;
      // After delete, if no child && is existing orch, then remove the orch too
      if (
        foundLicenseProductsDeleted.length === 0 &&
        foundRemoveLicenseParent.type === EXISTING_ORCHESTRATOR
      ) {
        tempProdArr = tempProdArr.filter(
          stateSku => stateSku._id !== parent_id
        );
      } else {
        tempProdArr = tempProdArr.map(stateSku => {
          if (stateSku._id !== parent_id) {
            return stateSku;
          }

          return {
            ...stateSku,
            products: foundLicenseProductsDeleted,
          };
        });
      }

      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          products: tempProdArr,
        },
      };
    }
    // Add to current based on lastSelectedOrchestrator
    case RFQ_ADD_2ND_LEVEL_LICENSE: {
      // For existing license - if it does not exist in state
      const stateExistingOrch = tempRFQProducts.find(
        (stateSku: any) =>
          stateSku._id === state.rfqCart.lastSelectedOrchestrator?._id
      );
      // If existing orch doesn't exist in products arr, add to it and set last_selected_orchestrator
      if (!stateExistingOrch) {
        lastSelectedOrchestrator = {
          ...state.rfqCart.lastSelectedOrchestrator,
          _id: tempId,
        };

        tempRFQProducts.splice(tempRFQProducts.length, 0, {
          _id: tempId,
          type: state.rfqCart.lastSelectedOrchestrator?.type,
          existing_license_code:
            state.rfqCart.lastSelectedOrchestrator?.existing_license_code,
          existing_license_name:
            state.rfqCart.lastSelectedOrchestrator?.license_name,
          display_name: state.rfqCart.lastSelectedOrchestrator?.display_name,
          products: [
            {
              _id: uuidv4(),
              sku_id: action.payload.sku_id,
              sku_name: action.payload.sku_name,
              quantity: action.payload.quantity,
              product_code: action.payload.product_code,
            },
          ],
        });

        return {
          ...state,
          rfqCart: {
            ...state.rfqCart,
            lastSelectedOrchestrator,
            products: tempRFQProducts,
          },
        };
      }

      // If orch exists in products arr, then just append the child
      tempRFQProducts = tempRFQProducts.map((stateSku: any) => {
        if (stateSku._id !== state.rfqCart.lastSelectedOrchestrator?._id) {
          return stateSku;
        }

        const tempChildProducts = (stateExistingOrch.products || []).slice();
        tempChildProducts.splice(tempChildProducts.length, 0, {
          _id: tempId,
          sku_id: action.payload.sku_id,
          sku_name: action.payload.sku_name,
          quantity: action.payload.quantity,
          product_code: action.payload.product_code,
        });

        return {
          ...stateSku,
          products: tempChildProducts,
        };
      });

      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          products: tempRFQProducts,
        },
      };
    }
    case RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY:
      if (!sku_id) {
        return state;
      }
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          products: tempRFQProducts.map(stateSku => {
            if (stateSku.sku_id !== sku_id) {
              return stateSku;
            }

            return {
              ...stateSku,
              quantity,
            };
          }),
        },
      };

    case RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY:
      if (!parent_id || !sku_id) {
        return state;
      }
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          products: tempRFQProducts.map(stateSku => {
            if (stateSku._id !== parent_id) {
              return stateSku;
            }
            return {
              ...stateSku,
              products: (stateSku.products || [])
                .slice()
                .map((stateChildSku: any) => {
                  if (stateChildSku.sku_id !== sku_id) {
                    return stateChildSku;
                  }
                  return {
                    ...stateChildSku,
                    quantity,
                  };
                }),
            };
          }),
        },
      };

    case RFQ_CLICK_LICENSE_IN_CART_VIEW: {
      // Find the item
      const rfqClickedItem = tempRFQProducts.find(
        stateSku => stateSku._id === _id
      );
      if (/New Orchestrator|Existing Orchestrator/.test(rfqClickedItem.type)) {
        lastSelectedOrchestrator = {
          type: rfqClickedItem.type,
          _id,
          license_name:
            rfqClickedItem.sku_name || rfqClickedItem.existing_license_name,
          existing_license_code: rfqClickedItem.existing_license_code,
          display_name: rfqClickedItem.display_name,
        };
      }

      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          lastSelectedOrchestrator,
          selectedCategory: APITypeToProductType.get(rfqClickedItem.type),
        },
      };
    }
    case RFQ_CONTINUE_TO_CART_SUCCESS:
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          server_cart_id: action.payload.server_cart_id,
          products: action.payload.products,
        },
      };
    case RFQ_CONTINUE_SHOPPING:
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          server_cart_id: undefined,
          lastSelectedOrchestrator: undefined,
        },
      };
    case RFQ_LICENSE_NICKNAMES_LOADED: {
      const customNicknamesArr: any[] = action.payload;
      return {
        ...state,
        rfqCart: {
          ...state.rfqCart,
          products: tempRFQProducts.map(bundle => {
            const licenseCode = bundle.existing_license_code;
            const customNickNameInfo = customNicknamesArr.find(
              licenseInfo => licenseInfo.boon_license_cd === licenseCode
            );
            if (customNickNameInfo?.display_name) {
              return {
                ...bundle,
                display_name: customNickNameInfo.display_name,
              };
            }
            return bundle;
          }),
          lastSelectedOrchestrator: state.rfqCart.lastSelectedOrchestrator
            ? {
              ...state.rfqCart.lastSelectedOrchestrator,
              display_name: (() => {
                const customNickNameInfo = customNicknamesArr.find(
                  licenseInfo =>
                    licenseInfo.boon_license_cd ===
                      state.rfqCart.lastSelectedOrchestrator
                        .existing_license_code
                );

                if (customNickNameInfo?.display_name) {
                  return customNickNameInfo.display_name;
                }

                return state.rfqCart.lastSelectedOrchestrator.display_name;
              })(),
            }
            : undefined,
        },
      };
    }
    case HAPO_RESET_CART:
      return {
        ...state,
        hapoProducts: initialState.hapoProducts,
        hapoLastSelectedOrchestrator: undefined,
        hapoSelectedCategory: initialState.hapoSelectedCategory,
      };
    case HAPO_CHANGE_CATEGORY:
      return {
        ...state,
        hapoSelectedCategory: action.payload,
        hapoLastSelectedOrchestrator: undefined,
      };
    case HAPO_CHANGE_ONPREM_SELECTION:
      lastSelectedOrchestrator = undefined;
      if (
        action.payload === NEW_ORCHESTRATOR ||
        action.payload === NEW_PROCESS_MINING ||
        action.payload === AUTOMATION_SUITE ||
        action.payload === CLOUD
      ) {
        const foundNewOrchestrator = state.hapoProducts.find(
          (stateSku: any) => stateSku.type === action.payload
        );
        lastSelectedOrchestrator = foundNewOrchestrator
          ? {
            type: foundNewOrchestrator.type as any,
            _id: foundNewOrchestrator._id,
            license_name: foundNewOrchestrator.sku_name,
            display_name: foundNewOrchestrator.display_name,
          }
          : undefined;
      }
      return {
        ...state,
        hapoLastSelectedOrchestrator: lastSelectedOrchestrator,
      };
    case HAPO_ADD_1ST_LEVEL_LICENSE:
      // Set the last selected option
      if (
        /New Orchestrator|Existing Orchestrator|New Process Mining|Automation Suite|Cloud/.test(
          action.payload.type
        )
      ) {
        lastSelectedOrchestrator = {
          type: action.payload.type,
          // Not setting the _id for existing orchestrators since they're not added to cart immediately
          _id:
            action.payload.type === NEW_ORCHESTRATOR ||
            action.payload.type === NEW_PROCESS_MINING ||
            action.payload.type === AUTOMATION_SUITE ||
            action.payload.type === CLOUD
              ? tempId
              : undefined,
          existing_license_code: action.payload.existing_license_code,
          license_name:
            action.payload.existing_license_name || action.payload.sku_name,
          display_name: action.payload.existing_license_displayName,
        };
      }

      tempHAPOProducts.splice(tempHAPOProducts.length, 0, {
        ...action.payload,
        _id: tempId,
      });

      return {
        ...state,
        hapoLastSelectedOrchestrator: lastSelectedOrchestrator,
        hapoProducts: tempHAPOProducts,
      };
    case HAPO_SELECT_EXISTING_ORCHESTRATOR_OPTION:
      return {
        ...state,
        hapoLastSelectedOrchestrator: {
          type: action.payload.type,
          _id: state.hapoProducts?.find(
            (sku: any) =>
              sku.existing_license_code === action.payload.existing_license_code
          )?._id,
          existing_license_code: action.payload.existing_license_code,
          license_name:
            action.payload.existing_license_name || action.payload.sku_name,
          display_name: action.payload.existing_license_displayName,
        },
      };
    case HAPO_REMOVE_1ST_LEVEL_LICENSE:
      if ((!sku_id && !_id) || (sku_id && _id)) {
        return state;
      }
      return {
        ...state,
        hapoLastSelectedOrchestrator: /New Orchestrator|Existing Orchestrator|New Process Mining|Existing Process Mining/.test(
          tempHAPOProducts.find(stateSku =>
            sku_id ? stateSku.sku_id === sku_id : stateSku._id === _id
          )!.type
        )
          ? undefined
          : state.hapoLastSelectedOrchestrator,
        hapoProducts: tempHAPOProducts.filter(stateSku =>
          sku_id ? stateSku.sku_id !== sku_id : stateSku._id !== _id
        ),
      };
    case HAPO_ADD_2ND_LEVEL_LICENSE: {
      // For existing license - if it does not exist in state
      const hapoExistingOrchestrator = tempHAPOProducts.find(
        (stateSku: any) =>
          stateSku._id === state.hapoLastSelectedOrchestrator?._id
      );
      // If existing orch doesn't exist in products arr, add to it and set last_selected_orchestrator
      if (!hapoExistingOrchestrator) {
        lastSelectedOrchestrator = {
          ...state.hapoLastSelectedOrchestrator,
          _id: tempId,
        };

        tempHAPOProducts.splice(tempHAPOProducts.length, 0, {
          _id: tempId,
          type: state.hapoLastSelectedOrchestrator?.type,
          existing_license_code:
            state.hapoLastSelectedOrchestrator?.existing_license_code,
          existing_license_name:
            state.hapoLastSelectedOrchestrator?.license_name,
          display_name: state.hapoLastSelectedOrchestrator?.display_name,
          products: [
            {
              _id: uuidv4(),
              sku_id: action.payload.sku_id,
              sku_name: action.payload.sku_name,
              quantity: action.payload.quantity,
              product_code: action.payload.product_code,
            },
          ],
        });

        return {
          ...state,
          hapoLastSelectedOrchestrator: lastSelectedOrchestrator,
          hapoProducts: tempHAPOProducts,
        };
      }

      // If orch exists in products arr, then just append the child
      tempHAPOProducts = tempHAPOProducts.map((stateSku: any) => {
        if (stateSku._id !== state.hapoLastSelectedOrchestrator?._id) {
          return stateSku;
        }

        const tempChildProducts = (
          hapoExistingOrchestrator.products || []
        ).slice();
        tempChildProducts.splice(tempChildProducts.length, 0, {
          _id: tempId,
          sku_id: action.payload.sku_id,
          sku_name: action.payload.sku_name,
          quantity: action.payload.quantity,
          product_code: action.payload.product_code,
        });

        return {
          ...stateSku,
          products: tempChildProducts,
        };
      });

      return {
        ...state,
        hapoProducts: tempHAPOProducts,
      };
    }
    case HAPO_UPDATE_1ST_LEVEL_LICENSE_QUANTITY:
      if (!sku_id) {
        return state;
      }
      return {
        ...state,
        hapoProducts: tempHAPOProducts.map(stateSku => {
          if (stateSku.sku_id !== sku_id) {
            return stateSku;
          }

          return {
            ...stateSku,
            quantity,
          };
        }),
      };
    case HAPO_UPDATE_2ND_LEVEL_LICENSE_QUANTITY:
      if (!parent_id || !sku_id) {
        return state;
      }
      return {
        ...state,
        hapoProducts: tempHAPOProducts.map(stateSku => {
          if (stateSku._id !== parent_id) {
            return stateSku;
          }
          return {
            ...stateSku,
            products: (stateSku.products || [])
              .slice()
              .map((stateChildSku: any) => {
                if (stateChildSku.sku_id !== sku_id) {
                  return stateChildSku;
                }
                return {
                  ...stateChildSku,
                  quantity,
                };
              }),
          };
        }),
      };
    case HAPO_CLICK_LICENSE_IN_CART_VIEW: {
      // Find the item
      const hapoClickedItem = tempHAPOProducts.find(
        stateSku => stateSku._id === _id
      );
      if (!hapoClickedItem) {
        return state;
      }
      if (
        /New Orchestrator|Existing Orchestrator|New Process Mining|Existing Process Mining|Cloud|Existing Cloud|Existing Automation Suite|Automation Suite/.test(
          hapoClickedItem.type
        )
      ) {
        lastSelectedOrchestrator = {
          type: hapoClickedItem.type,
          _id,
          license_name:
            hapoClickedItem.sku_name || hapoClickedItem.existing_license_name,
          existing_license_code: hapoClickedItem.existing_license_code,
          display_name: hapoClickedItem.display_name,
        };
      }

      return {
        ...state,
        hapoLastSelectedOrchestrator: lastSelectedOrchestrator,
        hapoSelectedCategory: APITypeToProductType.get(hapoClickedItem.type),
      };
    }
    case HAPO_CONTINUE_SHOPPING:
      return {
        ...state,
        hapoLastSelectedOrchestrator: undefined,
      };
    // Required: parent_id, sku_id
    case HAPO_REMOVE_2ND_LEVEL_LICENSE: {
      if (!parent_id || !sku_id) {
        return state;
      }
      const foundHapoParent = tempHAPOProducts.find(
        stateSku => stateSku._id === parent_id
      );
      const remainingChildrenProducts = (
        foundHapoParent?.products || []
      ).filter((stateChildSku: any) => stateChildSku.sku_id !== sku_id);

      tempProdArr = tempHAPOProducts;
      // After delete, if no child && is existing bundle, then remove the bundle too
      if (
        remainingChildrenProducts.length === 0 &&
        (foundHapoParent.type === EXISTING_ORCHESTRATOR ||
          foundHapoParent.type === EXISTING_PROCESS_MINING ||
          foundHapoParent.type === EXISTING_CLOUD ||
          foundHapoParent.type === EXISTING_AUTOMATION_SUITE)
      ) {
        tempProdArr = tempProdArr.filter(
          stateSku => stateSku._id !== parent_id
        );
      } else {
        tempProdArr = tempProdArr.map(stateSku => {
          if (stateSku._id !== parent_id) {
            return stateSku;
          }

          return {
            ...stateSku,
            products: remainingChildrenProducts,
          };
        });
      }

      return {
        ...state,
        hapoProducts: tempProdArr,
      };
    }
    case HAPO_LICENSE_NICKNAMES_LOADED: {
      const customNicknamesArr: any[] = action.payload;
      return {
        ...state,
        hapoProducts: tempHAPOProducts.map(bundle => {
          const licenseCode = bundle.existing_license_code;
          const customNickNameInfo = customNicknamesArr.find(
            licenseInfo => licenseInfo.boon_license_cd === licenseCode
          );
          if (customNickNameInfo?.display_name) {
            return {
              ...bundle,
              display_name: customNickNameInfo.display_name,
            };
          }
          return bundle;
        }),
        hapoLastSelectedOrchestrator: state.hapoLastSelectedOrchestrator
          ? {
            ...state.hapoLastSelectedOrchestrator,
            display_name: (() => {
              const customNickNameInfo = customNicknamesArr.find(
                licenseInfo =>
                  licenseInfo.boon_license_cd ===
                    state.hapoLastSelectedOrchestrator.existing_license_code
              );

              if (customNickNameInfo?.display_name) {
                return customNickNameInfo.display_name;
              }

              return state.hapoLastSelectedOrchestrator.display_name;
            })(),
          }
          : undefined,
      };
    }

    case HAPO_DISPLAY_ANNOUNCEMENT_MODAL: {
      return {
        ...state,
        hapoAnnouncementModalInfo: {
          message: action.payload.message,
          action: action.payload.action,
          sku_id: action.payload.sku_id,
          sku_name: action.payload.sku_name,
          min_quantity: action.payload.min_quantity,
          product_code: action.payload.product_code,
        },
      };
    }

    case SET_USER: {
      const currentUser: any = action.payload.currentUser;
      const companyAdditionalInfo: any = action.payload.companyAdditionalInfo;

      if (!currentUser) {
        return state;
      }

      const noRelatedContacts: boolean =
        !currentUser.sharedCompanies ||
        currentUser.sharedCompanies?.length === 0;
      const onlyRelatedContact: boolean =
        !currentUser.isUiPath &&
        currentUser.sharedCompanies?.length === 1 &&
        currentUser.viewBaseAccount === false;
      const canViewMainAccount: boolean =
        !currentUser.isUiPath &&
        (currentUser.viewBaseAccount === true ||
          currentUser.viewBaseAccount === undefined);
      const tempUserProfilePhoto =
        currentUser.picture && currentUser.picture_storage_account_name
          ? `${currentUser.picture_storage_account_name}/${currentUser.picture}`
          : undefined;

      const userProfilePhoto: string =
        // What is 005.png?
        currentUser.pictureId === '005.png'
          ? currentUser.pictureId
          : tempUserProfilePhoto;
      let companyId: string = '';
      let companyName: string = '';
      let sharedCompanies: any = [];
      let cpRole: string = '';
      let permissions: string[] = [];
      let isPermissionsSet: boolean = false;
      let isDisabled: boolean = false;

      if (noRelatedContacts) {
        // Set company, and status for users with no shared companies
        companyId = currentUser.myCompany?.companyId || '';
        companyName = currentUser.myCompany?.companyName || '';
        isDisabled =
          (currentUser.myCompany?.companyId === state.companyId ||
            !state.companyId) &&
          currentUser.status === DISABLED;
      } else if (onlyRelatedContact) {
        // Set company, cp role, and permissions for users with one shared company,
        // and no access to his main company
        companyId = currentUser.sharedCompanies[0].companyId || '';
        companyName = currentUser.sharedCompanies[0].companyName || '';
        cpRole = currentUser.sharedCompanies[0].cpRole || '';
        permissions = currentUser.sharedCompanies[0].permissions || [];
        isDisabled = currentUser.sharedCompanies[0].status === DISABLED;
        isPermissionsSet = true;
      } else {
        // Set users with more than one shared companies, and with access to main company
        sharedCompanies = currentUser.sharedCompanies || [];

        // Set permissions for uipath users that are defined in Account Team object in SFDC
        // with the role Pre-sales Generic, they will have the CP role of 'uipath-invisible-view'
        if (currentUser.isUiPath) {
          for (const account of sharedCompanies) {
            if (account.companyId === state.companyId) {
              cpRole = account.cpRole || '';
              permissions = account.permissions || [];
              isPermissionsSet = true;
            }
          }
        }

        // Add main account to shared account, for CP users, in order to be listed in switch account modal
        if (canViewMainAccount) {
          sharedCompanies.push({
            companyId: currentUser.myCompany.companyId || '',
            companyName: currentUser.myCompany.companyName || '',
            cpRole: currentUser.cpRole || '',
            permissions: currentUser.permissions || [],
            status: currentUser.status || '',
            rpaRole: currentUser.rpaRole || '',
            weeklyNotification: !!currentUser.weeklyNotification,
            invitedBy: currentUser.oid || '',
            invitedAt: currentUser.created_on || '',
            isCollabSpaceEnabled: currentUser.myCompany.isCollabSpaceEnabled,
          });
        }

        if (currentUser.lastSelectedAccount) {
          for (const item of currentUser.sharedCompanies) {
            // Block user if disabled for this account
            if (
              (item.companyId === state.companyId || !state.companyId) &&
              item.status === DISABLED
            ) {
              isDisabled = true;
              break;
            }
          }
        }
      }

      // Set role, and permissions if the permissions weren't set already
      if (!isPermissionsSet) {
        cpRole = currentUser.cpRole || '';
        permissions = currentUser.permissions || [];
      }

      // For uipath users ONLY, set the cpRole to the higher
      // of top-level cpRole vs company-specific cpRole
      if (
        !!currentUser.isUiPath &&
        UIPATH_ROLES_IN_ORDER_ASC.indexOf(currentUser.cpRole) > UIPATH_ROLES_IN_ORDER_ASC.indexOf(cpRole as UserRole)
      ) {
        cpRole = currentUser.cpRole;
      }

      const newState = {
        ...state,
        isUiPath: !!currentUser.isUiPath,
        isBlocked: isDisabled,
        userId: currentUser.oid || '',
        cloudUserId: currentUser.cloudId || '',
        userName: currentUser.name || '',
        userInitials: sessionHelper.getUserInitials(currentUser.name || ''),
        userEmail: currentUser.email || '',
        cpRole,
        permissions,
        rpaRole: currentUser.rpaRole || '',
        jobTitle: currentUser.jobTitle || '',
        country: currentUser.country || '',
        timezone: currentUser.timezone || '',
        phoneNumber: currentUser.phoneNumber || '',
        profilePhoto: userProfilePhoto,
        createdOn: currentUser.created_on || '',
        lastSelectedAccount: currentUser.lastSelectedAccount || '',
        showMultiAccountTutorial: !!currentUser.showMultiAccountTutorial,
        activatedThroughCaseCreation: !!currentUser.activatedThroughCaseCreation,
        viewBaseAccount: !!currentUser.viewBaseAccount,
        bookmarkedCompanies: currentUser.preferences?.bookmarkedAccounts || [],
        myCompanies: currentUser.myCompanies || [],
        companyId: companyId ? companyId : state.companyId,
        companyName: companyName ? companyName : state.companyName,
        sharedCompanies,
        isCollabSpaceEnabled:
          companyAdditionalInfo.isCollabSpaceEnabled === false ? false : true,
        isSelfRegistrationEnabled:
          companyAdditionalInfo.isSelfRegistrationEnabled === false ? false : true,
        territories: currentUser.territories || [],
        companyTerritory: companyAdditionalInfo.territory || '',
        uuid: currentUser.uuid,
        companyArea: companyAdditionalInfo.area,
        roles: currentUser.roles ?? [],
      };

      // when user logs in
      if (state.userId === '') {
        triggerTrackEvent(AUTHENTICATE_LOGIN, {
          UserCountry: currentUser.country,
          SystemInfo: navigator.userAgent,
          SystemLanguage: navigator.language,
          UserLanguage: localStorage.getItem(LANGUAGE_KEY_NAME),
        }, newState);
      }

      return newState;
    }
    case UPDATE_GLOBAL_STATE: {
      const now: number = Date.now();
      const oneDay: number = 60 * 60 * 24 * 1000;

      if (
        !state.stateUpdatedAt ||
        (state.stateUpdatedAt && now - state.stateUpdatedAt > oneDay)
      ) {
        return {
          ...initialState,
          stateUpdatedAt: Date.now(),
        };
      }

      return { ...state };
    }
    case 'addNotificationBannerMessages':
      return {
        ...state,
        notificationBannerMessages: [
          ...(state.notificationBannerMessages ?? []),
          ...action.payload,
        ],
      };
    case 'removeNotificationBannerMessage': {
      const notificationBannerMessages = [
        ...(state.notificationBannerMessages ?? []),
      ];
      notificationBannerMessages.splice(action.payload, 1);
      return {
        ...state,
        notificationBannerMessages,
      };
    }
    case 'setForceShowUserInfoModal':
      return {
        ...state,
        forceShowUserInfoModal: !!action.payload,
      };
    case 'toggleActionPanel':
      return {
        ...state,
        showActionPanel:
          typeof action.payload === 'boolean'
            ? action.payload
            : !state.showActionPanel,
      };
    case 'setUserAnnouncements':
      return {
        ...state,
        userAnnouncements: action.payload,
      };
    case 'setIsSelfRegistrationEnabled':
      return {
        ...state,
        isSelfRegistrationEnabled: action.payload,
      };
    case 'setHeaderFooterVisibility':
      return {
        ...state,
        ui: {
          header: { hidden: !!action.payload.header.hidden },
          footer: { hidden: !!action.payload.footer.hidden },
        },
      };
    case 'setCanUserSupportAccount':
      return {
        ...state,
        canUserSupportAccount: action.payload,
      };
    default:
      return { ...state };
  }
};

const StoreContext = React.createContext({} as IContextProps);

interface StoreProviderProps {
  children?: React.ReactNode;
}

const StoreProvider = ({ children }: StoreProviderProps) => {
  const [ privateState, privateDispatch ] = usePrivatePersistedReducer(reducer, initialState);
  const [ publicState, publicDispatch ] = useReducer(reducer, initialState);

  const isPublic = useShowPublicExperience();

  const storeStateValue = {
    state: isPublic ? publicState : privateState,
    dispatch: isPublic ? publicDispatch : privateDispatch,
  };

  return (
    <StoreContext.Provider value={storeStateValue}>
      {children}
    </StoreContext.Provider>
  );
};

export {
  StoreContext,
  StoreProvider,
};

