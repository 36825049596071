// Components
import {
  CaseRecordType,
  SupportWhiteListedFileExtensions,
} from '@customer-portal/constants';
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import React, {
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';

import * as styles from '../../../assets/css/Support/Case';
import ErrorBlue from '../../../assets/img/svg/browse/Error_Blue.svg';
// Images
import CloudUp from '../../../assets/img/svg/file_icons/Cloud_Up_Black.svg';
// Constants
import { INVALID_SITE_URL } from '../../../constants/support.constants';
import type { DropDownItemData } from '../../../interfaces/sfdc.interface';
// Interfaces
import type {
  CasePageProps,
  NewIFormDataKeys,
} from '../../../interfaces/support.interface';
// Lib
import { isFileAttachmentSupported } from '../../../lib/file.utils';
import {
  isValidSiteUrl,
  reduceListToLocalizedList,
} from '../../../lib/support.util';
import { StoreContext } from '../../../store';
import SelectWithSearch from '../../Select-With-Search';
import TextLabel from '../../TextLabel';
import SupportCaseDeflectionModal from '../CaseCreation/SupportCaseDeflection-Modal';
import DocsGptDeflection from './DocsGptDeflection';

const AdditionalDetailsPage = (props: CasePageProps) => {
  const { t } = useTranslation('common');
  const baseClass = 'SupportCaseBody';
  const {
    formData,
    setFormData,
    filteredSupportFormFieldMappings,
    engine,
    caseAssistState,
    attachedFile,
    setAttachedFile,
    caseRecordType,
    showCoveoResults,
    resultsShownFirst,
    doneStreamedText,
    setDoneStreamedText,
    siteUrlValidationError,
    setSiteUrlValidationError,
  } = props;
  const { dispatch } = useContext(StoreContext);
  const fileInput = React.createRef<any>();

  useEffect(() => {
    const addDetailFormElement = document.getElementById('AdditionalDetailsForm') as HTMLElement;
    const addDetailFormHeightElement = addDetailFormElement.offsetHeight;
    const deflectionElement = document.getElementById('AdditionalDetailsDeflection') as HTMLElement;
    const deflectionHeight = deflectionElement.offsetHeight;
    if (addDetailFormHeightElement > deflectionHeight) {
      addDetailFormElement.classList.add('divider-left');
    } else {
      deflectionElement.classList.add('divider-right');
    }

  }, []);

  // Validate Site URL
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSiteUrlValidationError(
        isValidSiteUrl(formData.siteUrl) ? null : INVALID_SITE_URL
      );
    }, 500);
    return () => clearTimeout(timeout);
  }, [ formData.siteUrl ]);

  // Only handles fields in second page
  const handleSelectOptionChange = async (
    option: DropDownItemData,
    inputType:
    | 'deploymentModel'
    | 'environment'
    | 'priority'
    | 'studioOrRobotVersion'
    | 'orchestratorVersion'
    | 'airgapped'
    | 'architecture'
    | 'packageName'
    | 'issueType'
  ) => {
    const newFormData = { ...formData };
    newFormData[inputType] = option;
    delete newFormData.errors[inputType];
    if (inputType === 'environment') {
      // Don't let users select 'Urgent' priority if the environment isn't 'Production'
      if (option.value !== 'Production' && formData.priority.value === 'Urgent') {
        newFormData.priority = props.priorities.find((p) => p.value === 'Medium') ?? props.priorities[0];
      }
    }
    setFormData(newFormData);
  };

  const handleInputChange = async (event: any, inputType: NewIFormDataKeys) => {
    const value: string = event.target.value;
    const newFormData = { ...formData };
    newFormData[inputType] = value;
    delete newFormData.errors[inputType];
    setFormData(newFormData);
  };

  const handleUploadButtonClick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const handleFileUploadInputClick = (e: any) => {
    const newFormData = { ...formData };
    const newAttachedFile = { ...attachedFile };
    if (attachedFile.name) {
      newFormData.attachment = attachedFile.name;
      newAttachedFile.isHidden = false;
      setFormData(newFormData);
      setAttachedFile(newAttachedFile);
    }
  };

  const handleFileUploadInputChange = (e: any) => {
    const newFormData = { ...formData };
    const newAttachedFile = { ...attachedFile };
    const file = e?.target?.files[0];
    if (!file) {
      return;
    }

    if (file && file.size / 1024 / 1024 > 25) {
      // Reset file input state, since any previously-uploaded file's data
      // is overwritten when this new file is selected
      handleRemoveFileUpload();
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'support_form_attachment_size_error',
          'Error uploading document: File exceeds 25MB'
        ),
      });
      return;
    }

    // Allowed mime types
    // Document: .txt, .csv, .rtf, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .xml
    // Image: .bmp, .gif, .jpeg, .jpg, .png
    // Arhive: .zip, .rar, .7zip
    // Audio: .mp3, .ogg
    // Video: .mpeg, .mpg, .mov

    if (!isFileAttachmentSupported(file, SupportWhiteListedFileExtensions)) {
      // Reset file input state, since any previously-uploaded file's data
      // is overwritten when this new file is selected
      handleRemoveFileUpload();
      formData.errors.attachment = t(
        'support_form_attachment_file_name_error',
        'File [{{fileName}}] is not allowed!',
        { fileName: file.name }
      );
    } else {
      delete formData.errors.attachment;
    }

    if (file && !formData.errors.attachment) {
      delete newFormData.errors.attachment;
      newFormData.attachment = file.name;
      newAttachedFile.isHidden = false;
    }

    setFormData(newFormData);
    setAttachedFile(newAttachedFile);
  };

  const handleRemoveFileUpload = () => {
    fileInput.current.value = null;
    const newAttachedFile = { ...attachedFile };
    newAttachedFile.isHidden = true;
    setAttachedFile(newAttachedFile);
  };

  useEffect(() => {
    if (doneStreamedText) {
      // if response is not scrollable, don't add the shadow
      const markdownBodyElement = document.querySelector('.markdown-body') as HTMLElement;
      const markdownBodyHeight = markdownBodyElement.clientHeight;
      if (markdownBodyHeight < window.innerHeight * 0.5) {
        const caseDeflectionResponseElement = document.querySelector('.SupportCaseBody__Case-Deflection-Response') as HTMLElement;
        caseDeflectionResponseElement.style.background = 'none';
      }
    }
  }, [ doneStreamedText ]);

  return (
    <styles.SupportCaseBody
      doneStreamedText={doneStreamedText}
      data-testid='additional-details-page'>
      <div
        id="AdditionalDetailsDeflection"
        className={`${baseClass}__Additional-Details`}>
        <div className={`${baseClass}__Case-Deflection`}>
          {
            showCoveoResults ? (
              <div>
                <h6 className={`${baseClass}__Case-Name`}>
                  {t(
                    'support_form_suggested_resources_title',
                    'Suggested Resources for {{product}}',
                    { product: formData.product.label }
                  )}
                </h6>
                <SupportCaseDeflectionModal
                  engine={engine}
                  responseState={caseAssistState}
                  isNewSupportCase
                  resultsShownFirst={resultsShownFirst} />
              </div>
            ) : (
              <DocsGptDeflection
                {...props}
                doneStreamedText={doneStreamedText}
                setDoneStreamedText={setDoneStreamedText} />
            )
          }
        </div>
      </div>
      <div className={`${baseClass}__Additional-Details`}>
        <div
          id="AdditionalDetailsForm"
          className={`${baseClass}__Additional-Details-Form`}>
          <div className={`${baseClass}__Case-Fields`}>
            {filteredSupportFormFieldMappings?.studioOrRobotVersion &&
                !filteredSupportFormFieldMappings?.studioOrRobotVersion?.isPrimaryVersionPicklist &&
                (
                  <FormControl className={`${baseClass}__Case-Field`}>
                    <SelectWithSearch
                      label={filteredSupportFormFieldMappings.studioOrRobotVersion.isRequired ?
                        t(
                          'support_form_studio_or_robot_version_label',
                          'Studio/Robot Version'
                        ) :
                        t(
                          'support_form_studio_or_robot_version_label_optional',
                          'Studio/Robot Version (Optional)'
                        )}
                      searchable
                      className="Custom-Select--White studioOrRobotVersion-input"
                      options={props.studioVersions}
                      onChange={(o: DropDownItemData) => {
                        handleSelectOptionChange(o, 'studioOrRobotVersion');
                      }}
                      value={formData.studioOrRobotVersion}
                      data-testid="studioOrRobotVersion-input"
                    />
                    {formData.errors?.studioOrRobotVersion && (
                      <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                        {formData.errors.studioOrRobotVersion}
                      </styles.SupportCaseValidationErrorMessage>
                    )}
                  </FormControl>
                )}

            {filteredSupportFormFieldMappings?.orchestratorVersion &&
                !filteredSupportFormFieldMappings?.orchestratorVersion?.isPrimaryVersionPicklist &&
                (
                  <FormControl
                    className={`${baseClass}__Case-Field`}
                  >
                    <SelectWithSearch
                      label={t(
                        'support_form_orchestrator_version_label',
                        'Orchestrator Version'
                      )}
                      searchable
                      className="Custom-Select--White orchestratorVersion-input"
                      options={props.orchestratorVersions}
                      onChange={(o: DropDownItemData) => {
                        handleSelectOptionChange(o, 'orchestratorVersion');
                      }}
                      value={formData.orchestratorVersion}
                      data-testid="orchestratorVersion-input"
                    />
                    {formData.errors?.orchestratorVersion && (
                      <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                        {formData.errors.orchestratorVersion}
                      </styles.SupportCaseValidationErrorMessage>
                    )}
                  </FormControl>
                )}

            {filteredSupportFormFieldMappings?.deploymentModel && (
              <FormControl
                className={`${baseClass}__Case-Field`}
              >
                <SelectWithSearch
                  label={t(
                    'support_form_deployment_model_label',
                    'Deployment Model'
                  )}
                  searchable
                  className="Custom-Select--White deploymentModel-input"
                  options={formData.product.deploymentModels ?? []}
                  onChange={(o: DropDownItemData) => {
                    handleSelectOptionChange(o, 'deploymentModel');
                  }}
                  value={formData.deploymentModel}
                  data-testid="deploymentModel-input"
                />
                {formData.errors?.deploymentModel && (
                  <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                    {formData.errors.deploymentModel}
                  </styles.SupportCaseValidationErrorMessage>
                )}
              </FormControl>
            )}

            {filteredSupportFormFieldMappings?.airgapped && (
              <div>
                <TextLabel label={t('support_form_airgapped_label', 'Airgapped')} />
                <RadioGroup
                  aria-label="airgapped"
                  value={formData.airgapped}
                  onChange={(e: any) => {
                    handleSelectOptionChange(e.target.value as DropDownItemData, 'airgapped');
                  }}
                  data-testid="airgapped__options"
                  className={`${baseClass}__Radio-Container`}
                >
                  {props.airgapped.map((value, i) => {
                    if (value.value !== 'None') {
                      return (
                        <FormControlLabel
                          className={`${baseClass}__Radio`}
                          value={value.label}
                          label={value.label}
                          name={value.label}
                          key={value.label}
                          control={<Radio />}
                          data-testid={`airgapped-input-${value.label}`}
                        />
                      );
                    }
                  })}
                </RadioGroup>
                {formData.errors?.airgapped && (
                  <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                    {formData.errors.airgapped}
                  </styles.SupportCaseValidationErrorMessage>
                )}
                <br />
              </div>
            )}

            {filteredSupportFormFieldMappings?.architecture && (
              <div>
                <TextLabel
                  label={filteredSupportFormFieldMappings?.architecture.isRequired ?
                    t('support_form_architecture_label', 'Architecture') :
                    t('support_form_architecture_optional_label', 'Architecture (Optional)')}
                />
                <RadioGroup
                  aria-label="architecture"
                  value={formData.architecture}
                  onChange={(e: any) => {
                    handleSelectOptionChange(e.target.value as DropDownItemData, 'architecture');
                  }}
                  data-testid="architecture__options"
                  className={`${baseClass}__Radio-Container`}
                >
                  {props.architectures.map((value, i) => {
                    if (value.value !== 'None') {
                      return (
                        <FormControlLabel
                          className={`${baseClass}__Radio`}
                          value={value.label}
                          label={value.label}
                          name={value.label}
                          key={value.label}
                          control={<Radio />}
                          data-testid={`architecture-input-${value.label}`}
                        />
                      );
                    }
                  })}
                </RadioGroup>
                {formData.errors?.architecture && (
                  <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                    {formData.errors.architecture}
                  </styles.SupportCaseValidationErrorMessage>
                )}
                <br />
              </div>
            )}

            {filteredSupportFormFieldMappings?.siteUrl && (
              <>
                <br />
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'support_form_site_url_help_text',
                      'Please copy the browser URL and paste it here. Site URL should be of the following format https://cloud.uipath.com/&lt;org_name&gt;/&lt;tenant_name&gt; where &lt;org_name&gt; is mandatory and &lt;tenant_name&gt; is optional.'
                    ),
                  }}
                />
                <FormControl className={`${baseClass}__Case-Field`}>
                  <TextLabel label={t('support_form_site_url_label')} />
                  <TextField
                    disabled={false}
                    variant="outlined"
                    value={formData.siteUrl}
                    onChange={(e) => {
                      handleInputChange(e, 'siteUrl');
                    }}
                    error={false}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ className: 'Tall' }}
                    inputProps={{
                      maxLength: 255,
                      'data-testid': 'siteUrl-input',
                    }}
                    className={`${baseClass}__Case-Field--Textfield`}
                  />
                  {siteUrlValidationError && (
                    <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                      {t(
                        siteUrlValidationError.textKey,
                        siteUrlValidationError.fallbackText
                      )}
                    </styles.SupportCaseValidationErrorMessage>
                  )}
                  {formData.errors?.siteUrl && (
                    <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                      {formData.errors.siteUrl}
                    </styles.SupportCaseValidationErrorMessage>
                  )}
                </FormControl>
                <br />
              </>
            )}

            {filteredSupportFormFieldMappings?.packageName && (
              <FormControl
                className={`${baseClass}__Case-Field`}
              >
                <SelectWithSearch
                  label={t(
                    'support_form_package_name_label',
                    'Package Name'
                  )}
                  searchable
                  className="Custom-Select--White packageName-input"
                  options={formData.product.packageNames ?? []}
                  onChange={(o: DropDownItemData) => {
                    handleSelectOptionChange(o, 'packageName');
                  }}
                  value={formData.packageName}
                  data-testid="packageName-input"
                />
                {formData.errors?.packageName && (
                  <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                    {formData.errors.packageName}
                  </styles.SupportCaseValidationErrorMessage>
                )}
              </FormControl>
            )}

            {filteredSupportFormFieldMappings?.packageVersion && (
              <FormControl className={`${baseClass}__Case-Field`}>
                <TextLabel
                  label={filteredSupportFormFieldMappings?.packageVersion.isRequired ?
                    t('support_form_package_version_label',
                      'Package Version') :
                    t('support_form_package_version_label_optional',
                      'Package Version (Optional)')}
                />
                <TextField
                  disabled={false}
                  variant="outlined"
                  value={formData.packageVersion}
                  onChange={e => {
                    handleInputChange(e, 'packageVersion');
                  }}
                  error={false}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ className: 'Tall' }}
                  inputProps={{
                    maxLength: 255,
                    'data-testid': 'packageVersion-input',
                  }}
                  className={`${baseClass}__Case-Field--Textfield`}
                />
                {formData.errors?.packageVersion && (
                  <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                    {formData.errors.packageVersion}
                  </styles.SupportCaseValidationErrorMessage>
                )}
              </FormControl>
            )}

            {filteredSupportFormFieldMappings?.environment && (
              <FormControl
                className={`${baseClass}__Case-Field`}
              >
                <SelectWithSearch
                  label={t('support_form_environment_label')}
                  searchable
                  className="Custom-Select--White environment-input"
                  options={props.environments}
                  onChange={(o: DropDownItemData) => {
                    handleSelectOptionChange(o, 'environment');
                  }}
                  value={formData.environment}
                  data-testid="environment-input"
                />
                {formData.errors?.environment && (
                  <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                    {formData.errors.environment}
                  </styles.SupportCaseValidationErrorMessage>
                )}
              </FormControl>
            )}

            {!!filteredSupportFormFieldMappings?.issueType?.values?.length && (
              <FormControl
                className={`${baseClass}__Case-Field`}
              >
                <SelectWithSearch
                  label={t('support_form_issue_type_label')}
                  searchable
                  className="Custom-Select--White issueType-input"
                  options={reduceListToLocalizedList(props.issueTypes, filteredSupportFormFieldMappings.issueType.values)}
                  onChange={(o: DropDownItemData) => {
                    handleSelectOptionChange(o, 'issueType');
                  }}
                  value={formData.issueType}
                  data-testid="issueType-input"
                />
                {formData.errors?.issueType && (
                  <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                    {formData.errors.issueType}
                  </styles.SupportCaseValidationErrorMessage>
                )}
              </FormControl>
            )}

            <FormControl
              className={`${baseClass}__Case-Field`}
            >
              <SelectWithSearch
                label={t('support_form_priority_label')}
                searchable
                className="Custom-Select--White priority-input"
                options={props.priorities
                  .filter(el =>
                    caseRecordType === CaseRecordType.SERVICE_REQUEST
                      ? el.value === 'Medium'
                      : true
                  )
                  .filter(el =>
                    filteredSupportFormFieldMappings?.deploymentTypeValue === 'Automation Cloud' ||
                      formData.environment.value === 'Production' ||
                      el.value !== 'Urgent'
                  )}
                onChange={(o: DropDownItemData) => {
                  handleSelectOptionChange(o, 'priority');
                }}
                value={formData.priority}
                helpText={t(
                  'support_form_priority_help_text',
                  '<b>Low:</b> Minor issues without business impact <br><b>Medium:</b> Specific functionality not working with minimum impact in operation <br><b>High:</b> Significant functionality not working, business can continue in an impaired mode <br><b>Critical:</b> Outage of production environment, serious business loss'
                )}
                data-testid="priority-input"
              />
              {formData.errors?.priority && (
                <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                  {formData.errors.priority}
                </styles.SupportCaseValidationErrorMessage>
              )}
            </FormControl>

            <div className={`${baseClass}__Case-Field`}>
              <TextLabel
                label={t('support_form_attachment_optional_label', 'Attachment (Optional)')}
              />
              <p>
                {t('support_form_attachment_blurb_1')}
              </p>
              <p>
                {t('support_form_attachment_blurb_2')}
              </p>
            </div>

            <div className={`${baseClass}__Case-Field--Upload`}>
              <Button
                variant="outlined"
                className={`${baseClass}__Case-Field--Upload-Button`}
                disableElevation
                onClick={handleUploadButtonClick}
                startIcon={<img
                  src={CloudUp}
                  alt="Cloud up icon" />}
              >
                {t('support_form_button_upload_file')}
              </Button>

              <form
                id="case_attachment"
                className={`${baseClass}__Case-Field--Upload-Form`}
              >
                <input
                  className="CustomerPortalUploadForm__Input"
                  name="attachments"
                  type="file"
                  onChange={handleFileUploadInputChange}
                  onClick={handleFileUploadInputClick}
                  ref={fileInput}
                  data-testid="file-input"
                />
              </form>
              {formData.errors.attachment && (
                <styles.SupportCaseValidationErrorMessage data-testid="input-ValidationErrorMessage">
                  {formData.errors.attachment}
                </styles.SupportCaseValidationErrorMessage>
              )}
              {!formData.errors.attachment && formData.attachment && (
                <styles.SupportCaseUploadedFileInfo>
                  {!attachedFile.isHidden && (
                    <div
                      className={`${baseClass}__Case-Field--Upload-File-Name`}
                      onClick={() => handleRemoveFileUpload()}
                      onKeyDown={() => handleRemoveFileUpload()}
                      data-testid="uploaded-file-name"
                      tabIndex={0}
                    >
                      <img
                        src={ErrorBlue}
                        alt="Cloud up icon" />
                      {formData.attachment}
                    </div>
                  )}
                </styles.SupportCaseUploadedFileInfo>
              )}
            </div>
          </div>
        </div>
      </div>
    </styles.SupportCaseBody>
  );
};

export default AdditionalDetailsPage;
