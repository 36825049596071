import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import TextField from '@mui/material/TextField';
import React, {
  useContext,
  useState,
} from 'react';
// Translations
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../assets/css/Company/Page';
// Clients
import { axiosPost } from '../../client/axios';
// Constants
import { BACKEND_HOST_NAME } from '../../constants/network.constants';
import { useAuth } from '../../contexts/auth';
// Store
import { StoreContext } from '../../store';
// components
import CustomerPortalLoader from '../CustomerPortal-Loader';
import Modal from '../Modal';

type AddLicenseAttributeProps = {
  onClose: (
    newAttribute: string,
    modalType: string,
    isChanged?: boolean
  ) => void;
  open: boolean;
  displayName: string;
  licenseId: string;
  note: string;
  modalVariant: string;
};

const AddCustomLicenseNameModal = (props: AddLicenseAttributeProps) => {
  // Translate method
  const { t } = useTranslation('common');
  const baseClass = 'AddCustomLicenseNameModal';
  const { getAccessToken } = useAuth();
  // Global state
  const {
    state, dispatch,
  } = useContext(StoreContext);
  // Local state
  const [ loading, setLoading ] = useState(false);
  const [ displayName, setDisplayName ] = useState(props.displayName);
  const [ note, setNote ] = useState(props.note);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(event.target.value);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
  };

  const handleSaveClose = (successfulSubmit: boolean) => {
    if (props.modalVariant === 'customName') {
      if (!successfulSubmit) {
        props.onClose(props.displayName, 'displayName', false);
      } else {
        props.onClose(displayName, 'displayName', true);
      }
    } else if (props.modalVariant === 'notes') {
      if (!successfulSubmit) {
        props.onClose(props.note, 'notes', false);
      } else {
        props.onClose(note, 'notes', true);
      }
    }
  };

  const handleSubmit = async () => {
    let updateResult: any;

    const body = {
      boon_license_cd: props.licenseId,
      last_updated_by: state.userId,
      last_updated_by_username: state.userName,
      comment: '',
      display_name: '',
    };

    if (props.modalVariant === 'customName') {
      body.display_name = displayName;
      body.comment = props.note;
    } else {
      body.display_name = props.displayName;
      body.comment = note;
    }

    setLoading(true);

    try {
      updateResult = await axiosPost(
        `${BACKEND_HOST_NAME}/api/v1/license/updateLicenseDisplayNameComment`,
        state.companyId,
        await getAccessToken(),
        body
      );
      setLoading(false);
    } catch (err) {
      console.log(err);
    }

    const bannerText =
      props.modalVariant === 'customName' ? 'display name' : 'note';

    if (updateResult && updateResult.status === 200) {
      dispatch({
        type: 'setBannerType',
        payload: 'success',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_licenses_add_custom_attribute_modal_success_msg',
          `License ${bannerText} was updated.`,
          { bannerText }
        ),
      });
      handleSaveClose(true);
    } else {
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: t(
          'company_licenses_add_custom_attribute_modal_error_msg',
          `License ${bannerText} could not be saved.`,
          { bannerText }
        ),
      });
      handleSaveClose(false);
    }
  };

  const generateModalBody = () => {
    if (props.modalVariant === 'customName') {
      return (
        <TextField
          className={`${baseClass}__Ticket-Field`}
          variant={'outlined' as any}
          onChange={handleNameChange}
          error={false}
          required={false}
          InputLabelProps={{ shrink: true }}
          InputProps={{ className: 'Tall' }}
          inputProps={{ maxLength: 40 }}
          placeholder={
            props.displayName
              ? ''
              : t(
                'company_licenses_add_custom_attribute_modal_add_custom_name_placeholder',
                'Enter name'
              )
          }
          defaultValue={props.displayName}
        />
      );
    }
    return (
      <FormControl className={`${baseClass}__Ticket-Field`}>
        <TextareaAutosize
          className={`${baseClass}__Ticket-Field--Textarea`}
          aria-label="Notes textarea"
          required
          minRows={5}
          onChange={handleNoteChange}
          defaultValue={props.note}
          placeholder={
            props.note
              ? props.note
              : t(
                'company_licenses_add_custom_attribute_modal_add_notes_placeholder',
                'Enter notes'
              )
          }
        />
      </FormControl>
    );

  };

  const generateModalHeading = () => {
    if (props.modalVariant === 'customName') {
      if (!props.displayName) {
        return t(
          'company_licenses_add_custom_attribute_modal_add_custom_name_title',
          'Add Custom Name'
        );
      }
      return t(
        'company_licenses_add_custom_attribute_modal_edit_custom_name_title',
        'Edit Custom Name'
      );

    }
    if (!props.note) {
      return t(
        'company_licenses_add_custom_attribute_modal_add_notes_title',
        'Add Notes'
      );
    }
    return t(
      'view_notes',
      'View Notes'
    );

  };

  const handleClose = () => {
    handleSaveClose(false);
  };

  return (
    <Modal
      modalTitle="Standard Modal"
      modalDescription="Add License Attribute Modal"
      modalHeading={generateModalHeading()}
      innerClasses={`${baseClass}`}
      handleClose={handleClose}
      open={props.open}
    >
      {loading && (
        <div className="Modal-Fields-Container">
          <CustomerPortalLoader />
        </div>
      )}

      {!loading && (
        <styles.NameModalInner
          className={`Modal-Fields-Container ${baseClass}__Form-Inner`}
        >
          {generateModalBody()}
          <Button
            variant="contained"
            className={`${baseClass}__Form-Button`}
            disableElevation
            color="secondary"
            onClick={handleSubmit}
          >
            {t('company_licenses_add_custom_attribute_modal_save_btn', 'Save')}
          </Button>
        </styles.NameModalInner>
      )}
    </Modal>
  );
};

export default AddCustomLicenseNameModal;
