import type {
  SearchBoxProps,
  SearchBoxState,
  SearchEngine,
  Suggestion,
} from '@coveo/headless';
import { buildSearchBox } from '@coveo/headless';
import type {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@material-ui/lab';
import { Autocomplete } from '@material-ui/lab';
import {
  Button,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ApolloIcon from '../../../components/ApolloIcon';
import {
  QUERY_TEXT_URL_KEY,
  SEARCH_MINIMUM_CHARACTER_LENGTH,
  SEARCH_SUGGESTIONS_DELAY_MS,
} from '../../../constants/search.constants';
import { pushNewHashToUrl } from '../../../utils/search';
import { DEFAULT_SEARCH_PROPS } from './constants';
import { KeyboardInputs } from './types';

const Container = styled.div`
  display: flex;
  height: 60px;
  margin: ${p => p.theme.spacing(4)}px 0;
`;

const Search = styled.div`
  display: flex;
  flex-grow: 1;
  width: inherit;
  height: inherit;
`;

const SearchBarStyled = styled(TextField)`
  display: flex;
  flex-grow: 1;
  width: inherit;
  height: inherit;
  margin-bottom: 10px;

  // Input's parent
  .MuiInputBase-root {
    height: inherit;
    padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(1)}px;
    border-radius: ${p => p.theme.spacing(1)}px;
  }

  // Input element
  input.MuiInputBase-input {
    font-size: 1.6rem !important;
  }

  // Error label
  .MuiInputBase-root.Mui-error::after {
    display: none;
  }

  // Helper text
  .MuiFormHelperText-root.Mui-error {
    margin: ${p => p.theme.spacing(1.25)}px 0;
    line-height: 0;
    font-size: 1.2rem;
    color: ${p => p.theme.palette.red[600]};
  }

  .MuiInputBase-root {
    border: 1px solid ${p => p.theme.palette.grey[500]};
  }

  .MuiInputBase-input {
    border: none;
    padding: 0px !important;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid ${p => p.theme.palette.grey[500]} !important;
    &:hover, &:focus, &:focus-within, &:active {
      border-color: transparent !important;
    }
  }
`;

const AutocompleteStyled = styled(Autocomplete)`
  display: flex;
  flex-grow: 1;
  width: inherit;
  height: inherit;
  padding: unset;
  margin: unset;

  .MuiAutocomplete-inputRoot {
    padding: ${p => p.theme.spacing(1)}px !important;
    flex-wrap: unset;
  }
`;

const SuggestionListItem = styled.li`
  font-size: 1.6rem;
`;

const SearchBarAdornmentButton = styled(Button)`
  && {
    background-color: ${p => p.theme.palette.blue[500]};
    padding: unset;
    margin: unset;
    box-shadow: none;
    height: 40px;
    width: 45px;
    min-width: unset;
    padding: unset;
    border-radius: ${p => p.theme.spacing(1)}px;
  }
`;

const SearchBarAdornmentIcon = styled(ApolloIcon)`
  color: white;

  && {
    font-size: ${p => p.theme.spacing(3.5)}px;
  }
`;

interface Props {
  engine: SearchEngine;
  searchBoxProps?: SearchBoxProps;
  id?: string;
  className?: string;
  onSearchCallback?: (
    event: React.ChangeEvent<{}>,
    value: unknown,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => void;
}

const SearchBarSection = (props: Props) => {
  const {
    engine,
    onSearchCallback,
    searchBoxProps = DEFAULT_SEARCH_PROPS,
    className = '',
    id,
  } = props;
  const { t } = useTranslation('common');

  if (id && searchBoxProps.options) {
    searchBoxProps.options.id = id;
  }

  const [ searchText, setSearchText ] = useState<string>(
    new URLSearchParams(window.location.hash.slice(1)).get(QUERY_TEXT_URL_KEY) ?? ''
  );
  const [ suggestionClicked, setSuggestionClicked ] = useState<{
    clicked: boolean;
    suggestion: string;
  }>({
    clicked: false,
    suggestion: '',
  });
  const searchBoxControllerRef = useRef(buildSearchBox(engine, searchBoxProps));
  const [ searchBoxState, setSearchBoxState ] = useState<SearchBoxState>(
    searchBoxControllerRef.current.state
  );
  const [ isInputError, setIsInputError ] = useState<boolean>(false);

  useEffect(() => {
    const updatedURLSearchParams = new URLSearchParams(
      window.location.hash.slice(1)
    );

    setSearchText(updatedURLSearchParams.get(QUERY_TEXT_URL_KEY) ?? '');
    setIsInputError(false);
  }, [ window.location.hash ]);

  useEffect(() => {
    const {
      clicked, suggestion,
    } = suggestionClicked;

    if (clicked && suggestion) {
      onSearch(suggestion);
      setSuggestionClicked({
        clicked: false,
        suggestion: '',
      });
    }
  }, [ suggestionClicked ]);

  const setAndSubscribeController = useCallback(() => {
    const cancelSubscription = searchBoxControllerRef.current.subscribe(() => {
      // do stuff with the suggestions
      setSearchBoxState(searchBoxControllerRef.current.state);
    });

    return () => {
      cancelSubscription && cancelSubscription();
    };
  }, []);

  useEffect(setAndSubscribeController, [ setAndSubscribeController ]);

  const debouncedControllerUpdateText = useCallback(
    debounce((value: string) => {
      searchBoxControllerRef.current.updateText(value);
    }, SEARCH_SUGGESTIONS_DELAY_MS),
    []
  );

  const onSearchTextChange = (
    e: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => {
    switch (reason) {
      case 'clear': {
        setSearchText(value);
        searchBoxControllerRef.current.updateText(value);
        setIsInputError(false);
        break;
      }
      case 'input': {
        setSearchText(value);
        value.length > 0
          ? debouncedControllerUpdateText(value)
          : searchBoxControllerRef.current.clear();
        setIsInputError(false);
        break;
      }
      default: {
        // When reason is 'reset'
        // Triggered when 'Enter' key is pressed.
        // Do nothing. let component handle enter
        break;
      }
    }
  };

  const onSearch = (autocompleteText?: string) => {
    const text: string = autocompleteText ?? searchText;
    if (text.length < SEARCH_MINIMUM_CHARACTER_LENGTH) {
      setIsInputError(true);
      return;
    }

    const updatedURLSearchParams = new URLSearchParams(
      window.location.hash.slice(1)
    );
    updatedURLSearchParams.set(QUERY_TEXT_URL_KEY, text);

    const updatedURLSearchParamsText = Array.from(updatedURLSearchParams.entries())
      .map(([ key, val ]) => `${key}=${val.split(',').map(encodeURIComponent)
        .join(',')}`)
      .join('&');

    pushNewHashToUrl(updatedURLSearchParamsText);
  };

  const autocompleteChange = (
    e: React.ChangeEvent<any>,
    value: any,
    reason: AutocompleteChangeReason
  ) => {
    const text = (value as Suggestion)?.rawValue;

    // Clearing text should not execute search
    // Have 'select-option' (which is the reason on 'Enter') handled
    // by setting the 'suggestionClicked' state and letting the 'useEffect'
    // and 'onAutocompleteKeyDown' handling the rest
    const EXCLUDED_REASONS: Set<AutocompleteChangeReason> = new Set([
      'clear',
      'create-option',
    ]);

    if (!EXCLUDED_REASONS.has(reason)) {
      onSearchTextChange(e, text, 'input');
      setSuggestionClicked({
        clicked: true,
        suggestion: text,
      });
    }
  };

  const onAutocompleteKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // Only run submit from 'searchText' if suggestion option wasn't clicked
    if (!suggestionClicked.clicked && e.key === KeyboardInputs.ENTER) {
      onSearch();
    }
  };

  return (
    <Container>
      <Search
        className={className}
        data-testid='SearchBarContainer'
      >
        <AutocompleteStyled
          freeSolo
          inputValue={searchText}
          onChange={onSearchCallback ? onSearchCallback : autocompleteChange}
          onInputChange={onSearchTextChange}
          options={searchBoxState.suggestions}
          getOptionLabel={(suggestion: any) => (
            (suggestion as Suggestion)?.rawValue ?? ''
          )}
          renderOption={(suggestion: any) => (
            <SuggestionListItem>
              {(suggestion as Suggestion).highlightedValue}
            </SuggestionListItem>
          )}
          onKeyDown={onAutocompleteKeyDown}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <SearchBarStyled
              {...params}
              error={isInputError}
              helperText={isInputError
                ? t('global_search_minimum_characters_error', 'Search term must be 3 characters or longer in length')
                : ''}
              variant={'outlined' as any}
              placeholder={t(
                'search_page_search_placeholder',
                'Search documents, discussions, and more across UiPath'
              )}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <SearchBarAdornmentButton
                    data-testid='SearchPageSearch__SubmitButton'
                    variant='contained'
                    onClick={() => onSearch()}
                  >
                    <SearchBarAdornmentIcon
                      icon='search'
                      className='search'
                    />
                  </SearchBarAdornmentButton>
                ),
              }}
            />
          )}
        />
      </Search>
    </Container>
  );
};

export default SearchBarSection;
