import type {
  Raw,
  SearchEngine,
} from '@coveo/headless';
import {
  buildSearchEngine,
  loadClickAnalyticsActions,
  loadContextActions,
} from '@coveo/headless';

import type { AnalyticsActionsPayload } from '../constants/search.constants';
import {
  DOCS_VERSION_SKIP_TAG_NAME,
  SEARCH_HUB_NAME,
  SEARCH_SOURCE,
  SearchAnalyticsActions,
  SearchResultSource,
} from '../constants/search.constants';
import { FacetFilterTypes } from '../pages/Search/Facets/types';
import CoveoUtil from './coveo';

type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export const headlessSearchEngine = (() => {
  let origin: string | undefined;

  try {
    if (process.env.REACT_APP_SEARCH_PUBLIC_ORIGIN) {
      origin = new URL(process.env.REACT_APP_SEARCH_PUBLIC_ORIGIN).origin;
    } else {
      throw new Error('Missing environment variable for search');
    }
  } catch (e) {
    console.error(e);
  }

  const coveoEngine = buildSearchEngine({
    configuration: {
      // organization id and access token will be replaced by relay service
      organizationId: '-',
      accessToken: '-',
      platformUrl: origin,
      preprocessRequest: (req, clientOrigin) => {
        const newHeaders: Headers = new Headers(req.headers);
        // delete authorization header
        newHeaders.delete('Authorization');
        req.headers = newHeaders;
        return req;
      },
      search: { searchHub: SEARCH_HUB_NAME },
      analytics: {
        analyticsClientMiddleware: (_eventType, payload) => {
          const {
            visitorId, sessionId, isUipathUser,
          } = CoveoUtil.getSessionData();
          payload.visitorId = visitorId;
          payload.customData = {
            ...payload.customData,
            sessionid: sessionId,
            is_internal_user: isUipathUser,
          };
          return payload;
        },
        enabled: true,
        originLevel3: origin,
      },
    },
  });

  // add context values for engine
  const { setContext } = loadContextActions(coveoEngine);
  const setContextAction = setContext({
    source: SEARCH_SOURCE,
    // TODO: add Guest user check when the logic is implemented
    userState: 'Registered',
  });

  coveoEngine.dispatch(setContextAction);
  return coveoEngine;
})();

export const logSearchAnalyticsEvent = <K extends SearchAnalyticsActions>(
  engine: SearchEngine,
  eventType: SearchAnalyticsActions,
  payload: PropType<AnalyticsActionsPayload, K>
) => {
  const { logDocumentOpen } = loadClickAnalyticsActions(engine);

  if (eventType === SearchAnalyticsActions.DOCUMENT_OPEN) {
    engine.dispatch(logDocumentOpen(payload));
  }
};

export const pushNewHashToUrl = (newHash: string) => {
  if (typeof window !== 'undefined') {
    const valueWithHash =
      newHash.length > 0 && newHash.startsWith('#') ? newHash : `#${newHash}`;

    window.location.replace(valueWithHash);
  }
};

/**
 *
 * @param facetValue raw facet value string
 * @param facetType the facet type
 * @returns processed facet value string to be used as a label.
 * Note that for content types, we further apply localization when rendering the component
 */
export const processFacetValue = (
  facetValue: string,
  facetType: FacetFilterTypes,
  rawQueryValues?: Raw,
) => {
  if (
    facetType === FacetFilterTypes.VERSION &&
    rawQueryValues &&
    isDocsSource(rawQueryValues[FacetFilterTypes.CONTENT_TYPE] ?? '')
  ) {
    facetValue = facetValue.split('|').every((val: string, index: number) => index === 0 ? !isNaN(+val) && +val < 10 : !isNaN(+val)) ? DOCS_VERSION_SKIP_TAG_NAME : facetValue.split('|').join('.');
  } else if (facetType === FacetFilterTypes.VERSION) {
    facetValue = facetValue.split('|').join('.');
  } else if (
    facetType === FacetFilterTypes.CONTENT_TYPE &&
    isDocsSource(facetValue)
  ) {
    facetValue = SearchResultSource.DOCS;
  }

  return facetValue;
};

export const isDocsSource = (sourceName: string) => {
  const docsPortalRegex = new RegExp(`^${SearchResultSource.DOCS.toLowerCase()}`);
  return docsPortalRegex.test(sourceName.toLowerCase());
};

export const isDocsSourceActive = () => {
  if (typeof window !== 'undefined') {
    const searchParams = new URLSearchParams(
      window.location.hash.slice(1)
    );

    const selectedSources = searchParams.get(`f-${FacetFilterTypes.CONTENT_TYPE}`);
    return !selectedSources || selectedSources.split(',').some(isDocsSource);
  }

  return false;
};
