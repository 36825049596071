// Components
import {
  Button,
  ClickAwayListener,
  Grow,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import * as styles from '../../../assets/css/CustomerPortal-BottomPageOptions';
import ArrowDown from '../../../assets/img/svg/arrows/Nav_Icon_ArrowDown_44x44.svg';
import { ReactComponent as LanguageIcon } from '../../../assets/img/svg/language.svg';
// Constants
import {
  getAllLanguageOptions,
  getValidLanguage,
  LANGUAGE_KEY_NAME,
  languageToLocale,
  Locale,
  localeToLanguage,
} from '../../../constants/localization.constants';
import { changeStoredLanguage } from '../../../utils/localization';

const BottomPageOptions = (props: { positionRelative: boolean }) => {
  const {
    t, i18n,
  } = useTranslation('common');
  const [ open, setOpen ] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const setPositionRelative: { [key: string]: string } = {
    position: 'relative',
    marginTop: '80px',
    marginBottom: '20px',
    marginLeft: '0px',
    marginRight: '0px',
    width: '400px',
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleChangeLanguage = (event: any) => {
    const selectedLanguage: string =
      event.target.getAttribute('data-lang') ?? localeToLanguage.get(Locale.en);
    const locale: string =
      languageToLocale.get(selectedLanguage)?.toString() || Locale.en;
    i18n.changeLanguage(locale);
    changeStoredLanguage(locale);
    handleClose();
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    switch (event.key) {
      case 'Tab':
        event.preventDefault();
        setOpen(false);
        break;
      case 'Escape':
        setOpen(false);
        break;
    }
  }

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem(LANGUAGE_KEY_NAME) ?? Locale.en);
    changeStoredLanguage(localStorage.getItem(LANGUAGE_KEY_NAME) ?? Locale.en);
  }, []);

  return (
    <styles.Container
      className="VerifyEmailPage__BottomOptions"
      data-testid="VerifyEmailPage__BottomOptions"
      style={props.positionRelative ? setPositionRelative : {}}
    >
      <div className="VerifyEmailPage__BottomOptions__languageSelector">
        <LanguageIcon />
        <Button
          className="VerifyEmailPage__BottomOptions__languageSelectorBtn"
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          data-testid="VerifyEmailPage__BottomOptions__LanguageButton"
        >
          <span>
            {localeToLanguage.get(
              getValidLanguage(localStorage.getItem(LANGUAGE_KEY_NAME))
            )}
          </span>
          <img
            src={ArrowDown}
            alt="dropdown arrow" />
        </Button>
        <Popper
          className="VerifyEmailPage__BottomOptions__languagesDropdown"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          data-testid="VerifyEmailPage__BottomOptions__LanguagesDropdown"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose as any}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {getAllLanguageOptions().map(
                      (language: string, idx: number) => (
                        <MenuItem
                          className={`VerifyEmailPage__BottomOptions__languageItem ${
                            language ===
                              localeToLanguage.get(
                                getValidLanguage(
                                  localStorage.getItem(LANGUAGE_KEY_NAME)
                                )
                              )
                              ? 'VerifyEmailPage__BottomOptions__languageItem--active'
                              : ''
                          }`}
                          key={`lang-${idx}`}
                          onClick={handleChangeLanguage}
                          data-lang={language}
                          data-testid="VerifyEmailPage__BottomOptions__LanguageItem"
                        >
                          {language}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className="VerifyEmailPage__BottomOptions__termsAndConditions">
        <Link
          style={{ textDecoration: 'none' }}
          href="https://www.uipath.com/hubfs/legalspot/Legal%20Terms/UiPath_General_Terms_of_Use.pdf"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="VerifyEmailPage__BottomOptions__Terms"
        >
          {t('footer_terms_of_use', 'Terms of Use')}
        </Link>
        <Link
          style={{ textDecoration: 'none' }}
          href="https://www.uipath.com/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          data-testid="VerifyEmailPage__BottomOptions__Privacy"
        >
          {t('footer_privacy_policy', 'Privacy Policy')}
        </Link>
      </div>
    </styles.Container>
  );
};

export default BottomPageOptions;
