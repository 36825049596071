import Cookies from 'js-cookie';

import { AUTH0_DOMAIN } from '../constants/auth.constants';
import { DOMAIN_PREFIX_TO_CLOUD_COOKIE_NAME_SUFFIX } from '../constants/cloud.constants';
import type { DotComLanguage } from '../constants/dotcom.constants';
import {
  DOT_COM_LANGUAGE_TO_LOCALE,
  LOCALE_TO_DOT_COM_LANGUAGE,
  NODE_ENV_TO_DOT_COM_COOKIE_NAME_SUFFIX,
} from '../constants/dotcom.constants';
import {
  LANGUAGE_COOKIE_DOMAIN_NAME,
  LANGUAGE_KEY_NAME,
  Locale,
} from '../constants/localization.constants';
import {
  getNodeEnv,
  isLocalhost,
} from '../lib/env.utils';

export type LocalizedString = { textKey: string; fallbackText: string };

const getCloudLoginLanguageCookieName = (): string => {
  const domainPrefix = AUTH0_DOMAIN.split(LANGUAGE_COOKIE_DOMAIN_NAME)[0];

  const cookieNamePrefix = DOMAIN_PREFIX_TO_CLOUD_COOKIE_NAME_SUFFIX.get(
    isLocalhost()
      ? 'local'
      : DOMAIN_PREFIX_TO_CLOUD_COOKIE_NAME_SUFFIX.has(domainPrefix)
        ? domainPrefix
        : 'id-alpha'
  );

  return `language-${cookieNamePrefix}`;
};

/**
 * Get the locale code of the selected language from the dropdown on the login page
 */
export const getCloudLoginLanguage = (): Locale => {
  const cookieValue = Cookies
    .withAttributes({
      domain: isLocalhost()
        ? 'localhost'
        : LANGUAGE_COOKIE_DOMAIN_NAME,
    })
    .get(getCloudLoginLanguageCookieName());

  for (const locale of Object.keys(Locale)) {
    if (cookieValue === locale.toLowerCase()) {
      return locale as Locale;
    }
  }

  return Locale.en;
};

/**
 * Set the locale code for the selected language on the login page
 */
const setCloudLoginLanguage = (language: Locale): void => {
  Cookies.set(
    getCloudLoginLanguageCookieName(),
    language.toLowerCase(),
    {
      domain: isLocalhost()
        ? 'localhost'
        : LANGUAGE_COOKIE_DOMAIN_NAME,
    }
  );
};

const getDotComLanguageCookieName = (): string => {
  const nodeEnv = getNodeEnv();

  const cookieNamePrefix = NODE_ENV_TO_DOT_COM_COOKIE_NAME_SUFFIX.get(
    NODE_ENV_TO_DOT_COM_COOKIE_NAME_SUFFIX.has(nodeEnv) ? nodeEnv : 'development'
  );

  return `locale-${cookieNamePrefix}`;
};

/**
 * Get the locale code of last localized page visited from UiPath.com
 */
export const getDotComLanguage = (): Locale => {
  const cookieValue = Cookies
    .withAttributes({ domain: LANGUAGE_COOKIE_DOMAIN_NAME })
    .get(getDotComLanguageCookieName());

  if (cookieValue && DOT_COM_LANGUAGE_TO_LOCALE.hasOwnProperty(cookieValue)) {
    return DOT_COM_LANGUAGE_TO_LOCALE[cookieValue as DotComLanguage];
  }

  return Locale.en;
};

/**
 * Set the locale code for UiPath.com
 */
const setDotComLanguage = (language: Locale): void => {
  Cookies.set(
    getDotComLanguageCookieName(),
    LOCALE_TO_DOT_COM_LANGUAGE[language],
    { domain: LANGUAGE_COOKIE_DOMAIN_NAME }
  );
};

export const changeStoredLanguage = (language: string) => {
  localStorage.setItem(LANGUAGE_KEY_NAME, language);
  setCloudLoginLanguage(language as Locale);
  setDotComLanguage(language as Locale);
};
