import { Container } from '@mui/material';
import axios from 'axios';
import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
// Translations
import { useTranslation } from 'react-i18next';
import {
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { axiosGet } from '../../client/axios';
import { fetchCompanyLicenses } from '../../client/licenses';
import ConfirmationModal from '../../components/ConfirmationModal';
// Google Analytics
import { CustomerPortalGoogleAnalyticsPageView } from '../../components/CustomerPortal-GoogleAnalytics';
// Components
import Loader from '../../components/CustomerPortal-Loader';
import CartPanel from '../../components/requestForQuote/CartPanel';
import RequestForQuoteBundleOptions from '../../components/requestForQuote/RequestForQuoteBundleOptions';
import {
  RFQ_CHANGE_CATEGORY,
  RFQ_CLICK_LICENSE_IN_CART_VIEW,
  RFQ_CONTINUE_SHOPPING,
  RFQ_CONTINUE_TO_CART_SUCCESS,
  RFQ_DISPLAY_ANNOUNCEMENT_MODAL,
  RFQ_LICENSE_NICKNAMES_LOADED,
  RFQ_REMOVE_1ST_LEVEL_LICENSE,
  RFQ_REMOVE_2ND_LEVEL_LICENSE,
  RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION,
  RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
  RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
} from '../../constants/requestForQuoteActions.constants';
import { RFQ as REQUEST_QUOTE } from '../../constants/telemetry.constants';
import {
  getAuthType,
  useAuth,
} from '../../contexts/auth';
import { useTrackPageViewEvent } from '../../lib/AppInsights/AppInsights';
import {
  getActiveBundles,
  getAvailableNewLicensesArr,
  getDeepCopyofProductsForAPI,
  getNonHAPOELAUTOBundles,
  getStandAloneOrchestratorBundles,
  handleAddLicenseToCart,
  handleAddOrchestratorToCart,
  handleOnPremOrchestratorChange,
  handleProductTypeChange,
  handleQuantityUpdate,
  handleRemoveLicenseFromCart,
  handleResetNewOrchestrator,
  handleSelectExistingOrchestratorDropdown,
  isCartProductsInvalid,
  trimSKUName,
} from '../../lib/requestForQuote.utils';
import type { SkuType } from '../../types/requestForQuote.types';
// styles
import * as styles from './../../assets/css/RequestForQuote/RequestForQuote';
import NoCompanyId from './../../components/CustomerPortal-NoCompanyId';
import BrowseLicenseContainer from './../../components/requestForQuote/BrowseProducts/CustomerPortal-RequestForQuote-BrowseLicenseContainer';
import Header from './../../components/requestForQuote/CustomerPortal-RequestForQuote-Header';
import { BACKEND_HOST_NAME } from './../../constants/network.constants';
// Constants
import RFQ from './../../constants/requestForQuote.constants';
import { UserPermissionsHelper } from './../../lib/UserPermissions';
import { StoreContext } from './../../store';

const RequestForQuote = (props: any) => {
  // Global state from Store
  const {
    state, dispatch,
  } = useContext(StoreContext);
  const { getAccessToken } = useAuth();
  const routerLocation = useLocation<any>();
  const routerHistory = useHistory();
  const { t } = useTranslation('common');

  const isCartEmpty = !state.rfqCart.products.length;
  const availableLicensesIds: string[] = getActiveBundles(
    state.companyLicenses?.boonLicense?.licenses || []
  ).map(license => license.licenseCode);
  // Check user permissions
  const isViewLicenseAllowed = UserPermissionsHelper.isViewLicenseAllowed();
  const isAddToCartAllowed = UserPermissionsHelper.isEditLicenseAllowed();

  // State
  const [ pageLoading, setPageLoading ] = useState(true);
  const [ licenses, setLicenses ] = useState<SkuType[]>([]);
  const [ buttonLoading, setButtonLoading ] = useState(false);
  const [ redirectToCart, setRedirectToCart ] = useState<boolean>(false);
  const [ continueButtonDisabled, setContinueButtonDisabled ] = useState(
    isCartEmpty || !isAddToCartAllowed
  );
  const confirmationModalInfo = state.rfqCart.announcementModalInfo;
  const confirmationModalMessage = confirmationModalInfo?.message;
  const openConfirmationModal = Boolean(confirmationModalMessage);

  // Handle create cart click
  const handleCreateCartClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setButtonLoading(true);
    // Get cart and products - transform for API call
    // If it came from a previous cart create attempt, will have _id that needs to be stripped
    const cartProducts = getDeepCopyofProductsForAPI(state.rfqCart.products);

    try {
      const res = await axios.post(
        `${BACKEND_HOST_NAME}/api/v1/rfq/cart`,
        { products: cartProducts },
        {
          headers: {
            Authorization: `Bearer ${await getAccessToken()}`,
            'x-selected-account': state.companyId,
            'x-auth-type': getAuthType(),
          },
        }
      );

      dispatch({
        type: RFQ_CONTINUE_TO_CART_SUCCESS,
        payload: {
          server_cart_id: res.data.id,
          products: res.data.products,
        },
      });
      setRedirectToCart(true);
    } catch (e) {
      setButtonLoading(false);
      dispatch({
        type: 'setBannerType',
        payload: 'error',
      });
      dispatch({
        type: 'setBannerMsg',
        payload: e.message,
      });
    }
  };

  /**
   * @param parentOrchId: The generated _id of orch
   * @param productId: The actual _id of license
   */
  const handleRemoveChildItem = (parentOrchId: string, productId: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    if (!parentOrchId || !productId) {
      return;
    }
    dispatch({
      type: RFQ_REMOVE_2ND_LEVEL_LICENSE,
      payload: {
        parent_id: parentOrchId,
        sku_id: productId,
      },
    });
  };

  /**
   *
   * @param cartProductId: The generated _id of the product in cart
   * @param productId : The sku_id of the product (actual license)
   */
  const handleRemoveTopLevel = (cartProductId?: string, productId?: string) => (
    event: React.MouseEvent<HTMLParagraphElement>
  ) => {
    event.stopPropagation();
    const payload = cartProductId
      ? { _id: cartProductId }
      : productId
        ? { sku_id: productId }
        : undefined;
    dispatch({
      type: RFQ_REMOVE_1ST_LEVEL_LICENSE,
      payload,
    });
  };

  /**
   * If changing quantity of 2nd level child, must pass in parentOrchId
   * @param productId: The sku_id of the product (actual license)
   * @param parentOrchId: The generated _id of orch in products array in cart
   */
  const handleQuantityChange = (productId: string, parentOrchId?: string) => (
    event: any // React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.stopPropagation();
    const quantity = parseInt(event.target.value) || 0;

    if (parentOrchId) {
      dispatch({
        type: RFQ_UPDATE_2ND_LEVEL_LICENSE_QUANTITY,
        payload: {
          parent_id: parentOrchId,
          sku_id: productId,
          quantity,
        },
      });
    } else {
      dispatch({
        type: RFQ_UPDATE_1ST_LEVEL_LICENSE_QUANTITY,
        payload: {
          sku_id: productId,
          quantity,
        },
      });
    }
    event.target.value = quantity;
  };

  const handlePanelLicenseClick = (cartProductId?: string) => (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    dispatch({
      type: RFQ_CLICK_LICENSE_IN_CART_VIEW,
      payload: { _id: cartProductId },
    });
  };

  // Set defaults if coming from Licenses page
  const setDefaultsIfRefLicensePage = () => {
    if (routerLocation.state) {
      const {
        category,
        existing_license_code,
        existing_license_name,
        existing_license_displayName,
      } = routerLocation.state;
      // If coming from an on-prem orch click
      if (existing_license_name && existing_license_code) {
        dispatch({
          type: RFQ_CHANGE_CATEGORY,
          payload: RFQ.productTypes.onPrem,
        });
        dispatch({
          type: RFQ_SELECT_EXISTING_ORCHESTRATOR_OPTION,
          payload: {
            type: 'Existing Orchestrator',
            existing_license_code,
            existing_license_name,
            existing_license_displayName,
          },
        });
      } else if (category === RFQ.productTypes.cloud) {
        // Otherwise a cloud orch click
        dispatch({
          type: RFQ_CHANGE_CATEGORY,
          payload: RFQ.productTypes.cloud,
        });
      }

      // Replace the state so refresh won't override other components
      routerHistory.replace({
        pathname: routerLocation.pathname,
        state: undefined,
      });
    } else if (state.hasCloudLicenses) {
      dispatch({
        type: RFQ_CHANGE_CATEGORY,
        payload: RFQ.productTypes.cloud,
      });
    }
  };

  const handleCloseAnnouncementModal = () => {
    dispatch({
      type: RFQ_DISPLAY_ANNOUNCEMENT_MODAL,
      payload: {},
    });
  };

  const handleConfirmAnnouncementModal = () => {
    const skuId = confirmationModalInfo?.sku_id;
    const skuName = confirmationModalInfo?.sku_name;
    const skuMinQty = confirmationModalInfo?.min_quantity;
    const skuProductCode = confirmationModalInfo?.product_code;
    if (skuId && skuName && skuMinQty && skuProductCode) {
      handleAddLicenseToCart(
        {
          _id: skuId,
          sku_name: skuName,
          min_quantity: skuMinQty,
          product_code: skuProductCode,
        },
        state.rfqCart.selectedCategory,
        dispatch,
        state.rfqCart.lastSelectedOrchestrator,
        true
      );
      handleCloseAnnouncementModal();
    }
  };

  useTrackPageViewEvent(REQUEST_QUOTE);

  /* Lifecycle */
  useEffect(() => {
    // Fetch Accounts Data
    const fetchCatalog = async () => {
      try {
        const licensesRes = await axiosGet(
          `${BACKEND_HOST_NAME}/api/v1/rfq/licenses?selection=active_rfqs`,
          state.companyId,
          await getAccessToken()
        );

        if (Array.isArray(licensesRes.data?.data)) {
          setLicenses(
            licensesRes.data.data.map((returnedSku: SkuType) => ({
              ...returnedSku,
              sku_name: trimSKUName(returnedSku.sku_name),
            }))
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    const getCompanyLicenses = async (companyId: string) => {
      try {
        const resultData: any = await fetchCompanyLicenses(
          companyId,
          await getAccessToken(),
          state.userId
        );

        return resultData.licenses ?? [];
      } catch (e) {
        console.log(`Unable to retrieve company licenses: ${e.message}`);
      }
    };

    setDefaultsIfRefLicensePage();
    Promise.all([ fetchCatalog(), getCompanyLicenses(state.companyId) ])
      .then(([ temp, customNamesAndNotes ]) => {
        if (
          Array.isArray(customNamesAndNotes) &&
          customNamesAndNotes.length > 0
        ) {
          // Update cart panel nickname
          dispatch({
            type: RFQ_LICENSE_NICKNAMES_LOADED,
            payload: customNamesAndNotes,
          });
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
    CustomerPortalGoogleAnalyticsPageView('Request Quote');

    window.addEventListener('beforeunload', handleCloseAnnouncementModal);

    // Cleanup - when unmount, reset lastSelectedOrch
    return () => {
      dispatch({
        type: RFQ_CONTINUE_SHOPPING,
        payload: null,
      });

      // Reset and close the announcement modal
      handleCloseAnnouncementModal();

      window.removeEventListener('beforeunload', handleCloseAnnouncementModal);
    };
  }, []);

  useEffect(() => {
    if (Array.isArray(licenses) && licenses.length > 0) {
      if (
        isCartEmpty ||
        !isAddToCartAllowed ||
        isCartProductsInvalid(
          state.rfqCart.products,
          licenses,
          availableLicensesIds
        )
      ) {
        setContinueButtonDisabled(true);
      } else {
        setContinueButtonDisabled(false);
      }
    }
  }, [ licenses, state.rfqCart.products ]);

  if (redirectToCart) {
    return <Redirect to="/request-quote/checkout" />;
  }

  /* Return if no company id selected */
  if (state.companyId === '') {
    return (
      <>
        <Helmet>
          <title>
            {t('rfq_page_title', 'Request Quote | Customer Portal')}
          </title>
        </Helmet>
        <NoCompanyId />
      </>
    );
  } else if (!isViewLicenseAllowed) {
    return <Redirect to="/unauthorized" />;
  } else if (pageLoading) {
    return (<styles.LoaderContainer>
      <Loader />
    </styles.LoaderContainer>);
  }

  return (
    <>
      <Helmet>
        <title>{t('rfq_page_title', 'Request Quote | Customer Portal')}</title>
      </Helmet>

      <styles.RequestForQuotePageContainer data-testid="RFQPageContainer">
        <styles.RequestForQuote>
          <Header
            pageTitle={t(RFQ.pageTitle.keyText, RFQ.pageTitle.fallbackText)}
            selectedProductType={state.rfqCart.selectedCategory}
            productTypesDisplayed={[
              {
                productType: RFQ.productTypes.onPrem,
                labelText: RFQ.productLabelText.onPrem,
              },
              {
                productType: RFQ.productTypes.automationSuite,
                labelText: RFQ.productLabelText.automationSuite,
              },
              {
                productType: RFQ.productTypes.cloud,
                labelText: RFQ.productLabelText.cloud,
              },
              {
                productType: RFQ.productTypes.standalone,
                labelText: RFQ.productLabelText.standalone,
              },
              {
                productType: RFQ.productTypes.services,
                labelText: RFQ.productLabelText.services,
              },
            ]}
            handleProductTypeChange={handleProductTypeChange}
          />

          {/* Select existing orchestrator */}
          {state.rfqCart.selectedCategory === RFQ.productTypes.onPrem && (
            <RequestForQuoteBundleOptions
              addToExistingBundleText={t(
                'rfq_add_existing_orchestrator_label',
                'Add products to one of your existing Orchestrators'
              )}
              newBundleRadioLabelText={t(
                'rfq_new_orchestrator_label',
                'Add new products to a new Orchestrator'
              )}
              noExistingBundlesText={t(
                'rfq_no_available_orchestrator_label',
                'No available orchestrators!'
              )}
              selectBundlePromptText={t(
                'rfq_select_orchestrator_label',
                'Select an Orchestrator'
              )}
              bundleType="orchestrator"
              availableNewBundles={licenses.filter(
                (license: any) =>
                  license.tag === RFQ.productTags.orchestrators.fallbackText
              )}
              existingBundles={getNonHAPOELAUTOBundles(
                getStandAloneOrchestratorBundles(state.companyLicenses?.boonLicense?.licenses)
              )}
              cartProducts={state.rfqCart.products}
              lastSelectedOrchestrator={state.rfqCart.lastSelectedOrchestrator}
              handleOnPremBundleChange={handleOnPremOrchestratorChange}
              handleAddBundleToCart={handleAddOrchestratorToCart}
              handleResetNewBundle={handleResetNewOrchestrator}
              handleSelectExistingBundleDropdown={
                handleSelectExistingOrchestratorDropdown
              }
            />
          )}

          {/* Browse Products */}
          <div
            className="RequestForQuote__browseProductsWrapper"
            data-testid="RequestForQuote__browseProductsWrapper"
          >
            <Container>
              {state.rfqCart.selectedCategory && (
                <BrowseLicenseContainer
                  licenses={getAvailableNewLicensesArr(
                    state.rfqCart.selectedCategory,
                    licenses,
                    state.rfqCart.lastSelectedOrchestrator,
                    false,
                    false
                  )}
                  selectedCategory={state.rfqCart.selectedCategory}
                  lastSelectedOrchestrator={
                    state.rfqCart.lastSelectedOrchestrator
                  }
                  cartProducts={state.rfqCart.products}
                  isAddToCartAllowed={isAddToCartAllowed}
                  requiresAutomationCloudBundle={false}
                  requiresAutomationSuiteBundle={false}
                  handleQuantityUpdate={handleQuantityUpdate}
                  handleRemoveLicenseFromCart={handleRemoveLicenseFromCart}
                  handleAddLicenseToCart={handleAddLicenseToCart}
                />
              )}
            </Container>
          </div>
        </styles.RequestForQuote>
        <CartPanel
          licenses={licenses}
          isCartEmpty={isCartEmpty}
          cartEmptyText={t(
            'rfq_cart_empty_label',
            `Your UiPath Request For Quote cart is empty. Select a few products to
          fill it up.`
          )}
          cartProducts={state.rfqCart.products}
          continueButtonDisabled={continueButtonDisabled}
          buttonLoading={buttonLoading}
          availableActiveOrchestratorsId={availableLicensesIds}
          invalidProducts={[]}
          handleCreateCartClick={handleCreateCartClick}
          handlePanelLicenseClick={handlePanelLicenseClick}
          handleRemoveTopLevel={handleRemoveTopLevel}
          handleRemoveChildItem={handleRemoveChildItem}
          handleQuantityChange={handleQuantityChange}
        />

        <ConfirmationModal
          modalHeading={t('warning', 'Warning')}
          primaryButtonText={t('confirm', 'Confirm')}
          secondaryButtontext={t('cancel', 'Cancel')}
          message={confirmationModalMessage || ''}
          open={openConfirmationModal}
          handleConfirm={handleConfirmAnnouncementModal}
          handleClose={handleCloseAnnouncementModal}
        />
      </styles.RequestForQuotePageContainer>
    </>
  ); // End of return
};

export default RequestForQuote;
