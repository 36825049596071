import { UserRole } from './roles.constants';

/**
 * All the types of super admins for UiPath users that can be set via the "Manage Admin Users" page
 */
export enum SuperAdminType {
  SUPER_ADMIN = 1,
  CONTENT_EDITOR = 2,
  SUPER_OPERATIONS = 3,
  SUPER_OPERATIONS_ADMIN = 4,
  TECHNICAL_ACCOUNT_MANAGER = 5,
  SPECIFIC_ACCOUNT_USER = 6,
  AUTOMATION_PROGRAM_ADVISOR = 7,
}

/**
 * Mapping of UiPath super admin types to their corresponding user roles
 */
export const SUPER_ADMIN_TYPE_TO_USER_ROLE: Record<SuperAdminType, UserRole> = {
  [SuperAdminType.SUPER_ADMIN]: UserRole.UI_SUPER_ADMIN,
  [SuperAdminType.CONTENT_EDITOR]: UserRole.UI_SUPER_CONTENT_EDITOR,
  [SuperAdminType.SUPER_OPERATIONS]: UserRole.UI_SUPER_OPERATIONS,
  [SuperAdminType.SUPER_OPERATIONS_ADMIN]: UserRole.UI_SUPER_OPERATIONS_ADMIN,
  [SuperAdminType.TECHNICAL_ACCOUNT_MANAGER]: UserRole.UI_TECHNICAL_ACCOUNT_MANAGER,
  [SuperAdminType.SPECIFIC_ACCOUNT_USER]: UserRole.UI_SPECIFIC_ACCOUNT_USER,
  [SuperAdminType.AUTOMATION_PROGRAM_ADVISOR]: UserRole.UI_AUTOMATION_PROGRAM_ADVISOR,
};

/**
 * List of all the UiPath user roles that should be able to see all companies
 */
export const USER_ROLES_THAT_SHOULD_SEE_ALL_COMPANIES: UserRole[] = Object.values(SuperAdminType)
  .filter(v => !isNaN(Number(v)))                 // 1. Filter out the enum keys
  .map(v => v as SuperAdminType)                  // 2. Cast the enum values to SuperAdminType
  .filter(v => (                                  // 3. Filter out the roles that should not see all companies (TAM, APA)
    ![
      SuperAdminType.TECHNICAL_ACCOUNT_MANAGER,
      SuperAdminType.AUTOMATION_PROGRAM_ADVISOR,
    ].includes(v)
  ))
  .map(v => SUPER_ADMIN_TYPE_TO_USER_ROLE[v]);    // 4. Return the corresponding user roles
